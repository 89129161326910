import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { HealthUnitProtocolRequest } from '../../requests/risk-classification/health-unit-protocol.request';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';

@Injectable({
  providedIn: 'root'
})
export class HealthUnitProtocolService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  createHealthUnitProtocol(healthUnitProtocolRequest: HealthUnitProtocolRequest): Observable<ListProtocolResponse>{
    let uri = `HealthUnitProtocol`
      
    return this.httpClient.post<ListProtocolResponse>(environment.urlApiRiskClassification + uri, healthUnitProtocolRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
