import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateMedicineTypeService } from 'src/app/shared/services/API/pharmacy/template-medicine-type.service';
import { TemplateManipulationService } from 'src/app/shared/services/API/pharmacy/template-manipulation.service';
import { TemplateModel } from 'src/app/shared/services/models/pharmacy/template.model';
import { MedicineTypeTemplateStruct } from 'src/app/shared/services/structs/pharmacy/medicine-type-template.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { TemplateTypeDeleteModalComponent } from './template-type-delete-modal/template-type-delete-modal.component';

@Component({
  selector: 'app-template-type',
  templateUrl: './template-type.component.html',
  styleUrls: ['./template-type.component.css']
})
export class TemplateTypeComponent implements OnInit {

  constructor(private templateMedicineTypeService: TemplateMedicineTypeService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private templateManipulationService: TemplateManipulationService,
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_template_type;
    
  public listMedicineType: MedicineTypeTemplateStruct[];
  public isLoading: boolean;
  public searchText: string;
  public idTemplate: number;
  public listTemplate: TemplateModel[];

  ngOnInit(): void {
    this.isLoading = true;
    this.listAllTemplate();
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
      this.searchText = null;
    
    if(!this.idTemplate)
      this.idTemplate = null;

    this.templateMedicineTypeService.listTemplateMedicineType(this.searchText, this.idTemplate).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listMedicineType = response.listMedicineType;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idMedicineType){
    const dialogRef = this.dialog.open(TemplateTypeDeleteModalComponent, {
      data: {
        idMedicineType: idMedicineType
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteMedicineType){
        this.search();
      }
    });
  }

  listAllTemplate(){
    this.templateManipulationService.GetAll(null, null).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listTemplate = response.listTemplate;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
