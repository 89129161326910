<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Integração</h1>
    <div class="white-body">
        <div class="filters">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <app-select-2 [multiple]="false" [url]="this.urlListHealthUnit"
                            [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" 
                            [formControlField]="'idHealthUnit'">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de Integração</mat-label>
                            <mat-select formControlName="idIntegrationType" (selectionChange)="integrationTypeChange()">
                                <mat-option *ngFor="let item of listIntegrationType" [value]="item.idIntegrationType">
                                    {{item.integrationTypeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>URL</mat-label>
                            <input matInput type="text" formControlName="url">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="form-field-toggle">
                            <mat-label>{{this.model.get('isActive').value ? 'Ativo' : 'Inativo'}}</mat-label>
                            <mat-slide-toggle formControlName="isActive"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="form-field-toggle">
                            <mat-label>Atualizar serviço local?</mat-label>
                            <mat-slide-toggle formControlName="sendMessage"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <textarea matInput type="text" formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Acesso ao conector local</mat-label>
                            <textarea  matInput type="text" formControlName="localAccess"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <app-relations *ngIf="integrationTypeHasValue" [formGroup]="model" [formArrayName]="'relation'"
                    [listVariableType]="listVariableType">
                </app-relations>
                <div class="row">
                    <div class="col-12 col-md-2">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                    <div class="col-12 col-md-2">
                        <a mat-stroked-button type="button" routerLink="/support/relation-config"
                            class="btn-secundary btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>