export class TokenAuthAdminModel {
    public idTokenAuthAdmin: number;
    public idUser: number;
    public userName: string;
    public healthUnitName: number;
    public idAdminUser: number;
    public idHealthUnit: number;
    public token: string;
    public ticket: string;
    public accessMotive: string;
    public dateTimeInclusion: Date;
    public dateTimeAccess: Date;
    public adminUserName: string;
}