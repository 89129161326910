<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Configurações</h1>
    <div class="white-body">
        <div class="col-6 col-sm-3">
            <a mat-button class="btn-primary add-user" routerLink="/billing/config-register"><mat-icon
                    aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar novo Billing</a>
        </div>
        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listBillingConfig != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listBillingConfig">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary"><b>[{{item.healthUnitName}}]</b></span>
                            <span class="secundary">[{{item.planName}}]</span>
                            <span class="secundary-status last-info">{{item.isActive? "Ativo":"Inativo"}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/billing/config-register', {idBillingConfig: item.idBillingConfig}]">
                                    edit</mat-icon>
                            </a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Excluir"
                                    (click)="openDeleteModal(item.idBillingConfig)">delete_forever</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-empty-list *ngIf="listBillingConfig != undefined && listBillingConfig.length == 0"></app-empty-list>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>