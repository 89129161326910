export class RelationTriggersProcedureSigtapStruct {
    public idTriggersProcedureSigtap: number;

    public triggersName: string;

    public procedureSigtapCboStructs: ProcedureSigtapStruct[];
}

export class ProcedureSigtapStruct {
    public idProcedureSigtap: number;

    public procedureName: string;

    public idProcedureSigtapCompetence: number;

    public codProcedure: string;

    public completeProcedureName: string;
}