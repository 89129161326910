export class HealthUnitDataRequest{
    
    public idHealthUnit: number;
    
    public idCoordination: number;
    
    public idSupervision: number;
    
    public cnes: string;
    
    public establishment: string;
}