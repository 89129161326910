import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyPassword } from 'src/app/shared/custom-validators/password.validator';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RecoverPasswordService } from 'src/app/shared/services/API/admin-user/recover-password.service';
import { RecoverPasswordRequest } from 'src/app/shared/services/requests/admin-user/recover-password.request';


@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {
  
  constructor(private alertService:AlertService, 
    private formBuilder: FormBuilder,
    private recoverPasswordService: RecoverPasswordService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }
    
    public isLoading:boolean;  
    public model: FormGroup;
    public urlToken: string;
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.auth;

    ngOnInit(): void {
      //this.alertService.show("Titulo", "Production: " + environment.production + " - Staging: " + environment.staging , AlertType.success);
      
      this.isLoading = false;
      
      this.model = this.formBuilder.group({
        newPassword: ['', [Validators.required, VerifyPassword()]],
        repetedPassword: ['', [Validators.required]],
      });

      this.activatedRoute.queryParams.subscribe(params => { 
        this.urlToken = params.token;

        this.verifyToken(this.urlToken);
      });
    }
    
    submit(){
      if(this.model.invalid)
        return;
      
      if(this.model.get('newPassword').value != this.model.get('repetedPassword').value){
        this.alertService.show('Erro', "Os dois campos de senha devem ser iguais", AlertType.error);
        return;
      }
        
      let body: RecoverPasswordRequest = {
        
        newPassword: this.model.get('newPassword').value,
        token: this.urlToken,
      }
      
      this.recoverPasswordService.recoverPassword(body).subscribe((response)=>{
     
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', 'Senha alterada com sucesso.' , AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/'])
      },
      (error)=>{
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      });
    }

    verifyToken(token: string){
      this.recoverPasswordService.verifyToken(token).subscribe((response)=>{
     
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
      },
      (error)=>{
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }
  }
  