<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list">
            <h3>{{this.data && this.data.deniesAllergies ? 'Realmente deseja retirar todos os principios ativos deste
                medicamento?!':'Deseja remover o principio ativo?' }} </h3>
        </div>
        <div class="form"(ngSubmit)="submit()">
            <div class="footer-confirm-modal-list">
                <button mat-flat-button color="primary" class="btn-block button-modal" type="submit" (click)="submit()">
                    <span>Sim, remover</span>
                </button>
                <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()"
                    type="button">Cancelar</button>
            </div>
        </div>
    </div>
</div>