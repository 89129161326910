import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { CoordinationRequest } from '../../requests/billing/coordination.request';
import { CoordinationResponse } from '../../responses/billing/coordination.response';
import { ListCoordinationResponse } from '../../responses/billing/list-coordination.response';

@Injectable({
  providedIn: 'root'
})
export class CoordinationService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  Get(idCoordination: number): Observable<CoordinationResponse>{
    let uri = `Coordination/idCoordination/${idCoordination}`;

    return this.httpClient.get<CoordinationResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  GetAll(searchText: string): Observable<ListCoordinationResponse>{
    let uri = `Coordination/getAll`;
    
    if(searchText)
      uri = uri + `?searchText=${searchText}`;

    return this.httpClient.get<ListCoordinationResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Create(request: CoordinationRequest): Observable<ReturnStruct>{
    let uri = `Coordination`;
  
    return this.httpClient.post<CoordinationResponse>(environment.urlApiBilling + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Update(idCoordination: number, request: CoordinationRequest): Observable<ReturnStruct>{
    let uri = `Coordination/idCoordination/${idCoordination}`;
  
    return this.httpClient.put<CoordinationResponse>(environment.urlApiBilling + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Delete(idCoordination: number): Observable<ReturnStruct>{
    let uri = `Coordination/idCoordination/${idCoordination}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
