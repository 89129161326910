import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenericAlertModalComponent } from 'src/app/shared/components/generic-alert-modal/generic-alert-modal.component';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { HealthUnitIntegrationRelationConfigService } from 'src/app/shared/services/API/integration-relation-config/health-unit-integration-relation-config.service';
import { OriginAndArrivalTypeService } from 'src/app/shared/services/API/medical-record/origin-and-arrival-type.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ArrivalTypeModel } from 'src/app/shared/services/models/medical-record/arrival-type.model';
import { OriginModel } from 'src/app/shared/services/models/medical-record/origin.model';
import { OriginAndArrivalTypeRequest } from 'src/app/shared/services/requests/medical-record/origin-and-arrival-type.request';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { ArrivalTypeStruct } from 'src/app/shared/services/structs/medical-record/arrival-type.struct';
import { OriginStruct } from 'src/app/shared/services/structs/medical-record/origin.struct';

@Component({
    selector: 'app-origin-and-arrival-type',
    templateUrl: './origin-and-arrival-type.component.html',
    styleUrls: ['./origin-and-arrival-type.component.css']
})
export class OriginAndArrivalTypeComponent implements OnInit {    
    constructor(private formBuilder: FormBuilder,
        private maskService: MaskService,
        private router: Router,
        public dialog: MatDialog, 
        private alertService: AlertService,
        private healthUnitService: HealthUnitService,
        private originAndArrivalTypeService: OriginAndArrivalTypeService,
        private healthUnitIntegrationRelationConfigService: HealthUnitIntegrationRelationConfigService
    ){ }
        
    public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
    public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_origin_arrival_type;
    
    public model: FormGroup;
    public isLoading: boolean;
    public isFirstLoading: boolean;
    
    public masks: Masks;
    public listHealthUnit: ListHealthUnitStruct[];
    public listOriginDefault: OriginModel[] = [];
    public listArrivalTypeDefault: ArrivalTypeModel[] = [];
    public listArrivalTypeNotDefaultDeleted: AbstractControl[] = [];
    public listOriginNotDefaultDeleted: AbstractControl[] = [];
    
    ngOnInit(): void {
        this.isLoading = false;
        
        this.masks = this.maskService.getMasks();
        
        this.populateOriginAndArrivalData();
        
        this.model = this.formBuilder.group({
            listArrivalTypeNotDefault: this.formBuilder.array([]),
            listOriginNotDefault: this.formBuilder.array([]),
        });
    }
    
    submit() {
        if (this.isLoading) {
            return;
        }
        if (!this.model.valid) {
            this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
            return;
        }
        
        this.isLoading = true;
        let originAndArrivalTypeRequest = new OriginAndArrivalTypeRequest();
        
        (this.model.controls['listOriginNotDefault'] as FormArray).controls.forEach(control => {
            let origin = new OriginStruct();

            origin.originNotDefault.idOrigin = control.value.idOrigin;
            origin.originNotDefault.originName = control.value.originName;
            origin.originNotDefault.isDeleted = control.value.isDeleted;
            origin.originNotDefault.dateTimeInclusion = control.value.dateTimeInclusion;
            origin.originNotDefault.showToEveryone = control.value.showToEveryone;
            origin.isCreate = control.value.isCreate;

            originAndArrivalTypeRequest.listOriginNotDefault.push(origin);
        });

        this.listOriginNotDefaultDeleted.forEach(control => {
            let origin = new OriginStruct();

            origin.originNotDefault.idOrigin = control.value.idOrigin;
            origin.originNotDefault.originName = control.value.originName;
            origin.originNotDefault.isDeleted = control.value.isDeleted;
            origin.originNotDefault.dateTimeInclusion = control.value.dateTimeInclusion;
            origin.originNotDefault.showToEveryone = control.value.showToEveryone;
            origin.isCreate = control.value.isCreate;
            
            originAndArrivalTypeRequest.listOriginNotDefault.push(origin);
        });

        (this.model.controls['listArrivalTypeNotDefault'] as FormArray).controls.forEach(control => {
            let arrivalType = new ArrivalTypeStruct();

            arrivalType.arrivalTypeNotDefault.idArrivalType = control.value.idArrivalType;
            arrivalType.arrivalTypeNotDefault.arrivalTypeName = control.value.arrivalTypeName;
            arrivalType.arrivalTypeNotDefault.isDeleted = control.value.isDeleted;
            arrivalType.arrivalTypeNotDefault.dateTimeInclusion = control.value.dateTimeInclusion;
            arrivalType.arrivalTypeNotDefault.showToEveryone = control.value.showToEveryone;
            arrivalType.isCreate = control.value.isCreate;

            originAndArrivalTypeRequest.listArrivalTypeNotDefault.push(arrivalType);
        });

        this.listArrivalTypeNotDefaultDeleted.forEach(control => {
            let arrivalType = new ArrivalTypeStruct();

            arrivalType.arrivalTypeNotDefault.idArrivalType = control.value.idArrivalType;
            arrivalType.arrivalTypeNotDefault.arrivalTypeName = control.value.arrivalTypeName;
            arrivalType.arrivalTypeNotDefault.isDeleted = control.value.isDeleted;
            arrivalType.arrivalTypeNotDefault.dateTimeInclusion = control.value.dateTimeInclusion;
            arrivalType.arrivalTypeNotDefault.showToEveryone = control.value.showToEveryone;
            arrivalType.isCreate = control.value.isCreate;

            originAndArrivalTypeRequest.listArrivalTypeNotDefault.push(arrivalType);
        });
       
        this.updateOriginAndArrival(originAndArrivalTypeRequest);
       
    }
    
    populateHealthUnitSelect() {
        this.healthUnitService.listHealthUnit().subscribe({
            next: (response) => {
                if (response.isError) {
                    this.alertService.show('Erro', response.errorDescription, AlertType.error);
                    this.isLoading = false;
                    return;
                }
                this.listHealthUnit = response.listHealthUnit;
                this.listHealthUnit.sort((a, b) => a.healthUnitName.localeCompare(b.healthUnitName));
                this.isLoading = false;
            },
            error: (error) => {
                console.log(error)
                this.isLoading = false;
                this.alertService.show('Erro inesperado', error, AlertType.error);
            }
        });
    }
    
    populateOriginAndArrivalData() {
        this.originAndArrivalTypeService.getOriginAndArrival().subscribe({
            next:(response) => {
                if (response.isError) {
                    this.alertService.show('Erro', response.errorDescription, AlertType.error);
                    this.isLoading = false;
                    return;
                }

                if(response.listArrivalTypeNotDefault && response.listArrivalTypeNotDefault.length > 0){
                    response.listArrivalTypeNotDefault?.forEach(x => {
                        (this.model.controls['listArrivalTypeNotDefault'] as FormArray).push(
                            this.formBuilder.group({
                                arrivalTypeName: new FormControl(x.arrivalTypeNotDefault.arrivalTypeName, Validators.required),
                                idArrivalType: new FormControl(x.arrivalTypeNotDefault.idArrivalType, Validators.required),
                                isDeleted: new FormControl(x.arrivalTypeNotDefault.isDeleted, Validators.required),
                                isCreate: new FormControl(x.isCreate, Validators.required),
                                dateTimeInclusion: new FormControl(x.arrivalTypeNotDefault.dateTimeInclusion, Validators.required),
                                showToEveryone: new FormControl(false)
                            })
                        )
                    })
                }              
                    
                response.listArrivalTypeDefault?.forEach(x => {
                    this.listArrivalTypeDefault.push(new ArrivalTypeModel(x));
                })
                    
                if(response.listOriginNotDefault && response.listOriginNotDefault.length > 0){
                    response.listOriginNotDefault?.forEach(x => {
                        (this.model.controls['listOriginNotDefault'] as FormArray).push(
                            this.formBuilder.group({
                                originName: new FormControl(x.originNotDefault.originName, Validators.required),
                                idOrigin: new FormControl(x.originNotDefault.idOrigin, Validators.required),
                                isDeleted: new FormControl(x.originNotDefault.isDeleted, Validators.required),
                                isCreate: new FormControl(x.isCreate, Validators.required),
                                dateTimeInclusion: new FormControl(x.originNotDefault.dateTimeInclusion, Validators.required),
                                showToEveryone: new FormControl(false)
                            })
                        )
                    })
                }
                        
                        
                response.listOriginDefault?.forEach(x => {
                    this.listOriginDefault.push(new OriginModel(x));
                })
                        
                this.isLoading = false;
            },
            error: (error) => {
                console.log(error)
                this.isLoading = false;
                this.alertService.show('Erro inesperado', error, AlertType.error);
            }
        });
    }
            
    updateOriginAndArrival(request: OriginAndArrivalTypeRequest) {
        this.originAndArrivalTypeService.createUpdateOriginAndArrivalType(request).subscribe({
            next: (response) => {                            
                if (response.isError && response.errorCode == 1) {
                    this.openGenericErrorMessageModal(response.errorDescription);
                    this.isLoading = false;
                    return;
                }
                else if (response.isError) {
                    this.alertService.show('Erro', response.errorDescription, AlertType.error);
                    this.isLoading = false;
                    return;
                }
                
                this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
                this.reloadComponent()
                this.isLoading = false;
                
            },
            error: (error) => {
                console.log(error)
                this.isLoading = false;
                this.alertService.show('Erro inesperado', error, AlertType.error);
            }
        });
    }

    openGenericErrorMessageModal(body: string){
        this.isLoading = true;
        const dialogRef = this.dialog.open(GenericAlertModalComponent, {
          data: {
            body: body
          },
          panelClass: 'generic-error',
        });
        
        dialogRef.afterClosed().subscribe(result => {
            this.isLoading = false;
            window.location.reload();
        });
    }

    reloadComponent(){
        const url= this.router.url;
        this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
            this.router.navigate([`/${url}`]).then(()=>{
            })
        })
    }
            
    createArrivalInput() {
        return this.formBuilder.group({
            arrivalTypeName: ['', Validators.required],
            isCreate: [true, Validators.required],
            isDeleted: [false, Validators.required]
        })
    }
            
    addNextArrival() {
        (this.model.controls['listArrivalTypeNotDefault'] as FormArray).push(this.createArrivalInput())
    }
    
    removeArrival(index: number) {
        var item: AbstractControl = (this.model.controls['listArrivalTypeNotDefault'] as FormArray).at(index);
        if(!item.value.isCreate){
            (this.model.controls['listArrivalTypeNotDefault'] as FormArray).at(index).value.isDeleted = true;
            this.listArrivalTypeNotDefaultDeleted.push((this.model.controls['listArrivalTypeNotDefault'] as FormArray).at(index));
        }
        
        (this.model.controls['listArrivalTypeNotDefault'] as FormArray).removeAt(index);   
    }
    
    createOriginInput() {
        return this.formBuilder.group({
            originName: ['', [Validators.required]],
            isCreate: [true, Validators.required],
            isDeleted: [false, Validators.required]
        })
    }
    
    addNextOrigin() {
        (this.model.controls['listOriginNotDefault'] as FormArray).push(this.createOriginInput())
    }
    
    removeOrigin(index: number) {
        var item: AbstractControl = (this.model.controls['listOriginNotDefault'] as FormArray).at(index);
        if(!item.value.isCreate){
            (this.model.controls['listOriginNotDefault'] as FormArray).at(index).value.isDeleted = true;
            this.listOriginNotDefaultDeleted.push((this.model.controls['listOriginNotDefault'] as FormArray).at(index));
        }
        
        (this.model.controls['listOriginNotDefault'] as FormArray).removeAt(index);   
    }

    routerListGroup() {
        this.router.navigate(['/origin-group']);
    }
}