import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelfTriageCounselingService } from 'src/app/shared/services/API/self-triage/self-triage-counseling.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { SelfTriageCounselingRequest } from 'src/app/shared/services/requests/self-triage/self-triage-counseling.request';

@Component({
  selector: 'app-self-triage-counseling-register',
  templateUrl: './self-triage-counseling-register.component.html',
  styleUrls: ['./self-triage-counseling-register.component.css']
})
export class SelfTriageCounselingRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private selfTriageCounselingService: SelfTriageCounselingService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_self_triage_counseling;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;

  public masks: Masks;
  public idSelfTriageCounseling: number;
  public selfTriageCounselingRequest: SelfTriageCounselingRequest;

  ngOnInit(): void {

    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      counseling: ['', [Validators.required]],
    });

    this.masks = this.maskService.getMasks();

    this.selfTriageCounselingRequest = new SelfTriageCounselingRequest();

    this.idSelfTriageCounseling == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idSelfTriageCounseling'))
      this.idSelfTriageCounseling = parseInt(this.activatedRoute.snapshot.paramMap.get('idSelfTriageCounseling'));

    if (this.idSelfTriageCounseling != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateCounselingData();
    }
  }

  submit() {
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.selfTriageCounselingRequest.counseling = this.model.get('counseling').value;
  
    this.selfTriageCounselingRequest.selfTriageCounselingName = this.model.get('name').value;

    if (this.isUpdate)
      this.updateCounseling();
    else
      this.createCounseling();
  }

  populateCounselingData() {
    this.selfTriageCounselingService.getCounseling(this.idSelfTriageCounseling).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.model.get('name').setValue(response.selfTriageCounseling.selfTriageCounselingName);
      this.model.get('counseling').setValue(response.selfTriageCounseling.counseling);

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateCounseling() {
    this.selfTriageCounselingService.updateCounseling(this.idSelfTriageCounseling, this.selfTriageCounselingRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/self-triage-counseling']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createCounseling() {
    this.selfTriageCounselingService.postCounseling(this.selfTriageCounselingRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/self-triage-counseling']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}