import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyDatasetName } from 'src/app/shared/custom-validators/dataSetName.validator';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DataSetService } from 'src/app/shared/services/API/data-mesh/datasets.service';
import { Masks } from 'src/app/shared/services/mask.service';
import { DataSetCreateRequest } from 'src/app/shared/services/requests/data-mesh/data-set-create.request';
import { QueryTestRequest } from 'src/app/shared/services/requests/data-mesh/query-test.request';


@Component({
  selector: 'app-data-set-register',
  templateUrl: './data-set-register.component.html',
  styleUrls: ['./data-set-register.component.css']
})
export class DataSetRegisterComponent {
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dataSetService: DataSetService,
  ) { }
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.datamesh;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.datamesh_analytics;
  public isLoading: boolean = false;
  public isLoadingSchema: boolean = false;
  public model: FormGroup;
  public masks: Masks;
  public idDataSet: number = null;
  public readOnly: boolean = false;
  public listDataBase: string[] = [];
  public schema: object = {};

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('idDataSet')) {
      this.idDataSet = parseInt(this.activatedRoute.snapshot.paramMap.get('idDataSet'));
      this.readOnly = true;
    }

    this.model = this.formBuilder.group({
      dataSetName: ['', [Validators.required, VerifyDatasetName()]],
      database: ['', [Validators.required]],
      query: ['', [Validators.required]],
      success: [false, [Validators.requiredTrue]],
    });
    this.listDatabases();
  }

  populateDataSet(idDataSet: number) {
    this.isLoading = true;
    this.dataSetService.getDataSetById(idDataSet).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        let dataSet = response.dataSet;
        if (dataSet == null)
          return;

        this.model.get('dataSetName').setValue(dataSet.dataSetName);
        this.model.get('query').setValue(dataSet.query);
        this.model.get('database').setValue(dataSet.database);
        this.getSchema(dataSet.database);
        this.model.disable();
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', 'Preencha todos os campos obrigatórios', AlertType.error);
      return;
    }

    this.isLoading = true;
    let dataSet = this.model.value;

    this.registerDataSet(dataSet);
  }

  registerDataSet(dataSet: any) {
    let request = new DataSetCreateRequest();

    request.dataSetName = dataSet.dataSetName;
    request.query = dataSet.query;
    request.database = dataSet.database;

    this.dataSetService.createDataset(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso!', "Origem de dados cadastrada com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/datamesh/data-sets']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listDatabases() {
    this.dataSetService.getDatabases().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        console.log(response)
        this.listDataBase = response.databases;

        if (this.readOnly)
          this.populateDataSet(this.idDataSet);
        else
          this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  queryTest() {
    this.isLoading = true;

    let dataSet = this.model.value;
    console.log(dataSet)
    let request = new QueryTestRequest();
    request.database = dataSet.database;
    request.query = dataSet.query;

    this.dataSetService.queryTest(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.model.get('success').setValue(false);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso!', "Query executada com sucesso", AlertType.success);
        this.model.get('success').setValue(true);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.model.get('success').setValue(false);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateQuery() {
    this.model.get('success').setValue(false);
  }

  getSchema(database: any) {
    console.log(database);
    this.isLoadingSchema = true;
    this.model.get('success').setValue(false);
    this.dataSetService.getDatabaseSchema(database).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoadingSchema = false;
          return;
        }
        this.schema = response.dbSchema;
        this.isLoadingSchema = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoadingSchema = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
