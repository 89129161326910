import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MessageRequest } from '../../requests/notification/message.request';
import { PostMessageRequest } from '../../requests/orchestrator-user/post-message.request';
import { GetMessageResponse } from '../../responses/notification/get-message.response';
import { MessageHistoryResponse } from '../../responses/notification/message-history.response';
import { UserSelectResponse } from '../../responses/user/user-select.response';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  createMessage(body: PostMessageRequest): Observable<ReturnStruct> {
    let uri = `Notification`

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorUser + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getHistory(datetimeStart: string, datetimeEnd: string): Observable<MessageHistoryResponse> {

    let uri = `Notification/getAll?start=${datetimeStart}&&end=${datetimeEnd}`;

      return this.httpClient.get<MessageHistoryResponse>(environment.urlApiOrchestratorUser + uri, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
  }
}
