import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportTypeStruct } from '../../services/structs/report/report-type.struct';
import { BtnReportTemplateListModalComponent } from './btn-report-template-list-modal/btn-report-template-list-modal.component';

@Component({
  selector: 'app-btn-report-template',
  templateUrl: './btn-report-template.component.html',
  styleUrls: ['./btn-report-template.component.css']
})
export class BtnReportTemplateComponent implements OnInit {

  @Input() public reportType: ReportTypeStruct;
  @Output() public idTemplateEvent = new EventEmitter<any>();

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openListModal() {
    const dialogRef = this.dialog.open(BtnReportTemplateListModalComponent, {
      data: {
        listTemplate: this.reportType.listTemplate,
        reportType: this.reportType
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.idTemplateEvent.emit({result: result, reportType: this.reportType});
      }
    });
  }
}
