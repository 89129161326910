export class TemplateBi {

    public idTemplateBi: number;
    
    public templateBiName: string;
    
    public templateBiDescription: string;
    
    public datetimeInclusion: Date;
    
    public isDeleted: boolean;
}