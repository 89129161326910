<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Faturamento Mensal Programado</h1>
    <div class="white-body">
        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Período</mat-label>
                            <mat-select [(ngModel)]="searchDate" (selectionChange)="search($event)">
                                <mat-option *ngFor="let item of listDate" [value]="item">{{item | date:"MMM/yyyy"}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>{{this.dayPeriod}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-sm-5 value-card">
                        <p class="price-title">Faturamento Mensal Programado</p>
                        <span *ngIf="totalValue == '0,00'" class="price-tag">R$ {{totalValue}}</span>
                        <span *ngIf="totalValue != '0,00'" class="price-tag">{{totalValue}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6 float-right">
                <a mat-button class="btn-primary" (click)="generateExcel()"><mat-icon aria-hidden="false"
                        aria-label="Plus">download</mat-icon> Exportar para planilha</a>
            </div>
        </div>

        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-3">

                    <mat-form-field appearance="outline">
                        <mat-label>Unidade de Saúde</mat-label>
                        <mat-select [(ngModel)]="idHealthUnit" (selectionChange)="search($event)">
                            <input class="inputSearchSelect" type="text" (keyup)="onKeySearch($event.target.value)"
                                (keydown)="$event.stopImmediatePropagation()" placeholder="Pesquisar"
                                autocomplete="off">
                                <mat-option [value]="default">SELECIONE</mat-option>
                            <mat-option *ngFor="let item of listHealthUnit" [value]="item.idHealthUnit">
                                {{item.healthUnitName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Plano</mat-label>
                        <mat-select [(ngModel)]="idBillingPlan" (selectionChange)="search($event)">
                            <mat-option (ngValue)="null">Selecione</mat-option>
                            <mat-option *ngFor="let item of listBillingPlan" [value]="item.idBillingPlan">
                                {{item.planName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="clean-filters" (click)="cleanFilters()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps" matTooltip="limpar filtro"
                        matTooltipPosition="above">cleaning_services</mat-icon>
                </div>
            </div>
        </div>
        <div *ngIf="listConsolidatedData && listConsolidatedData.length > 0" class="table-responsive table-height">
            <table class="table">
                <thead>
                    <tr>
                        <th class="billing-cell-width">Unidade de Saúde</th>
                        <th class="billing-cell-width">Plano</th>
                        <th class="billing-cell-width">Qtde. Atend. Presencial</th>
                        <th class="billing-cell-width">Total Atend. Realiz. Presencial</th>
                        <th class="billing-cell-width">Atendimentos Presenciais Extras</th>
                        <th class="billing-cell-width">Qtde. Atend. Online</th>
                        <th class="billing-cell-width">Total Atend. Realiz. Online</th>
                        <th class="billing-cell-width">Atendimentos Online Extras</th>
                        <th class="billing-cell-width">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="" *ngFor="let item of listConsolidatedData; let index = index;"
                        [style.background-color]="item.hasInconsistency? '#ffdbdb':'transparent'">
                        <td class="billing-cell-width" matTooltip="{{item.healthUnitName}}">
                            <span>{{item.healthUnitName}}</span>
                        </td>
                        <td class="billing-cell-width" matTooltip="{{item.billingPlanName}}">
                            <span class="table-plan">{{item.billingPlanName}}</span>
                        </td>
                        <td class="billing-cell-width">
                            <span>{{item.planPresential}}</span>
                        </td>
                        <td class="billing-cell-width" matTooltip="{{item.presentialHealthUnitAttendanceQuantity}}">
                            <span>{{item.presentialHealthUnitAttendanceQuantity}}</span>
                        </td>
                        <td class="billing-cell-width" matTooltip="{{item.extraAttendanceQuantity}}">
                            <span>{{item.extraPresentialAttendanceQuantity}}</span>
                        </td>
                        <td class="billing-cell-width">
                            <span>{{item.planRemote}}</span>
                        </td>
                        <td class="billing-cell-width">
                            <span>{{item.remoteRealthUnitAttendanceQuantity}}</span>
                        </td>
                        <td class="billing-cell-width">
                            <span>{{item.extraRemoteAttendanceQuantity}}</span>
                        </td>
                        <td class="billing-cell-width cell-status-select">
                            <mat-form-field appearance="outline">
                                <mat-label>Plano</mat-label>
                                <mat-select [value]="item.idBillingStatus"
                                    (selectionChange)="updateMonthlyBillingStatus(item.idMonthlyProgrammedBilling, $event)">
                                    <mat-option *ngFor="let status of listBillingStatus"
                                        [value]="status.idBillingStatus">
                                        {{status.billingStatusName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ngContainer *ngIf="this.jsonDataTopUnits && this.jsonDataTopUnits.length >0">
            <div class="title-header">
                <h1>Top Unidades</h1>
            </div>
            <div class="charts-container">
                <ngx-charts-bar-vertical-2d [scheme]="colorScheme" [results]="jsonDataTopUnits" [xAxis]="showXAxis"
                    [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                    [roundEdges]="false" [legend]="true" [legendTitle]="null" [showDataLabel]="true" [gradient]="true"
                    [noBarWhenZero]="false" (select)="onSelect($event)" (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)">
                </ngx-charts-bar-vertical-2d>
            </div>
        </ngContainer>

        <ngContainer *ngIf="this.jsonDataTopProducts && this.jsonDataTopProducts.length >0">
            <div class="title-header">
                <h1>Top Produtos</h1>
            </div>
            <div class="charts-container">
                <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="jsonDataTopProducts" [xAxis]="showXAxis"
                    [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                    [roundEdges]="false" [legend]="true" [legendTitle]="null" [showDataLabel]="true" [gradient]="true"
                    [noBarWhenZero]="false" (select)="onSelect($event)" (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)">
                </ngx-charts-bar-vertical>
            </div>
        </ngContainer>

        <app-empty-list *ngIf="listBillingPlan != undefined && listBillingPlan.length == 0"></app-empty-list>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>