import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { BillingStatusRequest } from '../../requests/orchestrator-billing-by-health-unit/billing-status.request';
import { MonthlyProgrammedBillingResponse } from '../../responses/orchestrator-billing-by-health-unit/monthly-programmed-billing.response';
import { PostExcelReportResponse } from '../../responses/orchestrator-billing-by-health-unit/post-excel-report.response';

@Injectable({
  providedIn: 'root'
})
export class MonthlyProgrammedBillingService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listBillingPlan(date: Date, idHealthUnit?: number, idPlan?: number): Observable<MonthlyProgrammedBillingResponse> {
    let uri = `MonthlyProgrammedBilling?`;

    if(idHealthUnit)
      uri += `idHealthUnit=${idHealthUnit}&`;

    if(idPlan)
      uri += `idPlan=${idPlan}&`;

    return this.httpClient.post<MonthlyProgrammedBillingResponse>(environment.urlApiOrchestratorBillingByHealthUnit + uri, date, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  postMonthlyBillingExcel(date: Date): Observable<PostExcelReportResponse> {
    let uri = `MonthlyProgrammedBilling/excel`

    return this.httpClient.post<PostExcelReportResponse>(environment.urlApiOrchestratorBillingByHealthUnit + uri, date, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  putMonthlyBillingStatus(idMonthlyProgrammedBilling: number, body: BillingStatusRequest): Observable<ReturnStruct> {
    let uri = `MonthlyProgrammedBilling/idMonthlyProgrammedBilling/${idMonthlyProgrammedBilling}`;

    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorBillingByHealthUnit + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}