<div class="container-out" (click)="close()" *ngIf="isVisible == true"></div>

<div class="container-modulo" *ngIf="isVisible == true">
    <h5>Módulo atual</h5>
    <h4>{{this.actualModuleName}}</h4>
    
    <div class="action">
        <ul *ngIf="listModuleStruct != null && listModuleStruct != undefined">
            <li *ngFor="let item of listModuleStruct">
                <a (click)="redirect(item)">
                    <span>{{item.nameModule}}</span>
                    <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                </a>
            </li>
        </ul>
    </div>
</div>