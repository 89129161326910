<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/support/key">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Emerges
    </a>
    <h1>Gerar Chave</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome da Unidade de Saúde</mat-label>
                        <mat-select formControlName="idEmerges">
                            <mat-option *ngFor="let item of listEmerges" [value]="item.idEmerges">
                                {{item.idHealthUnit? item.idHealthUnit + '-' : ''}} {{item.healthUnitName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('idEmerges').invalid">Informe o nome da unidade de saúde</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Data de expiração</mat-label>
                        <input matInput type="text" formControlName="datetimeExpiration"
                            [mask]="masks.date">
                        <mat-error *ngIf="model.get('datetimeExpiration').invalid">Informe a data de expiração
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>


            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Gerar chave</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/support/key" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>