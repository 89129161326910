import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { QuestionService } from 'src/app/shared/services/API/self-triage/question.service';


@Component({
  selector: 'app-self-triage-discriminator-delete-modal',
  templateUrl: './self-triage-discriminator-delete-modal.component.html',
  styleUrls: ['./self-triage-discriminator-delete-modal.component.css']
})
export class SelfTriageDiscriminatorDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<SelfTriageDiscriminatorDeleteModalComponent>,
    private questionService: QuestionService,
    private alertService: AlertService,
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteQuestion: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.questionService.deleteQuestion(this.data.idQuestion).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Pergunta excluída com sucesso!", AlertType.success); 
      this.matDialogRef.close({deleteQuestion: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteQuestion: false}); 
  }
}