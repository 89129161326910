<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Chaves</h1>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/support/key-register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>Gerar chave</a>
        <div class="filters">
            <div class="row">   
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>

            </div>
        </div>
        <div class="card-list" *ngIf="listKey != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listKey">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.healthUnitName}}</span>
                            <span class="secundary">Chave: {{item.keyCode}}</span>
                            <span class="secundary">Data da expiração: {{item.datetimeExpiration | date: 'dd/MM/yyyy'}}</span>
                            <span class="secundary last-info">{{item.idUser}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idKey)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listKey != undefined && listKey.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>