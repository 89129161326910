import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DeviceTypeService } from 'src/app/shared/services/API/device/device-type/device-type.service';
import { DeviceVisualizationService } from 'src/app/shared/services/API/device/device/device-visualization.service';
import { ListHealthUnitService } from 'src/app/shared/services/API/user/list-health-unit.service';
import { DeviceType } from 'src/app/shared/services/models/device/device-type.model';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { ListedDeviceStruct } from 'src/app/shared/services/structs/device/listed-device.struct';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit {

  constructor(private alertService: AlertService,
    private listHealthUnitService: ListHealthUnitService,
    private deviceService: DeviceVisualizationService,
    private deviceTypeService: DeviceTypeService) { }
    
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.device_list;

  public isLoading: boolean;

  public idHealthUnit: number;
  public idDeviceType: number;
  public assetNumber: string;
  public isOnline: boolean;

  public listDevices: ListedDeviceStruct[];
  public listDeviceType: DeviceType[];
  public listHealthUnit: ListHealthUnitStruct[];

  ngOnInit(): void {
    this.populateHealthUnitSelect(null);
    this.populateDeviceTypeSelect();
    this.search();
  }

  search() {
    this.isLoading = true;

    if (this.assetNumber == "")
      this.assetNumber = null;

    this.deviceService.listAllDevices(this.idHealthUnit, this.idDeviceType, this.assetNumber, this.isOnline).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listDevices = response.listDeviceStruct;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  buttonSearch() {
    if(this.isLoading)
      return;

    this.search();
  }

  populateHealthUnitSelect(searchText: string) {

    if(searchText == "")
      searchText = null;

      this.listHealthUnitService.listHealthUnit(searchText, null).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHealthUnit = response.list;
      this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateDeviceTypeSelect() {
    this.deviceTypeService.listAllDevices().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listDeviceType = response.listDeviceType;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getHealthUnitName(idHealthUnit: number) : string {
    if(idHealthUnit == -1)
      return "Admin";

    if(!this.listHealthUnit || this.listHealthUnit.length <= 0)
      return "";

    return this.listHealthUnit.find(l => l.idHealthUnit == idHealthUnit)?.healthUnitName;
  }

  public getIsOnline(isOnline: boolean) : string {
    return isOnline ? "Online" : "Offline";
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.populateHealthUnitSelect(event);
      }
    }, 1000);
  }

}