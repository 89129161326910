import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { HealthUnitDataRequest } from '../../requests/billing/health-unit-data.request';

@Injectable({
  providedIn: 'root'
})
export class HealthUnitDataService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  saveHealthUnitData(body: HealthUnitDataRequest): Observable<ReturnStruct>{
    let uri = `HealthUnitData`
      
    return this.httpClient.put<ReturnStruct>(environment.urlApiBilling + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
