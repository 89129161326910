<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consultation/health-unit">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Cadastrar procedimentos</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Data de compensação</mat-label>
                        <input matInput (change)="dateCompensationChange()" type="text" [mask]="masks.monthYear" formControlName="dateCompensation">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome do usuário</mat-label>
                        <input matInput type="text" formControlName="userName">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Observação</mat-label>
                        <input matInput type="text" formControlName="observation">
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!isReading" class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <ngx-mat-file-input formControlName="fileProcedure" placeholder="Upload Procedimentos"
                            [accept]="'.txt'" (change)="inputChangeProcedure($event)">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <ngx-mat-file-input formControlName="fileCBO" placeholder="Upload CBO"
                            [accept]="'.txt'" (change)="inputChangeCBO($event)">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <button mat-button type="submit" class="btn-primary btn-block" (click)="isValidate=2">
                        <span *ngIf="isLoading == false">Validar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
            </div>

            <div class="erroBox" *ngIf="procedureSigtapStruct != null && procedureSigtapStruct.length > 0">
                <div class="title-header">
                    <h1>Procedimentos</h1>
                </div>
                <cdk-accordion class="profile-register-accordion">
                    <cdk-accordion-item *ngFor="let item of procedureSigtapStruct; let index = index;"
                        #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItemModule.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                        <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                            class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                            ({{ item.codProcedure }} - {{ item.procedureName }})
                            <span
                                [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                                class="profile-register-accordion-item-description">
                                Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                            </span>
                        </div>
                        <div class="profile-register-accordion-item-body triage-body" role="region"
                            [style.display]="accordionItemModule.expanded ? '' : 'none'"
                            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
                            <div class="row">
                                <h4>{{item.cbo.join(", ")}}</h4>
                            </div>
                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>
            </div>
            <div class="card-list"  *ngIf="listErro.length > 0">
                <h2>Lista de erros</h2>
                <div class="row erroBox">
                    <div *ngFor="let item of listErro" class="col-12 col-md-6">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isReading" class="row">
                <div class="col-md-2">
                    <a mat-stroked-button type="button" (click)="openModalTrigger()" class="btn-secundary btn-block">
                        Adicionar Triggers
                    </a>
                </div>
            </div>
            <div class="card-list" *ngIf="isReading && listRelationTriggers != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listRelationTriggers; let index = index;">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.triggersName}}</span>
                            </div>
                            <div class="actions">
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModalTrigger(index)">edit</mat-icon> </a>
                                <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="deleteTrigger(index)">delete_forever</mat-icon> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="!isReading" class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block" (click)="isValidate=1">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <p class="loading-warn" *ngIf="isLoading">Essa operação pode demorar alguns minutos, aguarde!</p>
                </div>
                <div *ngIf="isReading" class="col-md-2">
                    <a mat-stroked-button type="button" class="btn-primary btn-block" (click)="saveTriggers()">
                        <span *ngIf="isLoading == false">Salvar Triggers</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </a>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/billing-sigtap"
                        class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>