import { E } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { LogBatchStatusEnum } from 'src/app/shared/enum/log-batch-status.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BatchConfigurationVisualizerService } from 'src/app/shared/services/API/srvlog/batch-configuration-visualizer.service';
import { BatchMonitoringService } from 'src/app/shared/services/API/srvlog/batch-monitoring.service';
import { LogBatchStatusService } from 'src/app/shared/services/API/srvlog/log-batch-status.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { LogBatchMessage } from 'src/app/shared/services/models/srvlog/log-batch-message.model';
import { LogBatchStatus } from 'src/app/shared/services/models/srvlog/log-batch-status.model';
import { LogBatch } from 'src/app/shared/services/models/srvlog/log-batch.model';
import { BatchMonitoringResponse } from 'src/app/shared/services/responses/srvlog/batch-monitoring.response';
import { GetBatchConfigurationStruct } from 'src/app/shared/services/structs/srvlog/get-batch-configuration.struct';
import { GraphOptions } from 'src/app/shared/structs/graph-options.struct';
import { DetailsModalComponent } from './details-modal/details-modal.component';
import { seriesGraph } from 'src/app/shared/services/structs/orchestrator-billing-by-health-unit/bar-graph.struct';
import { timestamp } from 'rxjs';

enum GraphControlEnum {
  executionHistogram = 1,
  executionHistory = 2
}

@Component({
  selector: 'app-log-batch-monitoring-details',
  templateUrl: './log-batch-monitoring-details.component.html',
  styleUrls: ['./log-batch-monitoring-details.component.css']
})

export class LogBatchMonitoringDetailsComponent implements OnInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private batchMonitoringService: BatchMonitoringService,
    private batchConfigurationVisualizerService: BatchConfigurationVisualizerService,
    private logBatchStatusService: LogBatchStatusService,
    private router: Router,
    private alertService: AlertService,
    private maskService: MaskService,
    private formBuilder: FormBuilder
  ) { }

  public title: string = 'Monitoramento de Batch'
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.developer_batch_configuration;

  public model: FormGroup;
  public masks: Masks;
  public isLoading: boolean;
  public isFirstLoading: boolean;

  public idBatchConfig: number;
  public batchConfig: GetBatchConfigurationStruct;
  public batchMonitoringResponse: BatchMonitoringResponse;
  public listLogBatchStatus: LogBatchStatus[] = [];
  public fullListSize: number;
  public results = new Array();
  public statusResults = [];
  public performanceHistogramResults = [];
  public performanceHistoryResults = [];
  public performanceHistoryLineChartResults: object[] = [];

  public referenceLines = [{
    "name": "Média",
    "value": 0
  }];

  //Graph options
  public statusColorScheme = {
    domain: ["#FFC200", "#81C433", "#FF6700", "#FF0000"]
  };
  public performanceColorScheme = {
    domain: ['#05adf5']
  };

  public view: any[] = [600, 400];

  public renderGraph: boolean = false;

  public statusGraphOptions: GraphOptions = new GraphOptions(
    true, //showDataLabel
    true, //legend
    'below', //legendPosition
    true, //showXAxis
    true, //showYAxis
    false, //gradient
    false, //showLegend
    false, //showXAxisLabel
    false, //showYAxisLabel
    "", //legendTitle
    'Status',//xAxisLabel
    'Contagem',//yAxisLabel
    10000, //yScaleMax
    0, //yScaleMin
    this.statusColorScheme,
    [400, 300]
  );

  public performanceGraphOptions: GraphOptions = new GraphOptions(
    true, //showDataLabel
    false, //legend
    'below',//legendPosition
    true, //showXAxis
    true, //showYAxis
    false, //gradient
    false, //showLegend
    false, //showXAxisLabel
    false, //showYAxisLabel
    "", //legendTitle
    'Tempo de execução',//xAxisLabel
    'Contagem',//yAxisLabel
    10000, //yScaleMax
    0, //yScaleMin
    this.performanceColorScheme,
    [800, 300]
  );

  public historyGraphOptions: GraphOptions = new GraphOptions(
    false, //showDataLabel
    true, //legend
    'below',//legendPosition
    true, //showXAxis
    true, //showYAxis
    false, //gradient
    false, //showLegend
    true, //showXAxisLabel
    true, //showYAxisLabel
    "", //legendTitle
    'Data',//xAxisLabel
    'Tempo (s)',//yAxisLabel
    1000, //yScaleMax
    0, //yScaleMin
    this.performanceColorScheme,
    [800, 300],
    [],
    this.formatXAxisTicks,
  );

  public graphControl: GraphControlEnum = GraphControlEnum.executionHistogram;
  public graphControlHistogram: GraphControlEnum = GraphControlEnum.executionHistogram;
  public graphControlHistory: GraphControlEnum = GraphControlEnum.executionHistory;

  ngOnInit(): void {
    this.isLoading = false;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      datetimeStart: [null],
      datetimeEnd: [null],
      searchText: [""],
      status: [null]
    });

    this.idBatchConfig = parseInt(this.activatedRoute.snapshot.paramMap.get('idBatchConfig'));
    if (this.idBatchConfig && !isNaN(this.idBatchConfig)) {
      this.populateStatusSelect();
      this.getBatchConfigData();
      this.getProjectLogs();
    }
    else {
      this.router.navigate([`/developer/log-batch-monitoring`, []]);
    }
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

  getBatchConfigData() {
    this.isLoading = true;
    this.batchConfigurationVisualizerService.getBatch(this.idBatchConfig).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.batchConfig = response.batchConfigurationStruct;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getProjectLogs(page: number = 0) {
    this.isLoading = true;
    this.renderGraph = false;

    let searchText = this.model.get('searchText')?.value;
    let status = this.model.get('status')?.value;
    let startDate: Date = this.model.get('datetimeStart')?.value;
    let endDate: Date = this.model.get('datetimeEnd')?.value;

    this.batchMonitoringService.getProjectLogs(
      this.idBatchConfig,
      status,
      searchText,
      startDate?.toISOString(),
      endDate?.toISOString(),
      page,
    ).subscribe(
      {
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.statusResults = [];
          this.performanceHistogramResults = [];

          this.batchMonitoringResponse = response;
          this.fullListSize = response.fullListSize;
          Object.keys(response.statusCounts).forEach((key, index) => {
            let label: string = ""
            switch (key) {
              case (LogBatchStatusEnum.Processing.toString()):
                label = "Processando";
                break;
              case (LogBatchStatusEnum.Success.toString()):
                label = "Sucesso";
                break;
              case (LogBatchStatusEnum.Warning.toString()):
                label = "Aviso";
                break;
              case (LogBatchStatusEnum.Error.toString()):
                label = "Erro";
                break;
            }

            this.statusResults.push({ name: label, value: response.statusCounts[key] })
            let result = this.statusResults.find(x => x.name == label);

            if (result && result.value > this.statusGraphOptions.yScaleMax) {
              this.statusGraphOptions.yScaleMax = result.value;
            }

          });

          Object.keys(response.executionTimes).forEach((key, index) => {
            this.performanceHistogramResults.push({ name: key + 's', value: response.executionTimes[key] })
            let result = this.performanceHistogramResults.find(x => x.name == key);

            if (result && result.value > this.performanceGraphOptions.yScaleMax) {
              this.performanceGraphOptions.yScaleMax = result.value;
            }
          });
          this.historyGraphOptions.xAxisLabel = `${response.executionHistory[0]['item1']} - ${response.executionHistory[response.executionHistory.length - 1]['item1']}`;

          let currentDate: Date = null;
          response.executionHistory.forEach((key, index) => {
            const timeStamp: string = key['item1'];
            const value = key['item2'];

            let label = "";
            let incomingDate = this.formatDate(timeStamp);

            if (!currentDate || ((currentDate.getDate() != incomingDate.getDate() || currentDate.getMonth() != incomingDate.getMonth())))
              label = incomingDate.toLocaleDateString();

            currentDate = incomingDate;

            // this.historyGraphOptions.xAxisTicks.splice(0, 0, { value: label, offset: 10 });

            this.performanceHistoryResults.push({ name: `${index}`, value: value })

            let result = this.performanceHistoryResults.find(x => x.name == key);

            if (result && result.value > this.performanceGraphOptions.yScaleMax) {
              this.performanceGraphOptions.yScaleMax = result.value;
            }
          });

          this.renderGraph = true;
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      }
    )
  }

  populateStatusSelect() {
    this.logBatchStatusService.listLogBatchStatus().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listLogBatchStatus = response.listLogBatchStatus;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changePage(event: PageEvent) {
    this.getProjectLogs(event.pageIndex);
  }

  dataAtualFormatada(date: Date) {
    if (date) {
      var data = new Date(date)
      return data.toLocaleString('pt-br');
    }
    return null;
  }

  graphClickListener(event: any) {
    console.log(event);
  }

  openDetailsModal(item: LogBatch) {
    const dialogRef = this.dialog.open(DetailsModalComponent, {
      data: {
        logBatch: item
      },
    });
  }

  graphSwitch(graphType: GraphControlEnum) {
    this.graphControl = graphType;
  }

  formatXAxisTicks(ticks: number): string {
    if (this.performanceHistoryResults &&
      (ticks == this.performanceHistoryResults[0]['name'] ||
        ticks == this.performanceHistoryResults[this.performanceHistoryResults.length - 1]['name'])) {
      const result = new Date(ticks).toLocaleDateString('pt-br');
      return result;
    }
    else {
      const result = new Date(ticks).toLocaleDateString('pt-br');
      return result;
    }
  }

  formatDate(dateString: string): Date {
    var parts = dateString.split(" ");
    var date = parts[0].split("/");
    var time = parts[1].split(":");

    const day = parseInt(date[0], 10);
    const month = parseInt(date[1], 10) - 1;
    const year = parseInt(date[2], 10);

    const hours = parseInt(time[0], 10);
    const minutes = parseInt(time[1], 10);
    const seconds = parseInt(time[2], 10);

    return new Date(year, month, day, hours, minutes, seconds);

  }
}
