import { NgModule } from '@angular/core';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { DeveloperComponent } from './pages/developer/developer.component';
import { LogVisualizerListComponent } from './pages/log-visualizer-list/log-visualizer-list.component';
import { LogVisualizerViewComponent } from './pages/log-visualizer-view/log-visualizer-view.component';
import { ExternalLogsVisualizerListComponent } from './pages/external-logs-visualizer-list/external-logs-visualizer-list.component';
import { LogBatchVisualizerListComponent } from './pages/log-batch-visualizer-list/log-batch-visualizer-list.component';
import { LogBatchVisualizerViewComponent } from './pages/log-batch-visualizer-view/log-batch-visualizer-view.component';
import { BatchConfigListComponent } from './pages/batch-config-list/batch-config-list.component';
import { BatchConfigRegisterComponent } from './pages/batch-config-register/batch-config-register.component';
import { MatInputModule } from '@angular/material/input'; import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CacheCleaningComponent } from './pages/cache-cleaning/cache-cleaning.component';
import { CacheListComponent } from './pages/cache-list/cache-list.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { CacheRegisterComponent } from './pages/cache-register/cache-register.component';
import { ProjectRegisterComponent } from './pages/project-register/project-register.component';
import { LogBatchMonitoringComponent } from './pages/log-batch-monitoring/log-batch-monitoring.component';
import { LogBatchMonitoringDetailsComponent } from './pages/log-batch-monitoring-details/log-batch-monitoring-details.component';

const routes: Routes = [
  { path: '', component: DeveloperComponent, canActivate: [AuthGuard] },
  { path: 'log-list', component: LogVisualizerListComponent, canActivate: [AuthGuard] },
  { path: 'external-log-list', component: ExternalLogsVisualizerListComponent, canActivate: [AuthGuard] },
  { path: 'log-batch-list', component: LogBatchVisualizerListComponent, canActivate: [AuthGuard] },
  { path: 'log-batch-monitoring', component: LogBatchMonitoringComponent, canActivate: [AuthGuard] },
  { path: 'log-batch-monitoring/details', component: LogBatchMonitoringDetailsComponent, canActivate: [AuthGuard] },
  { path: 'log-list/view', component: LogVisualizerViewComponent, canActivate: [AuthGuard] },
  { path: 'log-batch-list/view', component: LogBatchVisualizerViewComponent, canActivate: [AuthGuard] },
  { path: 'batch-config', component: BatchConfigListComponent, canActivate: [AuthGuard] },
  { path: 'batch-config/register', component: BatchConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'cache-cleaning', component: CacheCleaningComponent, canActivate: [AuthGuard] },
  { path: 'cache-list', component: CacheListComponent, canActivate: [AuthGuard] },
  { path: 'project-list', component: ProjectListComponent, canActivate: [AuthGuard] },
  { path: 'cache-register', component: CacheRegisterComponent, canActivate: [AuthGuard] },
  { path: 'project-register', component: ProjectRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    MatInputModule
  ],
  exports: [RouterModule],
})
export class DeveloperRoutingModule { }
