import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { InternalReportProfileService } from 'src/app/shared/services/API/business-intelligence/internal-report-profile.service';
import { ListProfileInternalReportStruct } from 'src/app/shared/services/structs/business-intelligence/list-profile-internal-report.struct';
import { ProfileService } from 'src/app/shared/services/API/admin-user/profile.service';
import { ListProfileStruct } from 'src/app/shared/services/structs/admin-user/list-profile.struct';

@Component({
  selector: 'app-business-intelligence-internal-list',
  templateUrl: './business-intelligence-internal-list.component.html',
  styleUrls: ['./business-intelligence-internal-list.component.css']
})
export class BusinessIntelligenceInternalListComponent implements OnInit {

  constructor(
    private bIService: InternalReportProfileService,
    private profileService: ProfileService,
    private alertService: AlertService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.business_intelligence_internal_list_report;
  public isFirstLoading: boolean = false;
  public isLoading: boolean = false;
  public filterProfileInternalReport: ListProfileInternalReportStruct[] = [];
  public listAllProfile: ListProfileStruct[];
  public profileInternalReport: ListProfileInternalReportStruct[] = [];
  public listIdProfile: number[];
  public preselectedList: any[] = [];
  public isMaster: boolean;
  public thisProfileName: string;

  ngOnInit(): void {
    this.isFirstLoading = true;    
    if(sessionStorage.getItem("listIdsProfileBI") == 'null'){
      this.getProfileList();
      
    }
    else{
      this.getProfileList(JSON.parse(sessionStorage.getItem("listIdsProfileBI")));
    }      
  }

  search(listSelectIds: number[]=[]) {      
    sessionStorage.setItem("listIdsProfileBI", JSON.stringify(listSelectIds));
    
    this.isLoading = true;
    this.bIService.getReports(listSelectIds).subscribe((response) => {
      this.isFirstLoading = false;
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listIdProfile = response.listIdProfile;
      this.isMaster = response.isMaster;

      
      
      if(this.isMaster && (listSelectIds == null || listSelectIds.length == 0)){
        listSelectIds = [];
        this.listAllProfile.forEach(profile => {
          listSelectIds.push(profile.idProfile);
        });
      }
      else if(listSelectIds == null || listSelectIds.length == 0){
        listSelectIds = this.listIdProfile;
      }

      listSelectIds.forEach(listIdProfile => {
        this.preselectedList.push({ idProfile: listIdProfile });
      });
      
      this.profileInternalReport = [];
      this.filterProfileInternalReport = [];

      
      if(!this.isMaster){
        var listUserProfile = response.listProfileReports.filter(c => listSelectIds.includes(c.idProfile));
      }
      else{
        var listUserProfile  = response.listProfileReports;
      }
      
      listUserProfile.forEach((c, index) => {
          this.profileInternalReport[index] = new ListProfileInternalReportStruct();
          this.filterProfileInternalReport[index] = new ListProfileInternalReportStruct();
          this.profileInternalReport[index].internalReports = c.internalReports.slice();
          this.filterProfileInternalReport[index].internalReports = c.internalReports.slice();
          this.filterProfileInternalReport[index].nameProfile = this.listAllProfile?.find(x => x.idProfile == c.idProfile)?.profileName ?? this.thisProfileName;
          this.profileInternalReport[index].nameProfile = this.listAllProfile?.find(x => x.idProfile == c.idProfile)?.profileName ?? this.thisProfileName;
               
      });

    },
      (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });    
      
  }

  selectChange(values) {
    this.search(values.map(x => x.idProfile));
  }

  getProfileList(listUserId: number[]=[]){
    this.profileService.listAllProfile().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listAllProfile = response.listProfile;
      this.search(listUserId);

    },
      (error) => {
        this.isLoading = false;
        this.isFirstLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  onKeySearch(event: any) {
    this.profileInternalReport.forEach((x, index) => {
      this.filterProfileInternalReport[index] = new ListProfileInternalReportStruct();
      this.filterProfileInternalReport[index].internalReports = this.profileInternalReport[index].internalReports.slice();
      this.filterProfileInternalReport[index].nameProfile = this.profileInternalReport[index].nameProfile;
    });
    if (event.target.value) {
      this.filterProfileInternalReport.forEach((x) => {
        x.internalReports = x.internalReports.filter(c => c.internalReportName.toLocaleLowerCase().search(event.target.value.toLocaleLowerCase()) >= 0)
      });
      this.filterProfileInternalReport = this.filterProfileInternalReport.filter(c => c.internalReports.length > 0);
    }
  }
}