import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AdminProtocolService } from 'src/app/shared/services/API/risk-classification/admin-protocol.service';
import { ProtocolService } from 'src/app/shared/services/API/risk-classification/protocol.service';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { ProtocolDeleteModalComponent } from './protocol-delete-modal/protocol-delete-modal.component';

@Component({
  selector: 'app-protocol-list',
  templateUrl: './protocol-list.component.html',
  styleUrls: ['./protocol-list.component.css']
})
export class ProtocolListComponent implements OnInit {

  constructor(
    private protocolService: ProtocolService,
    private adminProtocolService: AdminProtocolService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_protocol;

  public listProtocol: ProtocolModel[];
  public searchText: string;
  public isLoading: boolean;

  ngOnInit(): void {
    this.populateScreen();
    this.listProtocol == null;
    this.isLoading = true;
    this.searchText = null;
  }

  populateScreen() {
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    if (this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.adminProtocolService.listProtocol(this.searchText, null).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listProtocol = response.listProtocol;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idProtocol: number) {
    const dialogRef = this.dialog.open(ProtocolDeleteModalComponent, {
      data: {
        idProtocol: idProtocol
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteProtocol) {
        this.search();
      }
    });
  }
}
