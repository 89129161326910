import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MessageService } from 'src/app/shared/services/API/notification/message.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { ProfessionService } from 'src/app/shared/services/API/user/profession.service';
import { UserSelectService } from 'src/app/shared/services/API/user/user-select.service';
import { ListUserService } from 'src/app/shared/services/API/admin-user/list-user.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { ProfessionModel } from 'src/app/shared/services/models/user/profession.model';
import { MessageRequest } from 'src/app/shared/services/requests/notification/message.request';
import { MessageHistoryDisplayStruct } from 'src/app/shared/services/structs/notification/message-history-display.struct';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { ListAdminUserStruct } from 'src/app/shared/services/structs/admin-user/list-admin-user.struct';
import { PostMessageRequest } from 'src/app/shared/services/requests/orchestrator-user/post-message.request';
import { NotificationService } from 'src/app/shared/services/API/orchestrator-user/notification.service';
import { NotificationSelectService } from 'src/app/shared/services/API/orchestrator-user/notification-select.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'en-GB'}]
})
export class NotificationComponent implements OnInit {

  constructor(
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    public healthUnitService: HealthUnitService,
    public profesisonService: ProfessionService,
    public userSelectService: UserSelectService,
    public messageService: MessageService,
    public listAdminUserService: ListUserService,
    public notificationService: NotificationService,
    public notificationSelectService: NotificationSelectService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_notification;

  public model: FormGroup;
  public historyModel: FormGroup;
  public isLoading: boolean;
  public isLoadingHistory: boolean;
  public isFirstLoading: boolean;

  public defaultStartDate: Date;

  public listHealthUnit: HealthUnitStruct[] = [];
  public isAllHealthUnits: boolean = false;

  public listProfession: ProfessionModel[] = [];
  public isAllProfessions: boolean = false;

  public messageHistory: MessageHistoryDisplayStruct[] = [];

  public listAdminUser: ListAdminUserStruct[] = [];
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";

  ngOnInit(): void {
    this.isFirstLoading = true;

    this.model = this.formBuilder.group({
      messageTitle: ['', [Validators.required]],
      messageContent: ['', [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        listIdHealthUnit: [null, [Validators.required]],
      }),
      listIdProfession: [null, [Validators.required]],
    });

    let today: Date = new Date();
    this.defaultStartDate = this.addMonths(today, -1);

    this.historyModel = this.formBuilder.group({
      datetimeStart: [this.defaultStartDate],
      datetimeEnd: [today]
    });

    this.populateSelects();
    this.populateHistory();
  }

  addMonths(date: Date, months: number){
    let result: Date = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  }

  submit() {
    if (this.isLoading){
      return;
    }
    this.isLoading = true;
    let messageContent = this.model.get('messageContent').value;
    if (!messageContent){
      this.alertService.show('Erro', 'O corpo da mensagem é obrigatório', AlertType.error);
    }
    if (!this.model.valid){
      this.alertService.show('Erro', 'Todos os campos em vermelho devem ser corretamente preenchidos', AlertType.error);
    }
    let request: PostMessageRequest = new PostMessageRequest;
    request.listIdHealthUnit = this.model.get('firstChildGroup').get('listIdHealthUnit').value;
    request.listIdProfession = this.model.get('listIdProfession').value;
    request.messageContent = this.model.get('messageContent').value;
    request.messageTitle = this.model.get('messageTitle').value;

    this.notificationService.createMessage(request).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show('Sucesso', "Mensagem enviada com sucesso!", AlertType.success);
      this.clearForm();
      this.populateHistory();
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    return;
  }

  sendMessage(request: MessageRequest) {
    this.isLoading = true;
    this.messageService.postMessage(request).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show('Sucesso', "Notificação enviada com sucesso!", AlertType.success);
      this.clearForm();
      this.populateHistory();
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateHistory() {    
    this.isLoadingHistory = true;
    let datetimeStart: Date = this.historyModel.get('datetimeStart').value;
    let datetimeEnd: Date = this.historyModel.get('datetimeEnd').value;

    if (datetimeEnd < datetimeStart){
      this.isLoadingHistory = false;
      this.alertService.show('Erro', 'A data inicial não pode ser maior que a data final', AlertType.error);
      return;
    }

    let datetimeStartDay = datetimeStart.getDate();
    let datetimeStartMonth = datetimeStart.getMonth() + 1;
    let datetimeStartYear = datetimeStart.getFullYear();

    let datetimeEndDay = datetimeEnd.getDate() + 1;
    let datetimeEndMonth = datetimeEnd.getMonth() + 1;
    let datetimeEndYear = datetimeEnd.getFullYear();

    let datetimeStartRequest: string = `${('0' + datetimeStartDay).slice(-2)}/${('0' + datetimeStartMonth).slice(-2)}/${datetimeStartYear}`;
    let datetimeEndRequest: string = `${('0' + datetimeEndDay).slice(-2)}/${('0' + datetimeEndMonth).slice(-2)}/${datetimeEndYear}`;
    
    this.notificationService.getHistory(datetimeStartRequest, datetimeEndRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.messageHistory = response.listMessageDisplayHistory
      this.isLoadingHistory = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoadingHistory = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateSelects() {
    this.isLoading = true;
    this.notificationSelectService.populateSelect().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listProfession = response.listProfession;
      this.listHealthUnit = response.listHealthUnit;
      this.isLoading = false;
      if (this.isFirstLoading){
        this.isFirstLoading = false;
      }
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  selectAllProfessions() {
    this.isAllProfessions = !this.isAllProfessions;
    if (this.isAllProfessions) {
      this.model.get('listIdProfession').setValue(this.listProfession.map(x => x.idProfession));
    }
    else {
      this.model.get('listIdProfession').setValue(null);
    }
  }

  selectAllHealthUnits() {
    this.isAllHealthUnits = !this.isAllHealthUnits;
    if (this.isAllHealthUnits) {
      this.model.get('firstChildGroup').get('listIdHealthUnit').setValue(this.listHealthUnit.map(x => x.idHealthUnit));
    }
    else {
      this.model.get('firstChildGroup').get('listIdHealthUnit').setValue(null);
    }
  }

  clearForm() {
    this.model.reset();
    Object.keys(this.model.controls).forEach(control => {
      this.model.get(control).setErrors(null);
    })
  }

  async populateListAdminUser() {
    this.isLoading = true;
    this.listAdminUserService.listAdminUser().subscribe(async (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listAdminUser = response.listAdminUser;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

}
