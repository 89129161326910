import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CompetenceSelectService } from 'src/app/shared/services/API/billing/competence-select.service';
import { TriggerProcedureService } from 'src/app/shared/services/API/billing/trigger-procedure-sigtap.service';
import { TriggersProcedureSigtap } from 'src/app/shared/services/models/billing/triggers-procedure-sigtap.model';
import { ProcedureSigtapStruct, RelationTriggersProcedureSigtapStruct } from 'src/app/shared/services/structs/billing/relation-triggers-procedure-sigtap.struct';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-billing-sigtap-trigger-modal',
  templateUrl: './billing-sigtap-trigger-modal.component.html',
  styleUrls: ['./billing-sigtap-trigger-modal.component.css']
})
export class BillingSigtapTriggerModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BillingSigtapTriggerModalComponent>,
    private competenceSelectService: CompetenceSelectService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private triggerProcedureService: TriggerProcedureService) { }

  public isLoading: boolean;
  public urlListProcedure: string;
  public listPreselectedProcedure: ProcedureSigtapStruct[] = [];
  public listSelectedProcedure: ProcedureSigtapStruct[] = [];
  public triggerListSelect: TriggersProcedureSigtap[] = [];
  public model: FormGroup;

  ngOnInit(): void {
    this.populateTriggers();
    this.isLoading = false;
    this.urlListProcedure = environment.urlApiBilling + `TriggerProcedureSigtap/idCompetence/${this.data.idProcedureSigtapCompetence}`;
    this.model = this.formBuilder.group({
      idTriggersProcedureSigtap: [''],
    });
  }

  close() {
    this.matDialogRef.close({});
  }

  clickAdd() {
    let idTriggersProcedureSigtap = this.model.get("idTriggersProcedureSigtap").value;
    if (!idTriggersProcedureSigtap || (!this.listSelectedProcedure && this.listSelectedProcedure.length == 0)) {
      this.alertService.show("Erro", "Selecione a trigger e pelo menos um procedimento!", AlertType.error);
      return;
    }
    let selectedTrigger = this.triggerListSelect.find(c => c.idTriggersProcedureSigtap == idTriggersProcedureSigtap);

    let relationTrigger: RelationTriggersProcedureSigtapStruct = new RelationTriggersProcedureSigtapStruct();
    relationTrigger.idTriggersProcedureSigtap = idTriggersProcedureSigtap;
    relationTrigger.triggersName = selectedTrigger.triggersName;
    let procedureSigtapStruct: ProcedureSigtapStruct[] = [];

    this.listSelectedProcedure.forEach((c) => {
      procedureSigtapStruct.push(c);
    })
    
    relationTrigger.procedureSigtapCboStructs = procedureSigtapStruct;
    
    

    this.matDialogRef.close({
      relationTrigger
    });
  }

  clickCancel() {
    this.matDialogRef.close({});
  }

  addItemProcedure(event) {
    this.listSelectedProcedure = event;
  }

  populateTriggers() {
    this.triggerProcedureService.listTriggersSelect().subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      this.triggerListSelect = response.triggersProcedures;
      
      if(this.data.relationTriggers){
        this.model.get("idTriggersProcedureSigtap").setValue(this.data.relationTriggers.idTriggersProcedureSigtap.toString());
        this.listPreselectedProcedure = this.data.relationTriggers.procedureSigtapCboStructs;
        this.listSelectedProcedure = this.data.relationTriggers.procedureSigtapCboStructs;
      }
    })
  }
}
