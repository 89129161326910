import { Component, Input, OnInit } from "@angular/core";


@Component({
  selector: 'app-chip-status',
  templateUrl: './chip-status.component.html',
  styleUrls: ['./chip-status.component.css']
})
export class ChipStatusComponent implements OnInit {

  constructor() { }

  @Input() public status: string;

  ngOnInit(): void {
  }
}
