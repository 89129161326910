export class AgeStruct {
    public years: number;
    public months: number;
    public days: number;

    public IsLarger(a: AgeStruct): boolean {
        if (this.years > a.years) {
            return true;
        }
        else if (this.years == a.years) {

            if (this.months > a.months) {
                return true;
            }

            else if (this.months == a.months) {

                if (this.days > a.days) {
                    return true;
                }

                else {
                    return false;
                }
            }
            else {
                return false
            }
        }
        else {
            return false;
        }
    }

    public IsLesser(a: AgeStruct): boolean {
        if (this.years < a.years) {
            return true;
        }
        else if (this.years == a.years) {

            if (this.months < a.months) {
                return true;
            }

            else if (this.months == a.months) {

                if (this.days < a.days) {
                    return true;
                }

                else {
                    return false;
                }
            }
            else {
                return false
            }
        }
        else {
            return false;
        }
    }
}