import { AllergyModel } from "../../models/medical-record/allergy.model";

export class AllergyStruct {
    public idAllergyEpisode: number;

    public idAllergy: number;

    public allergyName: string;

    public idEpisode: number;

    public isDeleted: boolean;

    public motive: string;

    public idUser: number;

    constructor(allergy?: AllergyModel, idEpisode?: number) {
        if (allergy != null) {
            this.idAllergy = allergy.idAllergy;
            this.allergyName = allergy.allergyName;
            this.motive = allergy.motive;
            this.isDeleted = allergy.isDeleted;
            this.idUser = allergy.idUser;
        }

        if (idEpisode != null) {
            this.idEpisode = idEpisode
        }
    }
}