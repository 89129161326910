import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';

@Component({
  selector: 'app-customer-success',
  templateUrl: './customer-success.component.html',
  styleUrls: ['./customer-success.component.css']
})
export class CustomerSuccessComponent implements OnInit {
  constructor() { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.customer_success;
  
  ngOnInit(): void {
    
  }

}
