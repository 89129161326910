import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AnalyticsService } from 'src/app/shared/services/API/data-mesh/analytics.service';
import { DataProductService } from 'src/app/shared/services/API/data-mesh/data-product.service';
import { AnalyticsRequest } from 'src/app/shared/services/requests/data-mesh/analytics.request';
import { DataProductStruct } from 'src/app/shared/services/structs/data-mesh/data-product.struct';
import { CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent {
  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private analyticsService: AnalyticsService,
    private dataProductService: DataProductService,
    private router: Router,
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.datamesh;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.datamesh_analytics;
  public isLoading: boolean = false;
  public model: FormGroup;
  public data: MatTableDataSource<object> = new MatTableDataSource<object>();
  public dataProduct: DataProductStruct;
  public col_types: Object = {};
  public col_names: string[] = [];
  public fullSize: number = 0;
  public pageIndex: number = 0;

  async ngOnInit(): Promise<void> {
    if (!this.activatedRoute.snapshot.paramMap.get('idDataProduct')) {
      this.alertService.show('Erro', 'Nenhum produto de dados selecionado', AlertType.error);
      this.router.navigate(['/datamesh/data-products']);
      return;
    }

    this.getDataProduct(parseInt(this.activatedRoute.snapshot.paramMap.get('idDataProduct')));

    let end = new Date();
    let start = new Date();
    start.setMonth(start.getMonth() - 1);
    this.model = this.formBuilder.group({
      listColumns: [],
      datetimeEnd: [end, Validators.required],
      datetimeStart: [start, Validators.required],
    });
  }

  search(pageIndex: number = 0) {
    this.isLoading = true;
    let request: AnalyticsRequest = {
      dataProduct: this.dataProduct,
      filters: [
        [
          `${this.dataProduct.datetimeColumn}`,
          "<=",
          `${this.model.get('datetimeEnd').value.toISOString().split('Z')[0]}`
        ],
        [
          `${this.dataProduct.datetimeColumn}`,
          ">=",
          `${this.model.get('datetimeStart').value.toISOString().split('Z')[0]}`
        ]
      ],
      format: 'json',
      engine: 'pandas',
      nrows: 50,
      page: pageIndex,
    };

    this.analyticsService.readDataProductById(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.data = new MatTableDataSource<object>(response.dataframe);
        this.fullSize = response.metadata.fullSize;
        this.col_types = response.metadata.col_types;
        this.col_names = response.metadata.col_names;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  extractDataProduct() {
    let request: AnalyticsRequest = {
      dataProduct: this.dataProduct,
      filters: [
        [
          `${this.dataProduct.datetimeColumn}`,
          "<=",
          `${this.model.get('datetimeEnd').value.toISOString().split('Z')[0]}`
        ],
        [
          `${this.dataProduct.datetimeColumn}`,
          ">=",
          `${this.model.get('datetimeStart').value.toISOString().split('Z')[0]}`
        ]
      ],
      format: 'json',
      engine: 'pandas',
      nrows: 50,
      page: 0,
    };

    this.analyticsService.extractDataProduct(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso!', 'Você receberá um link no seu email para download do arquivo', AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  async getDataProduct(idDataProdcut: number): Promise<void> {
    this.dataProductService.getDataProductById(idDataProdcut).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.dataProduct = response.dataProduct;

        console.log(this.model)

        this.search();
        return;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    const prev: number = this.col_names.indexOf(event.previousContainer.id);
    const next: number = this.col_names.indexOf(event.container.id);
    moveItemInArray(this.col_names, prev, next);
  }
}