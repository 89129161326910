import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { MessageRequest } from '../../requests/srv-message-queue/message.request';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public SendMessage(body: MessageRequest, idHealthUnit: number): Observable<ReturnStruct> {

    return this.httpClient.post<ReturnStruct>(`${environment.urlApiSRVMessageQueue}Message?idHealthUnit=${idHealthUnit}`, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
