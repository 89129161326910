import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { SpecialtyAttendanceResponse } from '../../responses/flow/specialty-attendance.response';

@Injectable({
  providedIn: 'root'
})
export class LookupService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listSpecialtyAttendance(): Observable<SpecialtyAttendanceResponse> {

    let uri = `Lookup/SpecialtyAttendance`

    return this.httpClient.get<SpecialtyAttendanceResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}