<app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

<div class="container-body">
    <div class="white-body">
        <div class="title-header">
            <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon> 
            <h1>Módulo Billing</h1>
        </div>
        <p>Bem vindo ao seu módulo Billing! Aqui você controla toda o faturamento associado às unidades de saude.</p>
    </div>
</div>