import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CoordinationService } from 'src/app/shared/services/API/billing/coordination.service';
import { CoordinationRequest } from 'src/app/shared/services/requests/billing/coordination.request';

@Component({
  selector: 'app-coordination-register',
  templateUrl: './coordination-register.component.html',
  styleUrls: ['./coordination-register.component.css']
})
export class CoordinationRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private coordinationService: CoordinationService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_coordination;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public idCoordination: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.isUpdate = false;

    if (this.activatedRoute.snapshot.paramMap.get('idCoordination'))
      this.idCoordination = parseInt(this.activatedRoute.snapshot.paramMap.get('idCoordination'));

    this.isUpdate = this.idCoordination ? true : false;

    this.model = this.formBuilder.group({
      coordinationName: ['', [Validators.required]]
    });

    if(this.idCoordination){
      this.isLoading = true;
      this.populateCoordinationData();
    }      
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    let coordinationRequest: CoordinationRequest = new CoordinationRequest();
    coordinationRequest.coordinationName = this.model.get('coordinationName').value;
    
    if (this.isUpdate)
      this.updateCoordination(coordinationRequest);
    else
      this.createCoordination(coordinationRequest);
  }

  populateCoordinationData() {
    this.coordinationService.Get(this.idCoordination).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('coordinationName').setValue(response.coordination.coordinationName);
        this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateCoordination(coordinationRequest: CoordinationRequest) {
    this.coordinationService.Update(this.idCoordination, coordinationRequest).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/coordination']);
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createCoordination(coordinationRequest: CoordinationRequest) {
    this.coordinationService.Create(coordinationRequest).subscribe({ 
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/consulting/coordination']);
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}