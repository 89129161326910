import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ProfileRequest } from '../../requests/admin-user/profile.request';
import { ListProfileResponse } from '../../responses/admin-user/list-profile.response';
import { ProfileResponse } from '../../responses/admin-user/profile.response';
import { SupportContactModel } from 'src/app/shared/services/models/admin-user/support-contact.model';
import { SupportContactTypeModel } from 'src/app/shared/services/models/admin-user/support-contact-type.model';
import { listSupportContactTypeResponse } from '../../responses/admin-user/list-support-contact-type.response';


@Injectable({
  providedIn: 'root'
})
export class SupportContactTypeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public listSupportContactType(): Observable<listSupportContactTypeResponse> {
    
    let uri = 'SupportContactType'    
    return this.httpClient.get<listSupportContactTypeResponse>(environment.urlApiAdminUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
