import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FirstAccessRequest } from '../../requests/admin-user/first-access.request';

@Injectable({
  providedIn: 'root'
})
export class FirstAccessService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public firstAccessPassword(firstAccessRequest: FirstAccessRequest): Observable<ReturnStruct>{
    return this.httpClient.post<ReturnStruct>(environment.urlApiAdminUser + `FirstAccess`, firstAccessRequest)
    .pipe(
      catchError(this.handleError)
    )
  }
}
