import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicineAdministrationTypeEnum } from 'src/app/shared/enum/medicine-administration-type.enum';
import { MedicinePresentationTypeEnum } from 'src/app/shared/enum/medicine-presentation-type.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupMedicalCareService } from 'src/app/shared/services/API/medical-record/lookup-medical-care.service';
import { MedicineAdministrationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-administration-type.service';
import { MedicinePresentationTypeService } from 'src/app/shared/services/API/pharmacy/medicine-presentation-type.service';
import { TemplateGroupSelectService } from 'src/app/shared/services/API/pharmacy/template-group-select.service';
import { TemplateMedicineService } from 'src/app/shared/services/API/pharmacy/template-medicine.service';
import { TemplateSubgroupSelectService } from 'src/app/shared/services/API/pharmacy/template-subgroup-select.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { MedicineAdministrationTypeModel } from 'src/app/shared/services/models/pharmacy/medicine-administration-type.model';
import { MedicinePresentationTypeModel } from 'src/app/shared/services/models/pharmacy/medicine-presentation-type.model';
import { TemplateMedicineRequest } from 'src/app/shared/services/requests/pharmacy/template-medicine.request';
import { DilutionStruct } from 'src/app/shared/services/structs/medical-record/dilution.struct';
import { MeasurementUnitStruct } from 'src/app/shared/services/structs/medical-record/measurement-unit.struct';
import { TemplateGroupSelectStruct } from 'src/app/shared/services/structs/pharmacy/template-group-select.struct';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { AllergyStruct } from 'src/app/shared/services/structs/medical-record/allergy.struct';

@Component({
  selector: 'app-template-medication-register',
  templateUrl: './template-medication-register.component.html',
  styleUrls: ['./template-medication-register.component.css']
})
export class TemplateMedicationRegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private templateGroupSelectService: TemplateGroupSelectService,
    private templateSubgroupSelectService: TemplateSubgroupSelectService,
    private templateMedicineService: TemplateMedicineService,
    private medicinePresentationTypeService: MedicinePresentationTypeService,
    private medicineAdministrationTypeService: MedicineAdministrationTypeService,
    private lookupMedicalCareService: LookupMedicalCareService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_template_medicine;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public isGroup: boolean;
  public allergies: AllergyModel[] = [];
  public allergiesToSave: AllergyModel[] = [];
  public allergyError: boolean = false;
  public idTemplateMedicine: number;
  public listMedicineGroup: TemplateGroupSelectStruct[];
  public listMedicineSubgroup: TemplateGroupSelectStruct[];
  public listMedicineDilution: DilutionStruct[];
  public listMedicineMeasurementUnit: MeasurementUnitStruct[];
  public listMedicinePresentationType: MedicinePresentationTypeModel[];
  public listMedicineAdministrationType: MedicineAdministrationTypeModel[];
  public listIdMedicineAdministrationType: any[];

  public medicineRequest: TemplateMedicineRequest;

  ngOnInit(): void {
    this.isLoading = true;
    this.isGroup = true;
    this.idTemplateMedicine = null;
    this.isUpdate = false;

    this.model = this.formBuilder.group({
      code: [''],
      description: ['', [Validators.required]],
      idMedicineGroup: [null, [Validators.required]],
      idMedicineSubgroup: [null],
      idMedicinePresentationType: [null],
      idMedicineAdministrationType: [null],
      listIdMedicineAdministrationType: [],
      medicinePresentationOther: [''],
      medicineAdministrationOther: [''],
      radioGroup: [''],
      idDilution: [null],
      idMeasurementUnit: [null],
    });

    this.model.get('medicineAdministrationOther').disable();
    this.model.get('medicinePresentationOther').disable();

    this.medicineRequest = new TemplateMedicineRequest();

    this.populateMedicineGroupSelect();
    this.populateMedicineSubgroupSelect();
    this.populateMedicinePresentationTypeSelect();
    this.populateMedicineAdministrationTypeSelect();    
    this.populateMedicineDilution();
    this.populateMedicineMeasurementUnit();

    if (this.activatedRoute.snapshot.paramMap.get('idTemplateMedicine'))
      this.idTemplateMedicine = parseInt(this.activatedRoute.snapshot.paramMap.get('idTemplateMedicine'));

    if (this.idTemplateMedicine != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.model.get('idMedicineGroup').disable();
      this.model.get('idMedicineSubgroup').disable();
      this.model.get('radioGroup').disable();
      this.populateMedicineData();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.model.get('firstChildGroup').get('listIdAllergies').markAsTouched();
      this.allergyError = this.model.get('firstChildGroup').get('listIdAllergies').invalid && this.model.get('firstChildGroup').get('listIdAllergies').touched;
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    if (this.isGroup === true) {
      this.medicineRequest.idMedicineGroup = this.model.get('idMedicineGroup').value;
    }
    else {
      this.medicineRequest.idMedicineSubgroup = this.model.get('idMedicineSubgroup').value;
    }
    this.medicineRequest.idMedicinePresentationType = this.model.get('idMedicinePresentationType').value;
    this.medicineRequest.medicineDescription = this.model.get('description').value;
    this.medicineRequest.medicineCode = this.model.get('code').value?.toString();
    this.medicineRequest.idMedicineAdministrationType = this.model.get('idMedicineAdministrationType').value;
    this.medicineRequest.listIdMedicineAdministrationType = this.model.get('listIdMedicineAdministrationType').value ? this.model.get('listIdMedicineAdministrationType').value : null;
    this.medicineRequest.medicinePresentationOther = this.model.get('medicinePresentationOther').value;
    this.medicineRequest.medicineAdministrationOther = this.model.get('medicineAdministrationOther').value;
    this.medicineRequest.idDilution = this.model.get('idDilution').value ? this.model.get('idDilution').value : null;
    this.medicineRequest.idMeasurementUnit = this.model.get('idMeasurementUnit').value ? this.model.get('idMeasurementUnit').value : null;
    
    if (this.isUpdate) {
      this.updateMedicine();
    }
    else {
      this.createMedicine();
    }
  }

  mapActivePrinciple(){
    this.medicineRequest.listAllergy = this.allergiesToSave.map(a => new AllergyStruct(a, null));

  }

  populateMedicineData() {
    this.templateMedicineService.read(this.idTemplateMedicine).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isGroup = response.templateMedicine.idMedicineGroup != null ? true : false;
      this.model.get('description').setValue(response.templateMedicine.medicineDescription);
      this.model.get('code').setValue(response.templateMedicine.medicineCode);

      if (response.templateMedicine.idMedicineGroup) {
        this.model.get('idMedicineGroup').setValue(response.templateMedicine.idMedicineGroup);
      }
      if (response.templateMedicine.idMedicineSubgroup) {
        this.model.get('idMedicineSubgroup').setValue(response.templateMedicine.idMedicineSubgroup);
      }
      this.model.get('idMedicinePresentationType').setValue(response.templateMedicine.idMedicinePresentationType);
      this.model.get('idMedicineAdministrationType').setValue(response.templateMedicine.idMedicineAdministrationType);
      this.model.get('idDilution').setValue(response.templateMedicine.idDilution);
      this.model.get('idMeasurementUnit').setValue(response.templateMedicine.idMeasurementUnit);
      this.model.get('medicinePresentationOther').setValue(response.templateMedicine.medicinePresentationOther);
      this.model.get('listIdMedicineAdministrationType').setValue(response.listIdMedicineAdministrationType);
      this.model.get('medicineAdministrationOther').setValue(response.templateMedicine.medicineAdministrationOther);

      this.checkOtherPresentation();
      this.checkOtherAdministration();
      if(response.listAllergy != null)
      {
        this.allergies = response.listAllergy.map(a => new AllergyModel(a));
        this.updateAllergiesToSave(this.allergies);
      }
      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateMedicineGroupSelect() {
    this.templateGroupSelectService.list().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listMedicineGroup = response.listGroupSelect;

      this.isLoading = false

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateMedicineSubgroupSelect() {
    this.templateSubgroupSelectService.list().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listMedicineSubgroup = response.listSubgroupSelect;

      this.isLoading = false

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateMedicinePresentationTypeSelect() {
    this.medicinePresentationTypeService.listMedicinePresentationType().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listMedicinePresentationType = response.listMedicinePresentationType;

      this.isLoading = false

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateMedicineDilution() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listMedicineDilution = response.listDilution

      this.isLoading = false

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateMedicineMeasurementUnit() {
    this.lookupMedicalCareService.getLookupMedicalCare().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listMedicineMeasurementUnit = response.listMeasurementUnit

      this.isLoading = false

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateMedicineAdministrationTypeSelect() {
    this.medicineAdministrationTypeService.listMedicineAdministrationType().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listMedicineAdministrationType = response.listMedicineAdministrationType;

      this.isLoading = false

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateMedicine() {
    this.templateMedicineService.update(this.idTemplateMedicine, this.medicineRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/template-medication']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateAllergiesToSave(allergies: AllergyModel[]) {
    this.allergiesToSave = allergies;
    this.mapActivePrinciple();
  }

  createMedicine() {
    this.templateMedicineService.create(this.medicineRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/template-medication']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  onChange(value) {
    this.isGroup = value;
    this.model.get('idMedicineSubgroup').setValidators([]);
    this.model.get('idMedicineGroup').setValidators([]);
    if (value) {
      this.model.get('idMedicineGroup').setValidators([Validators.required]);
      this.model.get('idMedicineSubgroup').setValue(null);
    }
    else {
      this.model.get('idMedicineGroup').setValue(null);
      this.model.get('idMedicineSubgroup').setValidators([Validators.required]);
    }
    this.model.get('idMedicineGroup').updateValueAndValidity();
    this.model.get('idMedicineSubgroup').updateValueAndValidity();
  }

  checkOtherPresentation() {
    if (this.model.get('idMedicinePresentationType').value == MedicinePresentationTypeEnum.Outro) {
      this.model.get('medicinePresentationOther').enable();
      this.model.get('medicinePresentationOther').setValidators([Validators.required]);
    }
    else {
      this.model.get('medicinePresentationOther').disable();
      this.model.get('medicinePresentationOther').setValidators([]);
    }
    this.model.get('medicinePresentationOther').updateValueAndValidity();
  }

  checkOtherAdministration() {
    var listId = this.model.get("listIdMedicineAdministrationType").value ? this.model.get("listIdMedicineAdministrationType").value : [];
    if (listId.filter(x => x == MedicineAdministrationTypeEnum.Outros).length > 0) {
      this.model.get('medicineAdministrationOther').enable();
      this.model.get('medicineAdministrationOther').setValidators([Validators.required]);
    }
    else {
      this.model.get('medicineAdministrationOther').disable();
      this.model.get('medicineAdministrationOther').setValidators([]);
    }
    this.model.get('medicineAdministrationOther').updateValueAndValidity();
  }
}