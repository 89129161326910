import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { BusinessIntelligenceInternalDetailsComponent } from './pages/business-intelligence-internal-details/business-intelligence-internal-details.component';
import { BusinessIntelligenceInternalListComponent } from './pages/business-intelligence-internal-list/business-intelligence-internal-list.component';
import { BusinessIntelligenceComponent } from './pages/business-intelligence/business-intelligence.component';
import { InternalReportListComponent } from './pages/internal-report-list/internal-report-list.component';
import { InternalReportRegisterComponent } from './pages/internal-report-register/internal-report-register.component';
import { ReportListComponent } from './pages/report-list/report-list.component';
import { ReportRegisterComponent } from './pages/report-register/report-register.component';
import { TemplateBusinessIntelligenceRegisterComponent } from './pages/template-business-intelligence-register/template-business-intelligence-register.component';
import { TemplateBusinessIntelligenceListComponent } from './pages/template-business-intelligence-list/template-business-intelligence-list.component';

const routes: Routes = [
  { path: '', component: BusinessIntelligenceComponent, canActivate: [AuthGuard] },
  { path: 'report', component: ReportListComponent, canActivate: [AuthGuard] },
  { path: 'report/register', component: ReportRegisterComponent, canActivate: [AuthGuard] },
  { path: 'internal-report', component: InternalReportListComponent, canActivate: [AuthGuard] },
  { path: 'internal-report/register', component: InternalReportRegisterComponent, canActivate: [AuthGuard] },
  { path: 'internal-list', component: BusinessIntelligenceInternalListComponent, canActivate: [AuthGuard] },
  { path: 'internal-details/:idInternalReportFunctionProfile', component: BusinessIntelligenceInternalDetailsComponent, canActivate: [AuthGuard] },
  { path: 'template', component: TemplateBusinessIntelligenceListComponent, canActivate: [AuthGuard] },
  { path: 'template-register', component: TemplateBusinessIntelligenceRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessIntelligenceRoutingModule { }
