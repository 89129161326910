import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { ProjectCacheService } from 'src/app/shared/services/API/cache/project-cache.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProjectModel } from 'src/app/shared/services/models/cache/project.model';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit{
  constructor(private cacheService: ProjectCacheService,
    private alertService: AlertService,
    private dialog: MatDialog) { }
    public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
    public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.project;
    public isLoading: boolean;
    public listProject: ProjectModel[] = [];

    ngOnInit(): void {
      this.isLoading = true;
      this.populateCard();
    }

    populateCard()
    {
      this.cacheService.ListProject().subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          if(response.listProject != null)
            {
              this.listProject = response.listProject;
            }
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }

    DeleteProject(idProject: number) {

      this.isLoading = true;

      this.cacheService.Delete(idProject).subscribe({
        next: response => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          this.alertService.show('Sucesso', 'Projeto deletado com sucesso!', AlertType.success);
          this.populateCard();
  
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
}

