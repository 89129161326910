import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingConfigService } from 'src/app/shared/services/API/billing-by-health-unit/billing-config.service';

@Component({
  selector: 'app-billing-config-contract-delete-modal',
  templateUrl: './billing-config-contract-delete-modal.component.html',
  styleUrls: ['./billing-config-contract-delete-modal.component.css']
})
export class BillingConfigContractDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BillingConfigContractDeleteModalComponent>,
    private alertService: AlertService,
    private billingConfigService: BillingConfigService,) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ deleteConfig: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.billingConfigService.deleteBillingConfigContract(this.data.idBillingConfigContract).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Contrato excluído com sucesso!", AlertType.success);
        this.matDialogRef.close({ deleteConfig: true });
      },
      error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ deleteConfig: false });
  }
}