<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consultation/health-unit">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Cadastrar Produto de Dados</h1>
    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form *ngIf="!isLoading" class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Dados do produto</h1>
            </div>
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome do Produto</mat-label>
                        <input matInput type="text" formControlName="dataProductName">
                        <mat-error *ngIf="model.get('dataProductName').invalid">Informe o nome do produto</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Perfis</mat-label>
                        <mat-select formControlName="profiles" multiple>
                            <mat-option *ngFor="let item of listProfile" [value]="item.idProfile">
                                {{item.profileName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="title-header">
                <h1>Origens</h1>
            </div>
            <h2>Primária</h2>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Dataset primário</mat-label>
                        <mat-select (selectionChange)="updatePrimaryDataSet($event.value)"
                            formControlName="primaryDataset">
                            <mat-option *ngFor="let item of listDataSet" [value]="item.idDataSet">
                                {{item.dataSetName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field [disabled]="!model.get('datetimeColumn').value" appearance="outline">
                        <mat-label>Coluna de data</mat-label>
                        <mat-select formControlName="datetimeColumn">
                            <mat-option *ngFor="let item of getDatetimeCols()" [value]="item.name">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <h2>Auxiliares</h2>
            <div formArrayName="datasets" *ngFor="let item of model.get('datasets')['controls']; let i = index">
                <div [formGroupName]="i">
                    <div class="row">
                        <div class="col-12 col-md-4 col-sm-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Secundárias</mat-label>
                                <mat-select formControlName="idDataSet">
                                    <mat-option [disabled]="checkSecondaryDataset(dataSet.idDataSet)"
                                        *ngFor="let dataSet of listDataSet" [value]="dataSet.idDataSet">
                                        {{dataSet.dataSetName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="column-lock col-12 col-md-6 col-sm-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Coluna secundária</mat-label>
                                <mat-select formControlName="secondaryColumn">
                                    <mat-option *ngFor="let secondaryColumn of getSecondaryColumns(i)"
                                        [value]="secondaryColumn.name">
                                        {{secondaryColumn.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-icon>lock</mat-icon>
                            <mat-form-field appearance="outline">
                                <mat-label>Coluna primária</mat-label>
                                <mat-select formControlName="primaryColumn">
                                    <mat-option *ngFor="let primaryColumn of getPrimaryColumns()"
                                        [value]="primaryColumn.name">
                                        {{primaryColumn.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-2 col-sm-6">
                            <div *ngIf="!isLoading">
                                <button class="totem-button btn-primary" mat-mini-fab type="button"
                                    (click)="addNext(i)">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                </button>
                                <button matTooltip="Excluir configuração" class="totem-button btn-primary" mat-mini-fab
                                    type="button" (click)="removeDataSet(i)">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/datamesh/data-products"
                        class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>