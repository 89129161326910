import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateService } from 'src/app/shared/services/API/report/template.service';

@Component({
  selector: 'app-btn-report-template-view-modal',
  templateUrl: './btn-report-template-view-modal.component.html',
  styleUrls: ['./btn-report-template-view-modal.component.css']
})
export class BtnReportTemplateViewModalComponent implements OnInit {
  public src:any;
  public isLoading: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  private alertService: AlertService,
  private dialog: MatDialog, 
  private matDialogRef: MatDialogRef<BtnReportTemplateViewModalComponent>,
  private sanitizer: DomSanitizer,
  private templateService: TemplateService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getTemplate(this.data.idTemplate);
  }

  getTemplate(idTemplate: number) {
    this.templateService.viewTemplate(idTemplate).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + response.reportPdf64);
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  close(){
    this.matDialogRef.close(); 
  }
  
  selectItem(){
    if(!this.isLoading)
      this.matDialogRef.close(this.data.idTemplate); 
  }
}
