import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthUnitAdminService } from 'src/app/shared/services/API/user/health-unit-admin.service';
import { UpdateMasterUserHealthUnitRequest } from 'src/app/shared/services/requests/user/update-master-user-health-unit.request';

@Component({
  selector: 'app-change-master-user-modal',
  templateUrl: './change-master-user-modal.component.html',
  styleUrls: ['./change-master-user-modal.component.css']
})
export class ChangeMasterUserModalComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ChangeMasterUserModalComponent>,
  private healthUnitAdminService: HealthUnitAdminService,
  private alertService: AlertService,
  ) { }
  
  public isLoading:boolean;  
  
  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({changeMasterUser: false}); 
  }
  
  updateJustMasterUser() {
    if (this.isLoading) {
      return;
    }   

    this.isLoading = true;    
    let updateMasterUserHealthUnitRequest = new UpdateMasterUserHealthUnitRequest;
    updateMasterUserHealthUnitRequest.idNewUserMaster = this.data.idNewUserMaster;
    updateMasterUserHealthUnitRequest.idOldUserMaster = this.data.idOldMasterUser;

    this.healthUnitAdminService.updateMasterUser(this.data.idHealthUnit, updateMasterUserHealthUnitRequest).subscribe({
      next: (response) => {
        
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          if (response.errorCode != 2)
          return;
        }
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;   
        this.matDialogRef.close({changeMasterUser: true});  
        
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
    
    
  }
  
  clickCancel(){
    this.matDialogRef.close({changeMasterUser: false}); 
  }
}