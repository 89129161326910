import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { ExternalLogVisualizerService } from 'src/app/shared/services/API/srvlog/external-log-visualizer.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-external-logs-visualizer-list',
  templateUrl: './external-logs-visualizer-list.component.html',
  styleUrls: ['./external-logs-visualizer-list.component.css']
})
export class ExternalLogsVisualizerListComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private healthUnitService: HealthUnitService,
    private alertService: AlertService,
    private externalLogVisualizerService: ExternalLogVisualizerService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.log_list;
  public isLoading: boolean;
  public listLogs: any[]; //decidir se vai ser um LogStruct normal ou se devo criar outro
  public listHealthUnit: ListHealthUnitStruct[];
  public fullListSize: number;
  public interval: any;
  public model: FormGroup;
  public latestId: number;
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";
  public listIdHealthUnit: number[] = [];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      'idLog': [null],
      'start': [''],
      'end': [''],
      'searchText': [''],
      firstChildGroup: this.formBuilder.group({
        idHealthUnit: [[],],
      }),
      'type': [null],
      'panelMode': [false],
    });

    this.populateHealthUnitSelect();
    this.latestId = 0;
    this.search(0);
  }

  search(pageIndex: number = 0) {
    if(!this.model.value.firstChildGroup.idHealthUnit)
    this.model.value.firstChildGroup.idHealthUnit = []

    this.isLoading = true;
    this.externalLogVisualizerService.listExternalLogWithListHealthUnit(this.model.value.idLog,
      this.model.value.firstChildGroup.idHealthUnit,
      this.model.value.type,
      this.model.value.searchText,
      this.model.value.start,
      this.model.value.end,
      pageIndex).subscribe((response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (this.latestId < response.listLogs[0]?.idLog) {
          this.latestId = response.listLogs[0]?.idLog;
          if (this.model.value.panelMode) {
            let audio = new Audio();
            audio.src = "https://storage.googleapis.com/tolife-production-public-storage/admin_error.mp3";
            audio.load();
            audio.play();
          }
        }
        this.fullListSize = response.fullListSize;
        this.listLogs = response.listLogs;
        this.isLoading = false;
      },
        (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        });
  }

  getHealthUnitName(idHealthUnit: number): string {
    if (idHealthUnit == -1)
      return "Admin";

    if (!this.listHealthUnit || this.listHealthUnit.length <= 0)
      return "";

    return this.listHealthUnit.find(l => l.idHealthUnit == idHealthUnit)?.healthUnitName;
  }

  populateHealthUnitSelect() {
    this.healthUnitService.listHealthUnit().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHealthUnit = response.listHealthUnit;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changeMode(event: any) {
    if (event.checked) {
      var $this = this;
      this.interval = setInterval(function () {
        $this.search(0);
        if (!$this.model.get('panelMode').value) clearInterval($this.interval);
      }, 10000);
    }
    else {
      clearInterval(this.interval)
    }
  }
}
