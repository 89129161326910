import { Component, Input, OnInit } from "@angular/core";


@Component({
  selector: 'app-chip-batch-status',
  templateUrl: './chip-batch-status.component.html',
  styleUrls: ['./chip-batch-status.component.css']
})
export class ChipBatchStatusComponent implements OnInit {

  constructor() { }

  @Input() public status: number;

  ngOnInit(): void {
  }

}
