<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Cadastro Fluxograma</h1>
    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Fluxograma</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="name">
                        <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Ordem</mat-label>
                        <input matInput type="number" formControlName="order">
                        <mat-error *ngIf="model.get('order').invalid">Informe a ordenação</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Idade miníma</mat-label>
                        <input matInput [mask]="masks.age.mask" [pattern]="masks.age.pattern" type="text"
                            formControlName="minAge">
                        <mat-error *ngIf="model.get('minAge').invalid">Idade inválida</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                    <mat-form-field appearance="outline">
                        <mat-label>idade máxima</mat-label>
                        <input matInput [mask]="masks.age.mask" [pattern]="masks.age.pattern" type="text"
                            (keyup)="validateAge($event.target.value, 'max')" formControlName="maxAge">
                        <mat-error *ngIf="model.get('maxAge').invalid">Idade inválida</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo do fluxograma</mat-label>
                        <mat-select formControlName="idFlowchartType">
                            <mat-option *ngFor="let item of listFlowchartType"
                                [value]="item.idFlowchartType">{{item.flowchartTypeName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('idFlowchartType').invalid">Escolha uma opção</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Grupo do fluxograma</mat-label>
                        <mat-select [multiple]="true" formControlName="listIdFlowchartGroup">
                            <mat-option *ngFor="let item of listFlowchartGroup"
                                [value]="item.idFlowchartGroup">{{item.flowchartGroupName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('listIdFlowchartGroup').invalid">Escolha uma opção</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="description">
                        <mat-error *ngIf="model.get('description').invalid">Texto incorreto</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-slide-toggle class="can-be-denied" formControlName="lastCanBeDenied" 
                        matTooltip="Ao ativar esta opção, será possível negar o último discriminador e retornar à tela de fluxogramas.">
                        <mat-label>O último discriminador pode ser negado pela enfermagem</mat-label>
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar fluxograma</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <a mat-stroked-button type="button" (click)="redirectToProtocolRegister()"
                        class="btn-secundary btn-block">
                        Voltar
                    </a>
                </div>
            </div>
        </form>
    </div>
    <div class="white-body">
        <div class="row title-header discriminator-title-header">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <h1>Discriminador</h1>
            </div>
        </div>

        <div class="row priority-container" *ngIf="listPriorityColor && listPriorityColor.length > 0">
            <div class="col-12 priority-container" *ngFor="let color of listPriorityColor">
                <div class="row">
                    <div [ngStyle]="{'background-color': color.coloerHex}"
                        class="nivel-urgencia col-12 col-md-8 col-lg-9">{{color.priorityColorName}}
                    </div>
                    <div class="col-12 col-md-4 col-lg-3">
                        <button class="btn-secundary discriminator-creator-button" mat-stroked-button type="button"
                            (click)="openCreateEditDiscriminatorModal(color.idPriorityColor,null, null)">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            Adicionar novo discriminador
                        </button>
                    </div>
                </div>

                <div class="card-list row" *ngIf="listDiscriminator && listDiscriminator.length > 0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3"
                        *ngFor="let item of this.discriminatorsByColor[color.idPriorityColor]; let j = index;">
                        <div class="card-item">
                            <div class="infos">
                                <span [matTooltip]="item.discriminatorName+' - ('+item.order+')'"
                                    [matTooltipPosition]="'above'" class="primary">{{item.discriminatorName}} -
                                    ({{item.order}})</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openCreateEditDiscriminatorModal(color.idPriorityColor, j, item)">edit
                                    </mat-icon>
                                </a>
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                        (click)="openDeleteDiscriminatorModal(color.idPriorityColor,j)">
                                        delete_forever</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <app-empty-list *ngIf="!listDiscriminator || listDiscriminator.length == 0"></app-empty-list>
            </div>
        </div>
        <app-empty-list *ngIf="!listPriorityColor || listPriorityColor.length == 0"></app-empty-list>
    </div>
</div>