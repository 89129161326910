<div [formGroup]="formGroup">
    <div class="row" formArrayName="{{formArrayName}}"
        *ngFor="let item of formGroup.get(formArrayName)['controls']; let i = index;first as isFirst">
        <div class="col-12 col-sm-6 col-md-3">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="idVariableType">
                        <mat-select-trigger>
                            <mat-icon
                                matTooltip="{{ findVariableType(formGroup.get(formArrayName).value[i].idVariableType)?.description 
                            ?? findVariableType(formGroup.get(formArrayName).value[i].idVariableType)?.variableTypeName}}"
                                aria-hidden="false" class="custom-icon">help
                            </mat-icon>&nbsp;{{
                            findVariableType(formGroup.get(formArrayName).value[i].idVariableType)?.variableTypeName }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of listVariableType" [value]="item.idVariableType">
                            <mat-icon matTooltip="{{item.description ?? item.variableTypeName}}" aria-hidden="false"
                                class="custom-icon">help
                            </mat-icon>
                            {{item.variableTypeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Valor ToLife</mat-label>
                    <input matInput type="text" formControlName="variableToLife">
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <div [formGroupName]="i">
                <mat-form-field appearance="outline">
                    <mat-label>Valor Integração</mat-label>
                    <input matInput type="text" formControlName="variableIntegration">
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <button class="panel-origin-button btn-primary" mat-mini-fab type="button" (click)="addNext()">
                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
            </button>
            <button class="panel-origin-button btn-primary" mat-mini-fab type="button" (click)="removeLine(i)">
                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
            </button>
        </div>
    </div>
</div>

<button *ngIf="formGroup.get(formArrayName)['controls'].length == 0" class="btn-secundary" mat-stroked-button
    type="button" (click)="addNext()">
    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
    Adicionar novo DE-PARA
</button>