import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateManipulationService } from 'src/app/shared/services/API/pharmacy/template-manipulation.service';
import { TemplateModel } from 'src/app/shared/services/models/pharmacy/template.model';
import { TemplateDeleteModalComponent } from './template-delete-modal/template-delete-modal.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {

  constructor(
    private templateManipulationService: TemplateManipulationService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_template;

  public listTemplate: TemplateModel[];
  public isActive: boolean;
  public searchText: string;
  public isLoading: boolean;

  ngOnInit(): void {
    this.listTemplate = [];
    this.isLoading = true;
    this.searchText = null;
    this.isActive = null;
    this.populateScreen();
  }

  populateScreen() {
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    if (this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.templateManipulationService.GetAll(this.searchText, this.isActive).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listTemplate = response.listTemplate;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idTemplate: number) {
    const dialogRef = this.dialog.open(TemplateDeleteModalComponent, {
      data: {
        idTemplate: idTemplate
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteTemplate) {
        this.search();
      }
    });
  }
}
