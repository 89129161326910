import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { CommonService } from '../../common-service';
import { Router } from '@angular/router';
import { AnalyticsRequest } from '../../requests/data-mesh/analytics.request';
import { AnalyticsResponse } from '../../responses/data-mesh/analytics.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ListDataProductResponse } from '../../responses/data-mesh/list-data-product.response';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService extends CommonService {
    private apiUrl = 'https://api.example.com/data-products';

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    readDataProductById(request: AnalyticsRequest): Observable<AnalyticsResponse> {
        let uri = `analytics/read/`;
        return this.httpClient.post<AnalyticsResponse>(environment.urlApiDataMesh + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    listDataProductByProfile(): Observable<ListDataProductResponse> {
        let uri = `analytics/list`;
        return this.httpClient.get<ListDataProductResponse>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    extractDataProduct(request: AnalyticsRequest): Observable<ReturnStruct> {
        let uri = `analytics/extract/`;
        return this.httpClient.post<ReturnStruct>(environment.urlApiDataMesh + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }
}