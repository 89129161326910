<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Alterar ou remover um template de BI</h2>
         </div>
         
         <div class="body-modal-list">
            Ao alterar ou remover um template, todos os relatórios de BI contidos naquele template serão removidos da unidade. <br />
         </div>
         <span class="blue-email">Os relatórios de BI configurados para envio de email ou outros métodos não serão alterados</span>
         <div class="row footer-modal-list">
            <div class = "col-offset-3 col-6">
                <button mat-button class="btn-primary block" (click)="close()">
                    <span *ngIf="isLoading == false">Confirmar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>
</div>
