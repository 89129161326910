import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';

@Injectable({
  providedIn: 'root'
})
export class AdminProtocolService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listProtocol(searchText: string, isActive: boolean): Observable<ListProtocolResponse> {

    let uri = `AdminProtocol?`

    if (searchText) {
      uri = uri + `searchText=${searchText}&`
    }

    if (isActive) {
      uri = uri + `isActive=${isActive}`;
    }



    return this.httpClient.get<ListProtocolResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  listHealthUnitProtocol(idHealthUnit: number): Observable<ListProtocolResponse> {
    let uri = `AdminProtocol/idHealthUnit/${idHealthUnit}`

    return this.httpClient.get<ListProtocolResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
