import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { CacheService } from 'src/app/shared/services/API/cache/cache.service';
import { ProjectCacheService } from 'src/app/shared/services/API/cache/project-cache.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProjectModel } from 'src/app/shared/services/models/cache/project.model';
import { CleanCacheRequest } from 'src/app/shared/services/requests/cache/clean-cache.request';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cache-cleaning',
  templateUrl: './cache-cleaning.component.html',
  styleUrls: ['./cache-cleaning.component.css']
})
export class CacheCleaningComponent implements OnInit {
  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cacheService: CacheService,
    private projectCacheService: ProjectCacheService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog) { }

  public isLoading: boolean = true;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.cleaning;
  public model: FormGroup;
  public listProject: ProjectModel[] = [];
  public listIdHealthUnit: number[] = [];
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";

  ngOnInit(): void {

    this.model = this.formBuilder.group({
      idProject: ['']
    });

    this.isLoading = true;

    this.populateProjectSelect();
  }

  populateProjectSelect() {
    this.projectCacheService.ListProject().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if (!response.listProject || response.listProject.length == 0) {
          this.alertService.show('Erro', 'Não existem projetos cadastrados! Cadastre um projeto e uma URL de cachê relacionado ao mesmo para realizar uma limpeza de cachê.', AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listProject = response.listProject;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    if (!this.listProject || this.listProject.length == 0) {
      this.alertService.show('Erro', 'Não existem projetos cadastrados! Cadastre um projeto e uma URL de cachê relacionado ao mesmo para realizar uma limpeza de cachê.', AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    let body: CleanCacheRequest = new CleanCacheRequest();

    body.idHealthUnit = this.listIdHealthUnit;
    body.idProject = this.model.get('idProject').value ? this.model.get('idProject').value : [];

    this.cacheService.CleanCache(body).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show('Sucesso', 'Cache limpo com sucesso!', AlertType.success);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  selectHealthUnit(event) {
    this.listIdHealthUnit = event.map(x => x.idHealthUnit);
  }
}
