<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consulting/template-type"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Tipos de medicamentos</a>
    <h1>Cadastrar Tipo de medicamento</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">       
            <div class="row">   
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="name">
                        <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="description">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Template</mat-label>
                        <mat-select formControlName="idTemplate">
                          <mat-option *ngFor="let item of listTemplate" value="{{item.idTemplate}}">{{item.templateName}}</mat-option> 
                        </mat-select>
                        <mat-error *ngIf="model.get('idTemplate').invalid">Informe o template</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/template-type" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
