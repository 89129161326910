import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { RecoverPasswordService } from 'src/app/shared/services/API/orchestrator-user/recover-password.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RecoverPasswordResponse } from 'src/app/shared/services/responses/admin-user/recover-password.response';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private recoverPasswordService: RecoverPasswordService,
    private router: Router,
  ) { }

  public isLoading: boolean;
  public model: FormGroup;
  public forgotPassword: RecoverPasswordResponse
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.auth;

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
    });
  }

  submit() {
    if (this.model.invalid || this.isLoading == true)
      return;

    let login = this.model.get('login').value;

    this.isLoading = true;
    this.recoverPasswordService.recoverPasswordToken(login).subscribe({
      next: (response: RecoverPasswordResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', 'Email de recuperação enviado para o email resgistrado', AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/'])
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}