import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';

import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerSuccessRoutingModule } from './customer-success-routing.module';
import { CustomerSuccessComponent } from './pages/customer-success/customer-success.component';
import { CsatConfigComponent } from './pages/csat-config/csat-config.component';
import { DeleteConfirmModalComponent } from './pages/csat-config/delete-confirm-modal/delete-confirm-modal.component';
import { CsatResultComponent } from './pages/csat-result/csat-result.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';


@NgModule({
  declarations: [
    CustomerSuccessComponent,
    CsatConfigComponent,
    DeleteConfirmModalComponent,
    CsatResultComponent,
  ],
  imports: [
    CommonModule,
    CustomerSuccessRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    NgxChartsModule
  ]
})
export class CustomerSuccessModule { }
