<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-totem-modal-list">
            <h2>Relatórios não cadastrados</h2>
         </div>
         
         <div class="body-modal-list">
            <div class="row">
                <div class="col-12 col-sm-6" *ngFor="let item of listReportNotUsed" >
                    <p>{{item.workspaceName}}</p>
                    <div class="box">
                        <p *ngFor="let report of item.listReportName">{{report}}</p>
                    </div>
                </div>
            </div>
         </div>
         <div class="footer-modal-list">
            <button mat-button class="btn-primary block" (click)="close()">Sair</button>
        </div>
    </div>
</div>
