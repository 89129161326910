import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CounselingService } from 'src/app/shared/services/API/risk-classification/counseling.service';
import { CounselingModel } from 'src/app/shared/services/models/risk-classification/counseling.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { CounselingDeleteModalComponent } from './counseling-delete-modal/counseling-delete-modal.component';

@Component({
  selector: 'app-counseling-list',
  templateUrl: './counseling-list.component.html',
  styleUrls: ['./counseling-list.component.css']
})
export class CounselingListComponent implements OnInit {

  constructor(private counselingService: CounselingService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_counseling;

  public listCounseling: CounselingModel[];
  public isLoading: boolean;
  public searchText: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.counselingService.listCounseling(this.searchText).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listCounseling = response.listCounseling;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idCounseling){
    const dialogRef = this.dialog.open(CounselingDeleteModalComponent, {
      data: {
        idCounseling: idCounseling,
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteCounseling){
        this.search();
      }
    });
  }
}
