import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyPassword } from 'src/app/shared/custom-validators/password.validator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FirstAccessService } from 'src/app/shared/services/API/admin-user/first-access.service';
import { FirstAccessRequest } from 'src/app/shared/services/requests/admin-user/first-access.request';

@Component({
  selector: 'app-first-access-modal',
  templateUrl: './first-access-modal.component.html',
  styleUrls: ['./first-access-modal.component.css']
})
export class FirstAccessModalComponent implements OnInit {

  constructor(private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<FirstAccessModalComponent>,
    private formBuilder: FormBuilder,
    private firstAccessService: FirstAccessService) { }

  public isLoading: boolean;
  public model: FormGroup;

  public firstAccessRequest: FirstAccessRequest;

  ngOnInit(): void {
    this.isLoading = false;
    this.firstAccessRequest = new FirstAccessRequest();

    this.matDialogRef.disableClose = true;

    this.model = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, VerifyPassword()]],
      repetedPassword: ['', [Validators.required]],
    });
  }

  submit() {
    if (this.model.invalid)
      return;

    if (this.model.get('newPassword').value != this.model.get('repetedPassword').value) {
      this.alertService.show('Erro', "Os dois campos de senha devem ser iguais", AlertType.error);
      return;
    }

    this.firstAccessRequest.userLogin = this.data.userLogin;
    this.firstAccessRequest.oldPassword = this.model.get('oldPassword').value;
    this.firstAccessRequest.newPassword = this.model.get('newPassword').value;

    this.firstAccessService.firstAccessPassword(this.firstAccessRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show('Sucesso', "Senha atualizada com sucesso!", AlertType.success);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    this.matDialogRef.close();
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}