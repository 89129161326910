<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/bi/report">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Relatórios
    </a>
    <h1>Cadastrar subgrupos</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Cadastrar subgrupos</h1>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="medicineSubgroupName">
                        <mat-error *ngIf="model.get('medicineSubgroupName').invalid">Informe o Nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Grupos Medicamento</mat-label>
                        <mat-select formControlName="idMedicineGroup">
                            <mat-option *ngFor="let item of listAllGroup" [value]="item.idMedicineGroupOrSubgroup">{{item.medicineGroupOrSubgroupName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <textarea matInput type="text" formControlName="medicineSubgroupDescription"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/template-subgroup" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>