import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { MasterComponent } from './pages/master/master.component';
import { ProfileListComponent } from './pages/profile-list/profile-list.component';
import { ProfileRegisterComponent } from './pages/profile-register/profile-register.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserRegisterComponent } from './pages/user-register/user-register.component';

const routes: Routes = [
  { path: '', component: MasterComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'user/register', component: UserRegisterComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileListComponent, canActivate: [AuthGuard] },
  { path: 'profile/register', component: ProfileRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    MatInputModule
  ],
  exports: [RouterModule],
})
export class MasterRoutingModule { }
