import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { HealthGuidelineTriageResponse } from '../../responses/risk-classification/health-guideline-triage.response';

@Injectable({
  providedIn: 'root'
})
export class HealthGuidelineTriageService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public verifyCategorization(idCareLinePriority: number): Observable<HealthGuidelineTriageResponse> {
    return this.httpClient.get<HealthGuidelineTriageResponse>(environment.urlApiRiskClassification + `HealthGuidelineTriage/idCareLinePriority/${idCareLinePriority}`, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}