import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthGuidelineDeleteModalComponent } from './health-guideline-delete-modal/health-guideline-delete-modal.component';
import { ListHealthGuidelineResponse } from 'src/app/shared/services/responses/risk-classification/list-health-guideline.response';
import { HealthGuidelineService } from 'src/app/shared/services/API/risk-classification/health-guideline.service';
import { HealthGuideline } from 'src/app/shared/services/models/risk-classification/health-guideline.model';

@Component({
  selector: 'app-health-guideline-list',
  templateUrl: './health-guideline-list.component.html',
  styleUrls: ['./health-guideline-list.component.css']
})
export class HealthGuidelineListComponent implements OnInit{

  constructor(
    private alertService: AlertService,
    private healthGuidelineService: HealthGuidelineService,
    public dialog: MatDialog
  ) { }
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.health_guideline;
  public listHealthGuideline: HealthGuideline[] = [];
  public isLoading: boolean;

  ngOnInit(): void {
    this.populateScrean();
    this.isLoading = true;
  }

  populateScrean(){
    this.search();
  }

  search(){
    this.isLoading = true;
    this.healthGuidelineService.listGuideline().subscribe({
      next: (response: ListHealthGuidelineResponse)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.listHealthGuideline = response.listHealthGuideline;  
        this.isLoading = false;  
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModalDelete(idHealthGuideline: number){
    const dialogRef = this.dialog.open(HealthGuidelineDeleteModalComponent, {
      data: {
        idHealthGuideline: idHealthGuideline
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.delete) {
        this.search();
      }
    });
  }
}
