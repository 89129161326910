<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/csat"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
        Voltar
    </a>
    <h1>Cadastrar Configurações CSAT</h1>
    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model">
            <div class="title-header">
                <h1>Configurações</h1>
            </div>
            <div formArrayName="listCsatConfig"
                *ngFor="let item of model.get('listCsatConfig')['controls']; let i = index">
                <div class="row" [formGroupName]="i">
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="csatConfigName">
                            <mat-error *ngIf="item.get('csatConfigName').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Periodicidade</mat-label>
                            <mat-select formControlName="idPeriod">
                                <mat-option *ngFor="let item of listPeriod" [value]="item.idPeriod">{{item.periodName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="item.get('idPeriod').invalid">Informe a periodicidade</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Unidades</mat-label>
                            <mat-select formControlName="listIdHealthUnit" multiple
                                (selectionChange)="updateAssignedHealthUnits(i)">
                                <input class="inputSearchSelect" type="text" (keyup)="onKeySearch($event.target.value)"
                                    (keydown)="$event.stopImmediatePropagation()" placeholder="Pesquisar"
                                    autocomplete="off">
                                <mat-option *ngIf="i == index"(click)="updateSelectList(i)" [value]="default">Todas as Unidades</mat-option>
                                <mat-option *ngFor="let item of listHealthUnitDisplay" [value]="item.idHealthUnit"
                                    [disabled]="listAssignedHealthUnits.includes(item.idHealthUnit) && !model.get('listCsatConfig')['controls'][i].get('listIdHealthUnit').value.includes(item.idHealthUnit)">{{item.healthUnitName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="item.get('listIdHealthUnit').invalid">Informe as unidades</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2" *ngIf="!isLoading">
                        <div class="form-field-toggle">
                            <mat-label>{{item.value.isActive ? 'Ativa' : 'Inativa'}}</mat-label>
                            <mat-slide-toggle color="primary" formControlName="isActive">
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2" *ngIf="!isLoading">
                        <button matTooltip="Salvar alterações" class="totem-button btn-primary" mat-mini-fab
                            type="button" (click)="saveConfig(i)" *ngIf="item.value.idCsatConfig == null || item.dirty">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">done</mat-icon>
                        </button>
                        <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="addNext(i)">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                        </button>
                        <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="remove(i)"
                            *ngIf="item.value.idCsatConfig == null">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                        </button>
                        <button matTooltip="Excluir configuração" class="totem-button btn-primary" mat-mini-fab
                            type="button" (click)="openDeleteModal(i)" *ngIf="item.value.idCsatConfig != null">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">delete</mat-icon>
                        </button>
                    </div>
                    <div class="col-2 col-sm-2 col-md-3 spinner-container" *ngIf="isLoading">
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>