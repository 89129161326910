import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListTokenAuthAdminResponse } from '../../responses/user/list-token-auth-admin.response';
import { TokenAuthAdminResponse } from '../../responses/user/token-auth-admin.response';
import { TokenAuthAdminModel } from '../../models/user/token-auth-admin.model';
import { ListUserResponse } from '../../responses/user/list-user.response';

@Injectable({
  providedIn: 'root'
})
export class TokenAuthAdminService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public listTokenAuthAdmin(idAdminUser:number, idHealthUnit:number, dateAccess: string): Observable<ListTokenAuthAdminResponse> {
    
    let uri = 'SuperUser?'
    
    if(idAdminUser != null)
    uri = uri+ `idAdminUser=${idAdminUser}&`;
    
    if(idHealthUnit != null)
    uri = uri+ `idHealthUnit=${idHealthUnit}&`;
    
    if(dateAccess != null)
    uri = uri+ `dateAccess=${dateAccess}`;
    
    return this.httpClient.get<ListTokenAuthAdminResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listTokenAuthAdminWithListHealthUnit(idAdminUser:number, idHealthUnit:number[], dateAccess: string): Observable<ListTokenAuthAdminResponse> {
    
    let uri = 'SuperUser/WithListHealthUnit?';
    
    if(idAdminUser != null)
    uri = uri+ `idAdminUser=${idAdminUser}&`;
    
    if(dateAccess != null)
    uri = uri+ `dateAccess=${dateAccess}`;
    
    return this.httpClient.post<ListTokenAuthAdminResponse>(environment.urlApiUser + uri, idHealthUnit, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listUserHealthUnit(idHealthUnit:number): Observable<ListUserResponse> {
    return this.httpClient.get<ListUserResponse>(environment.urlApiUser + `SuperUser/idHealthUnit/${idHealthUnit}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createAdminUser(tokenAuthAdminModel: TokenAuthAdminModel): Observable<TokenAuthAdminResponse>{
    return this.httpClient.post<TokenAuthAdminResponse>(environment.urlApiUser + `SuperUser`, tokenAuthAdminModel, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    