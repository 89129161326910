import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ReportRequest } from "../../requests/business-intelligence/report.request";
import { GetReportResponse } from "../../responses/business-intelligence/get-report.response";
import { ListReportResponse } from "../../responses/business-intelligence/list-report.response";
import { ListReportNotUsedResponse } from "../../responses/business-intelligence/list-report-not-used.response";
import { UnifiedReportResponse } from "../../responses/business-intelligence/unified-report.response";

@Injectable({
  providedIn: 'root'
})
export class ReportService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listReport(searchText: string, isActive: boolean, isNotUsed: boolean, idHealthUnit: number, ordenation: number): Observable<ListReportResponse> {

    let uri = 'Report?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`

    if (isNotUsed != null)
      uri = uri + `isNotUsed=${isNotUsed}&`

    if (idHealthUnit != null)
      uri = uri + `idHealthUnit=${idHealthUnit}&`

    if (ordenation != null)
      uri = uri + `ordenation=${ordenation}&`



    return this.httpClient.get<ListReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listUnifiedReport(searchText: string, isActive: boolean, isNotUsed: boolean, idHealthUnit: number, isInternal: boolean): Observable<UnifiedReportResponse> {

    let uri = 'Report/getUnified?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    if (isActive != null)
      uri = uri + `isActive=${isActive}&`

    if (isNotUsed != null)
      uri = uri + `isNotUsed=${isNotUsed}&`

    if (idHealthUnit != null)
      uri = uri + `idHealthUnit=${idHealthUnit}&`

    if (isInternal != null)
      uri = uri + `isInternal=${isInternal}&`



    return this.httpClient.get<UnifiedReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }


  public deleteReport(idReport: number): Observable<ReturnStruct> {

    let uri = `Report/idReport/${idReport}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getReport(idReport: number): Observable<GetReportResponse> {

    let uri = `Report/idReport/${idReport}`;

    return this.httpClient.get<GetReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createReport(body: ReportRequest): Observable<ReturnStruct> {

    let uri = `Report`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateReport(idReport: number, body: ReportRequest): Observable<ReturnStruct> {

    let uri = `Report/idReport/${idReport}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public reportNotUsed(): Observable<ListReportNotUsedResponse> {
    let uri = `Report/getNotUsed`;

    return this.httpClient.get<ListReportNotUsedResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
