export class CsatResultRequest {

    constructor(datetimeStart: Date = null, datetimeEnd: Date = null, listIdHealthUnit: number[] = [], listIdModule: number[] = []) {
        if (datetimeStart != null) {
            this.datetimeStart = datetimeStart;
        }

        if (datetimeEnd != null) {
            this.datetimeEnd = datetimeEnd;
        }

        if (listIdHealthUnit != null) {
            this.listIdHealthUnit = listIdHealthUnit;
        }

        if (listIdModule != null) {
            this.listIdModule = listIdModule;
        }
    }

    public listIdHealthUnit: number[];

    public listIdModule: number[];

    public datetimeStart: Date;

    public datetimeEnd: Date;
}
