import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillingRoutingModule } from './billing-routing.module';
import { BillingPlanListComponent } from './pages/billing-plan-list/billing-plan-list.component';
import { BillingPlanRegisterComponent } from './pages/billing-plan-register/billing-plan-register.component';
import { BillingDeleteModalComponent } from './pages/billing-plan-list/billing-delete-modal/billing-delete-modal.component';
import { BillingComponent } from './pages/billing/billing.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BillingConfigListComponent } from './pages/billing-config-list/billing-config-list.component';
import { BillingConfigRegisterComponent } from './pages/billing-config-register/billing-config-register.component';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { BillingConfigDeleteModalComponent } from './pages/billing-config-list/billing-delete-modal/billing-config-delete-modal.component';
import { MonthlyProgrammedBillingComponent } from './pages/monthly-programmed-billing/monthly-programmed-billing.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatCardModule } from '@angular/material/card';
import { FilesService } from './pages/billing-config-register/files.service';
import { BillingConfigContractDeleteModalComponent } from './pages/billing-config-register/billing-config-contract-delete-modal/billing-config-contract-delete-modal.component';
import { BillingInactivationModalComponent } from './pages/billing-config-register/billing-inactivation-modal/billing-inactivation-modal.component';

@NgModule({
  declarations: [
    BillingPlanListComponent,
    BillingPlanRegisterComponent,
    BillingInactivationModalComponent,
    BillingDeleteModalComponent,
    BillingConfigDeleteModalComponent,
    BillingComponent,
    BillingConfigListComponent,
    BillingConfigRegisterComponent,
    MonthlyProgrammedBillingComponent,
    BillingConfigContractDeleteModalComponent
  ],
  imports: [
    CommonModule,
    BillingRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatTooltipModule,
    MatToolbarModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatOptionModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    NgxMatFileInputModule,
    NgxChartsModule,
    MatCardModule,
  ],
  providers: [FilesService],
})
export class BillingModule { }
