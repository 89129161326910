<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Medicamento</h1>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/consulting/template-medication/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Medicamento</a>
        <div class="filters">
            <div class="row">   
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Template</mat-label>
                        <mat-select [(ngModel)]="idTemplate" (selectionChange)="search()">
                            <mat-option [value]="">
                                Todos
                            </mat-option>
                            <mat-option *ngFor="let item of listTemplate" [value]="item.idTemplate">
                                {{item.templateName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> 
            </div>
        </div>
        <div class="card-list" *ngIf="listTemplateMedicine != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listTemplateMedicine">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.templateMedicineDescription}}</span>  
                            <span matTooltip="Template do Medicamento" class="secundary parent-name">{{item.medicineTemplateName}}</span>
                            <span matTooltip="Tipo do Medicamento" class="secundary parent-name">{{item.medicineTypeName}}</span>
                            <span matTooltip="Grupo do Medicamento" class="secundary parent-name">{{item.medicineGroupName}}</span>
                            <span matTooltip="Subgrupo do Medicamento" class="secundary parent-name">{{item.medicineSubgroupName}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/consulting/template-medication/register', {idTemplateMedicine: item.idTemplateMedicine}]">edit</mat-icon> </a>
                            <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idTemplateMedicine)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listTemplateMedicine != undefined && listTemplateMedicine.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>