<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/support/emerges">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Emerges
    </a>
    <h1>Cadastrar Emerges</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>ID da Unidade de Saúde</mat-label>
                        <input matInput type="number" formControlName="idHealthUnit">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome da Unidade de Saúde</mat-label>
                        <input matInput type="text" formControlName="healthUnitName">
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Informe o nome da unidade de saúde
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>CNPJ</mat-label>
                        <input [readonly]="this.isUpdate" matInput type="text" formControlName="cnpj"
                            [mask]="masks.cnpj">
                        <mat-error *ngIf="model.get('cnpj').invalid">Informe o CNPJ</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input matInput type="email" formControlName="email">
                        <mat-error *ngIf="model.get('email').invalid">Informe o e-mail</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/support/emerges" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>