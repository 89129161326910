import { Injectable } from '@angular/core';
import { FilterSelectModel } from '../components/filter-select/filter-select.model';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  creatInitials(userName):string{
    if(userName.split(' ').length == 2){
      return userName.split(' ')[0][0] + userName.split(' ')[1][0];
    }
    else{
      return userName[0];
    }
  }

  getKeySelectedModel(filterSelectModel: FilterSelectModel){

    if(filterSelectModel == null)
      return null;

    let listKeys: number[] = [];

    filterSelectModel.itemSelectModel.forEach(element => {
      if (element.checked)
      listKeys.push(parseInt(element.key))
    });  
    
    if(listKeys.length == 0)
      return null;

    return JSON.stringify(listKeys);
  }

  setSecundaryHeaderModuleConfig(data: any, moduleName: string): void{
    sessionStorage.setItem(moduleName, JSON.stringify(data));
  }

  getSecundaryHeaderModuleConfig(moduleName: string): any{
    const data: string = sessionStorage.getItem(moduleName);
    return data ? JSON.parse(data) : false
  }

  cleanSecundaryHeaderModuleConfig(moduleName: string): void{
    sessionStorage.removeItem(moduleName);
  }

  toISOFormat(dateTimeString) {
    const [DD, MM, YYYY] = dateTimeString.split('/');
    return `${YYYY}-${MM}-${DD}`;
  }

  dataAtualFormatada(dateToFormat: Date) {
    if (dateToFormat) {
      var data = new Date(dateToFormat),
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    }
    return null;
  }
}
