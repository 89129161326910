import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { RecoverPasswordResponse } from '../../responses/admin-user/recover-password.response';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService extends CommonService {

  constructor(private httpClient: HttpClient,) {
    super();
  }

  public recoverPasswordToken(login: string): Observable<RecoverPasswordResponse> {

    let body = { login: login };

    return this.httpClient.post<RecoverPasswordResponse>(environment.urlApiOrchestratorUser + "AdminRecoverPassword", body)
      .pipe(
        catchError(this.handleError)
      )
  }
}