import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LogBatchVisualizerService } from 'src/app/shared/services/API/srvlog/log-batch-visualizer.service';
import { LogBatchMessage } from 'src/app/shared/services/models/srvlog/log-batch-message.model';
import { LogBatch } from 'src/app/shared/services/models/srvlog/log-batch.model';

@Component({
  selector: 'app-log-batch-visualizer-view',
  templateUrl: './log-batch-visualizer-view.component.html',
  styleUrls: ['./log-batch-visualizer-view.component.css']
})
export class LogBatchVisualizerViewComponent implements OnInit, OnDestroy {

  constructor(private logBatchVisualizerService: LogBatchVisualizerService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.log_batch_list;

  public logBatch: LogBatch = new LogBatch();
  public listLogBatchMessage: LogBatchMessage[] = [];
  public idLogBatch: number;
  public isLoading: boolean;
  public interval: any;
  public counter: number = 0;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('idLogBatch'))
      this.idLogBatch = parseInt(this.activatedRoute.snapshot.paramMap.get('idLogBatch'));
    this.startTime();
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }

  startTime() {
    if (this.counter == 0)
      {
        this.populateView();
      }

    this.interval = setInterval(populateView, 15000);

    var $this = this;

    function populateView(){

      if ($this.counter > 720 || $this.logBatch.idLogBatchStatus > 1) // 4 x por minuto por 180 minutos e processamento não finalizado
      {
          clearInterval($this.interval);
      }
      
      $this.counter++;

      $this.populateView();
    }
}

populateView() {
  this.logBatchVisualizerService.getLog(this.idLogBatch).subscribe((response) => {
    if (response.isError) {
      this.alertService.show('Erro', response.errorDescription, AlertType.error);
      this.isLoading = false;
      return;
    }
    this.logBatch = response.getCompleteLogBatchStruct.logBatch;
    this.listLogBatchMessage = response.getCompleteLogBatchStruct.listLogBatchMassages.sort(x => x.idLogBatchMessage);
    this.isLoading = false;
  },
    (error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
}
}
