<div mat-dialog-content>
    <div class="mat-dialog-content">

        <h1>Visualização de Log Batch</h1>
        <div class="title-header">
            <h1>Dados:</h1>
        </div>
        <div class="row">
            <div class="col-md-3">
                <app-chip-batch-status [status]="logBatch.idLogBatchStatus"></app-chip-batch-status>
            </div>
            <div class="col-md-3">
                <label>batchID: </label>
                <p>{{logBatch.idLogBatch}}</p>
            </div>
            <div class="col-md-3">
                <label>Nome do projeto: </label>
                <p>{{logBatch.nameProject}}</p>
            </div>
            <div class="col-md-3">
                <label>Mensagem de início: </label>
                <p>{{logBatch.message}}</p>
            </div>
            <div class="col-md-3">
                <label>Mensagem de conclusão: </label>
                <p>{{logBatch.messageFinish}}</p>
            </div>
            <div class="col-md-3">
                <label>Data e hora de início do processo: </label>
                <p>{{logBatch.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</p>
            </div>
            <div class="col-md-3">
                <label>Data e hora de fim do processo: </label>
                <p>{{logBatch.datetimeFinish | date:'dd/MM/yyyy HH:mm:ss'}}</p>
            </div>
        </div>
        <div class="title-header">
            <h1>Mensagens:</h1>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12" *ngFor="let item of listLogBatchMessage">
                <div class="message">
                    <label>
                        <p>{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</p>
                    </label>
                    <p>{{item.message}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <a mat-stroked-button type="button" (click)="close()" class="btn-secundary btn-float">
                    Voltar
                </a>
            </div>
        </div>
        <!-- <div class="white-body">
        </div> -->
    </div>
</div>