import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateManipulationService } from 'src/app/shared/services/API/pharmacy/template-manipulation.service';
import { TemplateMedicineTypeService } from 'src/app/shared/services/API/pharmacy/template-medicine-type.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { TemplateModel } from 'src/app/shared/services/models/pharmacy/template.model';
import { TemplateMedicineTypeRequest } from 'src/app/shared/services/requests/pharmacy/template-medicine-type.request';

@Component({
  selector: 'app-template-type-register',
  templateUrl: './template-type-register.component.html',
  styleUrls: ['./template-type-register.component.css']
})
export class TemplateTypeRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private templateMedicineTypeService: TemplateMedicineTypeService,
    private templateManipulationService: TemplateManipulationService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_template_type;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
  public listTemplate: TemplateModel[];

  public masks: Masks;
  public idMedicineType: number;
  public templateMedicineTypeRequest: TemplateMedicineTypeRequest;

  ngOnInit(): void {

    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      idTemplate: ['', [Validators.required]],
      description: [''],
    });

    this.masks = this.maskService.getMasks();
    this.listAllTemplate();
    this.templateMedicineTypeRequest = new TemplateMedicineTypeRequest();

    this.idMedicineType == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idMedicineType'))
      this.idMedicineType = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicineType'));

    if (this.idMedicineType != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateMedicineTypeData();
      this.model.get('idTemplate').disable();
    }
  }

  submit() {
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.templateMedicineTypeRequest.medicineTypeDescription = this.model.get('description').value;
    this.templateMedicineTypeRequest.idTemplate = this.model.get('idTemplate').value;
    this.templateMedicineTypeRequest.medicineTypeName = this.model.get('name').value;

    if (this.isUpdate)
      this.updateMedicineType();
    else
      this.createMedicineType();
  }

  populateMedicineTypeData() {
    this.templateMedicineTypeService.getTemplateMedicineType(this.idMedicineType).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('name').setValue(response.medicineType.medicineTypeName);
      this.model.get('idTemplate').setValue(response.medicineType.idTemplate.toString());
      this.model.get('description').setValue(response.medicineType.medicineTypeDescription);

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateMedicineType() {
    this.templateMedicineTypeService.updateTemplateMedicineType(this.idMedicineType, this.templateMedicineTypeRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/template-type']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createMedicineType() {
    this.templateMedicineTypeService.createTemplateMedicineType(this.templateMedicineTypeRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/template-type']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  listAllTemplate(){
    this.templateManipulationService.GetAll(null, null).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listTemplate = response.listTemplate;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
