<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consulting/self-triage-discriminator">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Perguntas</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <app-question *ngIf="!isFirstLoading" [question]="question" [answerChild]="answerChild"
            [answerParent]="answerParent" [listButtonType]="listButtonType" [idParent]="idParent"
            (createChildQuestion)="reloadQuestion($event)"></app-question>
    </div>
</div>