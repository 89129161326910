import { FunctionHealthUnitStruct } from "../../structs/business-intelligence/function-health-unit-struct";

export class ReportRequest{

    public reportName: string;
    
    public isActive: boolean;
    
    public workspace: string;
    
    public biReport: string;
    
    public width: number;
    
    public height: number;
    
    public reportLink: string;
    
    public ordenation: number;
    
    public listFunctionHealthUnitStruct: FunctionHealthUnitStruct[];

    public pageNumber: number;
}