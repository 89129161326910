export class DiscriminatorModel {
    public idDiscriminator: number;
    public discriminatorName: string;
    public description: string;
    public order: number;
    public idDiscriminatorType: number;
    public minValueAllowed: number;
    public maxValueAllowed: number;
    public minValue2Allowed: number;
    public maxValue2Allowed: number;
    public idFlowchart: number;
    public idPriorityColor: number;
    public minAge: string;
    public maxAge: string;
    public advice: string;
    public isRequired: boolean;
}