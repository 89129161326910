<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/bi/internal-report"><mat-icon aria-hidden="false"
            aria-label="Plus">arrow_back</mat-icon> Relatórios internos</a>
    <h1>Cadastrar Relatório interno</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Dados do relatório interno</h1>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="internalReportName">
                        <mat-error *ngIf="model.get('internalReportName').invalid">Informe o Nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="isActive">
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Ordenação</mat-label>
                        <input matInput type="number" formControlName="ordenation">
                        <mat-error *ngIf="model.get('ordenation').invalid">Informe a ordenação</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Workspace</mat-label>
                        <mat-select formControlName="workspace"
                            (selectionChange)="populateBiInternalReportSelect($event.value)">
                            <mat-option *ngFor="let item of listWorkspace" [value]="item.id">{{item.workspaceName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Report BI</mat-label>
                        <mat-select formControlName="biReport" (selectionChange)="setInternalReportLink($event)">
                            <mat-option *ngFor="let item of listBiReport" [value]="item.id">{{item.biReportName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Link relatório interno</mat-label>
                        <input matInput type="text" formControlName="internalReportLink">
                        <mat-error *ngIf="model.get('internalReportLink').invalid">Informe o link</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Largura</mat-label>
                        <input matInput type="number" formControlName="width">
                        <mat-error *ngIf="model.get('width').invalid">Informe a largura</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Altura</mat-label>
                        <input matInput type="number" formControlName="height">
                        <mat-error *ngIf="model.get('height').invalid">Informe a altura</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Número da página</mat-label>
                        <input matInput type="text" formControlName="pageNumber">
                        <mat-error *ngIf="model.get('pageNumber').invalid">Informe o número da página</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="title-header">
                <h1>Funções por perfil</h1>
            </div>
            <div formArrayName="listInternalReportFunctionProfile"
                *ngFor="let item of model.get('listInternalReportFunctionProfile')['controls']; let i = index">
                <div class="row" [formGroupName]="i">
                    <div class="col-4 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Função</mat-label>
                            <input matInput type="text" formControlName="function">
                            <mat-error *ngIf="item.get('function').invalid">Informe a função</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Perfil</mat-label>
                            <mat-select formControlName="idProfile">
                                <mat-option *ngFor="let item of listProfile"
                                    [value]="item.idProfile">{{item.profileName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2 col-sm-2 col-md-3">
                        <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="addNext()">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                        </button>
                        <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="removeButton(i)"
                            *ngIf="i != 0">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/bi/report" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>