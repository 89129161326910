<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-totem-modal-list">
            <h2>Discriminador</h2>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submitDiscriminator()">
            <div class="body-modal-list">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Idade miníma</mat-label>
                            <input matInput [mask]="masks.age.mask" [pattern]="masks.age.pattern" type="text"
                                formControlName="minAge">
                            <mat-error *ngIf="model.get('minAge').invalid">Idade inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Idade máxima</mat-label>
                            <input matInput [mask]="masks.age.mask" [pattern]="masks.age.pattern" type="text"
                                (keyup)="validateAge($event.target.value, 'max')" formControlName="maxAge">
                            <mat-error *ngIf="model.get('minAge').invalid">Idade inválida</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo do Discriminador</mat-label>
                            <mat-select formControlName="idDiscriminatorType">
                                <mat-option *ngFor="let item of data.listDiscriminatorType"
                                    [value]="item.idDiscriminatorType">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-slide-toggle class="is-required-toggle" formControlName="isRequired"></mat-slide-toggle>
                        <mat-label> Preenchimento {{this.model.get('isRequired').value? 'obrigatório' :
                            'não obrigatório'}}</mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Ordem</mat-label>
                            <input matInput type="number" formControlName="order">
                            <mat-error *ngIf="model.controls.order.errors?.incomparable">
                                Ordem inválida <mat-icon
                                    [matTooltip]="'Há um outro discriminador nessa ordem que não possui faixa etária'">help</mat-icon>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Valor mínimo 1</mat-label>
                            <input matInput type="number" formControlName="minValueAllowed">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Valor máximo 1</mat-label>
                            <input matInput type="number" formControlName="maxValueAllowed">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Valor mínimo 2</mat-label>
                            <input matInput type="number" formControlName="minValue2Allowed">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Valor máximo 2</mat-label>
                            <input matInput type="number" formControlName="maxValue2Allowed">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4">
                        <app-select [preSelectedlist]="preSelectCounseling" (selectedValue)="selectCounseling($event)"
                            [multiple]="true" [list]="data.listCounseling" [idField]="'idCounseling'"
                            [nameField]="'counselingName'" [labelField]="'Conselho por telefone'"></app-select>
                    </div>
                    <div class="col-12 col-sm-12 col-md-5">
                        <mat-form-field appearance="outline">
                            <mat-label>descrição</mat-label>
                            <textarea matInput type="text" formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="footer-modal-list">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <a mat-stroked-button type="button" (click)="clickCancel()" class="btn-secundary btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>