import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListEvasionTimeResponse } from '../../responses/user/list-evation-time.response';

@Injectable({
  providedIn: 'root'
})
export class EvasionTimeService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public listEvasionTime(): Observable<ListEvasionTimeResponse> {

    let uri = 'EvasionTime/getAll'

    return this.httpClient.get<ListEvasionTimeResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
    
    