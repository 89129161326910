import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingConfigService } from 'src/app/shared/services/API/billing-by-health-unit/billing-config.service';
import { BillingConfigListStruct } from 'src/app/shared/services/structs/billing-by-health-unit/billing-config-list.struct';
import { BillingConfigDeleteModalComponent } from './billing-delete-modal/billing-config-delete-modal.component';

@Component({
  selector: 'app-billing-config-list',
  templateUrl: './billing-config-list.component.html',
  styleUrls: ['./billing-config-list.component.css']
})
export class BillingConfigListComponent implements OnInit {

  constructor(private alertService: AlertService,
    private dialog: MatDialog,
    private billingConfigService: BillingConfigService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.billing_config_list;

  public isLoading: boolean;
  public searchText: string;
  public listBillingConfig: BillingConfigListStruct[];

  ngOnInit(): void {
    this.isLoading = true;
    this.searchText = null;

    this.search();
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.billingConfigService.listBillingConfig(this.searchText).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listBillingConfig = response.listBillingConfig;
        this.isLoading = false;
      },
      error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openDeleteModal(idBillingConfig: number) {
    const dialogRef = this.dialog.open(BillingConfigDeleteModalComponent, {
      data: {
        idBillingConfig: idBillingConfig,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.deleteConfig) {
          this.search();
        }
      }
    });
  }
}