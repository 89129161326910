import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';
import { ListHealthGuidelineResponse } from '../../responses/risk-classification/list-health-guideline.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { GetHealthGuidelineResponse } from '../../responses/risk-classification/get-health-guideline.response';
import { HealthGuidelineRequest } from '../../requests/risk-classification/health-guideline.request';

@Injectable({
  providedIn: 'root'
})
export class HealthGuidelineService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listGuideline(): Observable<ListHealthGuidelineResponse> {

    let uri = `HealthGuideline?`

    return this.httpClient.get<ListHealthGuidelineResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getGuideline(idHealthGuideline: number): Observable<GetHealthGuidelineResponse> {
    let uri = `HealthGuideline/idHealthGuideline/${idHealthGuideline}`

    return this.httpClient.get<GetHealthGuidelineResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  updateGuideline(idHealthGuideline: number, healthGuidelineRequest: HealthGuidelineRequest): Observable<ReturnStruct> {
    let uri = `HealthGuideline/idHealthGuideline/${idHealthGuideline}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiRiskClassification + uri, healthGuidelineRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  craeteGuideline(healthGuidelineRequest: HealthGuidelineRequest): Observable<ReturnStruct> {
    let uri = `HealthGuideline`

    return this.httpClient.post<ReturnStruct>(environment.urlApiRiskClassification + uri, healthGuidelineRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteGuideline(idHealthGuideline: number): Observable<ReturnStruct> {
    let uri = `HealthGuideline/idHealthGuideline/${idHealthGuideline}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
