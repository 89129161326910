import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogBatchStatusEnum } from 'src/app/shared/enum/log-batch-status.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BatchMonitoringService } from 'src/app/shared/services/API/srvlog/batch-monitoring.service';
import { ListBatchMonitoringStruct } from 'src/app/shared/services/structs/srvlog/list-batch-monitoring.struct';

@Component({
  selector: 'app-log-batch-monitoring',
  templateUrl: './log-batch-monitoring.component.html',
  styleUrls: ['./log-batch-monitoring.component.css']
})
export class LogBatchMonitoringComponent implements OnInit {

  constructor(
    private router: Router,
    private alertService: AlertService,
    private batchMonitoringService: BatchMonitoringService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.batch_monitoring;

  public isPanelMode: boolean;

  public statusProcessing = LogBatchStatusEnum.Processing;
  public statusSuccess = LogBatchStatusEnum.Success;
  public statusWarning = LogBatchStatusEnum.Warning;
  public statusError = LogBatchStatusEnum.Error;
  public isLoading: boolean;
  public listBatch: ListBatchMonitoringStruct[];

  ngOnInit(): void {
    this.list();
  }

  list() {
    this.isLoading = true;
    this.batchMonitoringService.listBatch().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listBatch = response.listBatchMonitoring;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  details(projectName: string, idLogBatch: number = null) {
    this.router.navigate([`/developer/log-batch-monitoring/details`, { project: projectName, idLogBatch: idLogBatch }]);
  }

  changeMode(event: any) {
    if (event.checked) {
      var $this = this;
      var interval = setInterval(function () {
        $this.list();
        if (interval && !$this.isPanelMode) { clearInterval(interval) };
      }, 10000);
    }
  }
}
