import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetLogResponse } from '../../responses/integration-siga/get-log-siga.response';
import { GetLogsResponse } from '../../responses/integration-siga/get-logs-siga.response';
import { GetSensitiveLogDataResponse } from '../../responses/integration-siga/get-sensitive-log-siga-data.response';

@Injectable({
  providedIn: 'root'
})
export class LogSigaVisualizerService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }


  public listLog(idLog: number, idRequestType: number,idLogRequestType: number, searchText: string, startDateRange: Date, endDateRange: Date, pageIndex: number): Observable<GetLogsResponse> {

    let uri = 'LogVisualizer?'

    if (idLog)
      uri = uri + `idLog=${idLog}`;

    if (idRequestType)
      uri = uri + `&idRequestType=${idRequestType}`;
    
    if (idLogRequestType)
      uri = uri + `&idLogRequestType=${idLogRequestType}`;

    if (searchText)
      uri = uri + `&searchText=${searchText}`;

    if (startDateRange)
      uri = uri + `&startDateRange=${startDateRange.toISOString()}`;

    if (endDateRange)
      uri = uri + `&endDateRange=${endDateRange.toISOString()}`;

    if (pageIndex)
      uri = uri + `&pageIndex=${pageIndex}`;

    return this.httpClient.get<GetLogsResponse>(environment.urlApiIntegrationSiga + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getLog(idLog: number): Observable<GetLogResponse> {

    let uri = `LogVisualizer/idLog/${idLog}`

    return this.httpClient.get<GetLogResponse>(environment.urlApiIntegrationSiga + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getLogSensitiveData(idLog: number): Observable<GetSensitiveLogDataResponse> {

    let uri = `LogVisualizer/idLog/${idLog}`

    return this.httpClient.put<GetSensitiveLogDataResponse>(environment.urlApiIntegrationSiga + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
