export class RelationTriggersProcedureSigtapRequest {
    public listRelation: RelationStruct[];
    public idProcedureSigtapCompetence: number;
}

export class RelationStruct {

    public idRelationTriggersProcedureSigtap: number;

    public idTriggersProcedureSigtap: number;

    public idProcedureSigtap: number;

    public datetimeInclusion: Date;

    public idProcedureSigtapCompetence: number;
}