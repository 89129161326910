import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { HealthUnitRelationRequest } from '../../requests/integration-relation-config/health-unit-relation.request';
import { AllHealthUnitRelationResponse } from '../../responses/integration-relation-config/all-health-unit-relation.response';
import { HealthUnitRelationResponse } from '../../responses/integration-relation-config/health-unit-relation.response';

@Injectable({
  providedIn: 'root'
})
export class IntegrationRelationConfigService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  List(idHealthUnit: number, idIntegrationType: number): Observable<AllHealthUnitRelationResponse> {
    let uri = `Config?`

    if (idHealthUnit) {
      uri = uri + `idHealthUnit=${idHealthUnit}&`
    }

    if (idIntegrationType) {
      uri = uri + `idIntegrationType=${idIntegrationType}`
    }
    return this.httpClient.get<AllHealthUnitRelationResponse>(environment.urlApiIntegrationRelationConfig + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  ListWithHealthUnitList(idHealthUnit: number[], idIntegrationType: number): Observable<AllHealthUnitRelationResponse> {
    let uri = `Config/GetAll?`

    if (idIntegrationType) {
      uri = uri + `idIntegrationType=${idIntegrationType}`
    }
    return this.httpClient.post<AllHealthUnitRelationResponse>(environment.urlApiIntegrationRelationConfig + uri, idHealthUnit, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  Delete(idHealthUnitRelation: number): Observable<ReturnStruct> {
    let uri = `Config/idHealthUnitRelation/${idHealthUnitRelation}`
    return this.httpClient.delete<ReturnStruct>(environment.urlApiIntegrationRelationConfig + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  Read(idHealthUnitRelation: number): Observable<HealthUnitRelationResponse> {
    let uri = `Config/idHealthUnitRelation/${idHealthUnitRelation}`
    return this.httpClient.get<HealthUnitRelationResponse>(environment.urlApiIntegrationRelationConfig + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  Create(body: HealthUnitRelationRequest): Observable<ReturnStruct> {
    let uri = `Config`
    return this.httpClient.post<ReturnStruct>(environment.urlApiIntegrationRelationConfig + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  Update(idHealthUnitRelation: number, body: HealthUnitRelationRequest): Observable<ReturnStruct> {
    let uri = `Config/idHealthUnitRelation/${idHealthUnitRelation}`
    return this.httpClient.put<ReturnStruct>(environment.urlApiIntegrationRelationConfig + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
