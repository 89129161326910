<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consulting/self-triage-protocol">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>{{isUpdate ? "Editar" : "Cadastrar"}} Protocolo</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Protocolo</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-4 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="protocolName">
                        <mat-error *ngIf="model.get('protocolName').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-4 col-md-3">
                    <app-select-2 [multiple]="true" [url]="this.urlListHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" 
                        [formControlField]="'idHealthUnit'" [optionAll]="true" [allOptionText]="'TODAS AS UNIDADES'">
                    </app-select-2>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <textarea matInput type="text" formControlName="protocolDescription">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!isFirstLoading" class="row">
                <div class="card-list" *ngIf="listAssignedFlowchart != null">
                    <div class="row">
                        <div class="title-header">
                            <h1>
                                Fluxogramas deste protocolo
                            </h1>
                        </div>
                        <div *ngIf="!listAssignedFlowchart" class="col-12 col-sm-6 col-md-4">
                        </div>
                        <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listAssignedFlowchart">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">{{item.name}}</span>
                                    <span class="secundary">{{item.description}}</span>
                                    <span class="secundary">Idade mínima: {{item.minAge}}</span>
                                    <span class="secundary">Idade máxima: {{item.maxAge}}</span>
                                    <span class="secundary">Ordem: {{item.order}}</span>
                                </div>
                                <div class="actions">
                                    <a (click)="exchange(item.idFlowchart, listAssignedFlowchart, listUnassignedFlowchart)">
                                        <mat-icon aria-hidden="false" aria-label="Remover">
                                            remove
                                        </mat-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-list" *ngIf="listUnassignedFlowchart != null">
                    <div class="row">
                        <div class="title-header">
                            <div>

                            </div>
                            <h1>
                                Fluxogramas disponíveis
                            </h1>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listUnassignedFlowchart">
                            <div class="card-item">
                                <div class="infos">
                                    <span class="primary">{{item.name}}</span>
                                    <span class="secundary">{{item.description}}</span>
                                    <span class="secundary">Idade mínima: {{item.minAge}}</span>
                                    <span class="secundary">Idade máxima: {{item.maxAge}}</span>
                                    <span class="secundary">Ordem: {{item.order}}</span>
                                </div>
                                <div class="actions">
                                    <a (click)="exchange(item.idFlowchart, listUnassignedFlowchart, listAssignedFlowchart)">
                                        <mat-icon aria-hidden="false" aria-label="Adicionar">
                                            add
                                        </mat-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                        </mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/self-triage-protocol"
                        class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>