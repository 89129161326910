import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ModuleServiceSpecialtiesRequest } from '../../requests/user/module-service-specialties.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ModuleServiceSpecialtiesResponse } from '../../responses/user/module-service-specialties.response';

@Injectable({
  providedIn: 'root'
})
export class ModuleServiceSpecialtiesService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public postPut(request: ModuleServiceSpecialtiesRequest): Observable<ReturnStruct> {

    let uri = 'ModuleServiceSpecialties'

    return this.httpClient.post<ReturnStruct>(environment.urlApiUser + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getAll(): Observable<ModuleServiceSpecialtiesResponse> {

    let uri = 'ModuleServiceSpecialties'

    return this.httpClient.get<ModuleServiceSpecialtiesResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}