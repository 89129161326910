import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { SelfTriageProtocolRequest } from '../../requests/self-triage/self-triage-protocol.request';
import { ListSelfTriageProtocolResponse } from '../../responses/self-triage/list-self-triage-protocol.response';
import { SelfTriageProtocolResponse } from '../../responses/self-triage/self-triage-protocol.response';

@Injectable({
  providedIn: 'root'
})
export class SelfTriageProtocolService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listProtocol(searchText: string, idHealthUnit: number): Observable<ListSelfTriageProtocolResponse> {

    let uri = `Protocol/GetAll?`

    if (searchText) {
      uri = uri + `searchText=${searchText}&`
    }

    if (idHealthUnit) {
      uri = uri + `idHealthUnit=${idHealthUnit}`;
    }

    return this.httpClient.get<ListSelfTriageProtocolResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getProtocol(idProtocol: number): Observable<SelfTriageProtocolResponse> {

    let uri = `Protocol/idProtocol/${idProtocol}`

    return this.httpClient.get<SelfTriageProtocolResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  createProtocol(request: SelfTriageProtocolRequest): Observable<ReturnStruct> {
    let uri = `Protocol`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSelfTriage + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  updateProtocol(request: SelfTriageProtocolRequest, idProtocol: number): Observable<ReturnStruct> {
    let uri = `Protocol/idProtocol/${idProtocol}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiSelfTriage + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteProtocol(idProtocol: number): Observable<ReturnStruct> {
    let uri = `Protocol/idProtocol/${idProtocol}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
