<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <div class="header-info">

        <h1>Especialidades</h1>
        <div class="row container-button">
            <div class="col-12 col-sm-4 col-md-2">
                <button mat-flat-button (click)="submit()" color="primary" class="btn-primary btn-block">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>
    <div class="white-body">
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <a mat-button class="btn-primary add-user" routerLink="/consulting/specialty/manage-specialties"><mat-icon aria-hidden="false"
                aria-label="Plus">add</mat-icon>Gerenciar especialidades</a>

        <div class="col-12 col-md-6">
            <form [formGroup]="model">
                <div formArrayName="listModules">
                    <div *ngFor="let moduleControl of listModules.controls; let i = index" [formGroupName]="i">
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Nome Módulo</mat-label>
                                    <input matInput type="text" formControlName="idModule" disabled>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field appearance="outline">
                                    <mat-label>Especialidades</mat-label>
                                    <mat-select formControlName="listIdSpecialty" multiple>
                                        <mat-option (click)="toggleSelectAll(i)">
                                            Todas as opções
                                        </mat-option>
                                        <mat-option *ngFor="let specialty of listSpecialtyAttendance" [value]="specialty.idSpecialityAttendance">
                                            {{ specialty.specialityAttendanceName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>