import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListReportTypeResponse } from '../../responses/report/list-report-type.response';

@Injectable({
  providedIn: 'root'
})
export class ReportTypeService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listReportTypeWithTemplate(): Observable<ListReportTypeResponse>{
    let uri = `ReportType`
      
    return this.httpClient.get<ListReportTypeResponse>(environment.urlReport + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
