import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { CsatConfigService } from 'src/app/shared/services/API/customer-success/csat-config.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { PeriodModel } from 'src/app/shared/services/models/customer-success/period.model';
import { CsatConfigRequest } from 'src/app/shared/services/requests/customer-success/csat-config.request';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { CsatConfigStruct } from 'src/app/shared/services/structs/customer-success/csat-config.struct';
import { DeleteConfirmModalComponent } from './delete-confirm-modal/delete-confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ListHealthUnitService } from 'src/app/shared/services/API/user/list-health-unit.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-csat-config',
  templateUrl: './csat-config.component.html',
  styleUrls: ['./csat-config.component.css']
})
export class CsatConfigComponent implements OnInit, OnChanges {
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private listHealthUnitService: ListHealthUnitService,
    private csatConfigService: CsatConfigService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.customer_success;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.customer_success_csat_config;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public listHealthUnit: ListHealthUnitStruct[] = [];
  public listHealthUnitDisplay: ListHealthUnitStruct[] = [];
  public listAssignedHealthUnits: number[] = [];
  public listPeriod: PeriodModel[] = [];
  public listCsatConfig: CsatConfigStruct[] = [];
  public listIdHealthUnits: number[] = [];
  public selectAll: boolean = false;
  public index: number = 0;

  public masks: Masks;
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";
  public default: number = -1;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      listCsatConfig: this.formBuilder.array([]),
    });
    this.populateHealthUnitSelect();
    this.populateCsatConfig();
  }

  updateAssignedHealthUnits(index: number = null) {
    if (this.model.dirty) {
      this.listAssignedHealthUnits = [];
      this.model.get('listCsatConfig')['controls'].forEach((form, i) => {
        this.listAssignedHealthUnits = this.listAssignedHealthUnits.concat(form.get('listIdHealthUnit').value);
        if (index != null) {

          if (i != index) {
            form.get('listIdHealthUnit').disable();
          }
        }

        else {
          form.get('listIdHealthUnit').enable();
        }
      });
    }
  }

  clearForms() {
    this.model = this.formBuilder.group({
      listCsatConfig: this.formBuilder.array([]),
    });
  }

  createInput(csatConfig: CsatConfigStruct = null) {
    if (csatConfig != null) {
      return this.formBuilder.group({
        idCsatConfig: [csatConfig.idCsatConfig],
        csatConfigName: [csatConfig.csatConfigName, [Validators.required]],
        idPeriod: [csatConfig.idPeriod, [Validators.required]],
        isActive: [csatConfig.isActive],
        listIdHealthUnit: [csatConfig.listIdHealthUnit, [Validators.required]]
      });
    }

    else {
      return this.formBuilder.group({
        idCsatConfig: [null],
        csatConfigName: [null, [Validators.required]],
        idPeriod: [null, [Validators.required]],
        isActive: [true],
        listIdHealthUnit: [[], [Validators.required]],
      });
    }
  }

  addNext(index: number, csatConfig: CsatConfigStruct = null) {
    let formArray = this.model.controls['listCsatConfig'] as FormArray;
    if (csatConfig != null) {
      formArray.insert(index + 1, this.createInput(csatConfig))
    }

    else {
      formArray.insert(index + 1, this.createInput())
    }
  }

  remove(index: number) {
    let formArray = this.model.controls['listCsatConfig'] as FormArray;
    formArray.removeAt(index);

    if (formArray.length == 0) {
      this.addNext(0);
    }

    this.updateAssignedHealthUnits();
  }

  saveConfig(index: number) {
    let formArray = this.model.controls['listCsatConfig'].value as FormArray
    let formConfig = formArray[index];

    if (this.model.get('listCsatConfig')['controls'][index].invalid) {
      this.model.get('listCsatConfig')['controls'][index].markAllAsTouched();
      this.alertService.show('Aviso', 'Preencha todos os campos em vermelho', AlertType.warning);
      return;
    }



    let request: CsatConfigRequest = new CsatConfigRequest();

    request.csatConfigName = formConfig.csatConfigName;
    request.idPeriod = formConfig.idPeriod;
    request.isActive = formConfig.isActive;
    request.listIdHealthUnit = formConfig.listIdHealthUnit;

    if (formConfig.idCsatConfig) {
      request.idCsatConfig = formConfig.idCsatConfig
      this.updateConfig(request);
    }

    else {
      this.createConfig(request);
    }

    this.clearForms();
  }

  createConfig(request: CsatConfigRequest) {
    this.isLoading = true;
    this.csatConfigService.createCsatConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.clearForms();
        this.populateCsatConfig();
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateConfig(request: CsatConfigRequest) {
    this.isLoading = true;
    this.csatConfigService.updateCsatConfig(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.clearForms();
        this.populateCsatConfig();
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openDeleteModal(index: number) {
    const dialogRef = this.dialog.open(DeleteConfirmModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.delete) {
        this.deleteConfig(index);
      }
    });
  }

  deleteConfig(index: number) {
    this.isLoading = true;
    let formArray = this.model.controls['listCsatConfig'].value as FormArray;

    let idCsatConfig = formArray[index].idCsatConfig;
    this.csatConfigService.deleteCsatConfig(idCsatConfig).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.clearForms();
        this.populateCsatConfig();
        this.alertService.show('Sucesso', "Configuração excluída com sucesso", AlertType.success);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });

  }

  onKeySearch(event: any) {
    if (event != null) {
      this.listHealthUnitDisplay = this.listHealthUnit.filter(x => x.healthUnitName.toLocaleLowerCase().includes(event.toLocaleLowerCase()));
    }

    else {
      this.listHealthUnitDisplay = this.listHealthUnit;
    }
  }

  populateHealthUnitSelect() {
    this.listHealthUnitService.listHealthUnit(null, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listHealthUnit = response.list;
        this.listHealthUnitDisplay = this.listHealthUnit;
        this.listHealthUnit.forEach((listHealthUnit, index) =>
        {
          this.listIdHealthUnits.push(listHealthUnit.idHealthUnit);
        });
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCsatConfig() {
    this.isLoading = true;
    this.csatConfigService.getConfigs().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listPeriod = response.listPeriod;

        if (response.listCsatConfig && response.listCsatConfig.length > 0) {
          this.listCsatConfig = response.listCsatConfig;

          this.listCsatConfig.forEach((config, index) => {
            this.addNext(index, config);
            this.listAssignedHealthUnits = this.listAssignedHealthUnits.concat(config.listIdHealthUnit);
          });

        }

        else {
          this.addNext(0);
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateSelectList(index: number = null)
  {
    if(!this.selectAll)
    {
    this.listIdHealthUnits.push(this.default);
    this.model.get('listCsatConfig')['controls'][index].get('listIdHealthUnit').setValue(this.listIdHealthUnits);
    this.selectAll = !this.selectAll;
    this.updateAssignedHealthUnits(index);
    }
    else
    {
      this.model.get('listCsatConfig')['controls'][index].get('listIdHealthUnit').setValue(null);
      this.selectAll = !this.selectAll;
      this.updateAssignedHealthUnits(index);
    }
  }
}