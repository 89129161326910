<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Perguntas da Autotriagem</h1>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/consulting/self-triage-discriminator/register">
            <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Pergunta
        </a>
        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="idFlowchart" (selectionChange)="search()">
                            <mat-option value="">Todos</mat-option>
                            <mat-option *ngFor="let item of flowcharts" [value]="item.idFlowchart">{{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="questions != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of questions">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.statement}}</span>
                            <span class="secundary">Ordem: {{item.order}}</span>
                            <span class="secundary">{{item.datetimeInclusion | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                            <span class="secundary">{{item.flowchartName}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/consulting/self-triage-discriminator/register', {idQuestion: item.idQuestion, idParent: item.idQuestion}]">
                                    edit
                                </mat-icon>
                            </a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idQuestion)">
                                    delete_forever</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="questions != undefined && questions.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>