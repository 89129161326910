import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LogBatchStatusEnum } from 'src/app/shared/enum/log-batch-status.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LogBatchStatusService } from 'src/app/shared/services/API/srvlog/log-batch-status.service';
import { LogBatchVisualizerService } from 'src/app/shared/services/API/srvlog/log-batch-visualizer.service';
import { LogBatchStatus } from 'src/app/shared/services/models/srvlog/log-batch-status.model';
import { LogBatch } from 'src/app/shared/services/models/srvlog/log-batch.model';

@Component({
  selector: 'app-log-batch-visualizer-list',
  templateUrl: './log-batch-visualizer-list.component.html',
  styleUrls: ['./log-batch-visualizer-list.component.css']
})
export class LogBatchVisualizerListComponent implements OnInit {

  constructor(private alertService: AlertService,
    private logBatchVisualizerService: LogBatchVisualizerService,
    private logBatchStatusService: LogBatchStatusService,) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.log_batch_list;

  public isLoading: boolean;
  public idStatus: number;
  public searchText: string;
  public listLogBatch: LogBatch[];
  public listLogBatchStatus: LogBatchStatus[];

  public fullListSize: number;
  public isPanelMode: boolean;
  public latestId: number;

  ngOnInit(): void {
    this.populateStatusSelect();
    this.latestId = 0;
    this.search(0);
  }

  search(pageIndex: number) {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    this.logBatchVisualizerService.listLogBatch(this.searchText, this.idStatus, pageIndex).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      if (this.isPanelMode ) {
        for (let index = 0; response.listLogBatchStruct.listLogs[index]?.idLogBatch <= this.latestId; index++) {
          if (response.listLogBatchStruct.listLogs[index].idLogBatch == LogBatchStatusEnum.Error) {
            let audio = new Audio();
            audio.src = "assets/audios/log-error.wav"
            audio.load();
            audio.play();
          }
        }
        this.latestId = response.listLogBatchStruct.listLogs[0]?.idLogBatch
      }
      this.fullListSize = response.listLogBatchStruct.fullListSize;
      this.listLogBatch = response.listLogBatchStruct.listLogs;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  buttonSearch() {
    if (this.isLoading)
      return;

    this.paginator.firstPage();
    this.search(0);
  }

  populateStatusSelect() {
    this.logBatchStatusService.listLogBatchStatus().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listLogBatchStatus = response.listLogBatchStatus;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changeMode(event: any) {
    if (event.checked) {
      var $this = this;
      var interval = setInterval(function () {
        $this.search(0);
        if (!$this.isPanelMode) clearInterval(interval);
      }, 10000);
    }
  }
}