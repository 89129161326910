<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/master/exam-type"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Protocolos de saúde</a>
    <h1>Cadastrar Protocolo de saúde</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">       
            <div class="row">   
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="name">
                        <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-select-2 [multiple]="true" [url]="this.urlListHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" 
                        [formControlField]="'listIdHealthUnit'">
                    </app-select-2>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="isActive">
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4">
                    <app-select-2 [multiple]="true" [url]="this.urlListProtocol" (selectedValue)="selectedProtocol($event)"
                        [idField]="'id'" [nameField]="'label'" [labelField]="'Protocolos'" 
                        [formControlField]="'listIdProtocol'">
                    </app-select-2>
                </div>
                <div class="col-12 col-md-4" *ngIf="listFlowchart && listFlowchart.length > 0">
                    <app-select-2 [multiple]="true" [list]="listFlowchart" (selectedValue)="selectedFlowchart($event)"
                        [idField]="'id'" [nameField]="'label'" [labelField]="'Fluxogramas'" 
                        [formControlField]="'listIdFlowchart'">
                    </app-select-2>
                </div>
                <div class="col-12 col-md-4" *ngIf="listDiscriminator && listDiscriminator.length > 0">
                    <app-select-2 [multiple]="true" [list]="this.listDiscriminator"
                        [idField]="'id'" [nameField]="'label'" [labelField]="'Discriminadores'" 
                        [formControlField]="'listIdDiscriminator'">
                    </app-select-2>
                </div>
            </div>

            <h2>Categorizações</h2>

            <div formArrayName="listCategorization" 
                *ngFor="let item of model.get('listCategorization')['controls']; let i = index" >
                <div class="row" [formGroupName]="i">
                    <div class="col-4 col-sm-4 col-md-3" >
                        <mat-form-field appearance="outline">
                            <mat-label>Nome da categotia/grupo</mat-label>
                            <input matInput type="text" formControlName="categorizationName">
                            <mat-error *ngIf="item.get('categorizationName').invalid">Informe o nome da categoria/grupo</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-3" >
                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <input matInput type="text" formControlName="description">
                            <mat-error *ngIf="item.get('description').invalid">Informe a descrição</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Linha de cuidado</mat-label>
                            <mat-select formControlName="idCareLinePriority">
                                <mat-option *ngFor="let item of listCareLinePriority"
                                    [value]="item.idCareLinePriority">{{item.careLinePriorityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>   
                    <div class="col-2 col-sm-2 col-md-3">
                        <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="addNext()">
                            <mat-icon aria-hidden="false" aria-label="Menu apps" >add</mat-icon>
                        </button>
                        <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="removeButton(i)" *ngIf="(model.get('listCategorization')['controls'].length > 1)">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                        </button>
                    </div>                
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/health-guideline/list" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>


