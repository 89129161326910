import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CoordinationService } from 'src/app/shared/services/API/billing/coordination.service';
import { Coordination } from 'src/app/shared/services/models/billing/coordination.model';
import { CoordinationDeleteModalComponent } from './coordination-delete-modal/coordination-delete-modal.component';

@Component({
  selector: 'app-coordination-list',
  templateUrl: './coordination-list.component.html',
  styleUrls: ['./coordination-list.component.css']
})
export class CoordinationListComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private coordinationService: CoordinationService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_coordination;

 
  public searchText: string;
  public isLoading: boolean;
  public listCoordination: Coordination[];

  ngOnInit(): void {
    this.isLoading = true;
    this.searchText = null;
    this.populateScreen();
  }

  populateScreen() {
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    if (this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.coordinationService.GetAll(this.searchText).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.alertService.show('Alerta', response.errorDescription, AlertType.warning);
        } else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listCoordination = response.listCoordination;
        this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(idCoordination: number) {
    const dialogRef = this.dialog.open(CoordinationDeleteModalComponent, {
      data: {
        idCoordination: idCoordination
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteCoordination) {
        this.search();
      }
    });
  }
}
