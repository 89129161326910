import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { GetAdminUserResponse } from 'src/app/shared/services/responses/admin-user/get-admin-user.response';
import { ListAdminUserResponse } from '../../responses/admin-user/list-admin-user.response';
import { UserRegisterRequest } from '../../requests/admin-user/user-register-request';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public listAdminUser(isActive: boolean, searchText: string, jsonListIdDepartment: string): Observable<ListAdminUserResponse> {
    
    let uri = 'AdminUser?'
    
    if(isActive != null)
    uri = uri+ `isActive=${isActive}&`;
    
    if(searchText != null)
    uri = uri+ `searchText=${searchText}&`;
    
    if(jsonListIdDepartment != null)
    uri = uri+ `jsonListIdDepartment=${jsonListIdDepartment}&`;
    
    return this.httpClient.get<ListAdminUserResponse>(environment.urlApiAdminUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteAdminUser(idAdminUser: number): Observable<ReturnStruct>{
    return this.httpClient.delete<ReturnStruct>(environment.urlApiAdminUser + `AdminUser/idAdminUser/${idAdminUser}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getAdminUser(idAdminUser: number): Observable<GetAdminUserResponse>{
    return this.httpClient.get<GetAdminUserResponse>(environment.urlApiAdminUser + `AdminUser/idAdminUser/${idAdminUser}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateAdminUser(idAdminUser: number, updateUserRequest: UserRegisterRequest): Observable<ReturnStruct>{
    return this.httpClient.put<ReturnStruct>(environment.urlApiAdminUser + `AdminUser/idAdminUser/${idAdminUser}`, updateUserRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createAdminUser(createUserRequest: UserRegisterRequest): Observable<ReturnStruct>{
    return this.httpClient.post<ReturnStruct>(environment.urlApiAdminUser + `AdminUser`, createUserRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    