import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-billing-inactivation-modal',
  templateUrl: './billing-inactivation-modal.component.html',
  styleUrls: ['./billing-inactivation-modal.component.css']
})
export class BillingInactivationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BillingInactivationModalComponent>,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ inactivatePlan: false });
  }

  clickDelete() {
    this.matDialogRef.close({ inactivatePlan: true });
  }

  clickCancel() {
    this.matDialogRef.close({ inactivatePlan: false });
  }
}