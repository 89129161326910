<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <form class="form" [formGroup]="model" (ngSubmit)="search()">
        <div class="row">
            <div class="col-12 col-md-4">
                <h1>Logs externos</h1>
            </div>
            <div class="col-12 col-md-8">
                <mat-slide-toggle formControlName="panelMode" (change)="changeMode($event)">Modo
                    Painel</mat-slide-toggle>
            </div>
        </div>
        <div class="white-body">
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Log ID</mat-label>
                            <input matInput type="number" id="idLog" formControlName="idLog">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" formControlName="searchText">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-select-2 [multiple]="true" [url]="this.urlListHealthUnit"
                        [formControlField]="'idHealthUnit'"[idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" [optionAll]="true" [allOptionText]="'TODAS AS UNIDADES'">
                    </app-select-2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="E">E</mat-option>
                                <mat-option value="W">W</mat-option>
                                <mat-option value="S">S</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Seleciona o período</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate formControlName="start" placeholder="Início">
                                <input matEndDate formControlName="end" placeholder="Fim">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-button class="btn-primary btn-block" type="submit">
                            <span *ngIf="!isLoading">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-list" *ngIf="listLogs != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listLogs">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.idLog}}<ng-container *ngIf="item.idHealthUnit"> -
                                        {{this.getHealthUnitName(item.idHealthUnit)}} </ng-container></span>
                                <span class="secundary">{{item.projectSource}}</span>
                                <span class="secundary">{{item.functionSource}}</span>
                                <span class="secundary-status last-info">{{item.datetimeInclusion | date:'dd/MM/yyyy
                                    HH:mm:ss'}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Visualizar"
                                        [routerLink]="['/developer/log-list/view', {idLog: item.idLog, externalLogs: true}]">
                                        visibility
                                    </mat-icon>
                                </a>
                            </div>
                            <app-chip-status [status]="item.type"></app-chip-status>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="this.fullListSize" #paginator [pageIndex]="0" [pageSize]="30"
                        aria-label="Select page" (page)="search($event.pageIndex)">
                    </mat-paginator>
                </div>
            </div>

            <app-empty-list *ngIf="listLogs != undefined && listLogs.length == 0"></app-empty-list>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </form>
</div>