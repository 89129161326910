<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Produtos de dados</h1>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/datamesh/data-product/register"><mat-icon
                aria-hidden="false" aria-label="Plus">add</mat-icon> Novo produto</a>
        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Search</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listDataProduct != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listDataProduct">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.name}}</span>
                            <span class="secundary">{{item.datetimeInclusion | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Edit"
                                    [routerLink]="['/datamesh/data-product/register', {idDataProduct: item.idDataProduct}]">edit</mat-icon>
                            </a>
                            <a><mat-icon aria-hidden="false" aria-label="Delete"
                                    (click)="deleteDataProduct(item.idDataProduct)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listDataProduct != undefined && listDataProduct.length == 0"></app-empty-list>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>