import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateMedicineGroupService } from 'src/app/shared/services/API/pharmacy/template-medicine-grouop.service';
import { TemplateMedicineTypeService } from 'src/app/shared/services/API/pharmacy/template-medicine-type.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { TemplateMedicineGroupRequest } from 'src/app/shared/services/requests/pharmacy/template-medicine-group.request';
import { MedicineTypeTemplateStruct } from 'src/app/shared/services/structs/pharmacy/medicine-type-template.struct';

@Component({
  selector: 'app-template-group-register',
  templateUrl: './template-group-register.component.html',
  styleUrls: ['./template-group-register.component.css']
})
export class TemplateGroupRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private templateMedicineGroupService: TemplateMedicineGroupService,
    private templateMedicineTypeService: TemplateMedicineTypeService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_template_group;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
  public listMedicineType: MedicineTypeTemplateStruct[];

  public masks: Masks;
  public idMedicineGroup: number;
  public templateMedicineGroupRequest: TemplateMedicineGroupRequest;

  ngOnInit(): void {

    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      idMedicineType: ['', [Validators.required]],
      description: [''],
    });

    this.masks = this.maskService.getMasks();
    this.listAllType();
    this.templateMedicineGroupRequest = new TemplateMedicineGroupRequest();

    this.idMedicineGroup == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idMedicineGroup'))
      this.idMedicineGroup = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicineGroup'));

    if (this.idMedicineGroup != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateMedicineGroupData();
      this.model.get('idMedicineType').disable();
    }
  }

  submit() {
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.templateMedicineGroupRequest.medicineGroupDescription = this.model.get('description').value;
    this.templateMedicineGroupRequest.idMedicineType = this.model.get('idMedicineType').value;
    this.templateMedicineGroupRequest.medicineGroupName = this.model.get('name').value;

    if (this.isUpdate)
      this.updateMedicineGroup();
    else
      this.createMedicineGroup();
  }

  populateMedicineGroupData() {
    this.templateMedicineGroupService.getTemplateMedicineGroup(this.idMedicineGroup).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('name').setValue(response.medicineGroup.medicineGroupName);
      this.model.get('idMedicineType').setValue(response.medicineGroup.idMedicineType.toString());
      this.model.get('description').setValue(response.medicineGroup.medicineGroupDescription);

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateMedicineGroup() {
    this.templateMedicineGroupService.updateTemplateMedicineGroup(this.idMedicineGroup, this.templateMedicineGroupRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/template-group']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createMedicineGroup() {
    this.templateMedicineGroupService.createTemplateMedicineGroup(this.templateMedicineGroupRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/template-group']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  listAllType(){
    this.templateMedicineTypeService.listAllTemplateMedicineType().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listMedicineType = response.listMedicineType;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}