import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReportService } from 'src/app/shared/services/API/business-intelligence/report.service';
import { ListReportNotUsedStruct } from 'src/app/shared/services/structs/business-intelligence/list-report-not-used-struct';

@Component({
  selector: 'app-report-power-bi-modal',
  templateUrl: './report-power-bi-modal.component.html',
  styleUrls: ['./report-power-bi-modal.component.css']
})
export class ReportPowerBiModalComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportPowerBiModalComponent>,
    private reportService: ReportService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;
  public listReportNotUsed: ListReportNotUsedStruct[];


  ngOnInit(): void {
    this.loadReport();
    this.isLoading = false;
  }


  loadReport() {

    this.isLoading = true;

    this.reportService.reportNotUsed().subscribe({next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listReportNotUsed = response.listReportNotUsed;
        this.isLoading = false;
      },
      error: (error)=> {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  
  close(){
    this.matDialogRef.close(); 
  }
  

}
