<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <div class="row">
        <div class="col-12 col-md-4">
            <h1>Logs Batch</h1>
        </div>
        <div class="col-12 col-md-8">
            <mat-slide-toggle [(ngModel)]="this.isPanelMode" (change)="changeMode($event)">Modo Painel</mat-slide-toggle>
        </div>
    </div>
    <div class="white-body">
        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Nome do Projeto</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText">
                    </mat-form-field>
                </div>
                <div class="col-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="idStatus">
                            <mat-option ngValue="null">Todos</mat-option>
                            <mat-option *ngFor="let item of listLogBatchStatus" value="{{item.idLogBatchStatus}}">
                                {{item.logBatchStatusName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button class="btn-primary btn-block" (click)="buttonSearch()" >
                        <span *ngIf="!isLoading">Pesquisar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listLogBatch != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listLogBatch">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.idLogBatch}} - {{item.nameProject.split('Worker.')[1]}} </span>
                            <span class="secundary">{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                            <span class="secundary">{{item.datetimeFinish | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Visualizar"
                                    [routerLink]="['/developer/log-batch-list/view', {idLogBatch: item.idLogBatch}]">
                                    visibility
                                </mat-icon>
                            </a>
                        </div>
                        <app-chip-batch-status [status]="item.idLogBatchStatus"></app-chip-batch-status>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <mat-paginator [length]="this.fullListSize" #paginator [pageIndex]="0" [pageSize]="30" aria-label="Select page" (page)="changePage($event)">
                </mat-paginator>
            </div>
        </div>

        <app-empty-list *ngIf="listLogBatch != undefined && listLogBatch.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>