<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

    <div class="container-body">
        <div class="white-body">
            <a class="back-link" [routerLink]="['/origin-group']">
                <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
            </a>
            <h1 *ngIf="!isUpdate">Cadastro de novo Grupo de procedência</h1>
            <h1 *ngIf="isUpdate">Editar Grupo de procedência</h1>
            <hr />
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="filters">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do grupo</mat-label>
                                <input matInput type="text" formControlName="name">
                                <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de chegada</mat-label>
                        <mat-select formControlName="listIdArrivalType" multiple>
                            <mat-option *ngFor="let item of listArrivalType" value="{{item.idArrivalType}}">
                                {{item.arrivalTypeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Origem</mat-label>
                        <mat-select formControlName="listIdOrigin" multiple>
                            <mat-option *ngFor="let item of listOrigin" value="{{item.idOrigin}}">
                                {{item.originName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                

                <div style="display: flex; align-items: flex-end;">
                    <div class="spacing-button-back col-md-2">
                        <button mat-flat-button type="button" [routerLink]="['/origin-group']"
                            color="accent" class="btn-secundary btn-block">
                            <span *ngIf="isLoading == false">Cancelar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button mat-flat-button type="submit" color="primary" class="btn-primary btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>