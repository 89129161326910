import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthUnitGroupAdminService } from 'src/app/shared/services/API/user/health-unit-group-admin.service';
import { ListHealthUnitService } from 'src/app/shared/services/API/user/list-health-unit.service';
import { HealthUnitGroupModel } from 'src/app/shared/services/models/user/health-unit-group-model.model';
import { HealthUnitAdminStruct } from 'src/app/shared/services/structs/user/health-unit-admin.struct';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-health-unit-list',
  templateUrl: './health-unit-list.component.html',
  styleUrls: ['./health-unit-list.component.css']
})
export class HealthUnitListComponent implements OnInit {

  constructor(
    private listHealthUnitService: ListHealthUnitService,
    private healthUnitGroupAdminService: HealthUnitGroupAdminService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_healthUnit;

  public listHealthUnit: HealthUnitAdminStruct[];
  public searchText: string;
  public isActive: boolean;
  public isLoading: boolean;
  public idHealthUnitGroup: number;
  public listHealthUnitGroup: HealthUnitGroupModel[];

  ngOnInit(): void {
    this.populateScreen();
    this.populateHealthUnitGroupSelect()

    this.listHealthUnit == null;
    this.isLoading = true;
    this.isActive = null;
    this.searchText = null;
  }

  populateScreen(){     
    //Listando usuários
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  search(){
    if(this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.listHealthUnitService.listHealthUnit(this.searchText, this.idHealthUnitGroup).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listHealthUnit = response.list;  
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateHealthUnitGroupSelect() {
    this.healthUnitGroupAdminService.ListHealthUnitGroup().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHealthUnitGroup = response.listHealthUnitGroup;
    },
    (error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
