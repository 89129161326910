import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListHealthUnitSelectResponse } from '../../responses/user/list-health-unit-select.response';

@Injectable({
  providedIn: 'root'
})
export class ListHealthUnitService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public listHealthUnit(searchText: string, idHealthUnitGroup: number): Observable<ListHealthUnitSelectResponse> {
    
    let uri = 'ListHealthUnit?'
  
    if(searchText != null)
    uri = uri+ `searchText=${searchText}&`;

    if(idHealthUnitGroup != null)
    uri = uri+ `idHealthUnitGroup=${idHealthUnitGroup}&`;
    
    return this.httpClient.get<ListHealthUnitSelectResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    