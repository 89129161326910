import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { SupervisionResponse } from '../../responses/billing/supervision.response';
import { ListSupervisionResponse } from '../../responses/billing/list-supervision.response';
import { SupervisionRequest } from '../../requests/billing/supervision.request';

@Injectable({
  providedIn: 'root'
})
export class SupervisionService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  Get(idSupervision: number): Observable<SupervisionResponse>{
    let uri = `Supervision/idSupervision/${idSupervision}`;

    return this.httpClient.get<SupervisionResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  GetAll(searchText: string): Observable<ListSupervisionResponse>{
    let uri = `Supervision/getAll`;
    
    if(searchText)
      uri = uri + `?searchText=${searchText}`;

    return this.httpClient.get<ListSupervisionResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Create(request: SupervisionRequest): Observable<ReturnStruct>{
    let uri = `Supervision`;
  
    return this.httpClient.post<SupervisionResponse>(environment.urlApiBilling + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Update(idSupervision: number, request: SupervisionRequest): Observable<ReturnStruct>{
    let uri = `Supervision/idSupervision/${idSupervision}`;
  
    return this.httpClient.put<SupervisionResponse>(environment.urlApiBilling + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Delete(idSupervision: number): Observable<ReturnStruct>{
    let uri = `Supervision/idSupervision/${idSupervision}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
