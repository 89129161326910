<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal">
            <h1>Alerta !</h1>
        </div>
        <div class="body-modal">
            <p>{{this.body}}</p>
        </div>

        <div class="footer-modal row">
            <div class="col-12">
                <button mat-button class="btn-primary block" (click)="close()">
                    <span>Fechar</span>
                </button>
            </div>
        </div>
    </div>
</div>