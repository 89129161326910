<div class="container-out" (click)="close()" *ngIf="isVisible == true"></div>

<div class="container-user" *ngIf="isVisible == true">
    <a class="name">{{initials}}</a>
    <h2>{{userLoginResponse.userName}}</h2>
    <h4>{{userLoginResponse.healthUnit.healthUnitName}}</h4>
    
    <div class="action">
        <ul>
            <li>
                <a routerLink="/config">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon> 
                    <span>Configurar conta</span>
                    <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                </a>
            </li>
        </ul>
    </div>

    <div class="text-left">
        <button mat-button type="button" (click)="logout()">
            Sair
        </button>
    </div>
</div>