import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';

@Component({
  selector: 'app-data-mesh',
  templateUrl: './data-mesh.component.html',
  styleUrls: ['./data-mesh.component.css']
})
export class DataMeshComponent implements OnInit {

  constructor() { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.datamesh;

  ngOnInit(): void {
  }

}
