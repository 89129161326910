import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FlowchartService } from 'src/app/shared/services/API/self-triage/flowchart.service';
import { Flowchart } from 'src/app/shared/services/models/self-triage/flowchart.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { SelfTriageFlowchartDeleteModalComponent } from './self-triage-flowchart-delete-modal/self-triage-flowchart-delete-modal.component';

@Component({
  selector: 'app-self-triage-flowchart-list',
  templateUrl: './self-triage-flowchart-list.component.html',
  styleUrls: ['./self-triage-flowchart-list.component.css']
})
export class SelfTriageFlowchartListComponent implements OnInit {

  constructor(private flowchartService: FlowchartService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
  ) { }
    
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_flowchart;
    
  
  public isLoading: boolean;
  public searchText: string;
  public idFlowchart: number;
  public listFlowchart: Flowchart[];

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
      this.searchText = null;
    
    if(!this.idFlowchart)
      this.idFlowchart = null;

    this.flowchartService.listFlowchart(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listFlowchart = response.listFlowchart;  
    
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
  
  openModal(idFlowchart){
    const dialogRef = this.dialog.open(SelfTriageFlowchartDeleteModalComponent, {
      data: {
        idFlowchart: idFlowchart
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteFlowchart){
        this.search();
      }
    });
  }
}
