import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-report-source-modal',
  templateUrl: './report-source-modal.component.html',
  styleUrls: ['./report-source-modal.component.css']
})
export class ReportSourceModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportSourceModalComponent>,
  ) { }

  public isInternal: boolean = false;

  ngOnInit(): void {
  }

  chooseInternal() {
    this.matDialogRef.close({ isInternal: true });
  }

  chooseExternal() {
    this.matDialogRef.close({ isInternal: false });
  }
}
