import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupService } from 'src/app/shared/services/API/flow/lookup.service';
import { SpecialtyService } from 'src/app/shared/services/API/flow/specialty.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { SpecialtyAttendanceModel } from 'src/app/shared/services/models/flow/specialty-attendance.model';
import { ManageSpecialtiesRequest } from 'src/app/shared/services/requests/flow/health-unit-relation.request';

@Component({
  selector: 'app-manage-specialties',
  templateUrl: './manage-specialties.component.html',
  styleUrls: ['./manage-specialties.component.css']
})
export class ManageSpecialtiesComponent implements OnInit {
  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private lookupService: LookupService,
    private specialtyService: SpecialtyService,
    private router: Router,
  ) { }

  menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_origin_arrival_type;

  model: FormGroup;
  isLoading: boolean = true;
  isFirstLoading: boolean = true;

  masks: Masks = this.maskService.getMasks();
  listSpecialtyAttendance: SpecialtyAttendanceModel[] = [];
  listSpecialtyDefault: SpecialtyAttendanceModel[] = [];
  listSpecialtyNotDefaultDeleted: AbstractControl[] = [];

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      listSpecialtyNotDefault: this.formBuilder.array([]),
    });

    this.populateSpecialtyAttendanceSelect();
  }

  submit() {
    if (this.isLoading)
      return;

    this.model.markAllAsTouched();

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    let request = new ManageSpecialtiesRequest();

    request = {
      listSpecialtyDefault: this.listSpecialtyDefault,
      listSpecialtyAttendance: (this.model.controls['listSpecialtyNotDefault'] as FormArray).value,
    };

    this.saveSpecialties(request);
  }

  saveSpecialties(request: ManageSpecialtiesRequest) {
    this.specialtyService.saveSpecialties(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show('Sucesso', 'Especialidades salvas com sucesso!', AlertType.success);
        this.isLoading = false;

        const url = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([`/${url}`]).then(() => {
          })
        });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSpecialtyAttendanceSelect() {
    this.lookupService.listSpecialtyAttendance().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isFirstLoading = false;
          this.isLoading = false;  
          return;
        }

        if (response && response.listSpecialityAttendance && response.listSpecialityAttendance.length > 0) {
          this.listSpecialtyAttendance = response.listSpecialityAttendance;
          this.listSpecialtyDefault = this.listSpecialtyAttendance.filter(x => x.isDefault);

          const notDefaultSpecialties = this.listSpecialtyAttendance.filter(x => !x.isDefault);
          const specialtiesFormArray = this.model.get('listSpecialtyNotDefault') as FormArray;

          notDefaultSpecialties.forEach(specialty => {
            specialtiesFormArray.push(this.formBuilder.group({
              idSpecialityAttendance: [specialty.idSpecialityAttendance],
              specialityAttendanceName: [specialty.specialityAttendanceName, Validators.required],
              isCreate: [false, Validators.required],
              isDeleted: [false, Validators.required]
            }));
          });
        }

        this.isFirstLoading = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isFirstLoading = false;
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createSpecialtyInput() {
    return this.formBuilder.group({
      idSpecialityAttendance: [0],
      specialityAttendanceName: [, Validators.required],
      isCreate: [true, Validators.required],
      isDeleted: [false, Validators.required]
    });
  }

  addNextSpecialty() {
    (this.model.controls['listSpecialtyNotDefault'] as FormArray).push(this.createSpecialtyInput());
  }

  removeSpecialty(index: number) {
    var item: AbstractControl = (this.model.controls['listSpecialtyNotDefault'] as FormArray).at(index);
    if (!item.value.isCreate) {
      (this.model.controls['listSpecialtyNotDefault'] as FormArray).at(index).get('isDeleted').setValue(true);
      this.listSpecialtyNotDefaultDeleted.push((this.model.controls['listSpecialtyNotDefault'] as FormArray).at(index));
    }

    (this.model.controls['listSpecialtyNotDefault'] as FormArray).removeAt(index);
  }
}
