import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { BillingConfigListComponent } from './pages/billing-config-list/billing-config-list.component';
import { BillingConfigRegisterComponent } from './pages/billing-config-register/billing-config-register.component';
import { BillingPlanListComponent } from './pages/billing-plan-list/billing-plan-list.component';
import { BillingPlanRegisterComponent } from './pages/billing-plan-register/billing-plan-register.component';
import { BillingComponent } from './pages/billing/billing.component';
import { MonthlyProgrammedBillingComponent } from './pages/monthly-programmed-billing/monthly-programmed-billing.component';

const routes: Routes = [
  { path: '', component: BillingComponent, canActivate: [AuthGuard] },
  { path: 'plan-list', component: BillingPlanListComponent, canActivate: [AuthGuard] },
  { path: 'plan-register', component: BillingPlanRegisterComponent, canActivate: [AuthGuard] },
  { path: 'config', component: BillingConfigListComponent, canActivate: [AuthGuard] },
  { path: 'config-register', component: BillingConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'monthly-programmed-billing', component: MonthlyProgrammedBillingComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }