import { AgeStruct } from "../../structs/risk-classification/age.struct";

export class FlowchartRequest{
    name: string;
    maxAge: string;
    minAge: string;
    minAgeStruct: AgeStruct;
    maxAgeStruct: AgeStruct;
    description: string;
    order: number;
    questionsSelected: number;
}