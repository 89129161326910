import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule, MatOptionModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DeveloperRoutingModule } from './developer-routing.module';
import { LogVisualizerListComponent } from './pages/log-visualizer-list/log-visualizer-list.component';
import { LogVisualizerViewComponent } from './pages/log-visualizer-view/log-visualizer-view.component';
import { LogVisualizerConfirmationModalComponent } from './pages/log-visualizer-view/log-visualizer-confirmation-modal/log-visualizer-confirmation-modal.component';
import { DeveloperComponent } from './pages/developer/developer.component';
import { LogBatchVisualizerListComponent } from './pages/log-batch-visualizer-list/log-batch-visualizer-list.component';
import { LogBatchVisualizerViewComponent } from './pages/log-batch-visualizer-view/log-batch-visualizer-view.component';
import { BatchConfigListComponent } from './pages/batch-config-list/batch-config-list.component';
import { BatchDeleteModalComponent } from './pages/batch-config-list/batch-delete-modal/batch-delete-modal.component';
import { BatchRunModalComponent } from './pages/batch-config-list/batch-run-modal/batch-run-modal.component';
import { BatchConfigRegisterComponent } from './pages/batch-config-register/batch-config-register.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ExternalLogsVisualizerListComponent } from './pages/external-logs-visualizer-list/external-logs-visualizer-list.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { CacheListComponent } from './pages/cache-list/cache-list.component';
import { CacheCleaningComponent } from './pages/cache-cleaning/cache-cleaning.component';
import { ProjectRegisterComponent } from './pages/project-register/project-register.component';
import { CacheRegisterComponent } from './pages/cache-register/cache-register.component';

@NgModule({
  declarations: [
    LogVisualizerListComponent,
    LogVisualizerViewComponent,
    LogVisualizerConfirmationModalComponent,
    DeveloperComponent,
    LogBatchVisualizerListComponent,
    LogBatchVisualizerViewComponent,
    BatchConfigListComponent,
    BatchDeleteModalComponent,
    BatchRunModalComponent,
    BatchConfigRegisterComponent,
    ExternalLogsVisualizerListComponent,
    ProjectListComponent,
    CacheListComponent,
    CacheCleaningComponent,
    ProjectRegisterComponent,
    CacheRegisterComponent,
  ],
  imports: [
    CommonModule,
    DeveloperRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatOptionModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ],
})
export class DeveloperModule { }


