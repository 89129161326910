export class BillingPlanRequest {
    public planName: string;
    public planDescription: string;
    public quantityPresentialAttendance: number;
    public quantityRemoteAttendance: number;
    public monthlyPlanFee: number;
    public valueExtraAttendance: number;
    public planIsActive: boolean;
    public idHealthUnit: number;
    public listIdModule: number[];
    public listIdLinkedProduct: number[];
    public linkedProductQuantity: number;
}