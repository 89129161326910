import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-log-siga-visualizer-confirmation-modal',
  templateUrl: './log-siga-visualizer-confirmation-modal.component.html',
  styleUrls: ['./log-siga-visualizer-confirmation-modal.component.css']
})
export class LogSigaVisualizerConfirmationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<LogSigaVisualizerConfirmationModalComponent>) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ viewData: false });
  }

  clickConfirm() {
    this.matDialogRef.close({ viewData: true });
  }

  clickCancel() {
    this.matDialogRef.close({ viewData: false });
  }
}
