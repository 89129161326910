import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { InternalReportConfigResponse } from '../../responses/business-intelligence/internal-report-config.response';
import { InternalReportProfileResponse } from '../../responses/business-intelligence/internal-report-profile.response';
import { CsatConfigResponse } from '../../responses/customer-success/csat-config.response';
import { CsatConfigRequest } from '../../requests/customer-success/csat-config.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
@Injectable({
  providedIn: 'root'
})
export class CsatConfigService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getConfigs(): Observable<CsatConfigResponse> {
    let uri = `CsatConfig`

    return this.httpClient.get<CsatConfigResponse>(environment.urlApiCsat+ uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  createCsatConfig(body: CsatConfigRequest): Observable<ReturnStruct> {
    let uri = `CsatConfig`

    return this.httpClient.post<ReturnStruct>(environment.urlApiCsat + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  updateCsatConfig(body: CsatConfigRequest): Observable<ReturnStruct> {
    let uri = `CsatConfig`

    return this.httpClient.put<ReturnStruct>(environment.urlApiCsat + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteCsatConfig(idCsatConfig: number): Observable<ReturnStruct> {
    let uri = `CsatConfig/idCsatConfig/${idCsatConfig}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiCsat + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
