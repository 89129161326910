import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BatchConfigurationVisualizerService } from 'src/app/shared/services/API/srvlog/batch-configuration-visualizer.service';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { ListBatchConfigurationStruct } from 'src/app/shared/services/structs/srvlog/list-batch-configuration.struct';
import { BatchDeleteModalComponent } from './batch-delete-modal/batch-delete-modal.component';
import { BatchRunModalComponent } from './batch-run-modal/batch-run-modal.component';
import { PostPutBatchConfigurationRequest } from 'src/app/shared/services/requests/srv-log/post-put-batch-configuration.request';

@Component({
  selector: 'app-batch-config-list',
  templateUrl: './batch-config-list.component.html',
  styleUrls: ['./batch-config-list.component.css']
})
export class BatchConfigListComponent implements OnInit {

  constructor(private alertService: AlertService,
    private batchConfigurationVisualizerService: BatchConfigurationVisualizerService,
    private dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.developer_batch_configuration;

  public isLoading: boolean;
  // public isLoadingSubscription: boolean;
  public listBatch: ListBatchConfigurationStruct[];

  ngOnInit(): void {
    this.search();
  }

  search() {
    this.isLoading = true;
    this.batchConfigurationVisualizerService.listBatch().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listBatch = response.listBatchConfiguration;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idBatch) {
    const dialogRef = this.dialog.open(BatchDeleteModalComponent, {
      data: {
        idBatch: idBatch
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteBatch) {
        this.search();
      }
    });
  }

  subscribe(item: ListBatchConfigurationStruct) {
    item.isLoadingSubscription = true;
    let request: PostPutBatchConfigurationRequest = new PostPutBatchConfigurationRequest();
    request.subscribe = !item.subscribed;
    this.batchConfigurationVisualizerService.subscription(request, item.idBatchConfiguration).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          item.isLoadingSubscription = false;
          return;
        }
        item.subscribed = !item.subscribed;
        const title: string = request.subscribe ? "Inscrito" : "Cancelado";
        const message: string = request.subscribe ? 'Você receberá emails desse batch' : "Você não receberá mais emails desse batch";
        this.alertService.show(title, message, AlertType.success);
        this.isLoading = true;
        item.isLoadingSubscription = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        item.isLoadingSubscription = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  openRunModal(idBatch) {
    const dialogRef = this.dialog.open(BatchRunModalComponent, {
      data: {
        idBatch: idBatch
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.runBatch) {
        this.search();
      }
    });
  }
}