import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { CounselingModel } from 'src/app/shared/services/models/risk-classification/counseling.model';
import { DiscriminatorTypeModel } from 'src/app/shared/services/models/risk-classification/discriminator-type.model';
import { FlowchartGroupModel } from 'src/app/shared/services/models/risk-classification/flowchart-group.model';
import { PriorityColorModel } from 'src/app/shared/services/models/risk-classification/priority-color.model';
import { AgeStruct } from 'src/app/shared/services/structs/risk-classification/age.struct';
import { FlowchartService } from 'src/app/shared/services/API/risk-classification/flowchart.service';
import { SimpleFlowchartRequest } from 'src/app/shared/services/requests/risk-classification/simple-flowchart.request';
import { SimpleFlowchartStruct } from 'src/app/shared/services/structs/risk-classification/simple-flowchart.struct';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowchartType } from 'src/app/shared/services/models/risk-classification/flowchart-type.model';
import { VerifyAgeStruct } from 'src/app/shared/custom-validators/ageStruct.validator';
import { MatDialog } from '@angular/material/dialog';
import { CounselingService } from 'src/app/shared/services/API/risk-classification/counseling.service';
import { FlowchartGroupService } from 'src/app/shared/services/API/risk-classification/flowchart-group.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { DiscriminatorDeleteModalComponent } from './discriminator-delete-modal/discriminator-delete-modal.component';
import { DiscriminatorAddModalComponent } from './discriminator-add-modal/discriminator-add-modal.component';

@Component({
  selector: 'app-flowchart-register',
  templateUrl: './flowchart-register.component.html',
  styleUrls: ['./flowchart-register.component.css']
})
export class FlowchartRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private maskService: MaskService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private flowchartService: FlowchartService,
    private counselingService: CounselingService,
    private flowchartGroupService: FlowchartGroupService,
    private lookupService: LookupRiskService,
    private discriminatorService: DiscriminatorService,) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_protocol;
  public model: FormGroup;
  public masks: Masks;
  public isLoading: boolean;
  public isUpdate: boolean;
  public idProtocol: number;
  public idFlowchart: number;

  public listFlowchartType: FlowchartType[] = [];
  public listFlowchartGroup: FlowchartGroupModel[] = [];
  public listPriorityColor: PriorityColorModel[] = [];
  public listDiscriminatorType: DiscriminatorTypeModel[] = [];
  public listCounseling: CounselingModel[] = [];
  public listDiscriminator: DiscriminatorStruct[] = [];
  public discriminatorsByColor: { [key: number]: DiscriminatorStruct[] } = {};

  ngOnInit(): void {
    this.isLoading = true;
    this.masks = this.maskService.getMasks();

    this.idProtocol = null;

    if (this.activatedRoute.snapshot.paramMap.get('idProtocol')) {
      this.idProtocol = parseInt(this.activatedRoute.snapshot.paramMap.get('idProtocol'));
    }

    if (!this.idProtocol || this.idProtocol <= 0) {
      this.router.navigate(['/consulting/protocol']);
    }

    this.idFlowchart = null;

    if (this.activatedRoute.snapshot.paramMap.get('idFlowchart')) {
      this.idFlowchart = parseInt(this.activatedRoute.snapshot.paramMap.get('idFlowchart'));
    }

    this.isUpdate = false;

    if (this.idFlowchart && this.idFlowchart > 0) {
      this.isUpdate = true;
    }

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      minAge: ['', { validators: [VerifyAgeStruct()] }],
      maxAge: ['', [VerifyAgeStruct()]],
      order: ['', [Validators.required]],
      description: [''],
      listIdFlowchartGroup: [null, [Validators.required]],
      idFlowchartType: [null, [Validators.required]],
      lastCanBeDenied: [false],
    });

    this.isLoading = false;

    this.getCounselingList();
    this.getAllFlowchartsGroup();
    this.getLookup(true);

  }

  populateFlowchartData() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.flowchartService.GetFlowchart(this.idFlowchart).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get("name").setValue(response.flowchart.flowchartName);

        let minAgeString: string = response.flowchart.minAge && response.flowchart.minAgeStruct ? this.getAgeFormatted(response.flowchart.minAgeStruct) : "";
        this.model.get("minAge").setValue(minAgeString);

        let maxAgeString: string = response.flowchart.maxAge && response.flowchart.maxAgeStruct ? this.getAgeFormatted(response.flowchart.maxAgeStruct) : "";
        this.model.get("maxAge").setValue(maxAgeString);

        this.model.get("order").setValue(response.flowchart.order);
        this.model.get("description").setValue(response.flowchart.description);
        this.model.get("listIdFlowchartGroup").setValue(response.flowchart.flowchartGroup);
        this.model.get("idFlowchartType").setValue(response.flowchart.idFlowchartType);
        this.model.get("lastCanBeDenied").setValue(response.flowchart.lastCanBeDenied);

        if (response.flowchart.listDiscriminators && response.flowchart.listDiscriminators.length > 0) {
          this.listDiscriminator = [...response.flowchart.listDiscriminators];

          this.listDiscriminator.sort(item => item.order);

          this.listDiscriminator.forEach(item => {
            this.discriminatorsByColor[item.idPriorityColor]?.push(item);
          });

        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getCounselingList() {
    this.counselingService.listCounseling(null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.listCounseling && response.listCounseling.length > 0) {
          this.listCounseling = [...response.listCounseling];
        }

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAllFlowchartsGroup() {
    this.flowchartGroupService.getFlowchartGroup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.flowchartsGroup && response.flowchartsGroup.length > 0) {
          this.listFlowchartGroup = [...response.flowchartsGroup];
        }

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookup(isFirstLoading: boolean = false) {
    this.lookupService.getLookup(this.idProtocol).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.discriminatorType && response.discriminatorType.length > 0) {
          this.listDiscriminatorType = [...response.discriminatorType];
        }

        if (response.listFlowchartType && response.listFlowchartType.length > 0) {
          this.listFlowchartType = [...response.listFlowchartType];
        }

        if (response.priorityColors && response.priorityColors.length > 0) {
          this.listPriorityColor = [...response.priorityColors];

          this.discriminatorsByColor = this.listPriorityColor?.reduce((acc, color) => {
            acc[color.idPriorityColor] = [];
            return acc;
          }, {});

        }

        if (isFirstLoading && this.isUpdate) {
          this.populateFlowchartData();
        }

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getAgeFormatted(age: AgeStruct): string {
    let years = (age.years ? age.years.toString().padStart(3, '0') : '000') + 'a';
    let months = (age.months ? age.months.toString().padStart(2, '0') : '00') + 'm';
    let days = (age.days ? age.days.toString().padStart(2, '0') : '00') + 'd';
    return years + months + days;
  }

  validateAge(value: string, type: string = null): boolean {
    if (!value) {
      return true;
    }

    const pattern = /^(([0-9]{3}|[0-9]{2})a(0[0-9]|1[01])m((0|1|2)[0-9]|30)d)$/;

    if (!pattern.test(value)) {

      if (type === 'min') {
        this.model.get('minAge').setErrors({ invalidAge: true });
      }

      if (type === 'max') {
        this.model.get('maxAge').setErrors({ invalidAge: true });
      }

      return false;
    }

    if (value) {
      let result = this.formatDateToSave(value);

      if (result.months > 12) {
        this.alertService.show('Erro', "Quantidade máxima de meses na idade não pode ser maior que 12", AlertType.error);
        return false;
      }
      else if (result.days > 30) {
        this.alertService.show('Erro', "Quantidade máxima de dias na idade não pode ser maior que 30", AlertType.error);
        return false;
      }

      return true;
    }
  }

  formatDateToSave(age: string): AgeStruct {
    let ageStruct = new AgeStruct();

    if (age) {
      let date = age.split("a");
      ageStruct.years = parseInt(date[0]);
      date = date[1].split("m");
      ageStruct.months = parseInt(date[0]);
      date = date[1].split("d");
      ageStruct.days = parseInt(date[0]);
    }

    return ageStruct;
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    let minAge = this.model.get('minAge').value;

    let isValidAge = this.validateAge(minAge, 'min');

    if (!isValidAge) {
      this.alertService.show('Erro', "Idade miníma deve ser corretamente preenchida.", AlertType.error);
      return;
    }

    let maxAge = this.model.get('maxAge').value;

    isValidAge = this.validateAge(maxAge, 'max');

    if (!isValidAge) {
      this.alertService.show('Erro', "Idade máxima deve ser corretamente preenchida.", AlertType.error);
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    let flowchart: SimpleFlowchartStruct = new SimpleFlowchartStruct();

    flowchart.idProtocol = this.idProtocol;
    flowchart.flowchartName = this.model.value.name;
    flowchart.description = this.model.value.description;
    flowchart.isPunctuationSystem = false;
    flowchart.order = this.model.value.order;
    flowchart.flowchartGroup = this.model.value.listIdFlowchartGroup;
    flowchart.idFlowchartType = this.model.value.idFlowchartType;
    flowchart.minAgeStruct = this.formatDateToSave(this.model.value.minAge);
    flowchart.maxAgeStruct = this.formatDateToSave(this.model.value.maxAge);
    flowchart.lastCanBeDenied = this.model.value.lastCanBeDenied;

    if (this.idFlowchart && this.idFlowchart > 0) {
      flowchart.idFlowchart = this.idFlowchart;
      this.updateFlowchart(flowchart);
    }
    else {
      this.createFlowchart(flowchart);
    }
  }

  updateFlowchart(flowchart: SimpleFlowchartStruct) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: SimpleFlowchartRequest = new SimpleFlowchartRequest();
    request.flowchart = flowchart;

    this.flowchartService.UpdateSimpleFlowchart(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Fluxograma atualizado com sucesso", AlertType.success);
        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  createFlowchart(flowchart: SimpleFlowchartStruct) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: SimpleFlowchartRequest = new SimpleFlowchartRequest();
    request.flowchart = flowchart;

    this.flowchartService.PostSimpleFlowchart(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.idFlowchart = response.idFlowchart;
        this.isUpdate = true;

        let newUrl: string = `/protocol/flowchart/register;idProtocol=${this.idProtocol};idFlowchart=${this.idFlowchart}`;
        this.router.navigateByUrl(newUrl, { skipLocationChange: false });

        this.alertService.show('Sucesso', "Fluxograma criado com sucesso", AlertType.success);
        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  redirectToProtocolRegister() {
    if (this.isLoading) {
      return;
    }

    this.router.navigate(['/protocol/register', { idProtocol: this.idProtocol }]);

  }

  openCreateEditDiscriminatorModal(idPriorityColor: number, index: number, item: DiscriminatorStruct) {
    if (this.isLoading) {
      return;
    }

    if (!this.idFlowchart || this.idFlowchart == 0) {
      this.alertService.show('Erro', "Para criar um discriminador é necessário antes criar o fluxograma.", AlertType.error);
      return;
    }
    let lastDiscriminator = this.listDiscriminator[this.listDiscriminator.length - 1] ;
    
    const dialogRef = this.dialog.open(DiscriminatorAddModalComponent, {
      data: {
        idDiscriminator: item?.idDiscriminator,
        idPriorityColor: idPriorityColor,
        idFlowchart: this.idFlowchart,
        listDiscriminatorType: this.listDiscriminatorType,
        listCounseling: this.listCounseling,
        isTheLast: item ? lastDiscriminator.order == item.order : false,
      },
    });

    dialogRef.afterClosed().subscribe((result: DiscriminatorStruct) => {
      if (result) {
        if (item) {
          this.discriminatorsByColor[idPriorityColor].splice(index, 1);

          let indexDiscriminatorToRemove: number = this.listDiscriminator.findIndex(itemToFind => itemToFind.idDiscriminator == item.idDiscriminator);

          if (indexDiscriminatorToRemove) {
            this.listDiscriminator.splice(indexDiscriminatorToRemove, 1);
          }
        }

        result.idPriorityColor = idPriorityColor;

        this.discriminatorsByColor[idPriorityColor].push(result);
        this.discriminatorsByColor[idPriorityColor].sort(itemToOrder => itemToOrder.order);

        this.listDiscriminator.push(result);
        this.listDiscriminator.sort(itemToOrder => itemToOrder.order);
      }
    });
  }


  openDeleteDiscriminatorModal(idPriorityColor: number, index: number) {
    if (this.isLoading) {
      return;
    }

    const dialogRef = this.dialog.open(DiscriminatorDeleteModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteDiscriminator) {
        this.deleteDiscriminator(idPriorityColor, index);
      }
    });
  }

  deleteDiscriminator(idPriorityColor: number, index: number) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let idDiscriminator = this.discriminatorsByColor[idPriorityColor][index].idDiscriminator;

    this.discriminatorService.DeleteDiscriminator(idDiscriminator).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso!', 'Discriminador removido com sucesso', AlertType.success);

        this.discriminatorsByColor[idPriorityColor].splice(index, 1);

        let indexDiscriminatorToRemove: number = this.listDiscriminator.findIndex(item => item.idDiscriminator == idDiscriminator);

        if (indexDiscriminatorToRemove) {
          this.listDiscriminator.splice(indexDiscriminatorToRemove, 1);
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
