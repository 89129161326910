<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Perfil</h1>
    <div class="white-body">
        <div class="row">
            <div class="col-6 col-sm-3">
                <a mat-button class="btn-primary add-user" routerLink="/support/access-register">
                    <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon>Gerar SuperAcesso
                </a>
            </div>
        </div>
        <div class="filters">
            <form class="form" [formGroup]="model" (ngSubmit)="pesquisar()">
                <div class="row">
                    <div class="col-12 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Usuário</mat-label>
                            <mat-select formControlName="user">
                                <mat-option>Todos</mat-option>
                                <mat-option *ngFor="let item of listUserAdmin" value="{{item.idAdminUser}}">
                                    {{item.adminUserName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-2">
                        <app-select (selectedValue)="selectHealthUnit($event)" [multiple]="true" [url]="this.urlListHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" [optionAll]="true" [allOptionText]="'TODAS AS UNIDADES'">
                    </app-select>
                    </div>
                    <div class="col-12 col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Data de Acesso</mat-label>
                            <input matInput [mask]="masks.date" formControlName="dateAccess">
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span>Pesquisar</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-list" *ngIf="listSuperAccess != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listSuperAccess">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.healthUnitName}}</span>
                            <span class="secundary">Atentende: {{item.adminUserName }}</span>
                            <span class="secundary">Ticket: {{item.ticket}}</span>
                            <span class="secundary">Data de criação: {{item.dateTimeInclusion | date: 'dd/MM/yyyy
                                h:mm'}}</span>
                            <span class="secundary">Data de acesso: {{item.dateTimeAccess | date: 'dd/MM/yyyy
                                h:mm'}}</span>
                        </div>
                        <div class="actions">
                            <a (click)="openModal(item)">
                                <mat-icon aria-hidden="false" aria-label="Editar">visibility</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listSuperAccess != undefined && listSuperAccess.length == 0"></app-empty-list>
    </div>
</div>