<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/bi/template"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
        Relatórios</a>
    <h1>Cadastrar Template de Relatório</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Dados do relatório</h1>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="templateBiName">
                        <mat-error *ngIf="model.get('templateBiName').invalid">Informe o Nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-8">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="templateBiDescription">
                        <mat-error *ngIf="model.get('templateBiDescription').invalid">Descrição</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Relatórios BI</mat-label>
                        <mat-select formControlName="listIdReport" multiple
                            (selectionChange)="onReportSelectionChange($event)">
                            <mat-option *ngFor="let item of listAllBiReports" [value]="item.idReport">
                                {{item.reportName}} <span class="gray-report-name">{{item.powerBiReportName}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/bi/template" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>

        <div class="row aling-column">
            <div class="col-12 col-sm-6">
                <div class="title-header title-report">
                    <h1>Relatórios selecionados:</h1>
                </div>
                <ng-container *ngFor="let item of currentSelectedTemplates">
                    <div class="col-12  report-item" *ngIf="item.powerBiReportName">
                        <div class="title-header">
                            {{ item.reportName }} <span class="gray-report-name">{{ item.powerBiReportName }}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-12 col-sm-6">
                <div class="title-header title-report">
                    <h1>Unidades Atribuídas:</h1>
                </div>
                <ng-container *ngFor="let item of listHealthUnit">
                    <div class="col-12 report-item" *ngIf="item.nameHealthUnit">
                        <div class="title-header">
                            {{ item.idHealthUnit}} <span class="gray-report-name">{{ item.nameHealthUnit }}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>