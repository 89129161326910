<app-menu [isRenderMenu]="false" [menuModuleEnum]="menuModuleEnum"></app-menu>


<div class="container-body">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <div class="white-box">
                <h2>Redefinir senha</h2>
                <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
                    <p>Insira sua nova senha</p>
                    <div class="row">   
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Nova senha</mat-label>
                                <input matInput type="password" formControlName="newPassword" required>
                                <mat-error *ngIf="model.get('newPassword').invalid">Informe a nova senha</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">   
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Repetir senha</mat-label>
                                <input matInput type="password" formControlName="repetedPassword" required>
                                <mat-error *ngIf="model.get('repetedPassword').invalid">Informe a nova senha</mat-error>
                            </mat-form-field>
                            <p [ngStyle]="{ 'color': model.get('newPassword').invalid ? '#fc0303' : '#000000' }">Use entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras minúsculas, números e caracteres especiais.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-button type="submit" class="btn-primary btn-block">
                                <span *ngIf="isLoading == false">Redefinir Senha</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                            </button>
                            <button mat-stroked-button type="button" routerLink="/" class="btn-secundary btn-block">
                                Voltar
                            </button>
                        </div>
                    </div>
                   
                </form> 
            </div>
        </div>
    </div>
</div>
