import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyPassword } from 'src/app/shared/custom-validators/password.validator';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AdminUserService } from 'src/app/shared/services/API/admin-user/admin-user.service';
import { DepartmentService } from 'src/app/shared/services/API/admin-user/department.service';
import { ProfileService } from 'src/app/shared/services/API/admin-user/profile.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DepartmentModel } from 'src/app/shared/services/models/admin-user/department.model';
import { UserRegisterRequest } from 'src/app/shared/services/requests/admin-user/user-register-request';
import { ListProfileStruct } from 'src/app/shared/services/structs/admin-user/list-profile.struct';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.css']
})
export class UserRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private adminUserService: AdminUserService,
    private departmentService: DepartmentService,
    private profileService: ProfileService,) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.master_user;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idAdminUser: number;
  public listProfile: ListProfileStruct[];
  public listDepartment: DepartmentModel[];
  public userRegisterRequest: UserRegisterRequest;

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      phone: [''],
      department: ['', [Validators.required]],
      login: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      temporaryPassword: ['', [Validators.required, VerifyPassword()]],
      perfil: ['', [Validators.required]],
      status: ['', [Validators.required]],
    });

    this.masks = this.maskService.getMasks();

    this.userRegisterRequest = new UserRegisterRequest();

    this.idAdminUser == null;
    this.isUpdate == false;
    
    if (this.activatedRoute.snapshot.paramMap.get('idAdminUser'))
      this.idAdminUser = parseInt(this.activatedRoute.snapshot.paramMap.get('idAdminUser'));

    if (this.idAdminUser != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.model.get('cpf').disable();
      this.model.get('temporaryPassword').setValidators([VerifyPassword()]);
      this.model.get('temporaryPassword').updateValueAndValidity();
      this.populateUserData();
    }

    this.populateProfileSelect();
    this.populateSelectProfession();
  }

  submit() {
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.userRegisterRequest.cpf = this.model.get('cpf').value;
    this.userRegisterRequest.email = this.model.get('email').value;
    this.userRegisterRequest.idDepartment = this.model.get('department').value;
    this.userRegisterRequest.isActive = this.model.get('status').value == "true" ? true : false;
    this.userRegisterRequest.login = this.model.get('login').value;
    this.userRegisterRequest.phone = this.model.get('phone').value;
    this.userRegisterRequest.tempPassword = this.model.get('temporaryPassword').value;
    this.userRegisterRequest.userName = this.model.get('name').value;
    this.userRegisterRequest.listProfile = this.model.get('perfil').value;

    if (this.isUpdate)
      this.updateUser();
    else
      this.createUser();
  }

  populateUserData() {
    this.adminUserService.getAdminUser(this.idAdminUser).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('name').setValue(response.adminUser.adminUserName);
      this.model.get('cpf').setValue(response.adminUser.cpf);
      this.model.get('phone').setValue(response.adminUser.phone);
      this.model.get('login').setValue(response.adminUser.login);
      this.model.get('email').setValue(response.adminUser.email);
      this.model.get('status').setValue(response.adminUser.isActive.toString());
      this.model.get('department').setValue(response.adminUser.idDepartment.toString());
      this.model.get('perfil').setValue(response.listIdProfile.map(c => c.toString()));

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateProfileSelect() {
    this.profileService.listAllProfile().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listProfile = response.listProfile;
      this.isLoading = false;

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateSelectProfession() {
    this.departmentService.listDepartment().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listDepartment = response.listDepartment;
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  updateUser() {
    this.adminUserService.updateAdminUser(this.idAdminUser, this.userRegisterRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        if (response.errorCode != 2)
          return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/user']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  createUser() {
    this.adminUserService.createAdminUser(this.userRegisterRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        if (response.errorCode != 2)
          return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/master/user']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
   passwordValidation(control: AbstractControl) { (1)
    let email = control.value; (2)
    if (email && email.indexOf("@") != -1) { (3)
      let [_, domain] = email.split("@"); (4)
      if (domain !== "codecraft.tv") { (5)
        return {
          emailDomain: {
            parsedDomain: domain
          }
        }
      }
    }
    return null; (6)
  }
}