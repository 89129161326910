import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { TemplateMedicineRequest } from '../../requests/pharmacy/template-medicine.request';
import { GetTemplateMedicineResponse } from '../../responses/pharmacy/get-template-medicine.response';
import { ListTemplateMedicineResponse } from '../../responses/pharmacy/list-template-medicine.response';

@Injectable({
  providedIn: 'root'
})
export class TemplateMedicineService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  list(searchText: string, idTemplate: number): Observable<ListTemplateMedicineResponse> {
    let uri = `TemplateMedicine?`
    if (searchText) {
      uri = uri + `searchText=${searchText}&`
    }
    if (idTemplate) {
      uri = uri + `idTemplate=${idTemplate}&`
    }
    return this.httpClient.get<ListTemplateMedicineResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  delete(idTemplateMedicine: number): Observable<ReturnStruct> {
    let uri = `TemplateMedicine/idTemplateMedicine/${idTemplateMedicine}`
    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  read(idTemplateMedicine: number): Observable<GetTemplateMedicineResponse> {
    let uri = `TemplateMedicine/idTemplateMedicine/${idTemplateMedicine}`
    return this.httpClient.get<GetTemplateMedicineResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  create(request: TemplateMedicineRequest): Observable<ReturnStruct> {
    let uri = `TemplateMedicine`
    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  update(idTemplateMedicine: number, request: TemplateMedicineRequest): Observable<ReturnStruct> {
    let uri = `TemplateMedicine/idTemplateMedicine/${idTemplateMedicine}`
    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}