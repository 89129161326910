import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Injectable({
  providedIn: 'root'
})
export class GroupOriginOrchestratorUserService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public deleteGroupOrigin(idGroupOrigin: number): Observable<ReturnStruct> {
    let uri = `GroupOrigin/idGroupOrigin/${idGroupOrigin}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiOrchestratorUser + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}