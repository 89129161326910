import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataMeshRoutingModule } from './data-mesh-routing.module';
import { DataProductListComponent } from './pages/data-product-list/data-product-list.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatRadioButton } from '@angular/material/radio';
import { MatTree } from '@angular/material/tree';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { DataMeshComponent } from './pages/data-mesh/data-mesh.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DataProductRegisterComponent } from './pages/data-product-register/data-product-register.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AnalyticsListComponent } from './pages/analytics-list/analytics-list.component';
import { DataSetListComponent } from './pages/data-set-list/data-set-list.component';
import { DataSetRegisterComponent } from './pages/data-set-register/data-set-register.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [
    DataProductListComponent,
    DataMeshComponent,
    AnalyticsComponent,
    DataProductRegisterComponent,
    AnalyticsListComponent,
    DataSetListComponent,
    DataSetRegisterComponent
  ],
  imports: [
    CommonModule,
    DataMeshRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatToolbarModule,
    MatTableModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatToolbarModule,
    MatPaginatorModule,
    DragDropModule
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ],
})
export class DataMeshModule { }
