import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { KeyService } from 'src/app/shared/services/API/emerges-key/key.service';
import { KeyModel } from "src/app/shared/services/models/emerges-key/key.model";
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { KeyRequest } from 'src/app/shared/services/requests/emerges-key/key.request';
import { EmergesService } from 'src/app/shared/services/API/emerges-key/emerges.service';
import { EmergesModel } from 'src/app/shared/services/models/emerges-key/emerges.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-key-register',
  templateUrl: './key-register.component.html',
  styleUrls: ['./key-register.component.css']
})
export class KeyRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private keyService: KeyService,
    private emergesService: EmergesService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.support_key;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idKey: number;
  public listEmerges: EmergesModel[];
  public keyRegisterRequest: KeyRequest;

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      idEmerges: ['', [Validators.required]],
      datetimeExpiration: ['', [Validators.required]],
    });

    this.masks = this.maskService.getMasks();

    this.keyRegisterRequest = new KeyRequest();

    this.idKey == null;
    this.isUpdate == false;
    this.listAllEmerges();

    if (this.activatedRoute.snapshot.paramMap.get('idKey'))
      this.idKey = parseInt(this.activatedRoute.snapshot.paramMap.get('idKey'));

    if (this.idKey != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.keyRegisterRequest.idEmerges = this.model.get('idEmerges').value;
    var date = (this.formatDateBack(this.model.get('datetimeExpiration').value));
    this.keyRegisterRequest.datetimeExpiration = date;
    var now = new Date();
    if (date <= now) {
      this.alertService.show('Erro', "Data de expiração inválida", AlertType.error);
      this.isLoading = false;
      return;
    }
    this.createKey();
  }

  createKey() {
    this.keyService.createKey(this.keyRegisterRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        if (response.errorCode != 2)
          return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/support/key']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  listAllEmerges() {
    this.emergesService.getAllEmerges().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.isFirstLoading = false;
      this.listEmerges = response.listEmerges;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  formatDateBack(dateUnformatted: string) {
    if (dateUnformatted) {                 //  0    1    2    3    4    5    6    7
      let darr = dateUnformatted.split("");//["2", "9", "0", "1", "2", "0", "1", "6"]
      let dobj = new Date(parseInt(darr[4] + darr[5] + darr[6] + darr[7]), parseInt(darr[2] + darr[3]) - 1, parseInt(darr[0] + darr[1]));
      return dobj;
    }
    return null;
  }

} 