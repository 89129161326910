import { NextStepsAfterTriageProtocol } from "../../models/care-line-priority/next-steps-after-triage-procotol.model";

export class CareLinePriorityRequest {
    public idCareLinePriority: number;
    public careLinePriorityName: string;
    public iconClass: string;
    public protocolName: string;
    public isActive: boolean;
    public isDeleted: boolean;
    public openProtocolTriage: boolean;
    public listIdHealthUnit: number[] = [];
    public listNextStepsAfterTriageProtocolStruct: NextStepsAfterTriageProtocol[] = [];
    public idCareLinePriorityIcon: number;
}