<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="row">
            <div class="col-md-12">
                <iframe style="height: 600px;width: 100%;" [src]="src" frameborder="0" *ngIf="!isLoading"></iframe>
                <app-loading-list *ngIf="isLoading"></app-loading-list>
            </div>
        </div>
    </div>
    <div class="footer-panel-modal-list">
        <div class="row">
            <div class="col-md-6">
                <button mat-stroked-button class="btn-primary block"(click)="selectItem()">
                    <span *ngIf="isLoading == false">Aplicar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </button>
            </div>
            <div class="col-md-6">
                <button mat-stroked-button class="btn-secundary block"(click)="close()">Fechar</button>
            </div>
        </div>
    </div>
</div>