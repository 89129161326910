<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <div class="row">
        <div class="col-12 col-md-4">
            <h1>Logs SIGA</h1>
        </div>
        <div class="col-12 col-md-8">
            <mat-slide-toggle [(ngModel)]="this.isPanelMode" (change)="changeMode($event)">Modo Painel</mat-slide-toggle>
        </div>
    </div>
    <div class="white-body">
        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Log ID</mat-label>
                        <input matInput type="number" id="idLog" name="idLog" [(ngModel)]="idLog">
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de log</mat-label>
                        <mat-select [(ngModel)]="idLogRequestType">
                            <mat-option *ngFor="let item of listLogRequestType" value="{{item.idLogRequestType}}">
                                {{item.logRequestTypeName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Seleciona o período</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate [(ngModel)]="beginDate" placeholder="Início">
                            <input matEndDate [(ngModel)]="endDate" placeholder="Fim">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button class="btn-primary btn-block" (click)="buttonSearch()" >
                        <span *ngIf="!isLoading">Pesquisar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listLogs != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listLogs">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.idLogRequests}}</span>
                            <span class="secundary">{{item.requestType}}</span>
                            <span class="secundary-status last-info">{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Visualizar"
                                    [routerLink]="['/support/log-siga-list/view', {idLogRequest: item.idLogRequests}]">
                                    visibility
                                </mat-icon>
                            </a>
                        </div>
                        <div>
                        <app-chip-siga-status [status]="item.logRequestType"></app-chip-siga-status>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
                <mat-paginator [length]="this.fullListSize" #paginator [pageIndex]="0" [pageSize]="30" aria-label="Select page" (page)="changePage($event)">
                </mat-paginator>
            </div>
        </div>

        <app-empty-list *ngIf="listLogs != undefined && listLogs.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>