import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PowerBiService } from 'src/app/shared/services/API/business-intelligence/power-bi.service';
import { ReportService } from 'src/app/shared/services/API/business-intelligence/report.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ReportRequest } from 'src/app/shared/services/requests/business-intelligence/report.request';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { BiReportStruct } from 'src/app/shared/services/structs/business-intelligence/bi-report.struct';
import { FunctionHealthUnitStruct } from 'src/app/shared/services/structs/business-intelligence/function-health-unit-struct';
import { TemplateReportHealthUnitStruct } from 'src/app/shared/services/structs/business-intelligence/template-report-health-unit.struct';
import { WorkspaceStruct } from 'src/app/shared/services/structs/business-intelligence/workspace.struct';
import { MatDialog } from '@angular/material/dialog';
import { ListHealthUnitService } from 'src/app/shared/services/API/user/list-health-unit.service';

@Component({
  selector: 'app-report-register',
  templateUrl: './report-register.component.html',
  styleUrls: ['./report-register.component.css']
})
export class ReportRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private reportService: ReportService,
    private powerBiService: PowerBiService,
    private listHealthUnitService: ListHealthUnitService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.business_intelligence_report;

  public model: FormGroup;
  public isBlockRefresh = false;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idReport: number;
  public reportRequest: ReportRequest;

  public listAllHealthUnit: ListHealthUnitStruct[];
  public listTemplateHealthUnit: number[] = []
  public listWorkspace: WorkspaceStruct[];
  public listBiReport: BiReportStruct[];
  public listTemplateBiHealthUnit: TemplateReportHealthUnitStruct[] = [];

  public default: boolean = true;

  ngOnInit(): void {
    this.isLoading = false;

    this.masks = this.maskService.getMasks();

    this.reportRequest = new ReportRequest();

    this.idReport == null;
    this.isUpdate == false;

    if (this.activatedRoute.snapshot.paramMap.get('idReport')) {
      this.idReport = parseInt(this.activatedRoute.snapshot.paramMap.get('idReport'));

    }

    this.populateWorkspaceSelect();
    this.populateHealthUnitSelect(null);

    this.model = this.formBuilder.group({
      reportName: ['', [Validators.required]],
      isActive: ['', [Validators.required]],
      workspace: ['', [Validators.required]],
      biReport: ['', [Validators.required]],
      width: [, [Validators.required]],
      height: [, [Validators.required]],
      reportLink: ['', [Validators.required]],
      ordenation: ['', [Validators.required]],
      listReportFunctionHealthUnit: this.formBuilder.array([]),
      pageNumber: ['', [Validators.required]],
    });

    if (this.idReport != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateReportData();
    }
    else {
      this.model.get('biReport').disable();
      this.model.get('width').setValue(1280);
      this.model.get('height').setValue(720);
      this.addNext();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    this.reportRequest.listFunctionHealthUnitStruct = [];
    this.reportRequest.reportName = this.model.get('reportName').value;
    this.reportRequest.isActive = this.model.get('isActive').value === "true" ? true : false;
    this.reportRequest.workspace = this.model.get('workspace').value;
    this.reportRequest.biReport = this.model.get('biReport').value;
    this.reportRequest.width = this.model.get('width').value;
    this.reportRequest.height = this.model.get('height').value;
    this.reportRequest.reportLink = this.model.get('reportLink').value;
    this.reportRequest.ordenation = this.model.get('ordenation').value;
    this.reportRequest.pageNumber = this.model.get('pageNumber').value;

    this.model.get('listReportFunctionHealthUnit').value.forEach(x => {
      var functionHealthUnitStruct = new FunctionHealthUnitStruct();
      const isIdReportFunctionHealthUnitExist = this.listTemplateBiHealthUnit.some(item => item.idReportFunctionHealthUnit === x.idReportFunctionHealthUnit)
      if (!isIdReportFunctionHealthUnitExist) {
        functionHealthUnitStruct.function = x.function;
        functionHealthUnitStruct.idHealthUnit = x.idHealthUnit;
        functionHealthUnitStruct.idReportFunctionHealthUnit = x.idReportFunctionHealthUnit;
        this.reportRequest.listFunctionHealthUnitStruct.push(functionHealthUnitStruct);
      }
    });

    if (this.isUpdate)
      this.updateReport();
    else
      this.createReport();
  }

  populateReportData() {
    this.reportService.getReport(this.idReport).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.populateBiReportSelect(response.report.workspace);
      this.model.get('reportName').setValue(response.report.reportName);
      this.model.get('isActive').setValue(response.report.isActive.toString());
      this.model.get('workspace').setValue(response.report.workspace);
      this.model.get('width').setValue(response.report.width);
      this.model.get('height').setValue(response.report.height);
      this.model.get('reportLink').setValue(response.report.reportLink);
      this.model.get('ordenation').setValue(response.report.ordenation);
      this.model.get('pageNumber').setValue(response.report.page);

      response.report.listReportFunctionHealthUnit.forEach((item) => {
        if (item.idHealthUnit) {
          this.listTemplateHealthUnit.push(item.idHealthUnit);
        }
      });
      if (!response.report.listReportFunctionHealthUnit || response.report.listReportFunctionHealthUnit.length == 0) {
        this.addNext();
      }

      else {
        response.report.listReportFunctionHealthUnit.forEach(x => {
          let isBlocked = false;
          let result = response.listTemplateBiHealthUnit.find(item =>
            item.idReportFunctionHealthUnit === x.idReportFunctionHealthUnit
          );

          let templateReportName = '';
          let reportSendRule = '';
          if (result !== null && result !== undefined) {
            isBlocked = result.templateName ? true : false;
            templateReportName = result.templateName ? "(Template: " + result.templateName + ")" : "";
            reportSendRule = result.isReportRuled ? " Envia emails" : "";
          }
          const group = this.formBuilder.group({
            idReportFunctionHealthUnit: [{ value: x.idReportFunctionHealthUnit.toString(), disabled: isBlocked }],
            idHealthUnit: [{ value: x.idHealthUnit, disabled: isBlocked }],
            function: [{ value: x.reportFunction, disabled: isBlocked }],
            isTemplate: [{ value: isBlocked, disabled: isBlocked }],
            templateBiName: [{ value: templateReportName, disabled: isBlocked }],
            reportRuled: [{ value: reportSendRule, disabled: isBlocked }]
          });

          (this.model.controls['listReportFunctionHealthUnit'] as FormArray).push(group);
        });
      }
      this.listTemplateBiHealthUnit = response.listTemplateBiHealthUnit;
      this.model.get('biReport').setValue(response.report.biReport);
      this.isLoading = false;
      this.isBlockRefresh = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateReport() {
    this.reportService.updateReport(this.idReport, this.reportRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/bi/report']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createReport() {
    this.reportService.createReport(this.reportRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/bi/report']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createInput() {
    return this.formBuilder.group({
      function: [''],
      idHealthUnit: [''],
      isTemplate: [false],
      templateBiName: [''],
      reportRuled: ['']
    })
  }

  OnChangeHealthUnit(event: any, index: number) {
    if(event.value == this.default)
    {
      event.value = null;
    }
    let functionValue = this.model.get('listReportFunctionHealthUnit')['controls'][index].get("function").value;
    let healthUnitValue = this.model.get('listReportFunctionHealthUnit')['controls'][index].get("idHealthUnit").value;
    if (event.value || functionValue || healthUnitValue) {
      this.model.get('listReportFunctionHealthUnit')['controls'][index].get("function").setValidators([Validators.required])
      this.model.get('listReportFunctionHealthUnit')['controls'][index].get("idHealthUnit").setValidators([Validators.required])
      this.model.get('listReportFunctionHealthUnit')['controls'][index].get("function").updateValueAndValidity();
      this.model.get('listReportFunctionHealthUnit')['controls'][index].get("idHealthUnit").updateValueAndValidity();
    }
    else {
      this.model.get('listReportFunctionHealthUnit')['controls'][index].get("function").setValidators(null)
      this.model.get('listReportFunctionHealthUnit')['controls'][index].get("idHealthUnit").setValidators(null)
      this.model.get('listReportFunctionHealthUnit')['controls'][index].get("function").updateValueAndValidity();
      this.model.get('listReportFunctionHealthUnit')['controls'][index].get("idHealthUnit").updateValueAndValidity();
    }
  }

  addNext() {
    (this.model.controls['listReportFunctionHealthUnit'] as FormArray).push(this.createInput())
  }

  removeButton(index: number) {
    if (index != 0) {
      (this.model.controls['listReportFunctionHealthUnit'] as FormArray).removeAt(index)
    }
  }

  isInTemplateHealthUnit(idHealthUnit: number): boolean {
    return this.listTemplateHealthUnit.some((item) => item === idHealthUnit);
  }

  populateHealthUnitSelect(searchText: string) {
    if(searchText == "")
      searchText = null;

    this.listHealthUnitService.listHealthUnit(searchText, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listAllHealthUnit = response.list;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateBiReportSelect(event) {

    var workspaceId = event.toString();
    this.model.get('biReport').setValue('');
    this.powerBiService.listBiReport(workspaceId).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listBiReport = response.listBiReport;
      this.isLoading = false;
      this.model.get('biReport').enable();
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateWorkspaceSelect() {
    this.powerBiService.listWorkspace().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listWorkspace = response.listWorkspace;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  setReportLink(event) {

    let idBiReport = event.value.toString();
    let biReport = this.listBiReport.find(x => x.id == idBiReport);
    this.model.get('reportLink').setValue(biReport.embedUrl);
  }


  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.populateHealthUnitSelect(event);
      }
    }, 1000);
  }
  
}