import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateManipulationService } from 'src/app/shared/services/API/pharmacy/template-manipulation.service';
import { TemplateSubgroupService } from 'src/app/shared/services/API/pharmacy/template-subgroup.service';
import { TemplateSubgroupStruct } from 'src/app/shared/services/structs/pharmacy/template-subgroup.struct';
import { TemplateSubgroupDeleteModalComponent } from './template-subgroup-delete-modal/template-subgroup-delete-modal.component';

@Component({
  selector: 'app-template-subgroup',
  templateUrl: './template-subgroup.component.html',
  styleUrls: ['./template-subgroup.component.css']
})
export class TemplateSubgroupComponent implements OnInit {

  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private templateSubgroupService: TemplateSubgroupService,
    private templateManipulationService: TemplateManipulationService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_template_subgroup;

  public searchText: string;
  public listTemplate: any[];
  public isLoading: boolean;
  public idTemplate: number;
  public listTemplateSubgroup: TemplateSubgroupStruct[];
  ngOnInit(): void {
    this.search();
    this.populateTemplateSelect();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    if (this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.templateSubgroupService.list(this.searchText, this.idTemplate).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listTemplateSubgroup = response.listTemplateSubgroup;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateTemplateSelect() {
    this.templateManipulationService.listAllTemplate().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listTemplate = response.listTemplate;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal(idTemplateSubgroup: number) {
    const dialogRef = this.dialog.open(TemplateSubgroupDeleteModalComponent, {
      data: {
        idTemplateSubgroup: idTemplateSubgroup
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteTemplateSubgroup) {
        this.search();
      }
    });
  }

}
