
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { OrchestratorBusinessIntelligenceService } from 'src/app/shared/services/API/business-intelligence/orchestrator-business-intelligence.service';
import { ReportService } from 'src/app/shared/services/API/business-intelligence/report.service';
import { TemplateBiService } from 'src/app/shared/services/API/business-intelligence/template-bi.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { TemplateBi } from 'src/app/shared/services/models/business-intelligence/template-bi.model';
import { ReportRequest } from 'src/app/shared/services/requests/business-intelligence/report.request';
import { TemplateBiRequest } from 'src/app/shared/services/requests/business-intelligence/template-bi.request';
import { BiHealthUnitStruct } from 'src/app/shared/services/structs/business-intelligence/bi-health-unit-struct';
import { BiReportStruct } from 'src/app/shared/services/structs/business-intelligence/bi-report.struct';
import { ReportResponseStruct } from 'src/app/shared/services/structs/business-intelligence/report-list-response.struct';

@Component({
  selector: 'app-template-business-intelligence-register',
  templateUrl: './template-business-intelligence-register.component.html',
  styleUrls: ['./template-business-intelligence-register.component.css']
})
export class TemplateBusinessIntelligenceRegisterComponent {


  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private templateBiService: TemplateBiService,
    private orchestratorBiService: OrchestratorBusinessIntelligenceService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.business_intelligence_template;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idTemplateBi: number;
  public reportRequest: ReportRequest;
  public templateBi: TemplateBi = new TemplateBi;
  public currentSelectedTemplates: ReportResponseStruct[] = [];
  public listAllBiReports: ReportResponseStruct[] = [];
  public listBiReport: BiReportStruct[];
  public templateBiRequest: TemplateBiRequest = new TemplateBiRequest;
  public listHealthUnit: BiHealthUnitStruct[];

  ngOnInit(): void {
    this.isLoading = false;

    this.masks = this.maskService.getMasks();

    
    this.idTemplateBi = 0;
    if (this.activatedRoute.snapshot.paramMap.get('idTemplateBi')){
      this.idTemplateBi = parseInt(this.activatedRoute.snapshot.paramMap.get('idTemplateBi'));
    }
    this.model = this.formBuilder.group({
      templateBiName: ['', [Validators.required]],
      templateBiDescription: [''],
      listIdReport: [[], [Validators.required]],
    });

    if (this.idTemplateBi != null && this.idTemplateBi != 0) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateTemplateBiData();
    }
    this.getAllReports();
    this.isLoading = false;
    this.isFirstLoading = false;
  }

  submit() {    
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    this.templateBiRequest.templateBi = new TemplateBi;
    this.templateBiRequest.listBiReport = this.model.get('listIdReport').value;
    this.templateBiRequest.templateBi.idTemplateBi = this.idTemplateBi;
    this.templateBiRequest.templateBi.templateBiName = this.model.get('templateBiName').value;
    this.templateBiRequest.templateBi.templateBiDescription = this.model.get('templateBiDescription').value;

    this.createTemplate();
  
  }

  populateTemplateBiData() {
    this.templateBiService.getTemplateBi(this.idTemplateBi).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.model.get('templateBiName').setValue(response.templateBi.templateBiName);
      this.model.get('templateBiDescription').setValue(response.templateBi.templateBiDescription);
      this.model.get('listIdReport').setValue(response.listBiReport);
      this.listHealthUnit = response.listBiHealthUnitName;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getAllReports() {
    this.reportService.listReport(null, null, null, null, null).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listAllBiReports = response.listReport.filter(item => item.powerBiReportName);
      this.currentSelectedTemplates = this.listAllBiReports
          .filter(item => this.model.get('listIdReport').value.includes(item.idReport));
          this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
  
  onReportSelectionChange(event: MatSelectChange): void {
    const selectedReports = event.value;
    this.currentSelectedTemplates = this.listAllBiReports
        .filter(item => selectedReports.includes(item.idReport));
}

  createTemplate() {
    this.templateBiRequest.isUpdate = false;
    if (this.activatedRoute.snapshot.paramMap.get('idTemplateBi'))
    {
      this.templateBiRequest.isUpdate = true;
    }
    this.templateBiService.createUpdate(this.templateBiRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/bi/template']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

 
}