import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { RecoverPasswordResponse } from '../../responses/admin-user/recover-password.response';
import { RecoverPasswordRequest } from '../../requests/admin-user/recover-password.request';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public verifyToken(token: string): Observable<ReturnStruct> {

    return this.httpClient.get<ReturnStruct>(environment.urlApiAdminUser + `RecoverPassword?token=${token}`)
      .pipe(
        catchError(this.handleError)
    )
  }

  public recoverPassword(body: RecoverPasswordRequest): Observable<ReturnStruct> {

    return this.httpClient.put<ReturnStruct>(environment.urlApiAdminUser + "RecoverPassword", body)
      .pipe(
        catchError(this.handleError)
    )
  }

  public recoverPasswordToken(login: string): Observable<RecoverPasswordResponse> {

    let body  = {login: login};

    return this.httpClient.post<RecoverPasswordResponse>(environment.urlApiAdminUser + "RecoverPassword", body)
      .pipe(
        catchError(this.handleError)
    )
  }
}
