import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { QuestionModel } from '../../models/self-triage/question.model';
import { QuestionRequest } from '../../requests/self-triage/question.request';
import { ListQuestionResponse } from '../../responses/self-triage/list-question.response';
import { QuestionResponse } from '../../responses/self-triage/question.response';
import { ListFlowchartResponse } from '../../responses/self-triage/list-flowchart.response';

@Injectable({
  providedIn: 'root'
})
export class QuestionService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,
  ) { super(); }

  public listQuestion(searchText: string, idFlowchart: number): Observable<ListQuestionResponse> {
    let uri = "Question/GetAll?"
    if (searchText)
      uri = uri + `searchText=${searchText}&`;
    if (idFlowchart)
      uri = uri + `idFlowchart=${idFlowchart}`;

    return this.httpClient.get<ListQuestionResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getQuestion(idQuestion: number): Observable<QuestionResponse> {
    let uri = `Question/idQuestion/${idQuestion}`

    return this.httpClient.get<QuestionResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteQuestion(idQuestion: number): Observable<ReturnStruct> {
    let uri = `Question/idQuestion/${idQuestion}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createQuestion(question: QuestionRequest): Observable<QuestionResponse> {
    let uri = `Question`

    return this.httpClient.post<QuestionResponse>(environment.urlApiSelfTriage + uri, question, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateQuestion(idQuestion: number, question: QuestionRequest): Observable<QuestionResponse> {
    let uri = `Question/idQuestion/${idQuestion}`

    return this.httpClient.put<QuestionResponse>(environment.urlApiSelfTriage + uri, question, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
