import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LogSigaVisualizerService } from 'src/app/shared/services/API/integration-siga/log-siga-visualizer.service';
import { LogRequestsAccess } from 'src/app/shared/services/models/integration-siga/log-requests-access.model';
import { LogSigaVisualizerConfirmationModalComponent } from './log-siga-visualizer-confirmation-modal/log-siga-visualizer-confirmation-modal.component';

@Component({
  selector: 'app-log-siga-visualizer-view',
  templateUrl: './log-siga-visualizer-view.component.html',
  styleUrls: ['./log-siga-visualizer-view.component.css']
})
export class LogSigaVisualizerViewComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private logRequestsVisualizerService: LogSigaVisualizerService,
    public dialog: MatDialog
    ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.support_log_siga;

  public idLogRequests: number;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public model: FormGroup;
  public sensitiveDataModel: FormGroup;
  public listLogRequestsAccess: LogRequestsAccess[];
  public isSensistiveDataRevealed: boolean;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idLogRequests: [''],
      requestType: [''],
      logRequestType: [''],
      idEpisodePatientSiga: [''],
      datetimeInclusion: [''],
    });

    this.sensitiveDataModel = this.formBuilder.group({
      request: [''],
      response: [''],
      message: [''],
    });

    this.isSensistiveDataRevealed = false;

    if (this.activatedRoute.snapshot.paramMap.get('idLogRequest'))
      this.idLogRequests = parseInt(this.activatedRoute.snapshot.paramMap.get('idLogRequest'));
    this.populateLogData();
  }


  viewSensitiveData() {
    this.logRequestsVisualizerService.getLogSensitiveData(this.idLogRequests).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.sensitiveDataModel.get('request').setValue(response.logRequestsSensitiveData.request);
      this.sensitiveDataModel.get('response').setValue(response.logRequestsSensitiveData.response);
      this.sensitiveDataModel.get('message').setValue(response.logRequestsSensitiveData.message);

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal() {
    const dialogRef = this.dialog.open(LogSigaVisualizerConfirmationModalComponent, {

    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.viewData){
        this.isSensistiveDataRevealed = true;
        this.viewSensitiveData();
      }
    });
  }

  populateLogData() {
    this.logRequestsVisualizerService.getLog(this.idLogRequests).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('idLogRequests').setValue(response.log.idLogRequests);
      this.model.get('requestType').setValue(response.log.requestType);
      this.model.get('logRequestType').setValue(response.log.logRequestType);
      this.model.get('idEpisodePatientSiga').setValue(response.log.idEpisodePatientSiga);
      this.model.get('datetimeInclusion').setValue(response.log.datetimeInclusion);

      this.listLogRequestsAccess = response.log.listLogAccesses;
      
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
