import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { QuestionModel } from '../../models/self-triage/question.model';
import { QuestionRequest } from '../../requests/self-triage/question.request';
import { ListQuestionResponse } from '../../responses/self-triage/list-question.response';
import { QuestionResponse } from '../../responses/self-triage/question.response';
import { ListFlowchartResponse } from '../../responses/self-triage/list-flowchart.response';

@Injectable({
  providedIn: 'root'
})
export class QuestionSelectService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,
  ) { super(); }

  public listQuestion(idFlowchart: number): Observable<ListQuestionResponse> {
    let uri = "QuestionSelect"
    if(idFlowchart)
      uri = uri+ `?idFlowchart=${idFlowchart}`

    return this.httpClient.get<ListQuestionResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
