import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { SuspicionRequest } from '../../requests/medical-record/suspicion.request';
import { ListSuspicionResponse } from "../../responses/medical-record/list-suspicion-response";
import { SuspicionResponse } from "../../responses/medical-record/suspicion-response";
import { ReturnStruct } from "src/app/shared/structs/return.struct";


@Injectable({
    providedIn: 'root'
  })
  export class SuspicionService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

    public getAllSuspicion(searchText: string): Observable<ListSuspicionResponse>{
      let uri = `Suspicion`
  
      return this.httpClient.get<ListSuspicionResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    public getSuspicion(idSuspicion: number): Observable<SuspicionResponse>{
      let uri = `Suspicion/idSuspicion/${idSuspicion}`
  
      return this.httpClient.get<SuspicionResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
   }

   public createSuspicion(suspicionRequest: SuspicionRequest): Observable<ReturnStruct>{
    let uri = 'Suspicion'

    return this.httpClient.post<ReturnStruct>(environment.urlApiMedicalRecord + uri, suspicionRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
   }

   public updateSuspicion(idSuspicion: number, suspicionRequest: SuspicionRequest): Observable<ReturnStruct>{
    let uri = `Suspicion/idSuspicion/${idSuspicion}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + uri, suspicionRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
   }
}