<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Lista de Relatórios internos</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <div class="row">
            <div *ngIf="isMaster" class="col-12 col-sm-6 col-md-3">
                <app-select (selectedValue)="selectChange($event)" [multiple]="true" [list]="listAllProfile" 
                    [idField]="'idProfile'" [nameField]="'profileName'" [preSelectedlist]="preselectedList"
                    [labelField]="'Perfis'">
                </app-select>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-icon matPrefix class="my-icon">search</mat-icon>
                    <mat-label>Pesquisar</mat-label>
                    <input (keyup)="onKeySearch($event)" matInput type="text">
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="filterProfileInternalReport != null  && filterProfileInternalReport.length > 0">
            <div class="row" *ngFor="let profileReports of filterProfileInternalReport">
                <div *ngIf="profileReports.internalReports != null && profileReports.internalReports.length > 0" class="col-12">
                    <div class="title-header">
                        <h1>{{profileReports.nameProfile}}</h1>
                    </div>
                    <div class="card-list">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of profileReports.internalReports">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.internalReportName}}</span>
                                        <span class="secundary last-item">{{item.lastAccessDate | date: 'dd/MMM/yyyy HH:mm:ss'}}</span>
                                    </div>
                                    <div class="actions">
                                        <a>
                                            <mat-icon aria-hidden="false" aria-label="Visualizar"
                                                [routerLink]="['/bi/internal-details/' + item.idInternalReportFunctionProfile]">
                                                visibility
                                            </mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-empty-list *ngIf="filterProfileInternalReport != undefined && filterProfileInternalReport.length == 0 && !isFirstLoading">
        </app-empty-list> 
    </div>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>