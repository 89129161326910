import { AgeStruct } from "../../structs/risk-classification/age.struct";

export class QuestionRequest {
    public statement: string;
    public maxAgeStruct: AgeStruct;
    public minAgeStruct: AgeStruct;
    public description: string;
    public order: number;
    public idParent: number;
    public idAnswer: number;
}