<app-menu [isRenderMenu]="false" [menuModuleEnum]="menuModuleEnum"></app-menu>

<div class="container-body">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <div class="white-box">
                <h2>Acessar conta</h2>
                <form class="form" [formGroup]="model"  (ngSubmit)="singin()">
                    <div class="row">   
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Matricula</mat-label>
                                <input matInput type="text" formControlName="login" required>
                                <mat-error *ngIf="model.get('login').invalid">Informe a Matricula</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Senha</mat-label>
                                <input matInput type="password" formControlName="password" required>
                                <mat-error *ngIf="model.get('password').invalid">Informe a senha</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-button type="submit" class="btn-primary btn-block">
                                <span *ngIf="isLoading == false">Entrar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                            </button>
                            <asl-google-signin-button type="standard" size="large" width="330px"> </asl-google-signin-button>
                            <button mat-button type="button" routerLink="/forgot-password" class="btn-secundary btn-block">
                                Esqueci minha senha
                            </button>                          
                        </div>
                    </div>
                </form> 
            </div>
        </div>
    </div>
</div>