import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MessageHistoryResponse } from '../../responses/notification/message-history.response';
import { CsatResultRequest } from '../../requests/customer-success/csat-result.request';
import { CsatResultResponse } from '../../responses/orchestrator-user/csat-result.response';

@Injectable({
    providedIn: 'root'
})
export class CsatService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    getResult(body: CsatResultRequest): Observable<CsatResultResponse> {
        let uri = `Csat`

        return this.httpClient.post<CsatResultResponse>(environment.urlApiOrchestratorUser + uri, body, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}
