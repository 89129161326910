import { FunctionProfileStruct } from "../../structs/business-intelligence/function-profile-struct";


export class InternalReportRequest{

    public internalReportName: string;
    
    public isActive: boolean;
    
    public workspace: string;
    
    public biReport: string;
    
    public width: number;
    
    public height: number;
    
    public internalReportLink: string;
    
    public ordenation: number;
    
    public listFunctionProfileStruct: FunctionProfileStruct[];

    public pageNumber: number;
}