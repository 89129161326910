import { AllergyStruct } from "../../structs/medical-record/allergy.struct";
export class TemplateMedicineRequest {
    public idMedicinePresentationType: number;
    public idMedicineGroup: number;
    public idMedicineSubgroup: number;
    public medicineDescription: string;
    public medicineCode: string;
    public idMedicineAdministrationType: number;
    public medicinePresentationOther: string;
    public medicineAdministrationOther: string;
    public listIdMedicineAdministrationType: number[];
    public idMeasurementUnit: number;
    public idDilution: number;
    public listAllergy: AllergyStruct[];
}