<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-totem-modal-list">
            <h2>Deseja excluir esta configuração?</h2>
         </div>
         
         <div class="body-modal-list">
            Você realmente deseja excluir esta configuração cadastrada? Essa ação é irreversível.
         </div>
         <div class="footer-modal-list">
            <button mat-button class="btn-primary block" (click)="clickDelete()">
                <span>Sim, excluir</span>
            </button>
            <button mat-button class="btn-secundary block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>