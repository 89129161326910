<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Integrações</h1>
    <div class="white-body">
        <div class="row">
            <div class="col-6 col-sm-3">
                <a mat-button class="btn-primary add-user" routerLink="/support/relation-config/register">
                    <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Integração
                </a>
            </div>
        </div>

        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-2">
                    <app-select (selectedValue)="selectHealthUnit($event)" [multiple]="true" [url]="this.urlListHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" [optionAll]="true" [allOptionText]="'TODAS AS UNIDADES'">
                    </app-select>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de Integração</mat-label>
                        <mat-select [(ngModel)]="idIntegrationType" (selectionChange)="search()">
                            <mat-option>Todas</mat-option>
                            <mat-option *ngFor="let item of listIntegrationType" [value]="item.idIntegrationType">
                                {{item.integrationTypeName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listRelationConfig != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listRelationConfig">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">Unidade: {{item.healthUnitName}}</span>
                            <span class="secundary">Tipo integração: {{item.integrationTypeName}}</span>
                            <span class="secundary-status last-info">
                                <div class="bullet" [style.background-color]="'#85C443'" *ngIf="item.isOnline"></div>
                                <div class="bullet" [style.background-color]="'#a5a5a5'" *ngIf="!item.isOnline"></div>
                                {{item.isActive? "Ativo":"Inativo"}}
                            </span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/support/relation-config/register', {idHealthUnitRelation: item.idHealthUnitRelation}]">
                                    edit</mat-icon>
                            </a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    (click)="openModal(item.idHealthUnitRelation)">
                                    delete_forever</mat-icon>
                            </a>
                            <a (click)="navigateToRegister(item.idHealthUnitRelation)">
                                <mat-icon matTooltip="Criar a partir deste" aria-hidden="false"
                                    aria-label="Editar">control_point_duplicate</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listRelationConfig != undefined && listRelationConfig.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>