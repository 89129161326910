import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks } from 'src/app/shared/services/mask.service';
import { SuspicionService } from 'src/app/shared/services/API/medical-record/suspicion.service';
import { SuspicionRequest } from 'src/app/shared/services/requests/medical-record/suspicion.request';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-suspicion-register',
  templateUrl: './suspicion-register.component.html',
  styleUrls: ['./suspicion-register.component.css']
})
export class SuspicionRegisterComponent implements OnInit{

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private suspicionService: SuspicionService,
    ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.suspicion;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public isDefault: boolean = false;
  public masks: Masks;
  public idSuspicion: number;
  public suspicionRequest: SuspicionRequest;
  public listHealthUnit: ListHealthUnitStruct[] = [];
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";
  
  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      suspicionName: ['', [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        listIdHealthUnit: ['', [Validators.required]],
      }),
      isActive: ['', [Validators.required]]
    });


    this.idSuspicion == null;
    this.isUpdate == false;

    if (this.activatedRoute.snapshot.paramMap.get('idSuspicion'))
      this.idSuspicion = parseInt(this.activatedRoute.snapshot.paramMap.get('idSuspicion'));

    if (this.idSuspicion != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateSuspicionData();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    
    this.suspicionRequest = new SuspicionRequest();

    this.isLoading = true;

    if(this.idSuspicion != null)
    {
      this.suspicionRequest.idSuspicion = this.idSuspicion;
    }
    this.suspicionRequest.suspicionName = this.model.get('suspicionName').value;
    if(this.model.get('firstChildGroup').get('listIdHealthUnit').value)
    {
      this.suspicionRequest.listIdHealthUnit = this.model.get('firstChildGroup').get('listIdHealthUnit').value;
    }
    else
    {
      this.suspicionRequest.listIdHealthUnit = null;
    }
    this.suspicionRequest.isActive = this.model.get('isActive').value === "true" ? true : false;
    this.suspicionRequest.isDefault = this.isDefault;
    if (this.isUpdate)
    {
      this.updateSuspicion();
    }
    else
    {
      this.createSuspicion();
    }
  }

  populateSuspicionData() {
    this.suspicionService.getSuspicion(this.idSuspicion).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('suspicionName').setValue(response.suspicionStruct.suspicion.suspicionName);
        if(response.suspicionStruct.suspicion.isDefault == false)
        {
          this.model.get('firstChildGroup').get('listIdHealthUnit').setValue(response.suspicionStruct.listIdHealthUnit);
        }
        else
        {
          this.model.get('firstChildGroup').get('listIdHealthUnit').disable();
          this.model.get('suspicionName').disable();
          this.isDefault = true;
        }
        
        this.model.get('isActive').setValue(response.suspicionStruct.suspicion.isActive.toString());
        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  updateSuspicion() {
    this.suspicionService.updateSuspicion(this.idSuspicion, this.suspicionRequest).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/suspicion']);

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }


  createSuspicion() {
    this.suspicionService.createSuspicion(this.suspicionRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          if (response.errorCode != 2)
            return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/suspicion']);

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
