<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
<div class="container-body">
    <h1>Caches</h1>
    <div class="white-body">
        <div class="row">
            <div class="col-6 col-sm-3">
                <a mat-button class="btn-primary add-user" routerLink="/developer/cache-register">
                    <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Cache
                </a>
            </div>
        </div>
        <div class="card-list" *ngIf="listCache != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listCache">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">Cache: {{item.cacheName}}</span>
                            <span class="secundary">Projeto: {{item.projectName}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/developer/cache-register', {idCache: item.idCache}]">
                                    edit</mat-icon>
                            </a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    (click)="deleteCache(item.idCache)">
                                    delete_forever</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>