import { HealthGuidelineCategorization } from "../../models/risk-classification/health-guideline-categorization.model";

export class HealthGuidelineRequest {
    public healthGuidelineName: string;
    public isActive: boolean;  
   
    public listHealthGuidelineCategorization: HealthGuidelineCategorization[];
    public listIdHealthUnit: number[];
    public listIdProtocol: number[];
    public listIdFlowchart: number[];
    public listIdDiscriminator: number[];
}