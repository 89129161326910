import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportSourceModalComponent } from 'src/app/business-intelligence/pages/report-list/report-source-modal/report-source-modal.component';
import { LogBatchStatusEnum } from 'src/app/shared/enum/log-batch-status.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { InternalReportProfileService } from 'src/app/shared/services/API/business-intelligence/internal-report-profile.service';
import { LogBatchVisualizerService } from 'src/app/shared/services/API/srvlog/log-batch-visualizer.service';
import { LogBatchMessage } from 'src/app/shared/services/models/srvlog/log-batch-message.model';
import { LogBatch } from 'src/app/shared/services/models/srvlog/log-batch.model';

@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.css']
})

export class DetailsModalComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportSourceModalComponent>,
    private alertService: AlertService,
    private logBatchVisualizerService: LogBatchVisualizerService,
  ) { }

  public isLoading: boolean;

  public logBatch: LogBatch = new LogBatch();
  public listLogBatchMessage: LogBatchMessage[] = [];
  public idLogBatch: number;

  public interval: any;
  public counter: number = 0;

  ngOnInit(): void {
    if (this.data && this.data['logBatch']) {
      this.logBatch = this.data['logBatch'];
      this.idLogBatch = this.logBatch.idLogBatch;
      this.startTime();
    }
    else {
      this.matDialogRef.close({ error: 'no data' })
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  startTime() {
    if (this.counter == 0) {
      this.populateView();
    }

    this.interval = setInterval(populateView, 15000);

    var $this = this;

    function populateView() {

      if ($this.counter > 720 || $this.logBatch.idLogBatchStatus != LogBatchStatusEnum.Processing) // 4 x por minuto por 180 minutos e processamento não finalizado
      {
        clearInterval($this.interval);
      }

      $this.counter++;

      $this.populateView();
    }
  }

  populateView() {
    this.logBatchVisualizerService.getLog(this.idLogBatch).subscribe(
      {
        next: (response) => {
          console.log("Batch details response: ", response);
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.logBatch = response.getCompleteLogBatchStruct.logBatch;
          this.listLogBatchMessage = response.getCompleteLogBatchStruct.listLogBatchMassages.sort(x => x.idLogBatchMessage);
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
  }

  close() {
    this.matDialogRef.close({});
  }

}
