import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ListCareLinePriorityResponse } from '../../responses/care-line-priority/list-care-line-priority.response';
import { CareLinePriorityResponse } from '../../responses/care-line-priority/care-line-priority.response';
import { CareLinePriorityRequest } from '../../requests/care-line-priority/care-line-priority.request';

@Injectable({
  providedIn: 'root'
})
export class CareLinePriorityService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listCareLinePriority(searchText: string): Observable<ListCareLinePriorityResponse> {
    let uri = 'CareLinePriority/getAll?'

    if (searchText != null)
      uri = uri + `searchText=${searchText}&`;

    return this.httpClient.get<ListCareLinePriorityResponse>(environment.urlApicareLinePriority + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getCareLinePriority(idCareLinePriority: number): Observable<CareLinePriorityResponse> {
    return this.httpClient.get<CareLinePriorityResponse>(environment.urlApicareLinePriority + `CareLinePriority/idCareLinePriority/${idCareLinePriority}`, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public createCareLinePriority(createCareLinePriorityRequest: CareLinePriorityRequest): Observable<ReturnStruct> {
    return this.httpClient.post<ReturnStruct>(environment.urlApicareLinePriority + `CareLinePriority`, createCareLinePriorityRequest, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public updateCareLinePriority(idCareLinePriority: number, updateCareLinePriorityRequest: CareLinePriorityRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApicareLinePriority + `CareLinePriority/idCareLinePriority/${idCareLinePriority}`, updateCareLinePriorityRequest, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteCareLinePriority(idCareLinePriority: number): Observable<ReturnStruct> {
    return this.httpClient.delete<ReturnStruct>(environment.urlApicareLinePriority + `CareLinePriority/idCareLinePriority/${idCareLinePriority}`, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}