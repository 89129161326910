import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { IntegrationRelationConfigService } from 'src/app/shared/services/API/integration-relation-config/integration-relation-config.service';

@Component({
  selector: 'app-relation-config-delete-modal',
  templateUrl: './relation-config-delete-modal.component.html',
  styleUrls: ['./relation-config-delete-modal.component.css']
})
export class RelationConfigDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<RelationConfigDeleteModalComponent>,
    private alertService: AlertService,
    private integrationRelationConfigService:IntegrationRelationConfigService
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteRelationConfig: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.integrationRelationConfigService.Delete(this.data.idRelationConfig).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show('Sucesso', "Integração excluido com sucesso!", AlertType.success); 
      this.matDialogRef.close({deleteRelationConfig: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteRelationConfig: false}); 
  }
}