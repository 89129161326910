import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ListGroupOriginResponse } from '../../responses/medical-record/list-group-origin.response';
import { GroupOriginResponse } from '../../responses/medical-record/group-origin.response';
import { GroupOriginRequest } from '../../requests/medical-record/group-origin.request';

@Injectable({
  providedIn: 'root'
})
export class GroupOriginService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listGroupOrigin(): Observable<ListGroupOriginResponse> {
    let uri = `GroupOrigin/getAll`

    return this.httpClient.get<ListGroupOriginResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public getGroupOrigin(idGroupOrigin: number): Observable<GroupOriginResponse> {
    let uri = `GroupOrigin/idGroupOrigin/${idGroupOrigin}`

    return this.httpClient.get<GroupOriginResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
  
  public postGroupOrigin(request: GroupOriginRequest): Observable<ReturnStruct> {
    let uri = `GroupOrigin`

    return this.httpClient.post<ReturnStruct>(environment.urlApiMedicalRecord + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public putGroupOrigin(request: GroupOriginRequest): Observable<ReturnStruct> {
    let uri = `GroupOrigin`

    return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteGroupOrigin(idGroupOrigin: number): Observable<ReturnStruct> {
    let uri = `GroupOrigin/idGroupOrigin/${idGroupOrigin}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}