<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="header-info">
        <div class="col-12 col-sm-5 col-md-4">
            <h1>Procedência do paciente</h1>
        </div>
        <div class="row container-button">
            <div class="col-12 col-sm-7 col-md-4">
                <button mat-flat-button color="accent" class="btn-secundary btn-block" (click)="routerListGroup()">
                    <span *ngIf="isLoading == false">
                        Gerenciar grupos
                        <mat-icon class="edit-icon" aria-hidden="false" aria-label="Editar">edit</mat-icon>
                    </span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class="col-12 col-sm-7 col-md-4">
                <button mat-flat-button (click)="submit()" color="primary" class="btn-primary btn-block">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>

    <div class="body-form">

        <div class="white-content type-arrival-content col-12 col-md-6">
            <form class="form" [formGroup]="model">
                 <div class="title-header">
                    <h1>Tipos de chegada</h1>
                </div>
                <div *ngFor="let item of listArrivalTypeDefault; let i = index" >
                    <div class="row">
                        <div class="col-9" >
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" [disabled]="true" [value]="item.arrivalTypeName">
                            </mat-form-field>
                        </div>                 
                        <div class="col-3">
                            <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="addNextArrival()"
                                    *ngIf="0 == (model.get('listArrivalTypeNotDefault')['controls'].length) && i ==  listArrivalTypeDefault.length - 1">
                                <mat-icon aria-hidden="false" aria-label="Menu apps" >add</mat-icon>
                            </button>
                        </div>                
                    </div>
                </div>
                <div formArrayName="listArrivalTypeNotDefault" 
                    *ngFor="let item of model.get('listArrivalTypeNotDefault')['controls']; let i = index" >
                    <div class="row" [formGroupName]="i">
                        <div class="col-9" >
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" formControlName="arrivalTypeName">
                                <mat-error *ngIf="item.get('arrivalTypeName').invalid">Informe o nome do tipo de chegada</mat-error>
                            </mat-form-field>
                        </div>                 
                        <div class="col-3">
                            <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="addNextArrival()"
                                    *ngIf="(i + 1) == (model.get('listArrivalTypeNotDefault')['controls'].length)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps" >add</mat-icon>
                            </button>
                            <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="removeArrival(i)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>                
                    </div>
                </div>
            </form>
        </div>
        <div class="white-content origin-content col-12 col-md-6">
            <form class="form" [formGroup]="model">
    
                <div class="title-header">
                    <h1>Origem</h1>
                </div>
                <div *ngFor="let item of listOriginDefault; let i = index" >
                    <div class="row">
                        <div class="col-9" >
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" [disabled]="true" [value]="item.originName">
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="addNextOrigin()"
                                    *ngIf="0 == (model.get('listOriginNotDefault')['controls'].length) && i ==  listOriginDefault.length - 1">
                                <mat-icon aria-hidden="false" aria-label="Menu apps" >add</mat-icon>
                            </button>
                        </div>                
                    </div>
                </div>
                <div formArrayName="listOriginNotDefault" 
                    *ngFor="let item of model.get('listOriginNotDefault')['controls']; let i = index" >
                    <div class="row" [formGroupName]="i">
                        <div class="col-9" >
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" formControlName="originName">
                                <mat-error *ngIf="item.get('originName').invalid">Informe o nome do tipo de chegada</mat-error>
                            </mat-form-field>
                        </div>            
                        <div class="col-3">
                            <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="addNextOrigin()"
                                    *ngIf="(i + 1) == (model.get('listOriginNotDefault')['controls'].length)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps" >add</mat-icon>
                            </button>
                            <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="removeOrigin(i)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>                
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

