import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageHistoryDisplayStruct } from 'src/app/shared/services/structs/notification/message-history-display.struct';

@Component({
  selector: 'app-notification-accordion',
  templateUrl: './notification-accordion.component.html',
  styleUrls: ['./notification-accordion.component.css']
})
export class NotificationAccordionComponent implements OnInit {

  @Input() messageHistory: MessageHistoryDisplayStruct[];


  constructor(
    private formBuilder: FormBuilder,
  ) { }

  public model: FormGroup;

  public options: Object = {
    placeholderText: 'Corpo da mensagem',
    charCounterCount: false,
    iframe: true,
    toolbarButtons: [],
  };

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      messageTitle: ['', [Validators.required]],
      messageContent: ['', [Validators.required]],
      listIdHealthUnit: [null, [Validators.required]],
      listIdProfession: [null, [Validators.required]],
    });
  }

}
