import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CounselingService } from 'src/app/shared/services/API/risk-classification/counseling.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { CounselingRequest } from 'src/app/shared/services/requests/risk-classification/counseling.request';
import { CounselingTypeStruct } from 'src/app/shared/services/structs/risk-classification/counseling-type.struct';


@Component({
  selector: 'app-counseling-register',
  templateUrl: './counseling-register.component.html',
  styleUrls: ['./counseling-register.component.css']
})
export class CounselingRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private counselingService: CounselingService,
    private lookupRiskService: LookupRiskService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_counseling;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
  public listCounselingType:CounselingTypeStruct[];

  public masks: Masks;
  public idCounseling: number;
  public counselingRequest: CounselingRequest;

  ngOnInit(): void {

    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      observation: [''],
      idType: [null, [Validators.required]],
    });

    this.masks = this.maskService.getMasks();
    this.populateCounselingType();

    this.counselingRequest = new CounselingRequest();

    this.idCounseling == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idCounseling'))
      this.idCounseling = parseInt(this.activatedRoute.snapshot.paramMap.get('idCounseling'));

    if (this.idCounseling != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
       
      this.populateCounselingData();
      
    }
  }

  submit() {
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.counselingRequest.observation = this.model.get('observation').value;
  
    this.counselingRequest.counselingName = this.model.get('name').value;

    this.counselingRequest.idCounselingType = this.model.get('idType').value;

    if (this.isUpdate)
      this.updateCounseling();
    else
      this.createCounseling();
  }

  populateCounselingData() {
    this.counselingService.getCounseling(this.idCounseling).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('name').setValue(response.counseling.counselingName);
      this.model.get('observation').setValue(response.counseling.observation);
      this.model.get('idType').setValue(response.counseling.idCounselingType);

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateCounseling() {
    this.counselingService.updateCounseling(this.idCounseling, this.counselingRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/counseling']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateCounselingType() {
    this.lookupRiskService.getLookup().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listCounselingType = response.listCounselingType

      this.isLoading = false

    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createCounseling() {
    this.counselingService.postCounseling(this.counselingRequest).subscribe((response) => {

      if (response.isError) {
        console.log(this.counselingRequest)
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/counseling']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
