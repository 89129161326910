import { DeviceModel } from "../../models/device/device.model";
import { ChartMonthStruct } from "./chart-month.struct";
import { RequestStruct } from "./request.struct";

export class DeviceVisualizationStruct extends DeviceModel {
    public deviceTypeName: string;
    public isOnline: boolean;
    public lastRequest: Date;
    public timeOffline: number;
    public listDeviceRequest: ChartMonthStruct[];
    public listRequest: RequestStruct[];
}