<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <div class="row">
        <div class="col-12 col-md-4">
            <h1>Monitoramento de batches</h1>
        </div>
        <div class="col-12 col-md-8">
            <mat-slide-toggle [(ngModel)]="this.isPanelMode" (change)="changeMode($event)">Modo
                Painel</mat-slide-toggle>
        </div>
    </div>
    <div class="white-body">
        <div class="card-list" *ngIf="!isLoading && listBatch != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listBatch">
                    <div class="card-item">
                        <div class="infos">
                            <div class="primary-container">
                                <span class="primary">{{item.projectName}}</span>
                            </div>
                            <span class="secundary-status last-info" [ngClass]="{
                                'status-processing': run.idLogBatchStatus == statusProcessing,
                                'status-success': run.idLogBatchStatus == statusSuccess,
                                'status-warning': run.idLogBatchStatus == statusWarning,
                                'status-error': run.idLogBatchStatus == statusError,
                              }" *ngFor="let run of item.lastRuns">
                                {{run.datetimeInclusion | date: 'dd/MM/yyyy HH:mm:ss'}} -
                                {{run.timeSpan}}
                            </span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Iniciar"
                                    [routerLink]="['/developer/log-batch-monitoring/details', { idBatchConfig: item.idBatchConfiguration}]">
                                    visibility</mat-icon></a>
                            <a><mat-icon aria-hidden="false" aria-label="Deletar"
                                    (click)="openModal(item.idBatchConfiguration)">delete_forever</mat-icon></a>
                        </div>
                        <app-chip-status [status]="item.type"></app-chip-status>
                    </div>
                </div>
            </div>
        </div>
        <app-empty-list *ngIf="!isLoading &&(listBatch != undefined && listBatch.length == 0)"></app-empty-list>
    </div>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>