import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { CustomerSuccessComponent } from './pages/customer-success/customer-success.component';
import { CsatConfigComponent } from './pages/csat-config/csat-config.component';
import { CsatResultComponent } from './pages/csat-result/csat-result.component';

const routes: Routes = [
  { path: '', component: CustomerSuccessComponent, canActivate: [AuthGuard] },
  { path: 'config', component: CsatConfigComponent, canActivate: [AuthGuard] },
  { path: 'result', component: CsatResultComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerSuccessRoutingModule { }
