import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateStruct } from 'src/app/shared/services/structs/report/template.struct';
import { BtnReportTemplateViewModalComponent } from './btn-report-template-view-modal/btn-report-template-view-modal.component';
import { ReportTypeEnum } from 'src/app/shared/enum/report/report-type.enum';

@Component({
  selector: 'app-btn-report-template-list-modal',
  templateUrl: './btn-report-template-list-modal.component.html',
  styleUrls: ['./btn-report-template-list-modal.component.css']
})
export class BtnReportTemplateListModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<BtnReportTemplateListModalComponent>) { }

  public hideFirstOption: boolean = false;

  ngOnInit(): void {
    if (this.data.reportType.idReportType == ReportTypeEnum.SADT){
      this.hideFirstOption = true;
    }
  }

  openViewModal(value: number) {
    if (this.data.reportType.idReportType == ReportTypeEnum.Admissão){
      this.selectItem(value);
    } else {
      const dialogRef = this.dialog.open(BtnReportTemplateViewModalComponent, {
        data: {
          idTemplate: value
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result !== undefined){
          this.selectItem(value)
        }
      });
    }
    
  }
  
  selectItem(idTemplate: number){
    this.matDialogRef.close(idTemplate); 
  }

  close(){
    this.matDialogRef.close(); 
  }
}
