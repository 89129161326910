export class ProtocolModel{
    public idProtocol: number;
    public protocolName: string;
    public description: string;
    public isTemplate: boolean;
    public isActive: boolean;
    public outOfOrder: boolean;
    public datetimeInclusion: Date;
    public idPriorityColorGroup:number
    public isDeleted:boolean;
    public isTelephone:boolean;
}