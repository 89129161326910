import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BatchConfigurationVisualizerService } from 'src/app/shared/services/API/srvlog/batch-configuration-visualizer.service';

@Component({
  selector: 'app-batch-run-modal',
  templateUrl: './batch-run-modal.component.html',
  styleUrls: ['./batch-run-modal.component.css']
})
export class BatchRunModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BatchRunModalComponent>,
    private batchService: BatchConfigurationVisualizerService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ runBatch: false });
  }

  clickStart() {

    this.isLoading = true;

    this.batchService.runBatch(this.data.idBatch).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Batch iniciado com sucesso!", AlertType.success);
      this.matDialogRef.close({ runBatch: true });
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  clickCancel() {
    this.matDialogRef.close({ runBatch: false });
  }
}