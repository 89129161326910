import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnswerButtonTypeEnum } from 'src/app/shared/enum/answer-button-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AnswerService } from 'src/app/shared/services/API/self-triage/answer.service';
import { QuestionService } from 'src/app/shared/services/API/self-triage/question.service';
import { AnswerModel } from 'src/app/shared/services/models/self-triage/anwser.model';
import { AnswerRequest } from 'src/app/shared/services/requests/self-triage/answer.request';


@Component({
  selector: 'app-answer-modal',
  templateUrl: './answer-modal.component.html',
  styleUrls: ['./answer-modal.component.css']
})
export class AnswerModelComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<AnswerModelComponent>,
    private questionService: QuestionService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private answerService: AnswerService,
  ) { }

  public buttonTypeNewQuestion: AnswerButtonTypeEnum = AnswerButtonTypeEnum.NewQuestion;
  public buttonTypeCounseling: AnswerButtonTypeEnum = AnswerButtonTypeEnum.Counseling;
  public isLoading: boolean = false;
  public model: FormGroup;
  public idButtonType: number;
  public saveAnswer: boolean = false;
  private answer: AnswerModel;

  ngOnInit(): void {
    this.answer = this.data.answer;
    this.model = this.formBuilder.group({
      answer: [this.data.answer ? this.data.answer.answer : '', [Validators.required]],
      idButtonType: [this.data.answer ? this.data.answer.idButtonType : '', [Validators.required]],
      order: [this.data.answer ? this.data.answer.order : '', [Validators.required]],
      idCounseling: [this.data.answer ? this.data.answer.idCounseling : ''],
    });
    this.idButtonType = this.data.answer ? this.data.answer.idButtonType : null;
  }

  submit() {
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    if (this.isLoading)
      return;
    this.isLoading = true;
    let answerRequest: AnswerRequest = new AnswerRequest();
    answerRequest.answer = this.model.get("answer").value;
    answerRequest.idButtonType = this.model.get("idButtonType").value;
    answerRequest.idCounseling = answerRequest.idButtonType == this.buttonTypeCounseling.valueOf() ? this.model.get("idCounseling").value : null;
    answerRequest.idParent = this.data.idParent;
    answerRequest.order = this.model.get("order").value;
    answerRequest.idParentQuestion = this.data.idParentQuestion;

    if (this.data.answer) {
      answerRequest.idChildQuestion = answerRequest.idButtonType == this.buttonTypeNewQuestion.valueOf() ? this.data.answer.idChildQuestion : null;
      this.updateAnswer(answerRequest);
    } else {
      this.createAnswer(answerRequest);
    }
  }

  createAnswer(answerRequest: AnswerRequest) {
    this.answerService.createAnswer(answerRequest).subscribe((response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show('Sucesso', "Resposta criada com sucesso!", AlertType.success);
      this.answer = response.answer;
      this.saveAnswer = true;

    }, (error) => {
      this.alertService.show('Erro Inesperado', error, AlertType.error);
      this.isLoading = false;
    });
  }

  updateAnswer(answerRequest: AnswerRequest) {
    this.answerService.updateAnswer(this.data.answer.idAnswer, answerRequest).subscribe((response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.answer = response.answer;
      this.alertService.show('Sucesso', "Resposta atualizada com sucesso!", AlertType.success);
      this.saveAnswer = true;
    }, (error) => {
      this.alertService.show('Erro Inesperado', error, AlertType.error);
      this.isLoading = false;
    });
  }

  buttonTypeChange() {
    this.idButtonType = this.model.get("idButtonType").value;
    if (this.idButtonType == this.buttonTypeCounseling) {
      this.model.get('idCounseling').setValidators([Validators.required]);
    } else {
      this.model.get('idCounseling').setValidators(null);
    }
    this.model.get('idCounseling').updateValueAndValidity();
  }

  clickCancel() {
    this.matDialogRef.close({ saveAnswer: this.saveAnswer, answer: this.answer });
  }

  createQuestion(idParent: number, idAnswer: number, idQuestion: number) {
    this.matDialogRef.close({ createQuestion: true, idParent, idAnswer, idQuestion });
  }
}