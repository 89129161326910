import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { ProjectCacheService } from 'src/app/shared/services/API/cache/project-cache.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProjectRequest } from 'src/app/shared/services/requests/cache/project.request';

@Component({
  selector: 'app-project-register',
  templateUrl: './project-register.component.html',
  styleUrls: ['./project-register.component.css']
})
export class ProjectRegisterComponent implements OnInit{
  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cacheService: ProjectCacheService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog) { }
    
    public idProject: number;
    public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
    public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.project;
    public model: FormGroup;
    public isLoading: boolean = true;

    ngOnInit(): void {
      this.model = this.formBuilder.group({
        idProject: [''],
        projectName: ['', [Validators.required]],
      });
      if (this.activatedRoute.snapshot.paramMap.get('idProject'))
      {
        this.idProject = parseInt(this.activatedRoute.snapshot.paramMap.get('idProject'));
        this.search(this.idProject);
      }
      else
      this.isLoading = false;
    }

    search(idProject: number){
      this.cacheService.GetProject(idProject).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.model.get('idProject').setValue(response.project.idProject);
          this.model.get('projectName').setValue(response.project.projectName);
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }

    submit()
    {
      if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    let body: ProjectRequest = new ProjectRequest();

    body.projectName = this.model.get("projectName").value;

    this.isLoading = true;
    if (this.idProject)
      this.Update(body);
    else
      this.Create(body);
  }

  Create(projectRequest: ProjectRequest)
  {
    {
      this.cacheService.CreateProject(projectRequest).subscribe({
        next: response => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          this.alertService.show('Sucesso', 'Projeto criado com sucesso!', AlertType.success);
          this.router.navigate(['/developer/project-list']);
  
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
  }

  Update(projectRequest: ProjectRequest)
  {
    this.cacheService.UpdateProject(projectRequest, this.idProject).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show('Sucesso', 'Projeto alterado com sucesso!', AlertType.success);
        this.router.navigate(['/developer/project-list']);

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
