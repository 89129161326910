import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { BillingConfigRequest } from '../../requests/billing-by-health-unit/billing-config.request';
import { GetBillingConfigResponse } from '../../responses/billing-by-health-unit/get-billing-config.response';
import { GetBillingPlanResponse } from '../../responses/billing-by-health-unit/get-billing-plan.response';
import { ListBillingConfigResponse } from '../../responses/billing-by-health-unit/list-billing-config.response';
import { PostPDFReportResponse } from '../../responses/billing-by-health-unit/post-pdf-report.response';

@Injectable({
  providedIn: 'root'
})
export class BillingConfigService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listBillingConfig(searchText: string): Observable<ListBillingConfigResponse> {
    let uri = `BillingConfig?`

    if (searchText)
      uri = uri + `searchText=${searchText}&`

    return this.httpClient.get<ListBillingConfigResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getBillingConfig(idBillingConfig: number): Observable<GetBillingConfigResponse> {
    let uri = `BillingConfig/idBillingConfig/${idBillingConfig}`

    return this.httpClient.get<GetBillingConfigResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getBillingConfigContract(idBillingConfigContract: number): Observable<PostPDFReportResponse> {
    let uri = `BillingConfig/idBillingConfigContract/${idBillingConfigContract}`

    return this.httpClient.get<PostPDFReportResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getBillingConfigByPlan(idBillingPlan: number): Observable<ListBillingConfigResponse> {
    let uri = `BillingConfig/idBillingPlan/${idBillingPlan}`

    return this.httpClient.get<ListBillingConfigResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  postBillingConfig(body: BillingConfigRequest): Observable<ReturnStruct> {
    let uri = `BillingConfig`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  updateBillingConfig(idBillingConfig: number, body: BillingConfigRequest): Observable<ReturnStruct> {
    let uri = `BillingConfig/idBillingConfig/${idBillingConfig}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteBillingConfig(idBillingConfig: number): Observable<ReturnStruct> {
    let uri = `BillingConfig/idBillingConfig/${idBillingConfig}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
  
  deleteBillingConfigContract(idBillingConfigContract: number): Observable<ReturnStruct> {
    let uri = `BillingConfig/idBillingConfigContract/${idBillingConfigContract}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}