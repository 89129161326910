import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateManipulationService } from 'src/app/shared/services/API/pharmacy/template-manipulation.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PostPutTemplateRequest } from 'src/app/shared/services/requests/pharmacy/post-put-template.resquest';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-template-register',
  templateUrl: './template-register.component.html',
  styleUrls: ['./template-register.component.css']
})
export class TemplateRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private templateManipulationService: TemplateManipulationService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_template;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idTemplate: number;
  public isCopy: boolean;
  public templateRequest: PostPutTemplateRequest;

  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";

  ngOnInit(): void {
    this.isLoading = false;

    this.masks = this.maskService.getMasks();

    this.templateRequest = new PostPutTemplateRequest();

    this.idTemplate = null;
    this.isCopy = false;
    this.isUpdate = false;

    if (this.activatedRoute.snapshot.paramMap.get('idTemplate'))
      this.idTemplate = parseInt(this.activatedRoute.snapshot.paramMap.get('idTemplate'));

    if (this.activatedRoute.snapshot.paramMap.get('isCopy'))
      this.isCopy = Boolean(this.activatedRoute.snapshot.paramMap.get('isCopy'));

    this.isUpdate = (this.idTemplate ? true : false) && !this.isCopy;

    this.model = this.formBuilder.group({
      templateName: ['', [Validators.required]],
      templateDescription: [''],
      isActive: [false, [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        idHealthUnitAccess: [[],],
        idHealthUnitImport: [null,],
      }),
    });

    if(this.idTemplate || this.isCopy)
      this.populateTemplateData();
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    this.templateRequest.templateName = this.model.get('templateName').value;
    this.templateRequest.templateDescription = this.model.get('templateDescription').value;
    this.templateRequest.isActive = this.model.get('isActive').value;
    this.templateRequest.listIdHealthUnit = this.model.get('firstChildGroup').get('idHealthUnitAccess').value;
    let idHealthUnitImport: number = this.model.get('firstChildGroup').get('idHealthUnitImport').value;
    if (this.isUpdate)
      this.updateTemplate();
    else if (idHealthUnitImport)
      this.importTemplate(idHealthUnitImport);
    else if(this.isCopy)
      this.copyTemplate(this.idTemplate);
    else
      this.createTemplate();
  }

  populateTemplateData() {
    this.templateManipulationService.Get(this.idTemplate).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.model.get('templateName').setValue(response.templateStruct.template.templateName);
        this.model.get('templateDescription').setValue(response.templateStruct.template.templateDescription);
        this.model.get('isActive').setValue(response.templateStruct.template.isActive);
        this.model.get('firstChildGroup').get('idHealthUnitAccess').setValue(response.templateStruct.listTemplateHealthUnit?.map(l => l.idHealthUnit) ?? []);
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateTemplate() {
    this.templateManipulationService.Update(this.idTemplate, this.templateRequest).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
  
        this.router.navigate(['/consulting/template']);
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createTemplate() {
    this.templateManipulationService.Create(this.templateRequest).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
  
        this.router.navigate(['/consulting/template']);
  
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  importTemplate(idHealthUnitImport: number) {
    this.templateManipulationService.Import(idHealthUnitImport, this.templateRequest).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
  
        this.router.navigate(['/consulting/template']);
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  copyTemplate(idTemplate: number) {
    this.templateManipulationService.Copy(idTemplate, this.templateRequest).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/consulting/template']);
      },
      error:(error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}