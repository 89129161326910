import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { TokenAuthAdminService } from 'src/app/shared/services/API/user/token-auth-admin.service';
import { TokenAuthAdminModel } from 'src/app/shared/services/models/user/token-auth-admin.model';
import { UserModel } from 'src/app/shared/services/models/user/user.model';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-super-access-register',
  templateUrl: './super-access-register.component.html',
  styleUrls: ['./super-access-register.component.css']
})
export class SuperAccessRegisterComponent implements OnInit {

  constructor(private tokenService: TokenAuthAdminService,
    private formBuilder: FormBuilder,
    private healthUnitService: HealthUnitService,
    private alertService: AlertService,
    public dialog: MatDialog,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.super_access;
  public wasSaved: boolean = false;
  public model: FormGroup;
  public isLoading: boolean = false;
  public searchText: string;
  public listHealthUnit: HealthUnitStruct[] = [];
  public listUser: UserModel[] = [];
  public urlBase: string = environment.urlApiBaseGPA;
  public token: string;
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idUser: ['', [Validators.required]],
      firstChildGroup: this.formBuilder.group({
        idHealthUnit: ['', [Validators.required]],
      }),
      motive: ['', [Validators.required]],
      ticket: [''],
    });
    this.populateLookupHealthUnit();
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    let tokenAuth: TokenAuthAdminModel = new TokenAuthAdminModel();
    tokenAuth.idHealthUnit = this.model.get('firstChildGroup').get("idHealthUnit").value;
    tokenAuth.accessMotive = this.model.get("motive").value;
    tokenAuth.idUser = this.model.get("idUser").value;
    tokenAuth.ticket = this.model.get("ticket").value;
    this.tokenService.createAdminUser(tokenAuth).subscribe(response => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      } else {
        if (response.isError) {
          this.alertService.show('Sucesso', "Token gerada com sucesso!", AlertType.success);
        }
      }
      this.token = response.tokenAuthAdmin.token;
      this.wasSaved = true;
      this.model.get('firstChildGroup').get("idHealthUnit").disable();
      this.model.get("motive").disable();
      this.model.get("idUser").disable();
      this.model.get("ticket").disable();
    });
  }

  populateLookupHealthUnit() {
    this.healthUnitService.listHealthUnit().subscribe(response => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHealthUnit = response.listHealthUnit;
    });
  }

  populateuserByHealthUnit(event: any) {
    if(event.idHealthUnit != null)
    {
      this.tokenService.listUserHealthUnit(event.idHealthUnit).subscribe(response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listUser = response.listUser;
      });
    }
    else
    this.listUser = null;
  }

  onCopy(text: string) {
    this.alertService.show('Sucesso', text, AlertType.success);
  }
}
