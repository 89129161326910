import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { FlowchartRequest } from '../../requests/self-triage/flowchart.request';
import { CreateFlowchartResponse } from '../../responses/self-triage/create-flowchart.response';
import { GetFlowchartResponse } from '../../responses/self-triage/get-flowchart.response';
import { ListFlowchartResponse } from '../../responses/self-triage/list-flowchart.response';

@Injectable({
    providedIn: 'root'
})
export class FlowchartService extends CommonService {

    constructor(private router: Router,
        private httpClient: HttpClient,
    ) {super();}
    
      public listFlowchart(searchText: string): Observable<ListFlowchartResponse> {
        let uri = "Flowchart?"
        if (searchText)
          uri = uri + `searchText=${searchText}`;
    
        return this.httpClient.get<ListFlowchartResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
          .pipe(
            catchError(this.handleError)
        )
      }
    
      public listAllFlowchart(): Observable<ListFlowchartResponse> {
        let uri = "Flowchart/GetAll"
    
        return this.httpClient.get<ListFlowchartResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
          .pipe(
            catchError(this.handleError)
        )
      }
    
      public getFlowchart(idFlowchart:number): Observable<GetFlowchartResponse> {
        let uri = `Flowchart/idFlowchart/${idFlowchart}`
    
        return this.httpClient.get<GetFlowchartResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
          .pipe(
            catchError(this.handleError)
        )
      }
    
      public createFlowchart(request: FlowchartRequest): Observable<CreateFlowchartResponse> {
        let uri = `Flowchart`
    
        return this.httpClient.post<CreateFlowchartResponse>(environment.urlApiSelfTriage + uri, request, this.addHeaderToken())
          .pipe(
            catchError(this.handleError)
        )
      }
    
      public updateFlowchart(idFlowchart:number, request: FlowchartRequest): Observable<ReturnStruct> {
        let uri = `Flowchart/idFlowchart/${idFlowchart}`
    
        return this.httpClient.put<ReturnStruct>(environment.urlApiSelfTriage + uri, request, this.addHeaderToken())
          .pipe(
            catchError(this.handleError)
        )
      }
    
      public deleteFlowchart(idFlowchart:number): Observable<ReturnStruct> {
        let uri = `Flowchart/idFlowchart/${idFlowchart}`
    
        return this.httpClient.delete<ReturnStruct>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
          .pipe(
            catchError(this.handleError)
        )
      }
}
    
