import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListBiHealthUnitResponse } from "../../responses/business-intelligence/list-bi-health-unit.response";

@Injectable({
  providedIn: 'root'
})
export class OrchestratorBusinessIntelligenceService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

   public getHealthUnitName(idTemplateBi: number): Observable<ListBiHealthUnitResponse>{

    let uri = `GetHealthUnitTemplate/idTemplate/${idTemplateBi}`

    return this.httpClient.get<ListBiHealthUnitResponse>(environment.urlApiOrchestratorBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

}
