<app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

<div class="container-body">
    <div class="white-body">
        <div class="title-header">
            <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon>
            <!-- <h1>{{ 'DEVELOPER.DASHBOARD.TEXT.TITLE' | translate }}</h1> -->
            <h1>Módulo Data-mesh</h1>
        </div>
        <!-- <p>{{ 'DEVELOPER.DASHBOARD.TEXT.DESCRIPTION' | translate }}</p> -->
        <p>Bem vindo ao seu módulo Data-mesh!</p>

        <div class="documentation">
            <h2>Introdução</h2>
            <p>O módulo DataMesh apresenta os seguintes componentes:</p>
            <ul>
                <li>
                    <h3>
                        <span class="link" routerLink="/data-sets">
                            Configuração de origens de dados
                        </span>
                    </h3>
                    <details>
                        <img src="assets/images/data-mesh-wiki/register-data-set-1.png" class="wiki-img">

                        <summary>
                            <span>
                                Escolha um banco de dados e crie uma consulta em SQL
                            </span>
                        </summary>
                    </details>
                    <details>
                        <img src="assets/images/data-mesh-wiki/register-data-set-2.png" class="wiki-img">

                        <summary>
                            <span>
                                Teste sua consulta para poder salvá-la
                            </span>
                        </summary>
                    </details>
                    <details>
                        <img src="assets/images/data-mesh-wiki/register-data-set-3.png" class="wiki-img">

                        <summary>
                            <span>
                                A coluna à direita mostra as tabelas e colunas disponíveis no banco de dados selecionado
                            </span>
                        </summary>
                    </details>
                </li>
                <li>
                    <h3>
                        <span class="link" routerLink="/data-sets">
                            Configuração de produto de dados
                        </span>
                    </h3>
                    <details>
                        <img src="assets/images/data-mesh-wiki/register-data-product.png" class="wiki-img">
                        <summary>
                            <span>
                                Escolha uma origem de dados principal e adicione outras origens de dados para compor o
                                produto, associando as colunas de cada origem
                            </span>
                        </summary>
                    </details>
                </li>
                <li>
                    <h3>
                        <span class="link" routerLink="/data-sets">
                            Visualização e exportação dos resultados
                        </span>
                    </h3>
                    <details>
                        <img src="assets/images/data-mesh-wiki/data-product-export-1.png" class="wiki-img">
                        <summary>
                            <span>
                                Nesta tela, é disponibilizada uma visão tabular, permitindo a seleção de um intervalo de
                                tempo para filtragem e a exportação dos dados em formato .csv
                            </span>
                        </summary>
                    </details>
                </li>
            </ul>
        </div>
    </div>
</div>