export class SupportContactModel{
    
    public idSupportContact: number;
    
    public idSupportContactType: number;

    public contactValue: string;
    
    public buttonName: string;

}