import { BillingConfigContract } from "../../models/billing-by-health-unit/billing-config-contract.model";

export class BillingConfigRequest {
    public idBillingPlan: number;
    public startActivation: Date;
    public endActivation: Date;
    public idBillingDay: number;
    public listContract: BillingConfigContract[] = [];
    public isActive: boolean;
    public isDeleted: boolean;
    public idHealthUnit: number;
    public healthUnitName: string;
}