import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CoordinationService } from 'src/app/shared/services/API/billing/coordination.service';
import { HealthGuidelineService } from 'src/app/shared/services/API/risk-classification/health-guideline.service';

@Component({
  selector: 'app-health-guideline-delete-modal',
  templateUrl: './health-guideline-delete-modal.component.html',
  styleUrls: ['./health-guideline-delete-modal.component.css']
})
export class HealthGuidelineDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<HealthGuidelineDeleteModalComponent>,
    private alertService: AlertService,
    private healthGuidelineService: HealthGuidelineService,
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteCoordination: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.healthGuidelineService.deleteGuideline(this.data.idHealthGuideline).subscribe({
      next: (response)=>{      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }                
        this.alertService.show('Sucesso', "protocolo de saúde excluída com sucesso!", AlertType.success); 
        this.matDialogRef.close({delete: true}); 
    },
    error: (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }
  });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteCoordination: false}); 
  }
}