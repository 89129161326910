<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <div class="header-info">
        <div class="col-12 col-sm-5 col-md-4">
            <h1>Gerenciar especialidades</h1>
        </div>
        <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
        <div class="row container-button">
            <div class="col-12 col-sm-7 col-md-4">
                <button mat-flat-button (click)="submit()" color="primary" class="btn-primary btn-block">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>

    <div class="white-body">
        <div class="title-header">
            <h1>Especialidades adicionadas</h1>
        </div>
        <div class="col-12 col-md-6">
            <form class="form" [formGroup]="model">
                <div *ngFor="let item of listSpecialtyDefault; let i = index">
                    <div class="row">
                        <div class="col-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" [disabled]="true" [value]="item.specialityAttendanceName">
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <button class="totem-button btn-primary" mat-mini-fab type="button"
                                (click)="addNextSpecialty()"
                                *ngIf="0 == (model.get('listSpecialtyNotDefault')['controls'].length) && i ==  listSpecialtyDefault.length - 1">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div formArrayName="listSpecialtyNotDefault"
                    *ngFor="let item of model.get('listSpecialtyNotDefault')['controls']; let i = index">
                    <div class="row" [formGroupName]="i">
                        <div class="col-9">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome</mat-label>
                                <input matInput type="text" formControlName="specialityAttendanceName">
                                <mat-error
                                    *ngIf="item.get('specialityAttendanceName').invalid && (item.get('specialityAttendanceName').touched || item.get('specialityAttendanceName').dirty)">
                                    Informe a especialidade
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <button class="totem-button btn-primary" mat-mini-fab type="button"
                                (click)="addNextSpecialty()"
                                *ngIf="(i + 1) == (model.get('listSpecialtyNotDefault')['controls'].length)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="totem-button btn-primary" mat-mini-fab type="button"
                                (click)="removeSpecialty(i)">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>