import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProfileService } from 'src/app/shared/services/API/admin-user/profile.service';
import { InternalReportService } from 'src/app/shared/services/API/business-intelligence/internal-report.service';
import { PowerBiService } from 'src/app/shared/services/API/business-intelligence/power-bi.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { InternalReportRequest } from 'src/app/shared/services/requests/business-intelligence/internal-report.request';
import { ListProfileStruct } from 'src/app/shared/services/structs/admin-user/list-profile.struct';
import { BiReportStruct } from 'src/app/shared/services/structs/business-intelligence/bi-report.struct';
import { FunctionProfileStruct } from 'src/app/shared/services/structs/business-intelligence/function-profile-struct';
import { WorkspaceStruct } from 'src/app/shared/services/structs/business-intelligence/workspace.struct';

@Component({
  selector: 'app-internal-report-register',
  templateUrl: './internal-report-register.component.html',
  styleUrls: ['./internal-report-register.component.css']
})
export class InternalReportRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private internalReportService: InternalReportService,
    private powerBiService: PowerBiService,
    private profileService: ProfileService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.business_intelligence_internal_report;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idInternalReport: number;
  public internalReportRequest: InternalReportRequest;

  public listProfile: ListProfileStruct[];
  public listWorkspace: WorkspaceStruct[];
  public listBiReport: BiReportStruct[];

  ngOnInit(): void {
    this.isLoading = false;

    this.masks = this.maskService.getMasks();

    this.internalReportRequest = new InternalReportRequest();

    this.idInternalReport == null;
    this.isUpdate == false;

    if (this.activatedRoute.snapshot.paramMap.get('idInternalReport'))
      this.idInternalReport = parseInt(this.activatedRoute.snapshot.paramMap.get('idInternalReport'));

    this.populateWorkspaceSelect();
    this.populateProfileSelect();

    this.model = this.formBuilder.group({
      internalReportName: ['', [Validators.required]],
      isActive: ['', [Validators.required]],
      workspace: ['', [Validators.required]],
      biReport: ['', [Validators.required]],
      width: [, [Validators.required]],
      height: [, [Validators.required]],
      internalReportLink: ['', [Validators.required]],
      ordenation: ['', [Validators.required]],
      listInternalReportFunctionProfile: this.formBuilder.array([]),
      pageNumber: ['', [Validators.required]],
    });

    if (this.idInternalReport != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateInternalReportData();
    }
    else {
      this.model.get('biReport').disable();
      this.model.get('width').setValue(1280);
      this.model.get('height').setValue(720);
      this.addNext();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    this.internalReportRequest.listFunctionProfileStruct = [];
    this.internalReportRequest.internalReportName = this.model.get('internalReportName').value;
    this.internalReportRequest.isActive = this.model.get('isActive').value === "true" ? true : false;
    this.internalReportRequest.workspace = this.model.get('workspace').value;
    this.internalReportRequest.biReport = this.model.get('biReport').value;
    this.internalReportRequest.width = this.model.get('width').value;
    this.internalReportRequest.height = this.model.get('height').value;
    this.internalReportRequest.internalReportLink = this.model.get('internalReportLink').value;
    this.internalReportRequest.ordenation = this.model.get('ordenation').value;
    this.internalReportRequest.pageNumber  = this.model.get('pageNumber').value;
    
    this.model.get('listInternalReportFunctionProfile').value.forEach(x => {
      var functionProfileStruct = new FunctionProfileStruct();

      // if (x.idButton)
      //   button.idHealthUnit = parseInt(x.idButton);
      // else
      //   button.idHealthUnit = null;

      functionProfileStruct.function = x.function;
      functionProfileStruct.idProfile = x.idProfile;
      functionProfileStruct.idInternalReportFunctionProfile = x.idInternalReportFunctionProfile;

      this.internalReportRequest.listFunctionProfileStruct.push(functionProfileStruct);
    });

    if (this.isUpdate)
      this.updateInternalReport();
    else
      this.createInternalReport();
  }

  populateInternalReportData() {
    this.internalReportService.getInternalReport(this.idInternalReport).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.populateBiInternalReportSelect(response.internalReport.workspace);
      this.model.get('internalReportName').setValue(response.internalReport.internalReportName);
      this.model.get('isActive').setValue(response.internalReport.isActive.toString());
      this.model.get('workspace').setValue(response.internalReport.workspace);
      this.model.get('width').setValue(response.internalReport.width);
      this.model.get('height').setValue(response.internalReport.height);
      this.model.get('internalReportLink').setValue(response.internalReport.internalReportLink);
      this.model.get('ordenation').setValue(response.internalReport.ordenation);
      this.model.get('pageNumber').setValue(response.internalReport.page);
      response.internalReport.listInternalReportFunctionProfile.forEach(x => {
        (this.model.controls['listInternalReportFunctionProfile'] as FormArray).push(
          this.formBuilder.group({
            idReportFunctionHealthUnit: [x.idInternalReportFunctionProfile.toString()],
            idProfile: [x.idProfile],
            function: [x.internalReportFunction],
          })
        )
      })

      this.model.get('biReport').setValue(response.internalReport.biReport);
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateInternalReport() {
    this.internalReportService.updateInternalReport(this.idInternalReport, this.internalReportRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/bi/internal-report']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createInternalReport() {
    this.internalReportService.createInternalReport(this.internalReportRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/bi/internal-report']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createInput() {
    return this.formBuilder.group({
      //idReportFunctionHealthUnit: [''],
      function: ['', [Validators.required]],
      idProfile: ['', [Validators.required]],
    })
  }

  addNext() {
    (this.model.controls['listInternalReportFunctionProfile'] as FormArray).push(this.createInput())
  }

  removeButton(index: number) {
    if(index != 0) {
      (this.model.controls['listInternalReportFunctionProfile'] as FormArray).removeAt(index)
    }
  }

  populateProfileSelect() {
    this.profileService.listAllProfile().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listProfile = response.listProfile;
      this.isLoading = false;
    },
    (error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateBiInternalReportSelect(event) {

    var workspaceId = event.toString();
    this.model.get('biReport').setValue('');
    this.powerBiService.listBiReport(workspaceId).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listBiReport = response.listBiReport;
      this.isLoading = false;
      this.model.get('biReport').enable();
      this.isFirstLoading = false;
    },
    (error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  populateWorkspaceSelect() {
    this.powerBiService.listWorkspace().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listWorkspace = response.listWorkspace;
      this.isLoading = false;
    },
    (error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  setInternalReportLink(event){
    
    let idBiReport = event.value.toString();
    let biReport = this.listBiReport.find(x => x.id == idBiReport);
    this.model.get('internalReportLink').setValue(biReport.embedUrl);
  }
}