import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.css']
})
export class GenericModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<GenericModalComponent>,
  ) { }

  public isLoading: boolean = true;
  public img: string = this.data.img ?? 'assets/images/exclamation-circle.png';
  public textButtonConfirm: string = this.data.isTwoButtonsModal ? "Confirmar" : "Ok";
  public textButtonCancel: string = this.data.isTwoButtonsModal ? "Cancelar" : "Ok";
  public title: string = this.data.title ?? "Alerta!";
  public description: string = this.data.description ?? "";

  ngOnInit(): void {
    if (this.data.textButtonConfirm)
      this.textButtonConfirm = this.data.textButtonConfirm;

    if (this.data.textButtonCancel)
      this.textButtonCancel = this.data.textButtonCancel;

    if (this.data.isTwoButtonsModal) {
      this.textButtonConfirm = this.data.textButtonConfirm ?? 'Confirmar';
      this.textButtonCancel = this.data.textButtonCancel ?? 'Cancelar';
    }

    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close();
  }

  confirm() {
    this.matDialogRef.close({ confirm: true });
  }

  cancel() {
    this.matDialogRef.close({ confirm: false });
  }
}
