<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="options-container">
            <div class="option">
                <h2 (click)="chooseExternal()">Externo</h2>
            </div>
            <div class="option">
                <h2 (click)="chooseInternal()">Interno</h2>
            </div>
        </div>
    </div>
</div>