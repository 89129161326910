import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListModuleResponse } from '../../responses/admin-user/list-module.response';

@Injectable({
  providedIn: 'root'
})
export class ListAdminModuleService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public listModule(): Observable<ListModuleResponse> {

    let uri = 'ListAdminModule'

    return this.httpClient.get<ListModuleResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
    
    