import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { AnswerModel } from '../../models/self-triage/anwser.model';
import { AnswerRequest } from '../../requests/self-triage/answer.request';
import { AnswerResponse } from '../../responses/self-triage/answer.response';


@Injectable({
  providedIn: 'root'
})
export class AnswerService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,
  ) { super(); }

  public createAnswer(answer: AnswerRequest): Observable<AnswerResponse> {
    let uri = `Answer`

    return this.httpClient.post<AnswerResponse>(environment.urlApiSelfTriage + uri, answer, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getAnswer(idAnswer: number): Observable<AnswerResponse> {
    let uri = `Answer/idAnswer/${idAnswer}`

    return this.httpClient.get<AnswerResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateAnswer(idAnswer: number, answer: AnswerRequest): Observable<AnswerResponse> {
    let uri = `Answer/idAnswer/${idAnswer}`

    return this.httpClient.put<AnswerResponse>(environment.urlApiSelfTriage + uri, answer, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteAnswer(idAnswer: number): Observable<ReturnStruct> {
    let uri = `Answer/idAnswer/${idAnswer}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}

