<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consulting/template"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Relatórios</a>
    <h1>Cadastrar Template</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Dados do Template</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="templateName">
                        <mat-error *ngIf="model.get('templateName').invalid">Informe o Nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="templateDescription">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="isActive">
                          <mat-option [value]="true">Ativo</mat-option> 
                          <mat-option [value]="false">Inativo</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-select-2 [multiple]="true" [url]="this.urlListHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" 
                        [formControlField]="'idHealthUnitAccess'" [optionAll]="true" [allOptionText]="'TODAS AS UNIDADES'">
                    </app-select-2>
                </div>
                <div class="col-12 col-sm-6 col-md-4" *ngIf="!this.isUpdate && !this.isCopy">
                    <app-select-2 [multiple]="false" [url]="this.urlListHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Importar'" 
                        [formControlField]="'idHealthUnitImport'">
                    </app-select-2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/template" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>

