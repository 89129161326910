import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ProcedureSigtapRequest } from '../../requests/billing/procedure-sigtap.request';
import { ValidateProcedureSigtapRequest } from '../../requests/billing/validate-procedure-sigtap.request';
import { ProcedureSigtapCompetenceResponse } from '../../responses/billing/procedure-sigtap-competence.response';
import { ProcedureSigtapResponse } from '../../responses/billing/procedure-sigtap.response';
import { ValidateProcedureSigtapResponse } from '../../responses/billing/validate-procedure-sigtap.response';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';
import { CompetenceSelectResponse } from '../../responses/billing/competence-select.response';
import { TriggersProcedureSigtapResponse } from '../../responses/billing/triggers-procedure-sigtap.response';
import { RelationTriggersProcedureSigtapRequest } from '../../requests/billing/relation-triggers-procedure-sigtap.request';

@Injectable({
  providedIn: 'root'
})
export class TriggerProcedureService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listTriggersSelect(): Observable<TriggersProcedureSigtapResponse>{
    let uri = `TriggerProcedureSigtap`
      
    return this.httpClient.get<TriggersProcedureSigtapResponse>(environment.urlApiBilling + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  SaveTriggers(triggers: RelationTriggersProcedureSigtapRequest): Observable<TriggersProcedureSigtapResponse>{
    let uri = `RelationTriggerProcedureSigtap`
      
    return this.httpClient.post<TriggersProcedureSigtapResponse>(environment.urlApiBilling + uri,triggers,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

}
