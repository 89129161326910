import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AnalyticsService } from 'src/app/shared/services/API/data-mesh/analytics.service';
import { Masks } from 'src/app/shared/services/mask.service';
import { DataProductModel } from 'src/app/shared/services/models/data-mesh/data-product.model';
import { ListProfileStruct } from 'src/app/shared/services/structs/admin-user/list-profile.struct';

@Component({
  selector: 'app-analytics-list',
  templateUrl: './analytics-list.component.html',
  styleUrls: ['./analytics-list.component.css']
})
export class AnalyticsListComponent {
  constructor(
    private alertService: AlertService,
    private analyticsService: AnalyticsService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.datamesh;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.datamesh_data_products;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public searchText: string;
  public listDataProduct: DataProductModel[] = [];
  public listProfile: ListProfileStruct[] = [];
  public masks: Masks;

  ngOnInit(): void {
    this.populateScreen();
    this.listDataProduct == null;
    this.isLoading = true;
    this.searchText = null;
  }

  populateScreen() {
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    this.analyticsService.listDataProductByProfile().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listDataProduct = response.dataProducts;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}

