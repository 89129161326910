import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListProjectResponse } from '../../responses/cache/list-project.response';
import { ProjectResponse } from '../../responses/cache/project.response';
import { ProjectRequest } from '../../requests/cache/project.request';
import { ListCacheResponse } from '../../responses/cache/list-cache.response';
import { CacheResponse } from '../../responses/cache/cache.response';
import { CacheRequest } from '../../requests/cache/cache.request';
import { CleanCacheRequest } from '../../requests/cache/clean-cache.request';

@Injectable({
  providedIn: 'root'
})
export class CacheService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  ListCache(): Observable<ListCacheResponse> {
    let uri = `Cache`

    return this.httpClient.get<ListCacheResponse>(environment.urlApiCache + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  GetCache(idCache: number): Observable<CacheResponse> {
    let uri = `Cache/idCache/${idCache}`

    return this.httpClient.get<CacheResponse>(environment.urlApiCache + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  CreateCache(projectRequest: CacheRequest): Observable<ReturnStruct> {
    let uri = `Cache`

    return this.httpClient.post<ReturnStruct>(environment.urlApiCache + uri, projectRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  UpdateCache(projectRequest: CacheRequest, idCache: number): Observable<ReturnStruct> {
    let uri = `Cache/idCache/${idCache}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiCache + uri, projectRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  Delete(idCache: number): Observable<ReturnStruct> {
    let uri = `Cache/idCache/${idCache}`
    return this.httpClient.delete<ReturnStruct>(environment.urlApiCache + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  CleanCache(cleanCacheRequest: CleanCacheRequest): Observable<ReturnStruct> {
    let uri = `CleanCache`

    return this.httpClient.post<ReturnStruct>(environment.urlApiCache + uri, cleanCacheRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}
