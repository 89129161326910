import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.welcome;

  constructor() { }

  ngOnInit(): void {
  }

}
 