<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Usuários</h1>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/master/user/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar usuario</a>
        <div class="filters">
            <div class="row">   
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
                <div class="col-6 col-md-2">
                    <app-filter-select [filterSelectModel]="filterSelectModelProfession" [name]="'Departamento'" (seachEvent)="search()"></app-filter-select>
                </div>
                <div class="col-6 col-md-2"> 
                      <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="isActive" (selectionChange)="search()">
                          <mat-option value="">Todos</mat-option> 
                          <mat-option value="true">Ativo</mat-option>
                          <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
        </div>
        <div class="card-list" *ngIf="listUser != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listUser">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.adminUserName}}</span>
                            <span class="secundary">{{item.departmentName}}</span>
                            <span class="secundary-status last-info">{{item.isActive? "Ativo":"Inativo"}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/master/user/register', {idAdminUser: item.idAdminUser}]">edit</mat-icon> </a>
                            <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idAdminUser)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listUser != undefined && listUser.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>