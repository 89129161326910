import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListBiReportResponse } from "../../responses/business-intelligence/list-bi-report.response";
import { ListWorkspaceResponse } from "../../responses/business-intelligence/list-workspace.response";
import { ReturnStruct } from "src/app/shared/structs/return.struct";



@Injectable({
  providedIn: 'root'
})
export class PowerBiService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listWorkspace(): Observable<ListWorkspaceResponse> {
    let uri = "PowerBi"

    return this.httpClient.get<ListWorkspaceResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listBiReport(workspace: string): Observable<ListBiReportResponse> {
    let uri = `PowerBi/workspace/${workspace}`;

    return this.httpClient.get<ListBiReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public Refresh(idReport: number, isRefreshFull: boolean = false): Observable<ReturnStruct> {
    let uri = `PowerBi/refresh/${idReport}/isRefreshFull/${isRefreshFull}`;

    return this.httpClient.get<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public InternalRefresh(idReport: number, isRefreshFull: boolean = false): Observable<ReturnStruct> {
    let uri = `PowerBi/internalRefresh/${idReport}/isRefreshFull/${isRefreshFull}`;

    return this.httpClient.get<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
