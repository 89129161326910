<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-checkbox-list">
            <h2>{{data.name}}</h2>
            <button  mat-icon-button mat-dialog-close class="close-dialog d-inline" (click)="close()">
                <mat-icon aria-label="Close" >close</mat-icon>
            </button>
         </div>
         <div class="body-checkbox-list">
             <div *ngIf="data != undefined && data != null 
             && data.filterSelectModel != undefined && data.filterSelectModel != null
             && data.filterSelectModel.itemSelectModel != undefined && data.filterSelectModel.itemSelectModel != null">
                 <div *ngFor="let item of data.filterSelectModel.itemSelectModel" class="checkbox-line">
                     <mat-checkbox class="example-margin" [checked]="item.checked" [value]="item.key" (change)="onCheckChange($event)">{{item.value}}</mat-checkbox>           
                 </div>  
             </div>  
         </div>
         <div class="footer-checkbox-list">
            <button mat-button class="btn-primary block" (click)="clickSearch()">Filtrar</button>
            <button type="button" mat-button class="btn-secundary block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>