import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { HealthUnitAdminRequest } from '../../requests/admin-user/health-unit-admin.request';
import { GetHealthUnitAdminResponse } from '../../responses/user/get-health-unit-admin.response';
import { ListHealthUnitAdminResponse } from '../../responses/user/list-health-unit-admin.response';
import { PostHealthUnitAdminResponse } from '../../responses/user/post-health-unit-admin-response';

@Injectable({
  providedIn: 'root'
})
export class PostHealthUnitAdminService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getHealthUnit(idHealthUnit: number): Observable<GetHealthUnitAdminResponse>{
    return this.httpClient.get<GetHealthUnitAdminResponse>(environment.urlApiOrchestratorUser + `HealthUnitAdmin/idHealthUnit/${idHealthUnit}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateHealthUnit(idHealthUnit: number, updateHealthUnitRequest: HealthUnitAdminRequest): Observable<ReturnStruct>{
    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorUser + `HealthUnitAdmin/idHealthUnit/${idHealthUnit}`, updateHealthUnitRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createHealthUnit(createHealthUnitRequest: HealthUnitAdminRequest): Observable<PostHealthUnitAdminResponse>{
    return this.httpClient.post<PostHealthUnitAdminResponse>(environment.urlApiOrchestratorUser + `HealthUnitAdmin`, createHealthUnitRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    