import { ThemePalette } from '@angular/material/core';
import { ModuleProfileStruct } from 'src/app/shared/services/structs/admin-user/module-profile.struct';
import { ProfileRequest } from 'src/app/shared/services/requests/admin-user/profile.request';
import { RoleStruct } from 'src/app/shared/services/structs/admin-user/role.struct';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ModuleProfileService } from 'src/app/shared/services/API/admin-user/module-profile.service';
import { ProfileService } from 'src/app/shared/services/API/admin-user/profile.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-profile-register',
  templateUrl: './profile-register.component.html',
  styleUrls: ['./profile-register.component.css']
})
export class ProfileRegisterComponent implements OnInit {

  expandedIndex: number = 0;
  menuModuleEnum:MenuModuleEnum = MenuModuleEnum.master;
  menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.master_profile;
  model: FormGroup;
  isLoading:boolean;
  isFirstLoading: boolean
  isUpdate: boolean;
  listModuleProfileStruct: Array<ModuleProfileStruct> = new Array<ModuleProfileStruct>();
  idProfile: number;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private profileService: ProfileService,
    private moduleProfileService: ModuleProfileService,
  ) {
    this.listModule();
  }

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['']
    });
            
    this.idProfile == null;
    this.isUpdate == false;
    if(this.activatedRoute.snapshot.paramMap.get('idProfile'))
    this.idProfile = parseInt(this.activatedRoute.snapshot.paramMap.get('idProfile'));
      
   
  }

  submit(): void{
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;
    const selectedRoles: Array<RoleStruct> = this.getSelectedRoles();
    const selectedRolesIds: Array<number> = selectedRoles.map((role: RoleStruct) => role.idRole);
    const profile: ProfileRequest = {
      description: this.model.value.description,
      listIdRole: selectedRolesIds,
      profileName: this.model.value.name

    }
    let profileSubmitProfile;
    if(this.isUpdate){
      profileSubmitProfile = this.profileService.editProfileRoles(this.idProfile, profile);
    } else {
      profileSubmitProfile = this.profileService.createProfileRoles(profile);
    }

    profileSubmitProfile.subscribe((response) => {
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.alertService.show('Sucesso','Perfil salvo com sucesso', AlertType.success);
      this.isLoading = false;
      this.router.navigate(['/master/profile']);
    }, (error) => {
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });

    return; 
  }

  getSelectedRoles(): Array<RoleStruct>{
    let selectedRoles: Array<RoleStruct> = new Array<RoleStruct>();
    this.listModuleProfileStruct.forEach((module) => {
      module.listFunctionality.forEach((feature) => {
        let selected = feature.listRole.filter((role) => role.selected);
        selectedRoles = selectedRoles.concat(selected);
      });
    });

    return selectedRoles;
  }

  setProfileRoles(rolesList: Array<number>){
    this.listModuleProfileStruct.forEach((module) => {
      module.listFunctionality.forEach((feature) => {
        feature.listRole.forEach((role) => {
          role.selected = rolesList.indexOf(role.idRole) > -1;
        });
      });
    });
  }

  populateProfileData(){
    this.profileService.getProfileRoles(this.idProfile).subscribe((response) => {
      this.setProfileRoles(response.listIdRole);
      this.model.setValue({
        name: response.profileName,
        description: response.description
      });

      this.isFirstLoading = false;

    }, (error) => {
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  listModule(){
    this.moduleProfileService.listModule().subscribe((response)=>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listModuleProfileStruct = response.listModuleStruct;

      if(this.idProfile != null){
        this.isFirstLoading = true;
        this.isUpdate = true;
        this.populateProfileData();
      }  
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
