<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>{{dataProduct.name}}</h1>
    <div class="white-body" *ngIf="model">
        <form class="form" [formGroup]="model" (ngSubmit)="search()">
            <div class="filters">
                <div class="row headers">
                    <div class="col-md-4">
                        <mat-form-field appearance="fill">
                            <mat-label>Selecione um intervalo</mat-label>
                            <mat-date-range-input [formGroup]="model" [rangePicker]="picker">
                                <input matInput formControlName="datetimeStart" matStartDate placeholder="Data inicial">
                                <input matInput formControlName="datetimeEnd" matEndDate placeholder="Data final">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <button [disabled]="isLoading" mat-button type="submit" class="btn-primary">
                            <span>Pesquisar</span>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button [disabled]="isLoading" mat-button type="button" (click)="extractDataProduct()"
                            class="btn-primary">
                            <span>Exportar</span>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="col_names" class="table-responsive">
                <app-loading-list *ngIf="isLoading"></app-loading-list>
                <table mat-table [dataSource]="data" class="z-depth-1 mat-elevation-z8" cdkDropListGroup>
                    <div *ngIf="!isLoading">
                        <ng-container *ngFor="let column of col_names; let i=index" matColumnDef="{{column}}">
                            <th class="header-list" mat-header-cell *matHeaderCellDef id="{{column}}" cdkDropList
                                cdkDropListOrientation="horizontal" cdkDropListLockAxis="x"
                                (cdkDropListDropped)="drop($event)">
                                <div class="header-box" cdkDrag>
                                    {{column}}
                                </div>
                            </th>
                            <td class="body" mat-cell *matCellDef="let element"> {{col_types[column] == 'datetime64[ns]'
                                ?
                                (element[column] | date:'dd/MM/yy, H:mm') : element[column]}}
                            </td>
                        </ng-container>
                    </div>
                    <tr mat-header-row *matHeaderRowDef="col_names; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: col_names;"></tr>
                </table>
            </div>
            <mat-paginator [length]="fullSize" #paginator [pageIndex]="pageIndex" [pageSize]="50"
                aria-label="Select page" (page)="search($event.pageIndex)">
            </mat-paginator>
        </form>
        <app-empty-list *ngIf="false"></app-empty-list>
    </div>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>