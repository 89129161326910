import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FlowchartService } from 'src/app/shared/services/API/self-triage/flowchart.service';
import { QuestionSelectService } from 'src/app/shared/services/API/self-triage/question-select.service';
import { QuestionService } from 'src/app/shared/services/API/self-triage/question.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { QuestionModel } from 'src/app/shared/services/models/self-triage/question.model';
import { FlowchartRequest } from 'src/app/shared/services/requests/self-triage/flowchart.request';
import { AgeStruct } from 'src/app/shared/services/structs/risk-classification/age.struct';

@Component({
  selector: 'app-self-triage-flowchart-register',
  templateUrl: './self-triage-flowchart-register.component.html',
  styleUrls: ['./self-triage-flowchart-register.component.css']
})
export class SelfTriageFlowchartRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private flowchartService: FlowchartService,
    private questionService: QuestionSelectService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_flowchart;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public questions: QuestionModel[];
  public idFlowchart: number;
  public isCopy: boolean;
  public question: number[];
  public questionsSelected: QuestionModel[];
  public flowchartRequest: FlowchartRequest;
  public selectQuestion: number[];

  ngOnInit(): void {
    this.isLoading = false;

    this.masks = this.maskService.getMasks();

    this.flowchartRequest = new FlowchartRequest();
    this.idFlowchart = null;
    this.isUpdate = false;

    if (this.activatedRoute.snapshot.paramMap.get('idFlowchart')) {
      this.idFlowchart = parseInt(this.activatedRoute.snapshot.paramMap.get('idFlowchart'));
      this.populateQuestions(this.idFlowchart);
    } else {
      this.populateQuestions(null);
    }


    this.isUpdate = this.idFlowchart ? true : false;

    this.model = this.formBuilder.group({
      flowchartName: ['', [Validators.required]],
      flowchartDescription: [''],
      flowchartMinAge: ['', [Validators.required]],
      flowchartMaxAge: ['', [Validators.required]],
      question: [''],
    });


    if (this.idFlowchart)
      this.populateFlowchartData();
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if(!this.validateAge(this.model.get('flowchartMinAge').value)){
      return;
    }

    if(!this.validateAge(this.model.get('flowchartMaxAge').value)){
      return;
    }

    this.isLoading = true;

    this.flowchartRequest.questionsSelected = this.model.get('question').value == 0 ? null : this.model.get('question').value;
    this.flowchartRequest.name = this.model.get('flowchartName').value;
    this.flowchartRequest.description = this.model.get('flowchartDescription').value;
    this.flowchartRequest.minAgeStruct = this.formatDateToSave(this.model.get('flowchartMinAge').value);
    this.flowchartRequest.maxAgeStruct = this.formatDateToSave(this.model.get('flowchartMaxAge').value);

    if (this.isUpdate)
      this.updateTemplate();
    else
      this.createTemplate();
  }

  formatDateToSave(age: string): AgeStruct {
    let ageStruct = new AgeStruct();
    if (age) {
      let date = age.split("a");
      ageStruct.years = parseInt(date[0]);
      date = date[1].split("m");
      ageStruct.months = parseInt(date[0]);
      date = date[1].split("d");
      ageStruct.days = parseInt(date[0]);
    }
    return ageStruct;
  }

  populateQuestions(idFlowchart: number) {
    this.questionService.listQuestion(idFlowchart).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.questions = response.listQuestion;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getAgeFormatted(age: AgeStruct): string {
    let years = (age.years ? age.years.toString().padStart(3, '0') : '000') + 'a';
    let months = (age.months ? age.months.toString().padStart(2, '0') : '00') + 'm';
    let days = (age.days ? age.days.toString().padStart(2, '0') : '00') + 'd';
    return years + months + days;
  }

  validateAge(value: string):boolean {
    if (value) {
      let result = this.formatDateToSave(value);
      if (result.months > 12) {
        this.alertService.show('Erro', "Quantidade máxima de meses na idade não pode ser maior que 12", AlertType.error);
        return false;
      } else if (result.days > 29) {
        this.alertService.show('Erro', "Quantidade máxima de dias na idade não pode ser maior que 29", AlertType.error);
        return false;
      }
      return true;
    }
    return;
  }

  populateFlowchartData() {
    this.flowchartService.getFlowchart(this.idFlowchart).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.model.get('question').setValue(response.questions ? response.questions.idQuestion : 0);
      this.model.get('flowchartName').setValue(response.flowchart.name);
      this.model.get('flowchartDescription').setValue(response.flowchart.description);
      this.model.get('flowchartMinAge').setValue(this.getAgeFormatted(response.flowchart.minAgeStruct));
      this.model.get('flowchartMaxAge').setValue(this.getAgeFormatted(response.flowchart.maxAgeStruct));
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateTemplate() {
    this.flowchartService.updateFlowchart(this.idFlowchart, this.flowchartRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/self-triage-flowchart']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createTemplate() {
    this.flowchartService.createFlowchart(this.flowchartRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/self-triage-flowchart']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  selectedQuestion(event: any) {
    this.selectQuestion = event.value;
  }
}