import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { InternalReportRequest } from "../../requests/business-intelligence/internal-report.request";
import { GetInternalReportResponse } from "../../responses/business-intelligence/get-internal-report.response";
import { ListInternalReportResponse } from "../../responses/business-intelligence/list-internal-report.response";

@Injectable({
  providedIn: 'root'
})
export class InternalReportService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listInternalReport(searchText: string, isActive: boolean, idProfile: number, ordenation: number): Observable<ListInternalReportResponse> {
    
    let uri = 'InternalReport?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;
    
    if(isActive !=null)
      uri = uri + `isActive=${isActive}&`  
    
    if(idProfile !=null)
      uri = uri + `idProfile=${idProfile}&`  

    if(ordenation !=null)
      uri = uri + `ordenation=${ordenation}&`  
    
    return this.httpClient.get<ListInternalReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteInternalReport(idInternalReport: number): Observable<ReturnStruct>{

    let uri = `InternalReport/idInternalReport/${idInternalReport}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getInternalReport(idInternalReport: number): Observable<GetInternalReportResponse> {
    
    let uri = `InternalReport/idInternalReport/${idInternalReport}`;  
    
    return this.httpClient.get<GetInternalReportResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createInternalReport(body: InternalReportRequest): Observable<ReturnStruct>{

    let uri = `InternalReport`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateInternalReport(idInternalReport: number, body: InternalReportRequest): Observable<ReturnStruct>{

    let uri = `InternalReport/idInternalReport/${idInternalReport}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
