<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/csat"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
        Voltar
    </a>
    <h1>Resultados das avaliações</h1>
    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
    <div class="white-body">
        <!-- <div class="title-header">
            <h1>Filtros</h1>
        </div> -->
        <form class="form" [formGroup]="model">
            <div class="row">
                <div class="col-4 col-sm-12 col-md-4">
                    <mat-form-field appearance="fill">
                        <mat-label>Selecione um intervalo</mat-label>
                        <mat-date-range-input [formGroup]="model" [rangePicker]="picker">
                            <input matInput formControlName="datetimeStart" matStartDate placeholder="Data inicial">
                            <input (dateChange)="updateDates()" matInput formControlName="datetimeEnd" matEndDate
                                placeholder="Data final">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-4 col-sm-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidades</mat-label>
                        <mat-select (blur)="resetHealthUnits()" formControlName="listIdHealthUnit" multiple
                            (selectionChange)="updateSelection()">
                            <input class="inputSearchSelect" type="text" (keydown)="$event.stopImmediatePropagation()"
                                (keyup)="onKeySearchHealthUnit($event.target.value)" placeholder="Pesquisar"
                                autocomplete="off">
                            <mat-checkbox color="primary" [checked]="allHealthUnits" (click)="selectAllHealthUnits()">
                                Selecionar todas
                            </mat-checkbox>
                            <mat-option *ngFor="let item of listHealthUnitDisplay"
                                [value]="item.idHealthUnit">{{item.healthUnitName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4 col-sm-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Módulos</mat-label>
                        <mat-select (blur)="resetModules()" formControlName="listIdModule" multiple
                            (selectionChange)="updateSelection()">
                            <input class="inputSearchSelect" type="text" (keydown)="$event.stopImmediatePropagation()"
                                (keyup)="onKeySearchModule($event.target.value)" placeholder="Pesquisar"
                                autocomplete="off">
                            <mat-checkbox color="primary" [checked]="allModules" (click)="selectAllModules()">
                                Selecionar todos
                            </mat-checkbox>
                            <mat-option *ngFor="let item of listModuleDisplay"
                                [value]="item.idModule">{{item.nameModule}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="container-pages-result">

        <div class="white-body">
            <!-- <div class="title-header">
                <h1>Resultados</h1>
            </div> -->
            <div class="choice-results">
                <button [ngClass]="{'btn-focus': showTotal}" (click)="changeGraphTotal()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">speed</mat-icon> 
                </button>
                <button [ngClass]="{'btn-focus': !showTotal}" (click)="changeGraphTotal()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">insert_chart_outlined</mat-icon> 
                </button>
            </div>
            <div class="row" *ngIf="showTotal">
                <div class="col-12">
                    <h1>CSAT</h1>
                    <div class="csat-container" *ngIf="renderGraph">
                        <div class="csat">
                            <app-gauge-chart [value]="this.csat" [colorValue]="this.colorCsat"></app-gauge-chart>
                        </div>
                    </div>
                    <div class="satisfaction" *ngIf="renderGraph">
                        <div class="legend">
                            <span>
                                Insatisfeitos: {{unsatisfiedRelative | number : '2.0-2'}}%
                            </span>
                            <span>
                                Satisfeitos: {{satisfiedRelative | number : '2.0-2'}}%
                            </span>
                        </div>
                        <div class="linear-gauge">
                            <div class="unsatisfied"></div>
                            <div class="satisfied"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showTotal">
                <div class="col-12">
                    <h1>CSAT</h1>
                    <!-- <ngx-charts-gauge [view]="view" [scheme]="colorScheme" [results]="results" [min]="0" [max]="5"
                        [legend]="legend" [smallSegments]="0" [bigSegments]="10" [legendPosition]="legendPosition"
                        [angleSpan]="180" [startAngle]="-90">
                    </ngx-charts-gauge> -->
                    <ngx-charts-bar-vertical *ngIf="renderGraph" [view]="view" [scheme]="colorSchemeBar"
                        [results]="barResults" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                        [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                        [legendTitle]="legendTitle" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
                    </ngx-charts-bar-vertical>
                    <div class="satisfaction" *ngIf="renderGraph">
                        <div class="legend">
                            <span>
                                Insatisfeitos: {{unsatisfiedRelative | number : '2.0-2'}}%
                            </span>
                            <span>
                                Satisfeitos: {{satisfiedRelative | number : '2.0-2'}}%
                            </span>
                        </div>
                        <div class="linear-gauge">
                            <div class="unsatisfied"></div>
                            <div class="satisfied"></div>
                        </div>
                    </div>
                    <!-- <ngx-charts-linear-gauge [view]="view" [scheme]="colorScheme" [value]="value"
                        [previousValue]="previousValue" [units]="units">
                    </ngx-charts-linear-gauge> -->
                </div>
            </div>
        </div>
        <div class="white-body">
            <div class="row">
                <div class="col-12 result-container">
                    <h1>Comentários</h1>
                    <div class="result" *ngFor="let item of csatResultComment">
                        <div class="score-flag" [ngStyle]="{'background-color':colorDict[item.vote]}"></div>
                        <div class="result-info" *ngIf="!isLoading">
                            <span class="health-unit-name">
                                {{item.healthUnitName}}
                            </span>
                            <span class="module-name">
                                {{item.moduleName}}
                            </span>
                            <span class="vote-description">
                                {{item.voteDescription}}
                            </span>
                            <span class="date">
                                {{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm'}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>