<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Relatório interno</h1>
    <div class="white-body">
        <div class="row">
            <div class="col-6 col-sm-3">
                <a mat-button class="btn-primary add-user" routerLink="/bi/internal-report/register"><mat-icon
                        aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Relatório interno</a>
            </div>
        </div>

        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
                <div class="col-6 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="isActive" (selectionChange)="search()">
                            <mat-option value="">Todos</mat-option>
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Perfil</mat-label>
                        <mat-select [(ngModel)]="idProfile" (selectionChange)="search()">
                            <mat-option>Todos</mat-option>
                            <mat-option *ngFor="let item of listProfile" value="{{item.idProfile}}">
                                {{item.profileName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Ordenação</mat-label>
                        <mat-select [(ngModel)]="ordenation" (selectionChange)="search()">
                            <mat-option>Todas</mat-option>
                            <mat-option *ngFor="let item of listOrdenation" value="{{item}}">
                                {{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listInternalReport != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listInternalReport">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.internalReportName}}</span>
                            <span class="secundary">
                                <span
                                    [ngClass]="{'secundary': item.powerBiReportName, 'color-red': !item.powerBiReportName}">
                                    {{item.powerBiReportName ? 'Relatório: '+item.powerBiReportName+'.pbix' : 'Relátorio
                                    pbix não encontrado'}}
                                </span>
                            </span>
                            <span class="secundary">Ordenação: {{item.ordenation}}</span>
                            <span class="secundary-status last-info">{{item.isActive? "Ativo":"Inativo"}}</span>
                        </div>
                        <div class="actions">
                            <a *ngIf="item.powerBiReportName"><mat-icon aria-hidden="false" aria-label="Schedule"
                                    (click)="scheduleModal(item.idInternalReport, item.powerBiReportName)">restart_alt</mat-icon>
                            </a>
                            <a><mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/bi/internal-report/register', {idInternalReport: item.idInternalReport}]">edit</mat-icon>
                            </a>
                            <a><mat-icon aria-hidden="false" aria-label="Editar"
                                    (click)="openModal(item.idInternalReport)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listInternalReport != undefined && listInternalReport.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>