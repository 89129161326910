import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DeviceTypeService } from 'src/app/shared/services/API/device/device-type/device-type.service';
import { DeviceVisualizationService } from 'src/app/shared/services/API/device/device/device-visualization.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceType } from 'src/app/shared/services/models/device/device-type.model';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { ListedDeviceStruct } from 'src/app/shared/services/structs/device/listed-device.struct';
import { SupportContactModel } from 'src/app/shared/services/models/admin-user/support-contact.model';
import { SupportContactTypeModel } from 'src/app/shared/services/models/admin-user/support-contact-type.model';
import { SupportContactTypeService } from 'src/app/shared/services/API/admin-user/support-contact-type.service';
import { SupportContactService } from 'src/app/shared/services/API/admin-user/support-contact.service';
import { ListSupportContactRequest } from 'src/app/shared/services/requests/admin-user/list-support-contact.request';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support-channels',
  templateUrl: './support-channels.component.html',
  styleUrls: ['./support-channels.component.css']
})
export class SupportChannelsComponent implements OnInit {

  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private healthUnitService: HealthUnitService,
    public supportContactTypeService: SupportContactTypeService,
    public supportContactService: SupportContactService
  ) { }
  public model: FormGroup;
  public masks: Masks;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public listSupportContact: SupportContactModel[] = [];
  public supportContactRequest: SupportContactModel;
  public listSupportContactType: SupportContactTypeModel[] = [];
  public isLoading: boolean;
  public listSupportContactRequest: ListSupportContactRequest;
  public isNumber: boolean;
  public isEmail: boolean;
  public isQrCode: boolean;
  public showQrCode: boolean;
  public listMasksPhone: string[];
  public environmentVariable: any;

  ngOnInit(): void {
    this.environmentVariable = environment;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      listSupportContact: this.formBuilder.array([]),
    });
    this.isNumber = true;
    this.isEmail = true;
    this.isLoading = true;
    this.isQrCode = false;
    this.showQrCode = true;
    this.supportContactRequest = new SupportContactModel;
    this.listSupportContactRequest = new ListSupportContactRequest;
    this.getSupportContactType();
    this.getSupportContact();    
  }

  addNext(){
    this.listMasksPhone.push('');
    (this.model.controls['listSupportContact'] as FormArray).push(this.createInput())
  }

  showCode(){
    this.isQrCode = true;
    this.showQrCode = false;
  }

  createInput(){
    return this.formBuilder.group({
      idSupportContact: [''],
      buttonName: ['', [Validators.required]],
      idSupportContactType: ['', [Validators.required]],
      contactValue: [{value:'', disabled: true}, [Validators.required]],
    });    
  }

  removeField(index: number) {
    if (index != 0){ 
      this.listMasksPhone.splice(index, 1);  
      (this.model.controls['listSupportContact'] as FormArray).removeAt(index)
    }
  }
  getSupportContactType(){
    this.isLoading = true;
    this.supportContactTypeService.listSupportContactType().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listSupportContactType = response.supportContactType;
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getSupportContact(){
    this.isLoading = true;
    this.supportContactService.listSupportContact().subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listSupportContact = response.supportContact        
        if(this.listSupportContact && this.listSupportContact.length > 0){
          this.listMasksPhone = new Array(this.listSupportContact.length).fill('');
          this.populateSupportContact(); 
        } else{
          this.listMasksPhone = new Array();          
          this.addNext();
        }         
        
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSupportContact()
  {    
    this.listSupportContact.forEach((x, index) => {
      if (x.idSupportContactType == 2){
        if (x.contactValue.charAt(0) == '0')
          this.listMasksPhone[index] = this.masks.phoneSac;
        else
          this.listMasksPhone[index] = this.masks.phone; 

      } else this.listMasksPhone[index] = '';  

      if (x.idSupportContactType == 3){
        (this.model.controls['listSupportContact'] as FormArray).push(
          this.formBuilder.group({
            idSupportContact: [x.idSupportContact, [Validators.required]],
            idSupportContactType: [x.idSupportContactType, [Validators.required]],
            contactValue: [x.contactValue, [Validators.required, Validators.email]],
            buttonName: [x.buttonName, [Validators.required]],
          })
        )
      } else{
        (this.model.controls['listSupportContact'] as FormArray).push(
          this.formBuilder.group({
            idSupportContact: [x.idSupportContact, [Validators.required]],
            idSupportContactType: [x.idSupportContactType, [Validators.required]],
            contactValue: [x.contactValue, [Validators.required]],
            buttonName: [x.buttonName, [Validators.required]],
          })
        )
      } 
    });
  }

  enableValue(index: number){
    (this.model.controls['listSupportContact'] as FormArray).at(index).get('contactValue').enable();
    (this.model.controls['listSupportContact'] as FormArray).at(index).get('contactValue').setValue(null);
    (this.model.controls['listSupportContact'] as FormArray).at(index).get('contactValue').updateValueAndValidity();
    if ((this.model.controls['listSupportContact'] as FormArray).at(index).get('idSupportContactType').value == 3)
      (this.model.controls['listSupportContact'] as FormArray).at(index).get('contactValue').setValidators([Validators.required, Validators.email]);
    else  
      (this.model.controls['listSupportContact'] as FormArray).at(index).get('contactValue').setValidators([Validators.required]);
    
    if ((this.model.controls['listSupportContact'] as FormArray).at(index).get('idSupportContactType').value == 2)
      this.listMasksPhone[index] = this.masks.phone;   
    else 
      this.listMasksPhone[index] = '';  
  }

  onKeyContactValue(value: string, index: number) {
    if((this.model.controls['listSupportContact'] as FormArray).at(index).value.idSupportContactType == 2){
      if (value.charAt(0) == '0')
        this.listMasksPhone[index] = this.masks.phoneSac;
      else
        this.listMasksPhone[index] = this.masks.phone;
    } else this.listMasksPhone[index] = ''; 
  }

  reloadComponent(){
    const url= this.router.url;
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
        this.router.navigate([`/${url}`]).then(()=>{
        })
    })
}

  submit(){
    this.isLoading = true;

    if (this.model.invalid){
      this.model.get('listSupportContact')['controls'].forEach(x => {
        x.get('buttonName').markAsTouched();
        x.get('idSupportContactType').markAsTouched();
        x.get('contactValue').markAsTouched();
      });

      this.alertService.show('Erro', "Todos os campos em vermelho são de preenchimento obrigatório", AlertType.error);
      this.isLoading = false;
      return;
    }

    if(this.model.get('listSupportContact').value.length > 6){
      this.alertService.show('Erro', "Só podem existir um máximo de 6 botões", AlertType.error);
      this.isLoading = false;
      return;
    }


    this.model.get('listSupportContact').value.forEach(x => {
      this.supportContactRequest.idSupportContact = (x.idSupportContact != '') ? x.idSupportContact : 0;
      this.supportContactRequest.buttonName = x.buttonName;
      this.supportContactRequest.idSupportContactType = x.idSupportContactType;
      this.supportContactRequest.contactValue = x.contactValue;

      this.listSupportContactRequest.supportContact.push(this.supportContactRequest);
      this.supportContactRequest = new SupportContactModel;
    });


    if(this.listSupportContactRequest.supportContact.length > 5){
      this.alertService.show('Erro', "Só podem existir um máximo de 6 botões", AlertType.error);
      this.isLoading = false;
      this.listSupportContactRequest = new ListSupportContactRequest;
      return;
    }
    

    this.supportContactService.createSupportContact(this.listSupportContactRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.isLoading = false;
          this.listSupportContactRequest = new ListSupportContactRequest;
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.reloadComponent();
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.listSupportContactRequest = new ListSupportContactRequest;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
