import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ProtocolModel } from '../../models/risk-classification/protocol.model';
import { CreateProtocolResponse } from '../../responses/risk-classification/create-protocol.response';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';
import { ProtocolResponse } from '../../responses/risk-classification/protocol.response';
import { ProtocolStruct } from '../../structs/risk-classification/protocol.struct';

@Injectable({
  providedIn: 'root'
})
export class ProtocolService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listProtocol(searchText: string): Observable<ListProtocolResponse> {
    let uri = `Protocol?`
    if (searchText) {
      uri = uri + `searchText=${searchText}`
    }

    return this.httpClient.get<ListProtocolResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  GetProtocol(idProtocol: number): Observable<ProtocolResponse> {
    let uri = `Protocol/idProtocol/${idProtocol}`

    return this.httpClient.get<ProtocolResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getProtocolAndFlowchasts(idProtocol: number): Observable<ProtocolResponse> {
    let uri = `Protocol/getProtocolAndFlowchasts/idProtocol/${idProtocol}`

    return this.httpClient.get<ProtocolResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  PostCompleteProtocol(body: ProtocolStruct): Observable<ReturnStruct> {
    let uri = `Protocol/completeProtocol`

    return this.httpClient.post<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  PostSimpleProtocol(body: ProtocolModel): Observable<CreateProtocolResponse> {
    let uri = `Protocol`

    return this.httpClient.post<CreateProtocolResponse>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  UpdateCompleteProtocol(idProtocol: number, body: ProtocolStruct): Observable<ReturnStruct> {
    let uri = `Protocol/idProtocol/${idProtocol}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  UpdateSimpleProtocol(body: ProtocolModel): Observable<ReturnStruct> {
    let uri = `Protocol`

    return this.httpClient.put<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  DeleteProtocol(idProtocol: number): Observable<ReturnStruct> {
    let uri = `Protocol/idProtocol/${idProtocol}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
