<div class="container-generic">

    <app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

    <div class="container-body">
        <div class="white-body">
            <h1>Gerenciamento de Grupos de procedência</h1>
            <hr />
            <button mat-flat-button color="primary" class="btn-primary add-user" [routerLink]="['/origin-group/register']">
                Cadastrar novo grupo
                <mat-icon aria-hidden="false" aria-label="Plus">add
                </mat-icon>
            </button>
            <div class="filters">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix class="my-icon">search</mat-icon>
                            <mat-label>Pesquisar</mat-label>
                            <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                        </mat-form-field>
                    </div> 
                </div>
            </div>           
            <div class="card-list" *ngIf="listGroupOriginFiltered && listGroupOriginFiltered.length > 0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listGroupOriginFiltered">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.groupOriginName}}</span>
                                <span class="secundary">{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm'}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        [routerLink]="['/origin-group/register', {idGroupOrigin: item.idGroupOrigin}]">edit</mat-icon>
                                </a>
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="openDeleteModal(item.idGroupOrigin)">delete_forever</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-loading-list *ngIf="isLoading"></app-loading-list>
            <app-empty-list
                *ngIf="(!listGroupOriginFiltered || listGroupOriginFiltered.length == 0) && !isLoading"></app-empty-list>

            <div class="spacing-top-button-back col-md-2">
                <a mat-flat-button type="button" color="accent" class="btn-secundary btn-block" (click)="clickBack()">
                    <span>
                        Voltar
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>