import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { VerifyHealthUnitHasIntegrationRequest } from '../../requests/integration-relation-config/verify-health-unit-has-integration.request';
import { AllHealthUnitRelationResponse } from '../../responses/integration-relation-config/all-health-unit-relation.response';

@Injectable({
  providedIn: 'root'
})
export class HealthUnitIntegrationRelationConfigService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  VerifyHealthUnitsHasIntegration(body: VerifyHealthUnitHasIntegrationRequest): Observable<AllHealthUnitRelationResponse> {
    let uri = `HealthUnitRelation`
    return this.httpClient.post<AllHealthUnitRelationResponse>(environment.urlApiIntegrationRelationConfig + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
