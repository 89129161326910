<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <div class="row">
        <div class="col-12 col-md-4">
            <h1>Configuração de Batchs</h1>
        </div>
    </div>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/developer/batch-config/register"><mat-icon
                aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Configuração de Batch</a>
        <div class="card-list" *ngIf="listBatch != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listBatch">
                    <div class="card-item">
                        <div class="infos">
                            <div class="primary-container">
                                <span class="primary">{{item.projectName}}</span>
                                <mat-icon *ngIf="item.subscribed" class="subscription">verified</mat-icon>
                            </div>
                            <span class="secundary-status last-info">Período de execução: {{item.timeCourseHour}}
                                {{item.timeCourseHour > 1 ? "Horas": "Hora"}}</span>
                            <span class="secundary-status last-info">Hora da última execução: {{item.datetimeLastRun |
                                date:'dd/MM/yyyy HH:mm:ss'}}</span>
                            <span class="secundary">Hora do último pedido: {{item.datetimeLastRunRequest |
                                date:'dd/MM/yyyy HH:mm:ss'}}</span>
                        </div>
                        <div class="actions">
                            <div *ngIf="item.isLoadingSubscription" class="loader"></div>
                            <a *ngIf="!item.isLoadingSubscription">
                                <mat-icon aria-hidden="false" aria-label="Iniciar"
                                    (click)="subscribe(item)">{{item.subscribed ? 'unsubscribe' : 'add'}}</mat-icon>
                            </a>
                            <a><mat-icon aria-hidden="false" aria-label="Iniciar"
                                    (click)="openRunModal(item.idBatchConfiguration)">restart_alt</mat-icon></a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Visualizar"
                                    [routerLink]="['/developer/batch-config/register', {idBatchConfiguration: item.idBatchConfiguration}]">
                                    visibility
                                </mat-icon>
                            </a>
                            <a><mat-icon aria-hidden="false" aria-label="Deletar"
                                    (click)="openModal(item.idBatchConfiguration)">delete_forever</mat-icon></a>
                        </div>
                        <app-chip-status [status]="item.type"></app-chip-status>
                    </div>
                </div>
            </div>
        </div>
        <app-empty-list *ngIf="listBatch != undefined && listBatch.length == 0"></app-empty-list>
    </div>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>