import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingByHealthUnitLookupService } from 'src/app/shared/services/API/billing-by-health-unit/billing-by-health-unit-lookup.service';
import { MonthlyProgrammedBillingService } from 'src/app/shared/services/API/orchestrator-billing-by-health-unit/monthly-programmed-billing.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { ListHealthUnitService } from 'src/app/shared/services/API/user/list-health-unit.service';
import { BillingPlan } from 'src/app/shared/services/models/billing-by-health-unit/billing-plan.model';
import { BillingStatus } from 'src/app/shared/services/models/billing-by-health-unit/billing-status.model';
import { BillingStatusRequest } from 'src/app/shared/services/requests/orchestrator-billing-by-health-unit/billing-status.request';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { BarGraphStruct } from 'src/app/shared/services/structs/orchestrator-billing-by-health-unit/bar-graph.struct';

@Component({
  selector: 'app-monthly-programmed-billing',
  templateUrl: './monthly-programmed-billing.component.html',
  styleUrls: ['./monthly-programmed-billing.component.css']
})
export class MonthlyProgrammedBillingComponent implements OnInit {
  constructor(private alertService: AlertService,
    private billingByHealthUnitLookupService: BillingByHealthUnitLookupService,
    private listhealthUnitService: ListHealthUnitService,
    private monthlyProgrammedBillingService: MonthlyProgrammedBillingService
  ) {
    Object.assign(this, { jsonDataTopUnits: this.jsonDataTopUnits })
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.billing_plan_list;

  public isLoading: boolean;
  public searchText: string;
  public planIsActive: boolean;
  public idBillingPlan: number;
  public listBillingPlan: BillingPlan[];
  public listBillingStatus: BillingStatus[];
  public listHealthUnit: ListHealthUnitStruct[];
  public listDate: Date[];
  public idHealthUnit: number;
  public searchDate: Date;
  public totalValue: string = "0,00";
  public dayPeriod: string;
  public listConsolidatedData: any[];
  public jsonDataTopUnits: BarGraphStruct[] = [];
  public jsonDataTopProducts: BarGraphStruct[] = [];
  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public showLegend: boolean = true;
  public showXAxisLabel: boolean = true;
  public showYAxisLabel: boolean = true;
  public legendTitle: string = '';
  public default: boolean = true;
  public colorScheme = {
    domain: ['#7FA6E7', '#A27DA9', '#7b32a8', '#3292a8', '#a89032', '#690735', '#071969']
  };

  ngOnInit(): void {
    this.isLoading = true;
    this.searchText = null;
    this.planIsActive = null;

    this.populateDateSelect();
    this.listLookup();
    this.populateHealthUnitSelect(null);
  }

  listLookup() {
    this.billingByHealthUnitLookupService.listLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listBillingPlan = response.listBillingPlanEvenDeleted;
        this.listBillingStatus = response.listBillingStatus;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateHealthUnitSelect(searchText: string) {
    if(searchText == "")
      searchText = null;

    this.listhealthUnitService.listHealthUnit(searchText, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listHealthUnit = response.list;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateDateSelect() {
    this.listDate = [];
    var todayDate = new Date();
    var year = todayDate.getFullYear();
    var month = todayDate.getMonth();
    for (var _i = 0; _i < 60; _i++) {
      this.listDate.push(new Date(year, month - _i));
    }
  }

  search(event: any) {

    if(event != null && event.value == this.default)
    return;
  
    if (!this.searchDate) {
      this.alertService.show('Erro', "preencha a o período para gerar a planilha", AlertType.error);
      return;
    }

    if (this.isLoading)
      return;

    this.isLoading = true;
    this.setDays(this.searchDate);

    var date = new Date(this.searchDate.getFullYear(), this.searchDate.getMonth(), 1);
    //buscar tabela de valores;
    this.monthlyProgrammedBillingService.listBillingPlan(date, this.idHealthUnit, this.idBillingPlan).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listConsolidatedData = response.filteredListConsolidatedData;

        this.totalValue = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(parseFloat(response.totalValueSum.replace('.', '').replace(',', '.')));

        this.jsonDataTopUnits = response.topHealthUnitBarGraphData;
        this.jsonDataTopProducts = response.topProductBarGraphData;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  generateExcel() {
    if (!this.searchDate) {
      this.alertService.show('Erro', "preencha a o período para gerar a planilha", AlertType.error);
      return;
    }
    if (this.isLoading)
      return;

    this.setDays(this.searchDate);

    this.isLoading = true;

    var date = new Date(this.searchDate.getFullYear(), this.searchDate.getMonth(), 1, 0, 0, 0, 0);
    this.monthlyProgrammedBillingService.postMonthlyBillingExcel(date).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.downloadFile(response.fileBase64, response.fileName, response.mimeType);
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  setDays(yearMonth: Date) {
    let month: number = new Date(yearMonth).getMonth();
    let year: number = new Date(yearMonth).getFullYear();
    let longMonth: number[] = [0, 2, 4, 6, 7, 9, 11];
    let shortMonth: number[] = [3, 5, 8, 10];

    let finalDay = 30;

    if (longMonth.indexOf(month) > -1)
      finalDay = 31;
    else if (shortMonth.indexOf(month) > -1)
      finalDay = 30;
    else
      finalDay = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0) ? 29 : 28;

    const monthString = yearMonth.toLocaleString('pt', { month: 'long' });

    this.dayPeriod = `1 de ${monthString} à ${finalDay} de ${monthString} de ${year}`;
  }

  downloadFile(file64: string, fileName: string, fileMimeType: string) {
    const blobData = this.convertBase64ToBlobData(file64, fileMimeType);
    const blob = new Blob([blobData], { type: fileMimeType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  convertBase64ToBlobData(base64Data: string, contentType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  updateMonthlyBillingStatus(id: number, event: any) {
    let billingStatusRequest: BillingStatusRequest = new BillingStatusRequest();
    billingStatusRequest.idBillingStatus = event.value;
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.monthlyProgrammedBillingService.putMonthlyBillingStatus(id, billingStatusRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onSelect(data): void {
    JSON.parse(JSON.stringify(data));
  }

  onActivate(data): void {
    JSON.parse(JSON.stringify(data));
  }

  onDeactivate(data): void {
    JSON.parse(JSON.stringify(data));
  }

  cleanFilters() {
    if (this.idHealthUnit != null
      || this.idBillingPlan != null) {
      this.idHealthUnit = null;
      this.idBillingPlan = null;
      this.search(null);
    }
  }
  
  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.populateHealthUnitSelect(event);
      }
    }, 1000);
  }
}
