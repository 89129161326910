<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/master/user">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Cadastrar usuários</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Dados pessoais</h1>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="name">
                        <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>CPF</mat-label>
                        <input [readonly]="this.isUpdate" matInput type="text" formControlName="cpf"
                            [mask]="masks.cpf">
                        <mat-error *ngIf="model.get('cpf').invalid">Informe o CPF</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone</mat-label>
                        <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Departamento</mat-label>
                        <mat-select formControlName="department" >
                            <mat-option *ngFor="let item of listDepartment" value="{{item.idDepartment}}">
                                {{item.departmentName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>            
            </div>

            <div class="title-header">
                <h1>Dados para acesso</h1>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Matrícula</mat-label>
                                <input matInput type="text" formControlName="login">
                                <mat-error *ngIf="model.get('login').invalid">Informe a matrícula</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Perfil</mat-label>
                                <mat-select formControlName="perfil" multiple>
                                    <mat-option *ngFor="let item of listProfile" value="{{item.idProfile}}">{{item.profileName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput type="email" formControlName="email">
                                <mat-error *ngIf="model.get('email').invalid">Informe a e-mail</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="status">
                                    <mat-option value="true">Ativo</mat-option>
                                    <mat-option value="false">Inativo</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Senha Temporária</mat-label>
                                <input matInput type="password" formControlName="temporaryPassword">
                                <mat-error *ngIf="model.get('temporaryPassword').invalid">Informe a Senha
                                    Temporária</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div>
                                <p [ngStyle]="{ 'color': model.get('temporaryPassword').invalid ? '#fc0303' : '#000000' }">Use entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras minúsculas, números e caracteres especiais.</p>
                            </div>
                        </div>
                    </div>              
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/master/user" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>