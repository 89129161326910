<app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>
<div class="container-body">
    <h1>Configuração De Botões</h1>
    <div class="white-body">
        <div class="filters">
            <form class="form" [formGroup]="model">
                <div class="row" formArrayName="listSupportContact"
            *ngFor="let item of model.get('listSupportContact')['controls']; let i=index">
                <div class="row" [formGroupName]="i">
                    <div class="idSupportContact">
                        <mat-form-field appearance="outline">
                            <input matInput type="number" formControlName="idSupportContact">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome Do Botão</mat-label>
                            <input matInput type="text" inputmode="text" formControlName="buttonName"
                                maxlength="40">
                            <mat-error *ngIf="item.get('buttonName').invalid">Informe o nome do botão</mat-error>    
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo</mat-label>
                            <mat-select formControlName="idSupportContactType">
                                <mat-option *ngFor="let it of listSupportContactType"
                                    [value]="it.idSupportContactType" (click)="enableValue(i)">
                                    {{it.supportContactType}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="item.get('idSupportContactType').invalid">Informe o tipo do botão</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Valor</mat-label>
                            <input matInput type="text" inputmode="text" formControlName="contactValue" [mask]="listMasksPhone[i]" (keyup)="onKeyContactValue(item.get('contactValue').value, i)">
                            <mat-error *ngIf="item.get('contactValue').invalid">Informe o valor do botão</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-3 rules-button">
                        <button class="side-button btn-primary" mat-mini-fab type="button" (click)="addNext()">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                        </button>
                        <button class="side-button btn-primary" mat-mini-fab type="button"
                            (click)="removeField(i)" *ngIf="i != 0">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            </form>
        </div>
        <div class="col-12 col-md-2 align-qr-code" *ngIf="isQrCode == true">
            <a *ngIf="environmentVariable.production" href="assets/images/support-production-qr-code.png" download>
                <img src="assets/images/support-production-qr-code.png" class="qr-img">
            </a>  
            <a *ngIf="environmentVariable.staging" href="assets/images/support-staging-qr-code.png" download>
                <img src="assets/images/support-staging-qr-code.png" class="qr-img">
            </a>  
            <a *ngIf="!environmentVariable.production && !environmentVariable.staging" href="assets/images/support-development-qr-code.png" download>
                <img src="assets/images/support-development-qr-code.png" class="qr-img">
            </a>  
        </div>
        <div class="col-12 col-md-2 align-qr-code" *ngIf="isQrCode == true">
            <a *ngIf="!environmentVariable.production && !environmentVariable.staging" href="assets/images/support-development-qr-code.png" download>
                <button mat-flat-button class="btn-primary btn-sm">
                    <span *ngIf="isLoading == false">Baixar Imagem</span>
                </button>
            </a>
            <a *ngIf="environmentVariable.staging" href="assets/images/support-staging-qr-code.png" download>
                <button mat-flat-button class="btn-primary btn-sm">
                    <span *ngIf="isLoading == false">Baixar Imagem</span>
                </button>
            </a>
            <a *ngIf="environmentVariable.production" href="assets/images/support-production-qr-code.png" download>
                <button mat-flat-button class="btn-primary btn-sm">
                    <span *ngIf="isLoading == false">Baixar Imagem</span>
                </button>
            </a>  
        </div>
        <div class="col-12 col-md-2">
            <button *ngIf="showQrCode == true" mat-flat-button class="btn-primary btn-sm" (click)="showCode()">
                <span *ngIf="isLoading == false">Gerar QRCODE</span>
            </button>
        </div>
        <div class="col-12 col-md-2">
            <button mat-flat-button type="submit" class="btn-primary btn-block" (click)="submit()">
                <span *ngIf="isLoading == false">Salvar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            </button>
        </div>
    </div>
</div>