import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetLogResponse } from '../../responses/srvlog/get-log.response';
import { GetLogsResponse } from '../../responses/srvlog/get-logs.response';
import { GetSensitiveLogDataResponse } from '../../responses/srvlog/get-sensitive-log-data.response';

@Injectable({
  providedIn: 'root'
})
export class LogVisualizerService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }


  public listLog(idLog: number, idHealthUnit: number, type: string, searchText: string, startDateRange: Date, endDateRange: Date, pageIndex: number): Observable<GetLogsResponse> {

    let uri = 'LogVisualizer?'

    if (idLog)
      uri = uri + `idLog=${idLog}`;

    if (idHealthUnit)
      uri = uri + `&idHealthUnit=${idHealthUnit}`;

    if (type)
      uri = uri + `&type=${type}`;

    if (searchText)
      uri = uri + `&searchText=${searchText}`;

    if (startDateRange)
      uri = uri + `&startDateRange=${startDateRange.toISOString()}`;

    if (endDateRange)
      uri = uri + `&endDateRange=${endDateRange.toISOString()}`;

    if (pageIndex)
      uri = uri + `&pageIndex=${pageIndex}`;

    return this.httpClient.get<GetLogsResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listLogWithListHealthUnit(idLog: number, idHealthUnit: number[], type: string, searchText: string, startDateRange: Date, endDateRange: Date, pageIndex: number): Observable<GetLogsResponse> {

    let uri = 'LogVisualizer?'

    if (idLog)
      uri = uri + `idLog=${idLog}`;

    if (type)
      uri = uri + `&type=${type}`;

    if (searchText)
      uri = uri + `&searchText=${searchText}`;

    if (startDateRange)
      uri = uri + `&startDateRange=${startDateRange.toISOString()}`;

    if (endDateRange)
      uri = uri + `&endDateRange=${endDateRange.toISOString()}`;

    if (pageIndex)
      uri = uri + `&pageIndex=${pageIndex}`;

    return this.httpClient.post<GetLogsResponse>(environment.urlApiSrvLog + uri, idHealthUnit, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getLog(idLog: number): Observable<GetLogResponse> {

    let uri = `LogVisualizer/idLog/${idLog}`

    return this.httpClient.get<GetLogResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getLogSensitiveData(idLog: number): Observable<GetSensitiveLogDataResponse> {

    let uri = `LogVisualizer/idLog/${idLog}`

    return this.httpClient.put<GetSensitiveLogDataResponse>(environment.urlApiSrvLog + uri, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
