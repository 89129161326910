import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {

  constructor(private alertService:AlertService, private formBuilder: FormBuilder) { }
  
  public model: FormGroup;
  public isLoading:boolean;
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.auth;
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
    });

    this.isLoading = false;
  }

  submit(){
    
  }
}
