import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/care-line-priority.service';

@Component({
  selector: 'app-validate-health-guideline-modal',
  templateUrl: './validate-health-guideline-modal.component.html',
  styleUrls: ['./validate-health-guideline-modal.component.css']
})
export class ValidateHealthGuidelineModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ValidateHealthGuidelineModalComponent>,
  private careLinePriorityService: CareLinePriorityService,
  private alertService: AlertService,) { }
  
  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteCareLinePriority: false}); 
  }
  
  clickConfirm(){
    this.matDialogRef.close({deleteCareLinePriority: true}); 
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteCareLinePriority: false}); 
  }

}