import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyAgeStruct } from 'src/app/shared/custom-validators/ageStruct.validator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DiscriminatorService } from 'src/app/shared/services/API/risk-classification/discriminator.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { CounselingModel } from 'src/app/shared/services/models/risk-classification/counseling.model';
import { DiscriminatorRequest } from 'src/app/shared/services/requests/risk-classification/discriminator.request';
import { AgeStruct } from 'src/app/shared/services/structs/risk-classification/age.struct';
import { DiscriminatorStruct } from 'src/app/shared/services/structs/risk-classification/discriminator.struct';

@Component({
  selector: 'app-discriminator-add-modal',
  templateUrl: './discriminator-add-modal.component.html',
  styleUrls: ['./discriminator-add-modal.component.css']
})
export class DiscriminatorAddModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<DiscriminatorAddModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private maskService: MaskService,
    private discriminatorService: DiscriminatorService
  ) { }

  public isLoading: boolean;
  public model: FormGroup;
  public masks: Masks;
  public preSelectCounseling: CounselingModel[] = [];
  private selectedCounseling: CounselingModel[] = [];

  ngOnInit(): void {
    this.isLoading = true;

    this.masks = this.maskService.getMasks();
   
    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      minAge: ['', [VerifyAgeStruct()]],
      maxAge: ['', [VerifyAgeStruct()]],
      order: [null, [Validators.required]],
      description: [''],
      idDiscriminatorType: ['', [Validators.required]],
      minValueAllowed: [''],
      maxValueAllowed: [''],
      minValue2Allowed: [''],
      maxValue2Allowed: [''],
      isRequired: [true, [Validators.required]],
    });

    this.isLoading = false;
    
    if (this.data.idDiscriminator) {
      this.getDiscriminatorDataByIdDiscriminator(this.data.idDiscriminator);
    }
  }

  getDiscriminatorDataByIdDiscriminator(idDiscriminator: number) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.discriminatorService.GetDiscriminatorByIdDiscriminator(idDiscriminator).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;

        if (response.discriminatorStruct) {
          this.populateModel(response.discriminatorStruct);
        }

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateModel(discriminator: DiscriminatorStruct) {
    this.model.get("name").setValue(discriminator.discriminatorName);

    if (!this.IsUndefined(discriminator.minAgeStruct)) {
      this.model.get("minAge").setValue(this.getAgeFormatted(discriminator.minAgeStruct));
    }

    if (!this.IsUndefined(discriminator.maxAgeStruct)) {
      this.model.get("maxAge").setValue(this.getAgeFormatted(discriminator.maxAgeStruct));
    }

    this.model.get("order").setValue(discriminator.order);
    this.model.get("description").setValue(discriminator.description);
    this.model.get("idDiscriminatorType").setValue(discriminator.idDiscriminatorType);
    this.model.get("minValueAllowed").setValue(discriminator.minValueAllowed);
    this.model.get("maxValueAllowed").setValue(discriminator.maxValueAllowed);
    this.model.get("minValue2Allowed").setValue(discriminator.minValue2Allowed);
    this.model.get("maxValue2Allowed").setValue(discriminator.maxValue2Allowed);
    this.model.get("isRequired").setValue(discriminator.isRequired);

    this.preSelectCounseling = [...discriminator.listCounseling];
    this.selectedCounseling = [...discriminator.listCounseling]
  }

  IsUndefined(age: AgeStruct): boolean {
    if (!age) {
      return true;
    }
    let yearNull: boolean = age.years === null || age.years === undefined;
    let monthNull: boolean = age.months === null || age.months === undefined;
    let dayNull: boolean = age.days === null || age.days === undefined;

    return yearNull && monthNull && dayNull
  }

  submitDiscriminator() {
    if (this.isLoading) {
      return;
    }

    let minAge = this.model.get('minAge').value;

    let isValidAge = this.validateAge(minAge, 'min');

    if (!isValidAge) {
      this.alertService.show('Erro', "Idade miníma deve ser corretamente preenchida.", AlertType.error);
      return;
    }

    let maxAge = this.model.get('maxAge').value;

    isValidAge = this.validateAge(maxAge, 'max');

    if (!isValidAge) {
      this.alertService.show('Erro', "Idade máxima deve ser corretamente preenchida.", AlertType.error);
      return;
    }

    if (this.model.invalid) {
      this.alertService.show("Erro", "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    let discriminator: DiscriminatorStruct = new DiscriminatorStruct();
    discriminator.order = this.model.get("order").value;
    discriminator.minAgeStruct = this.formatDateToSave(this.model.get("minAge").value);
    discriminator.maxAgeStruct = this.formatDateToSave(this.model.get("maxAge").value);
    discriminator.idFlowchart = this.data.idFlowchart;
    discriminator.discriminatorName = this.model.get("name").value;
    discriminator.description = this.model.get("description").value;
    discriminator.idDiscriminatorType = this.model.get("idDiscriminatorType").value;
    discriminator.minValueAllowed = this.model.get("minValueAllowed").value ? parseFloat(this.model.get("minValueAllowed").value) : null;
    discriminator.maxValueAllowed = this.model.get("maxValueAllowed").value ? parseFloat(this.model.get("maxValueAllowed").value) : null;
    discriminator.minValue2Allowed = this.model.get("minValue2Allowed").value ? parseFloat(this.model.get("minValue2Allowed").value) : null;
    discriminator.maxValue2Allowed = this.model.get("maxValue2Allowed").value ? parseFloat(this.model.get("maxValue2Allowed").value) : null;
    discriminator.listCounseling = this.selectedCounseling;
    discriminator.idCounseling = this.selectedCounseling.map(c => c.idCounseling);
    discriminator.isRequired = this.model.get("isRequired").value;
    discriminator.idPriorityColor = this.data.idPriorityColor;

    if (this.data.idDiscriminator) {
      discriminator.idDiscriminator = this.data.idDiscriminator;
      this.UpdateDiscriminator(discriminator);
    }
    else {
      this.CreateDiscriminator(discriminator);
    }
  }

  clickCancel() {
    this.matDialogRef.close(null);
  }

  validateAge(value: string, type: string = null) {
    if (!value) {
      return true;
    }

    const pattern = /^(([0-9]{3}|[0-9]{2})a(0[0-9]|1[01])m((0|1|2)[0-9]|30)d)$/;

    if (!pattern.test(value)) {

      if (type === 'min') {
        this.model.get('minAge').setErrors({ invalidAge: true });
      }

      if (type === 'max') {
        this.model.get('maxAge').setErrors({ invalidAge: true });
      }

      return false;
    }

    if (value) {
      let result = this.formatDateToSave(value);

      if (result.months > 12) {
        this.alertService.show('Erro', "Quantidade máxima de meses na idade não pode ser maior que 12", AlertType.error);
        return false;
      } 

      else if (result.days > 29) {
        this.alertService.show('Erro', "Quantidade máxima de dias na idade não pode ser maior que 29", AlertType.error);
        return false;
      }

      return true;
    }

  }

  formatDateToSave(age: string): AgeStruct {
    let ageStruct = new AgeStruct();

    if (age) {
      let date = age.split("a");
      ageStruct.years = parseInt(date[0]);
      date = date[1].split("m");
      ageStruct.months = parseInt(date[0]);
      date = date[1].split("d");
      ageStruct.days = parseInt(date[0]);
    }

    return ageStruct;
  }

  getAgeFormatted(age: AgeStruct): string {
    let years = (age.years ? age.years.toString().padStart(3, '0') : '000') + 'a';
    let months = (age.months ? age.months.toString().padStart(2, '0') : '00') + 'm';
    let days = (age.days ? age.days.toString().padStart(2, '0') : '00') + 'd';
    return years + months + days;
  }

  selectCounseling(event: any) {
    this.selectedCounseling = [...event];
  }

  CreateDiscriminator(discriminatorStruct: DiscriminatorStruct) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: DiscriminatorRequest = new DiscriminatorRequest();
    request.discriminator = discriminatorStruct;

    this.discriminatorService.CreateDiscriminator(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso!', 'Discriminador criado com sucesso', AlertType.success);

        discriminatorStruct.idDiscriminator = response.idDiscriminator;
        this.matDialogRef.close(discriminatorStruct);
      
      },

      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.matDialogRef.close();
      }
    });
  }

  UpdateDiscriminator(discriminatorStruct: DiscriminatorStruct) {
      if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    let request: DiscriminatorRequest = new DiscriminatorRequest();
    request.discriminator = discriminatorStruct;

    this.discriminatorService.UpdateDiscriminator(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.alertService.show('Sucesso!', 'Discriminador atualizado com sucesso', AlertType.success);

        this.matDialogRef.close(discriminatorStruct);
      
      },

      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}