import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SupervisionService } from 'src/app/shared/services/API/billing/supervision.service';
import { Supervision } from 'src/app/shared/services/models/billing/supervision.model';
import { SupervisionDeleteModalComponent } from './supervision-delete-modal/supervision-delete-modal.component';

@Component({
  selector: 'app-supervision-list',
  templateUrl: './supervision-list.component.html',
  styleUrls: ['./supervision-list.component.css']
})
export class SupervisionListComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    private supervisionService: SupervisionService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_supervision;

 
  public searchText: string;
  public isLoading: boolean;
  public listSupervision: Supervision[];

  ngOnInit(): void {
    this.isLoading = true;
    this.searchText = null;
    this.populateScreen();
  }

  populateScreen() {
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    if (this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.supervisionService.GetAll(this.searchText).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.alertService.show('Alerta', response.errorDescription, AlertType.warning);
        } else if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listSupervision = response.listSupervision;
        this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(idSupervision: number) {
    const dialogRef = this.dialog.open(SupervisionDeleteModalComponent, {
      data: {
        idSupervision: idSupervision
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteSupervision) {
        this.search();
      }
    });
  }
}
