export const environment = {
  production: false,
  staging: false,
  hashEmailSmsAuthorization: "960d5b108784b4f20f38d79dcacb1fda",
  googleClientId: "293710484941-muhr7gfcivijti9mausmamutuspcaoad.apps.googleusercontent.com",
  urlApiBase: "https://clusterdev.tolife.app/",
  urlApiBaseGPA: "https://gpa.clusterdev.tolife.app/",
  urlApiAuth: "https://api.clusterdev.tolife.app/tolifecloud-api-auth/",
  urlApiSrvEmail: "https://api.clusterdev.tolife.app/tolifecloud-api-srvemail/",
  urlApiAdminUser: "https://api.clusterdev.tolife.app/tolifecloud-api-admin-user/",
  urlApiUser: "https://api.clusterdev.tolife.app/tolifecloud-api-user/",
  urlOrchestratorIntegration: "https://api.clusterdev.tolife.app/integration/",
  urlApiSrvLog: "https://api.clusterdev.tolife.app/tolifecloud-api-srvlog/",
  urlApiDevice: "https://api.clusterdev.tolife.app/tolifecloud-api-device/",
  urlApiRiskClassification: "https://api.clusterdev.tolife.app/tolifecloud-api-risk-classification/",
  urlReport: "https://api.clusterdev.tolife.app/tolifecloud-api-report/",
  urlApiBilling: "https://api.clusterdev.tolife.app/tolifecloud-api-billing/",
  urlApiBusinessIntelligence: "https://api.clusterdev.tolife.app/tolifecloud-api-business-intelligence/",
  urlApiPharmacy: "https://api.clusterdev.tolife.app/tolifecloud-api-pharmacy/",
  urlApiNotification: "https://api.clusterdev.tolife.app/tolifecloud-api-notification/",
  urlApiOrchestratorUser: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-user/",
  urlApiIntegration: "https://api.clusterdev.tolife.app/tolifecloud-api-integration/",
  urlApiEmerges: "https://api.clusterdev.tolife.app/emerges-key/",
  urlApiSelfTriage: "https://api.clusterdev.tolife.app/tolifecloud-api-self-triage/",
  urlApiIntegrationRelationConfig: "https://api.clusterdev.tolife.app/tolifecloud-api-integration-relation-config/",
  urlApiEmergesKey: "https://api.clusterdev.tolife.app/tolifecloud-api-emerges-key/",
  urlApiMedicalRecord: "https://api.clusterdev.tolife.app/tolifecloud-api-medical-record/",
  urlApiIntegrationSiga: "https://api.clusterdev.tolife.app/tolifecloud-api-integration-siga/",
  urlApiBillingByHealthUnit: "https://api.clusterdev.tolife.app/tolifecloud-api-billing-by-health-unit/",
  urlApiOrchestratorBillingByHealthUnit: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-billing-by-health-unit/",
  urlApicareLinePriority: "https://api.clusterdev.tolife.app/tolifecloud-api-care-line-priority/",
  urlApiCsat: "https://api.clusterdev.tolife.app/tolifecloud-api-csat/",
  urlApiSRVMessageQueue:"https://api.clusterdev.tolife.app/tolifecloud-api-srv-message-queue/",
  urlApiOrchestratorBusinessIntelligence: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-business-intelligence/",
  urlApiCache: "https://api.clusterdev.tolife.app/tolifecloud-api-cache/",
  urlApiDataMesh: "https://api.clusterdev.tolife.app/tolifecloud-api-data-mesh/",
  urlApiOrchestratorClassification: "https://api.clusterdev.tolife.app/tolifecloud-api-orchestrator-classification/",
  urlApiFlow: "https://api.clusterdev.tolife.app/tolifecloud-api-flow/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
