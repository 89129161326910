import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MessageRequest } from '../../requests/notification/message.request';
import { GetMessageResponse } from '../../responses/notification/get-message.response';
import { MessageHistoryResponse } from '../../responses/notification/message-history.response';
import { UserSelectResponse } from '../../responses/user/user-select.response';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  getMessageByUser(): Observable<GetMessageResponse> {
    
    let uri = `Message`
    
    return this.httpClient.get<GetMessageResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  postMessage(body: MessageRequest): Observable<ReturnStruct> {
    let uri = `Message`

    return this.httpClient.post<ReturnStruct>(environment.urlApiNotification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getHistory(): Observable<MessageHistoryResponse> {
    
    let uri = `Message/getAll`
    
    return this.httpClient.get<MessageHistoryResponse>(environment.urlApiNotification + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
