import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DeviceVisualizationService } from 'src/app/shared/services/API/device/device/device-visualization.service';
import { RequestVisualizationService } from 'src/app/shared/services/API/device/device/request-visualization.service';
import { ChartDayStruct, ChartHourStruct, ChartMonthStruct } from 'src/app/shared/services/structs/device/chart-month.struct';
import { DeviceVisualizationStruct } from 'src/app/shared/services/structs/device/device-visualization.struct';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.css']
})
export class DeviceDetailsComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private deviceService: DeviceVisualizationService,
    private requestService: RequestVisualizationService,
    private activatedRoute: ActivatedRoute,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.device_list;
  public isLoading: boolean = false;
  public device: DeviceVisualizationStruct;
  public beginDate: Date;
  public endDate: Date;
  public granularityType: number;
  public idDevice: number;
  public connectionChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Conexão',
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      }
    ],
    labels: []
  };

  public resourceChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'CPU',
        backgroundColor: 'rgba(141,255,141,0.2)',
        borderColor: 'rgba(141,255,141,1)',
        pointBackgroundColor: 'rgba(141,255,141,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
      {
        data: [],
        label: 'Mem',
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      }
    ],
    labels: []
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.0
      }
    },
    scales: {
      x: {},
      'y-axis-0':
      {
        position: 'left',
      },
    },

    plugins: {
      legend: { display: true },
    }
  };

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  public lineChartType: ChartType = 'line';

  ngOnInit(): void {
    this.beginDate = new Date(Date.now());
    this.endDate = new Date(Date.now());
    this.beginDate.setDate(1);
    this.granularityType = 2;
    this.device = new DeviceVisualizationStruct();
    if (this.activatedRoute.snapshot.paramMap.get('idDevice')) {
      this.idDevice = parseInt(this.activatedRoute.snapshot.paramMap.get('idDevice'));
      this.populateDeviceDetails(parseInt(this.activatedRoute.snapshot.paramMap.get('idDevice')));
    }
  }

  dateChange() {
    this.changeGranularity(this.granularityType);
  }

  changeGranularity(event: any) {
    if (!this.beginDate || !this.endDate || !this.device)
      return;

    if (event == 1) {
      let monthList = this.changeToMonths();
      this.connectionChartData.datasets[0].data = monthList.map(l => l.averageUpTime);
      this.connectionChartData.labels = monthList.map(l => l.month + "/" + l.year);
      this.resourceChartData.datasets[0].data = monthList.map(l => l.averageCpu);
      this.resourceChartData.datasets[1].data = monthList.map(l => l.averageMemory);
      this.resourceChartData.labels = monthList.map(l => l.month + "/" + l.year);
    }
    else if (event == 2) {
      let monthList = this.changeToMonths();
      let dayList = this.changeToDays(monthList);
      this.connectionChartData.datasets[0].data = dayList.map(l => l.averageUpTime);
      this.connectionChartData.labels = dayList.map(l => l.day);
      this.resourceChartData.datasets[0].data = dayList.map(l => l.averageCpu);
      this.resourceChartData.datasets[1].data = dayList.map(l => l.averageMemory);
      this.resourceChartData.labels = dayList.map(l => l.day);
    }
    else if (event == 3) {
      let monthList = this.changeToMonths();
      let dayList = this.changeToDays(monthList);
      let hourList = this.changeToHours(dayList);
      this.connectionChartData.datasets[0].data = hourList.map(l => l.averageUpTime);
      this.connectionChartData.labels = hourList.map(l => l.hour);
      this.resourceChartData.datasets[0].data = hourList.map(l => l.averageCpu);
      this.resourceChartData.datasets[1].data = hourList.map(l => l.averageMemory);
      this.resourceChartData.labels = hourList.map(l => l.hour);
    }
    else if (event == 4) {
      this.changeToRequest()
    }

    this.charts.forEach((child) => {
      child.chart.update()
    });
  }

  changeToRequest() {
    this.requestService.listRequest(this.idDevice, this.beginDate, this.endDate).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
      }
      this.connectionChartData.datasets[0].data = response.listRequestStruct.map(l => + l.haveConnection);
      this.connectionChartData.labels = response.listRequestStruct.map(l => l.datetimeRequestGeneration);
      this.resourceChartData.datasets[0].data = response.listRequestStruct.map(l => l.cpuUsage);
      this.resourceChartData.datasets[1].data = response.listRequestStruct.map(l => l.memoryUsage);
      this.resourceChartData.labels = response.listRequestStruct.map(l => l.datetimeRequestGeneration);
      this.charts.forEach((child) => {
        child.chart.update()
      });
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
  

  changeToMonths(): ChartMonthStruct[] {
    let dataList = this.device.listDeviceRequest.filter(l => l.year >= this.beginDate.getFullYear()
      && l.year <= this.endDate.getFullYear()
      && l.month >= (this.beginDate.getMonth() + 1)
      && l.month <= (this.endDate.getMonth() + 1));
    return dataList;
  }

  changeToDays(monthList: ChartMonthStruct[]): ChartDayStruct[] {
    let dataList: ChartDayStruct[] = [];
    monthList.forEach(month => {
      let isFirstMonth: boolean = false;
      let isLastMonth: boolean = false;
      if (month.year == this.beginDate.getFullYear() && month.month == this.beginDate.getMonth() + 1)
        isFirstMonth = true;

      if (month.year == this.endDate.getFullYear() && month.month == this.endDate.getMonth() + 1)
        isLastMonth = true;

      month.days.forEach(day => {
        if (isFirstMonth && !isLastMonth && day.day >= this.beginDate.getDate()) {
          dataList.push(day)
        }
        else if (!isFirstMonth && isLastMonth && day.day <= this.endDate.getDate()) {
          dataList.push(day)
        }
        else if (isFirstMonth && isLastMonth && day.day >= this.beginDate.getDate() && day.day <= this.endDate.getDate()) {
          dataList.push(day)
        }
        else if (!isFirstMonth && !isLastMonth) {
          dataList.push(day)
        }
      });
    });

    return dataList;
  }

  changeToHours(dayList: ChartDayStruct[]): ChartHourStruct[] {
    let dataList: ChartHourStruct[] = [];
    dayList.forEach(day => {
      day.hours.forEach(day => {
        dataList.push(day)
      });
    });

    return dataList;
  }

  populateDeviceDetails(idDevice: number) {
    this.deviceService.deviceDetails(idDevice).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
      }
      this.device = response.deviceStruct;
      this.dateChange();
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  public getIsOnline(haveConnection: boolean) : string {
    return haveConnection ? "Online" : "Offline";
  }
}
