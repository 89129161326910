<form class="form" [formGroup]="model" (ngSubmit)="submit()">
    <div *ngIf="this.answerParent" class="row">
        <div class="col-12 col-sm-4 col-md-2">
            <a mat-stroked-button type="button" class="btn-primary btn-block" (click)="goToParent()">
                Voltar à pergunta anterior
            </a>
        </div>
        <div class="col-12 col-sm-4 col-md-8"></div>
        <div *ngIf="question && question.idQuestion" class="col-12 col-sm-4 col-md-2">
            <a mat-stroked-button type="button" class="btn-primary btn-block btn-delete"
                (click)="DeleteQuestion(question.idQuestion)">
                Deletar Pergunta
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Enunciado</mat-label>
                <input matInput type="text" formControlName="statement">
            </mat-form-field>
        </div>
        <div class="col-12 col-sm-6 col-md-6">
            <mat-form-field appearance="outline">
                <mat-label>Descrição</mat-label>
                <input matInput type="text" formControlName="description">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div *ngIf="!this.answerParent" class="col-4 col-sm-5 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Ordem</mat-label>
                <input matInput type="number" formControlName="order">
            </mat-form-field>
        </div>
        <div class="col-4 col-sm-5 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>Idade miníma</mat-label>
                <input (blur)="validateAge($event.target.value)" matInput [mask]="masks.age.mask" [pattern]="masks.age.pattern" type="text"
                    formControlName="minAge">
            </mat-form-field>
        </div>
        <div class="col-4 col-sm-5 col-md-3">
            <mat-form-field appearance="outline">
                <mat-label>idade máxima</mat-label>
                <input (blur)="validateAge($event.target.value)" matInput [mask]="masks.age.mask" [pattern]="masks.age.pattern" type="text"
                    formControlName="maxAge">
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="question && question.idQuestion" class="row">
        <div class="col-md-2">
            <a mat-button type="button" (click)="openAnswerModel(null)" class="btn-primary btn-block">
                Cadastrar resposta
            </a>
        </div>
    </div>
    <div class="card-list" *ngIf="answers != null">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of answers">
                <div class="card-item">
                    <div class="infos">
                        <span class="primary">{{item.answer}}</span>
                        <span *ngIf="(!item.idChildQuestion && !item.idCounseling)" class="secundary">
                            Não possui Aconselhamento ou Pergunta
                        </span>
                        <span *ngIf="item.idCounseling" class="primary">Aconselhamento:</span>
                        <span *ngIf="item.idCounseling"
                            class="secundary">{{item.counseling.selfTriageCounselingName}}</span>
                        <span *ngIf="item.idChildQuestion" class="primary">Pegunta:</span>
                        <span *ngIf="item.idChildQuestion" class="secundary">{{item.questionChild.statement}}</span>
                        <span class="secundary">Ordem: {{item.order}}</span>
                        <span class="secundary">{{item.datetimeInclusion | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                    </div>
                    <div class="actions">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="openAnswerModel(item)">
                                edit
                            </mat-icon>
                        </a>
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idAnswer)">
                                delete_forever</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <button mat-button type="submit" class="btn-primary btn-block">
                <span *ngIf="isLoading == false">Salvar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                </mat-spinner>
            </button>
        </div>
        <div class="col-md-2">
            <a mat-stroked-button type="button" routerLink="/consulting/self-triage-discriminator"
                class="btn-secundary btn-block">
                Cancelar
            </a>
        </div>
    </div>
</form>