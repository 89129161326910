<mat-form-field class="chip-list" appearance="fill">
  <mat-label>Principio Ativo</mat-label>
  <mat-chip-grid #chipGrid aria-label="Tags">
    <mat-chip-row #chip *ngFor="let tag of listTags" [selectable]="selectable" [removable]="!tag.isDeleted"
      (removed)="remove(tag)" [matTooltipDisabled]="!tag.isDeleted"
      matTooltip="Principio Ativo removido" [matTooltipPosition]="'above'">
      <span [style.text-decoration]="tag.isDeleted? 'line-through' : 'none'">
        {{tag.allergyName}}
      </span>
      <button matChipRemove *ngIf="!tag.isDeleted" class="btn-remove-chip">
        <mat-icon>cancel</mat-icon>
      </button>
      <button (click)="reintroduce(tag)" *ngIf="tag.isDeleted" class="btn-remove-chip add">
        <mat-icon [style.color]="'rgb(33,33,33, 0.5)'">add_circle</mat-icon>
      </button>
    </mat-chip-row>
    <input placeholder="Procurar Principio Ativo..." #tagInput (keyup)="onKeySearch($event)" [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags" [value]="tag">
      {{tag.allergyName}}
    </mat-option>
  </mat-autocomplete>
  <span class="error-message" *ngIf="isError">Escolha uma opção</span>
</mat-form-field>