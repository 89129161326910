import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

// To validate age struct
export function VerifyAgeStruct(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }
        
        let isValid: boolean = true;

        const regex = /^(([0-9]{3}|[0-9]{2})a(0[0-9]|1[01])m((0|1|2)[0-9])d)$/;

        if (!regex.test(value)) {
            isValid = false;
        }

        isValid = true;

        return !isValid ? { invalidAge: true } : null;
    };
}