<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-first-access-modal">
            <h2>Primeiro Acesso</h2>
        </div>
        <div class="body-first-access-modal">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">   
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Senha Temporária</mat-label>
                            <input matInput type="password" formControlName="oldPassword" required>
                            <mat-error *ngIf="model.get('oldPassword').invalid">Informe a senha temporária</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">   
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Nova senha</mat-label>
                            <input matInput type="password" formControlName="newPassword" required>
                            <mat-error *ngIf="model.get('newPassword').invalid">Informe a nova senha</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">   
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Repetir senha</mat-label>
                            <input matInput type="password" formControlName="repetedPassword" required>
                            <mat-error *ngIf="model.get('repetedPassword').invalid">Informe a nova senha</mat-error>
                        </mat-form-field>
                        <p [ngStyle]="{ 'color': model.get('newPassword').invalid ? '#fc0303' : '#000000' }">Use entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras minúsculas, números e caracteres especiais.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <a mat-stroked-button type="button" class="btn-secundary btn-block" (click)="clickCancel()">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
