import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { VerifyDate } from 'src/app/shared/custom-validators/date.validator';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingConfigService } from 'src/app/shared/services/API/billing-by-health-unit/billing-config.service';
import { BillingPlanService } from 'src/app/shared/services/API/billing-by-health-unit/billing-plan.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { BillingConfigContract } from 'src/app/shared/services/models/billing-by-health-unit/billing-config-contract.model';
import { BillingDay } from 'src/app/shared/services/models/billing-by-health-unit/billing-day.model';
import { BillingPlan } from 'src/app/shared/services/models/billing-by-health-unit/billing-plan.model';
import { BillingConfigRequest } from 'src/app/shared/services/requests/billing-by-health-unit/billing-config.request';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { BillingConfigContractDeleteModalComponent } from './billing-config-contract-delete-modal/billing-config-contract-delete-modal.component';
import { BillingInactivationModalComponent } from './billing-inactivation-modal/billing-inactivation-modal.component';
import { FilesService, SelectedFiles } from './files.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-billing-config-register',
  templateUrl: './billing-config-register.component.html',
  styleUrls: ['./billing-config-register.component.css']
})
export class BillingConfigRegisterComponent implements OnInit {

  public selectedFiles: SelectedFiles[] = [];
  public isLoadingPDF = new BehaviorSubject(false);

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private healthUnitService: HealthUnitService,
    private billingPlanService: BillingPlanService,
    public sanitizer: DomSanitizer,
    private billingConfigService: BillingConfigService,
    private maskService: MaskService,
    private files: FilesService
  ) {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.billing_config_list;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public isActive: boolean = true;
  public idBillingConfig: number;
  public listContract: BillingConfigContract[] = [];
  public file: any;
  public listHealthUnit: ListHealthUnitStruct[];
  public listBillingPlan: BillingPlan[];
  public listBillingDay: BillingDay[];
  public masks: Masks;
  public billingConfigRequest: BillingConfigRequest;
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    this.isLoading = false;
    this.idBillingConfig == null;
    this.isUpdate == false;

    this.populateHealthUnitSelect();
    this.populatePlanSelect();

    this.billingConfigRequest = new BillingConfigRequest();

    this.model = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({
        idHealthUnitAccess: [[], [Validators.required]],
      }),
      idBillingPlan: ['', [Validators.required]],
      idBillingDay: [, [Validators.required]],
      startActivation: ['', [Validators.required, VerifyDate()]],
      isActive: ['', [Validators.required]],
      endActivation: ['', [VerifyDate()]],
      multiplefile: ['']
    });


    if (this.activatedRoute.snapshot.paramMap.get('idBillingConfig')) {
      this.idBillingConfig = parseInt(this.activatedRoute.snapshot.paramMap.get('idBillingConfig'));
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.populateBillingPlanData();
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid)
      return this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);

    if (this.listContract != null)
      this.billingConfigRequest.listContract = this.listContract;
    
    this.billingConfigRequest.idBillingDay = this.model.get('idBillingDay').value;
    this.billingConfigRequest.isActive = this.model.get('isActive').value === "true" ? true : false;
    this.billingConfigRequest.isDeleted = false;
    this.billingConfigRequest.idHealthUnit = this.model.get('firstChildGroup').get('idHealthUnitAccess').value;
    this.billingConfigRequest.healthUnitName = this.listHealthUnit.find(x => x.idHealthUnit == this.model.get('firstChildGroup').get('idHealthUnitAccess').value).healthUnitName;
    this.billingConfigRequest.idBillingPlan = this.model.get('idBillingPlan').value;
    this.billingConfigRequest.startActivation = this.convertToDate(this.model.get('startActivation').value);

    let dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);
    this.billingConfigRequest.startActivation.setHours(1);
    if(!this.isUpdate && this.billingConfigRequest.startActivation < dateNow)
      return this.alertService.show('Erro', "A data de ativação não pode ser anterior a data atual", AlertType.error);

    if (this.model.get('endActivation').value)
      this.billingConfigRequest.endActivation = this.convertToDate(this.model.get('endActivation').value);

    this.isLoading = true;
    if (this.isUpdate)
      this.updateBillingConfig();
    else
      this.createBillingConfig();
  }

  populateHealthUnitSelect() {
    this.healthUnitService.listHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listHealthUnit = response.listHealthUnit;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populatePlanSelect() {
    this.billingPlanService.listBillingPlanActive(this.isActive).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listBillingPlan = response.listBillingPlan;
        this.listBillingDay = response.listBillingDay;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createBillingConfig() {
    this.billingConfigService.postBillingConfig(this.billingConfigRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/billing/config']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateBillingConfig() {
    this.billingConfigService.updateBillingConfig(this.idBillingConfig, this.billingConfigRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/billing/config']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateBillingPlanData() {
    this.billingConfigService.getBillingConfig(this.idBillingConfig).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listContract = response.listContract;
        this.model.get('idBillingDay').setValue(response.billingConfig.idBillingDay);
        this.model.get('firstChildGroup').get('idHealthUnitAccess').setValue(response.billingConfig.idHealthUnit);
        this.model.get('isActive').setValue(response.billingConfig.isActive.toString());
        this.model.get('idBillingPlan').setValue(response.billingConfig.idBillingPlan);
        this.model.get('startActivation').setValue(this.dataAtualFormatada(response.billingConfig.startActivation));

        if (response.billingConfig.endActivation != null)
          this.model.get('endActivation').setValue(this.dataAtualFormatada(response.billingConfig.endActivation));
        if(this.isUpdate){
          this.model.get('idBillingDay').disable();
          this.model.get('firstChildGroup').get('idHealthUnitAccess').disable();
          this.model.get('idBillingPlan').disable();
          this.model.get('startActivation').disable();
          this.model.get('endActivation').disable();
        }
        if(!response.billingConfig.isActive)
          this.model.get('isActive').disable();

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  convertToDate(dateString) {
    let dat;
    let newDate = dateString.replaceAll('/' || '-', '');
    let day = newDate.substring(0, 2);
    let month = newDate.substring(2, 4) - 1; // because months in JS start from 0 
    let year = newDate.substring(4, 8);
    return dat = new Date(year, month, day);
  }

  dataAtualFormatada(date: Date) {
    if (date) {
      var data = new Date(date),
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
      return diaF +  mesF + anoF;
    }
    return null;
  }

  onSelectFile(files) {
    this.isLoadingPDF.next(true);
    this.selectedFiles = [];
    this.files.toBase64(files, this.selectedFiles).subscribe((res) => {
      if (res) {
        this.isLoadingPDF.next(false);
        this.selectedFiles = res;
      }

      res.forEach(x => {
        let contract = new BillingConfigContract();
        contract.datetimeInclusion = x.file.lastModifiedDate;
        contract.billingContractReportName = x.base64.split(';base64,')[1];
        contract.reportMimeType = x.file.type;
        contract.billingContractName = x.name.substring(0, x.name.length - 4);
        if (this.idBillingConfig != null)
          contract.idBillingConfig = this.idBillingConfig;
        contract.capacity = this.formatFileSize(x.file.size)

        this.listContract.push(contract);
      })
    });
  }

  formatFileSize(size) {
    var sizes = [' Bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
    for (var i = 1; i < sizes.length; i++) {
      if (size < Math.pow(1024, i)) return (Math.round((size / Math.pow(1024, i - 1)) * 100) / 100) + sizes[i - 1];
    }
    return size.toString();
  }

  openDeleteModal(idBillingConfigContract: number) {
    if (idBillingConfigContract == null)
      return this.alertService.show('Erro', "Só da para excluir um contrato, depois de salva-lo.", AlertType.error);
    const dialogRef = this.dialog.open(BillingConfigContractDeleteModalComponent, {
      data: {
        idBillingConfigContract: idBillingConfigContract,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result && result.deleteConfig)
          this.router.navigate(['/billing/config']);
      }
    });
  }

  generatePDF(idBillingConfigContract: number) {
    if (idBillingConfigContract == null)
      return this.alertService.show('Erro', "O contrato ainda não foi salvo.", AlertType.error);

    this.billingConfigService.getBillingConfigContract(idBillingConfigContract).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.downloadFile(response.fileBase64, response.fileName, response.mimeType);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  downloadFile(file64: string, fileName: string, fileMimeType: string) {
    const blobData = this.convertBase64ToBlobData(file64, fileMimeType);
    const blob = new Blob([blobData], { type: fileMimeType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  convertBase64ToBlobData(base64Data: string, contentType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  checkChangeStatus() {
    if(this.model.get('isActive').value === "false"){
      this.openInactivateModal();
    }
  }

  openInactivateModal() {
    const dialogRef = this.dialog.open(BillingInactivationModalComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.inactivatePlan) {
        this.model.get('isActive').setValue('true');
      }
    });
  }
}