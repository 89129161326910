import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupService } from 'src/app/shared/services/API/self-triage/lookup.service';
import { QuestionService } from 'src/app/shared/services/API/self-triage/question.service';
import { SelfTriageCounselingService } from 'src/app/shared/services/API/self-triage/self-triage-counseling.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AnswerModel } from 'src/app/shared/services/models/self-triage/anwser.model';
import { ButtonTypeModel } from 'src/app/shared/services/models/self-triage/button-type.model';
import { QuestionModel } from 'src/app/shared/services/models/self-triage/question.model';
import { SelfTriageCounselingModel } from 'src/app/shared/services/models/self-triage/self-triage-counseling.model';
import { QuestionRequest } from 'src/app/shared/services/requests/self-triage/question.request';
import { AgeStruct } from 'src/app/shared/services/structs/risk-classification/age.struct';
import { AnswerStruct } from 'src/app/shared/services/structs/self-triage/answer.struct';
import { QuestionEditStruct } from 'src/app/shared/services/structs/self-triage/question-edit.struct';
import { SelfTriageDiscriminatorDeleteModalComponent } from '../../self-triage-discriminator-list/self-triage-discriminator-delete-modal/self-triage-discriminator-delete-modal.component';
import { AnswerDeleteModalComponent } from './answer-delete-modal/answer-delete-modal.component';
import { AnswerModelComponent } from './answer-modal/anwser-modal.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
    private maskService: MaskService,
    private questionService: QuestionService,
    private router: Router,
    private lookupService: LookupService,
    private selfTriageCounselingService: SelfTriageCounselingService
  ) { }

  @Input() idParent: number;
  @Input() question: QuestionEditStruct;
  @Input() answerParent: AnswerStruct;
  @Input() answerChild: AnswerStruct[] = [];
  @Output() createChildQuestion = new EventEmitter<any>();

  public model: FormGroup;
  public isLoading: boolean = false;
  public masks: Masks;
  public answers: AnswerStruct[];
  private listButtonType: ButtonTypeModel[];
  private listSelfTriageCounseling: SelfTriageCounselingModel[];

  ngOnInit(): void {
    this.getLookup();
    this.listCounseling();
    this.masks = this.maskService.getMasks();

    if (!this.answerParent) {
      this.model = this.formBuilder.group({
        statement: [this.question && this.question.statement ? this.question.statement : '', [Validators.required]],
        maxAge: [this.question && this.question.maxAgeStruct ? this.getAgeFormatted(this.question.maxAgeStruct) : ''],
        minAge: [this.question && this.question.minAgeStruct ? this.getAgeFormatted(this.question.minAgeStruct) : ''],
        description: [this.question && this.question.description ? this.question.description : ''],
        order: [this.question ? this.question.order : '', [Validators.required]],
      });
    } else {
      this.model = this.formBuilder.group({
        statement: [this.question && this.question.statement ? this.question.statement : '', [Validators.required]],
        maxAge: [this.question && this.question.maxAgeStruct ? this.getAgeFormatted(this.question.maxAgeStruct) : ''],
        minAge: [this.question && this.question.minAgeStruct ? this.getAgeFormatted(this.question.minAgeStruct) : ''],
        description: [this.question && this.question.description ? this.question.description : '']
      });
    }
    this.answers = this.answerChild != null && this.answerChild.length > 0 ? [...this.answerChild] : [];
  }

  submit() {
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    if (this.isLoading)
      return;
    this.isLoading = true;
    let questionRequest: QuestionRequest = new QuestionRequest();
    questionRequest.statement = this.model.get("statement").value;
    questionRequest.maxAgeStruct = this.formatDateToSave(this.model.get("maxAge").value);
    questionRequest.minAgeStruct = this.formatDateToSave(this.model.get("minAge").value);
    questionRequest.description = this.model.get("description").value;
    if (!this.answerParent) {
      questionRequest.order = this.model.get("order").value;
    }
    if ((this.idParent && !this.question) || (this.idParent && this.question && this.idParent != this.question.idQuestion)) {
      questionRequest.idParent = this.idParent;
    }

    if (this.question) {
      this.updateQuestion(questionRequest);
    } else {
      this.createQuestion(questionRequest);
    }
  }

  createQuestion(questionResquest: QuestionRequest) {
    if (this.answerParent) {
      questionResquest.idAnswer = this.answerParent.idAnswer;
      questionResquest.order = null;
    }
    this.questionService.createQuestion(questionResquest).subscribe((response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show('Sucesso', "Questão salva com sucesso!", AlertType.success);
      this.question = response.question;
      if(!this.question.idParent){
        this.idParent = this.question.idQuestion;
      }
    }, (error) => {
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
      return;
    });
  }

  updateQuestion(questionResquest: QuestionRequest) {
    this.questionService.updateQuestion(this.question.idQuestion, questionResquest).subscribe((response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show('Sucesso', "Questão salva com sucesso!", AlertType.success);
    }, (error) => {
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
      return;
    });
  }

  validateAge(value: string) {
    if (!value) {
      return;
    }
    
    let result = this.formatDateToSave(value);
    if (result.months > 11) {
      this.alertService.show('Erro', "Quantidade máxima de meses na idade não pode ser maior que 11", AlertType.error);
    } else if (result.days > 29) {
      this.alertService.show('Erro', "Quantidade máxima de dias na idade não pode ser maior que 29", AlertType.error);
    }
  }

  formatDateToSave(age: string): AgeStruct {
    let ageStruct = new AgeStruct();
    if (age) {
      let date = age.split("a");
      ageStruct.years = parseInt(date[0]);
      date = date[1].split("m");
      ageStruct.months = parseInt(date[0]);
      date = date[1].split("d");
      ageStruct.days = parseInt(date[0]);
    }
    return ageStruct;
  }

  getAgeFormatted(age: AgeStruct): string {
    let years = (age.years ? age.years.toString().padStart(3, '0') : '000') + 'a';
    let months = (age.months ? age.months.toString().padStart(2, '0') : '00') + 'm';
    let days = (age.days ? age.days.toString().padStart(2, '0') : '00') + 'd';
    return years + months + days;
  }

  openAnswerModel(answer: AnswerStruct) {
    const dialogRef = this.dialog.open(AnswerModelComponent, {
      disableClose: true,
      data: {
        answer,
        listButtonType: this.listButtonType,
        idParent: this.idParent,
        idParentQuestion: this.question.idQuestion,
        listSelfTriageCounseling: this.listSelfTriageCounseling,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.createQuestion) {
        this.createChildQuestion.emit(result);
      } else if (result && result.saveAnswer) {
        if (result.answer.idCounseling) {
          result.answer.counseling = this.listSelfTriageCounseling.find(c => c.idSelfTriageCounseling == result.answer.idCounseling);
        }
        this.answers[0]
        var index = this.answers.findIndex(c => c.idAnswer == result.answer.idAnswer);
        if (index >= 0) {
          this.answers.splice(index, 1);
        }
        this.answers.push(result.answer);
        let aux = [...this.answers];
        this.answers = null;
        this.answers = [...aux];
      }
    });
  }

  goToParent() {
    this.createChildQuestion.emit({ idParent: this.idParent, idAnswer: this.answerParent.idAnswer, idQuestion: this.answerParent.idParentQuestion });
  }

  DeleteQuestion(idQuestion: number) {
    const dialogRef = this.dialog.open(SelfTriageDiscriminatorDeleteModalComponent, { data: { idQuestion } });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteQuestion) {
        this.goToParent();
      }
    });
  }

  openModal(idAnswer: number) {
    const dialogRef = this.dialog.open(AnswerDeleteModalComponent, { data: { idAnswer } });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteAnswer) {
        let index = this.answers.findIndex(c => c.idAnswer == idAnswer);
        let aux = [...this.answers];
        aux.splice(index, 1)
        this.answers = null;
        this.answers = [...aux];
      }
    });
  }

  getLookup() {
    this.lookupService.getLookups().subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      this.listButtonType = response.listButtonType;
    }, (error) => {
      this.alertService.show("Erro Inesperado", error, AlertType.error);
    });
  }

  listCounseling() {

    this.selfTriageCounselingService.listCounseling(null).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listSelfTriageCounseling = response.listSelfTriageCounseling;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
