import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportService } from 'src/app/shared/services/API/business-intelligence/report.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReportPowerBiModalComponent } from '../report-power-bi-modal/report-power-bi-modal.component';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { SchedulePowerBiService } from 'src/app/shared/services/API/business-intelligence/schedule-power-bi.servce';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchedulePowerBi } from 'src/app/shared/services/models/business-intelligence/schedule-power-bi.model';
import { ListSchedulePowerBiRequest } from 'src/app/shared/services/requests/business-intelligence/list-schedule-power-bi.request';
import { PowerBiService } from 'src/app/shared/services/API/business-intelligence/power-bi.service';
import { ReportConfirmRefreshModalComponent } from '../../../../shared/components/report-confirm-refresh-modal/report-confirm-refresh-modal.component';
import { RefreshTypeEnum } from 'src/app/shared/enum/business-intelligence/refresh-type.enum';

@Component({
  selector: 'app-report-schedule-modal',
  templateUrl: './report-schedule-modal.component.html',
  styleUrls: ['./report-schedule-modal.component.css']
})
export class ReportScheduleModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportPowerBiModalComponent>,
    private reportService: ReportService,
    private alertService: AlertService,
    public maskService: MaskService,
    public formBuilder: FormBuilder,
    public schedulePowerBiService: SchedulePowerBiService,
    public powerBiService: PowerBiService
  ) { }

  public model: FormGroup;
  public isLoading: boolean;
  public reportName: string;
  public idReport: number;
  public enableNormalRefresh: boolean = false;
  public enableFullRefresh: boolean = false;
  public masks: Masks;
  public datetimeInit: Date;
  public workspaceBi: string;
  public reportBi: string;
  public isInternal: boolean = false;


  ngOnInit(): void {
    console.log(this.data);

    if (this.data.isInternal)
      this.isInternal = this.data.isInternal

    this.reportBi = this.data.reportBi;
    this.workspaceBi = this.data.workspaceBi;

    this.isLoading = false;
    this.model = this.formBuilder.group({
      datetimeInitNormal: [null, Validators.required],
      updateIntervalNormal: [null, Validators.required],
      datetimeInitFull: [null],
      updateIntervalFull: [null],
    });
    this.loadSchedule();
    this.masks = this.maskService.getMasks();
  }

  loadSchedule() {
    this.isLoading = true;
    this.schedulePowerBiService.getScheduleList(this.data.idReport, this.isInternal).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        let normalSchedule = response.listSchedulePowerBi.filter(x => x.idRefreshType == RefreshTypeEnum.normalRefresh)[0]
        let fullSchedule = response.listSchedulePowerBi.filter(x => x.idRefreshType == RefreshTypeEnum.fullRefresh)[0]

        if (normalSchedule) {
          this.enableNormalRefresh = normalSchedule.isActive;
          this.model.get('updateIntervalNormal').setValue(normalSchedule.updateInterval);
          this.model.get('datetimeInitNormal').setValue(this.dataAtualFormatada(normalSchedule.datetimeInit));
        }

        if (fullSchedule) {
          this.enableFullRefresh = fullSchedule.isActive;
          this.model.get('updateIntervalFull').setValue(fullSchedule.updateInterval);
          this.model.get('datetimeInitFull').setValue(this.dataAtualFormatada(fullSchedule.datetimeInit));
        }

        this.model
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  convertToDate(dateString) {
    dateString = dateString.replaceAll('/', '').replaceAll(' ', '').replaceAll(':', '').replaceAll(',', '')
    let day = dateString.substring(0, 2);
    let month = dateString.substring(2, 4); // because months in JS start from 0 
    let year = dateString.substring(4, 8);
    let hour = dateString.substring(8, 10);
    let minutes = dateString.substring(10, 12);
    let seconds = dateString.substring(12, 14);

    let dat;
    dat = year + "-" + month + "-" + day + "T" + hour + ":" + minutes + ":" + seconds
    return dat;
  }

  dataAtualFormatada(date: Date) {
    console.log(date)
    if (date) {
      var data = new Date(date)
      console.log(data);
      return data.toLocaleString('pt-br');
    }
    return null;
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    let request: ListSchedulePowerBiRequest = new ListSchedulePowerBiRequest();

    let itemNormal: SchedulePowerBi = new SchedulePowerBi();
    let itemFull: SchedulePowerBi = new SchedulePowerBi();

    itemNormal.biReport = this.reportBi;
    itemNormal.workspace = this.workspaceBi;
    itemNormal.idRefreshType = RefreshTypeEnum.normalRefresh;
    itemNormal.status = "";
    itemNormal.lastUpdate = this.convertToDate(this.model.get('datetimeInitNormal').value);
    itemNormal.isActive = this.enableNormalRefresh;
    itemNormal.isDeleted = false;
    itemNormal.datetimeInit = this.convertToDate(this.model.get('datetimeInitNormal').value);
    itemNormal.datetimeInclusion = this.convertToDate(this.model.get('datetimeInitNormal').value);
    itemNormal.updateInterval = this.model.get('updateIntervalNormal').value;
    request.listSchedulePowerBi.push(itemNormal);

    if (this.model.get('datetimeInitFull').value && this.model.get('updateIntervalFull').value) {
      itemFull.biReport = this.reportBi;
      itemFull.workspace = this.workspaceBi;
      itemFull.idRefreshType = RefreshTypeEnum.fullRefresh;
      itemFull.status = "";
      itemFull.lastUpdate = this.convertToDate(this.model.get('datetimeInitFull').value);
      itemFull.isActive = this.enableFullRefresh;
      itemFull.isDeleted = false;
      itemFull.datetimeInit = this.convertToDate(this.model.get('datetimeInitFull').value);
      itemFull.datetimeInclusion = this.convertToDate(this.model.get('datetimeInitFull').value);
      itemFull.updateInterval = this.model.get('updateIntervalFull').value;
      request.listSchedulePowerBi.push(itemFull);
    }

    this.schedulePowerBiService.updateScheduleList(request).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    this.clickCancel();
  }

  clickCancel() {
    this.matDialogRef.close();
  }

  refreshReport() {
    if (this.isInternal) {
      this.powerBiService.InternalRefresh(this.data.idReport).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          this.alertService.show('Sucesso', "Refresh iniciado", AlertType.success);
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    else {
      this.powerBiService.Refresh(this.data.idReport).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          this.alertService.show('Sucesso', "Refresh iniciado", AlertType.success);
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
    this.clickCancel();
  }


  changeNormalRefresh() {
    this.enableNormalRefresh = !this.enableNormalRefresh;
  }

  changeFullRefresh() {
    this.enableFullRefresh = !this.enableFullRefresh;
  }

  openModalConfirmRefresh() {
    const dialogRef = this.dialog.open(ReportConfirmRefreshModalComponent, {
      data: {
        idReport: this.data.idReport,
        isInternal: this.isInternal,
      },
    });
    this.clickCancel();
  }

}
