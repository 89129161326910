import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListProjectResponse } from '../../responses/cache/list-project.response';
import { ProjectResponse } from '../../responses/cache/project.response';
import { ProjectRequest } from '../../requests/cache/project.request';

@Injectable({
  providedIn: 'root'
})
export class ProjectCacheService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  ListProject(): Observable<ListProjectResponse> {
    let uri = `Project`

    return this.httpClient.get<ListProjectResponse>(environment.urlApiCache + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  GetProject(idProject: number): Observable<ProjectResponse> {
    let uri = `Project/idProject/${idProject}`

    return this.httpClient.get<ProjectResponse>(environment.urlApiCache + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  CreateProject(projectRequest: ProjectRequest): Observable<ReturnStruct> {
    let uri = `Project`

    return this.httpClient.post<ReturnStruct>(environment.urlApiCache + uri, projectRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  UpdateProject(projectRequest: ProjectRequest, idProject: number): Observable<ReturnStruct> {
    let uri = `Project/idProject/${idProject}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiCache + uri, projectRequest, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  Delete(idProject: number): Observable<ReturnStruct> {
    let uri = `Project/idProject/${idProject}`
    return this.httpClient.delete<ReturnStruct>(environment.urlApiCache + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
