<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Templates</h1>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/consulting/template/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Template</a>
        <div class="filters">
            <div class="row">   
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
                <div class="col-6 col-md-2"> 
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="isActive" (selectionChange)="search()">
                            <mat-option [value]="">Todos</mat-option> 
                            <mat-option [value]="true">Ativo</mat-option>
                            <mat-option [value]="false">Inativo</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            
        </div>
        <div class="card-list" *ngIf="listTemplate != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listTemplate">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.templateName}}</span>
                            <span class="secundary last-info">{{item.isActive ? "Ativo" : "Inativo"}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/consulting/template/register', {idTemplate: item.idTemplate}]">edit</mat-icon> </a>
                            <a><mat-icon aria-hidden="false" aria-label="Copiar" [routerLink] = "['/consulting/template/register', {idTemplate: item.idTemplate, isCopy: true}]">content_copy</mat-icon> </a>
                            <a><mat-icon aria-hidden="false" aria-label="Excluir" (click)="openModal(item.idTemplate)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listTemplate != undefined && listTemplate.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>