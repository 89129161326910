import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AlertType } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { UserLoginResponse } from '../services/responses/admin-user/auth.response';
import { FunctionalityStruct } from '../services/structs/admin-user/funcionality.struct';
import { FunctionalityGroupStruct } from '../services/structs/admin-user/funcionalityGroup.struct';
import { ModuleStruct } from '../services/structs/admin-user/module.struct';
import { UtilService } from '../services/util.service';
import { MenuFunctionalityEnum } from './menu.functionality.enum';
import { MenuModuleEnum } from './menu.module.enum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private authService:AuthService, private alterService:AlertService, private router: Router,private utilService:UtilService) { }
  isAuthenticated:boolean;
  isVisibleUserInfo:boolean;
  isVisibleModule:boolean;
  isVisibleSubMenu:boolean;
  listFunctionalityStruct:FunctionalityStruct[];
  listFunctionalityStructPlus:FunctionalityStruct[];
  selectedSubMenu:Array<any>;
  uriModule:string;
  initials:string;
  isMenuMobileOpen:boolean;
  isMasterModule: boolean;
  selectedModuleName: string;
  onlineOffline: boolean = navigator.onLine;
  openedMainMenu: string;
  systemMainMenu: Array<MenuStructView> = new Array<MenuStructView>();
  uriModuleSelectd:string;

  @Input() public isRenderMenu:boolean;
  @Input() public menuModuleEnum:MenuModuleEnum;
  @Input() public menuFunctionalityEnum:MenuFunctionalityEnum;

  //Secundary Header Params
  @Input() public episode:number;
  @Input() public hasSecundaryHeader:true;
  @Input() discriminators:any[];
  @Input() hasCachedModule: boolean = false;
  @Input() cachedModuleName: string = '';
  @Input() painColorCode: string = '';
  @Input() gravityColorCode: string = '';
  @Input() pain: string = '';
  @Input() gravity: string = '';
  @Input() isClassification: boolean = false;

  ngDoCheck(): void {
    setTimeout(() => {
      if(this.onlineOffline !== navigator.onLine){
        this.onlineOffline = navigator.onLine;
      }
    });
  }

  ngOnInit(): void {
    if(this.menuModuleEnum == undefined || this.menuModuleEnum == null){
      this.alterService.show('Erro no menu', 'Informe o id do modulo do usuário logado para o menu', AlertType.error);
      return;
    }

    this.populateMenu();
    
    this.getSelectedMenu();
  }

  private setHeaderInfo(userInfo: UserLoginResponse): void{
    const module: ModuleStruct = userInfo.listModule.find(c=> c.idModule == this.menuModuleEnum.valueOf());
    this.isMasterModule = module.idModule === 1;
    this.selectedModuleName = module.nameModule;
    this.uriModuleSelectd = module.uri;
  }

  private populateMenu(): void{
    this.isAuthenticated = this.authService.isAuthenticated();
    this.listFunctionalityStruct = null;
    this.uriModule = "/";
    this.isMenuMobileOpen = false;
    this.listFunctionalityStructPlus = null;

    //Se o usuario estiver desligado, não pode renderizar o menu
    if(this.isAuthenticated == false)
      this.isRenderMenu = false;

    //inicializa variaveis default
    this.isVisibleUserInfo = false;
    this.isVisibleModule = false;

    //Se o usuario estiver logado
    if(this.isAuthenticated){
      let userLoginResponse:UserLoginResponse = this.authService.getTokenMenu();
      this.initials = this.utilService.creatInitials(userLoginResponse.userName);

      //Ontem as funcionalidades do menu
      if(this.isRenderMenu != false){

        this.uriModule = "/welcome";

        let moduleSelected = userLoginResponse.listModule.find(c=> c.idModule == this.menuModuleEnum.valueOf());

        if(moduleSelected == undefined)
          return;

        let listFunctionalityStructAll = moduleSelected.listFunctionality;
        let listFunctionalityGroupStructAll = moduleSelected.listFunctionalityGroup;
        
        this.setSubMenu(listFunctionalityStructAll, listFunctionalityGroupStructAll);

        this.setHeaderInfo(userLoginResponse);
      }
    }
  }

  setSubMenu(listFunctionality: Array<FunctionalityStruct>, listFunctionalityGroup: Array<FunctionalityGroupStruct>){
    let mainMenu: Array<MenuStructView> = new Array<MenuStructView>();
    listFunctionality.forEach((functionality: FunctionalityStruct) => {
      if(functionality.idFunctionalityGroup){
        if(mainMenu.filter((menu:MenuStructView) => menu.idFunctionalityGroup === functionality.idFunctionalityGroup).length === 0){
          let group: FunctionalityGroupStruct = listFunctionalityGroup.find((group: FunctionalityGroupStruct) => group.idFunctionalityGroup === functionality.idFunctionalityGroup);
          mainMenu.push({
            name: group.functionalityGroupName,
            iconClass: group.iconClass,
            idFunctionalityGroup: group.idFunctionalityGroup,
            active: false,
            id: group.idFunctionalityGroup + '_',
            subMenu: [
              {
                name: functionality.functionalityName,
                idFunctionalityGroup: functionality.idFunctionalityGroup,
                id: group.idFunctionalityGroup + '_' + functionality.idFunctionality,
                uri: functionality.uri
              }
            ]
          });
        } else {
          mainMenu.forEach((menu: MenuStructView) => {
            if(menu.idFunctionalityGroup === functionality.idFunctionalityGroup){
              menu.subMenu.push({
                name: functionality.functionalityName,
                idFunctionalityGroup: functionality.idFunctionalityGroup,
                id: functionality.idFunctionalityGroup + '_' + functionality.idFunctionality,
                uri: functionality.uri
              });
            }
          });
        }
      } else {
        mainMenu.push({
          name: functionality.functionalityName,
          iconClass: functionality.iconClass,
          uri: functionality.uri,
          id: '_' + functionality.idFunctionality,
          active: false
        });
      }
    });

    this.systemMainMenu = mainMenu;
  }

  closeUserInfo(){
    this.isVisibleUserInfo = false;
  }

  openUserInfo(){
    this.isVisibleUserInfo = true;
  }

  openModuleInfo(){
    this.isVisibleModule = true;
  }

  closeModuleInfo(){
    this.isVisibleModule = false;
  }

  closeSubMenu(){
    this.isVisibleSubMenu = false;
  }

  redirect(uri){
    this.router.navigate([uri]);
  }

  selectItem(item: MenuStructView){
    
    this.setActiveItem(item);
    item.active = true;
    sessionStorage.setItem('selectedMenu', JSON.stringify(item));

    if(!item.subMenu){
      this.redirect(item.uri);
      return;
    }

    //if(item.active) console.log(item);

    this.selectedSubMenu = item.subMenu;
    this.isVisibleSubMenu = !this.isVisibleSubMenu;
    this.openedMainMenu = item.name;
  }

  getSelectedMenu(){
    let selectedMenu: MenuStructView = sessionStorage.getItem('selectedMenu') ? JSON.parse(sessionStorage.getItem('selectedMenu')) : {};
    this.setActiveItem(selectedMenu);
  }

  setActiveItem(item: MenuStructView){
    this.systemMainMenu.forEach((menu: MenuStructView) => {
      menu.active = false;
      
      if(!item) return;

      menu.active = item.id === menu.id;
    });
  }

  getActiveItem(item: MenuStructView){
    if(item.active) console.log(item);
    return item.active;
  }

  closeMenuMobile(){
    this.isMenuMobileOpen = false;
  }

  openMenuMobile(){
    this.isMenuMobileOpen = true;
  }

  toggleMenuMobile(){
    this.isMenuMobileOpen = !this.isMenuMobileOpen;
  }
}

class MenuStructView{
  id: string;
  name: string;
  iconClass?: string;
  idFunctionalityGroup?: number;
  uri?: string;
  subMenu?: Array<MenuStructView>;
  active?: boolean;
}