<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/billing/plan-list"><mat-icon aria-hidden="false"
            aria-label="Plus">arrow_back</mat-icon> Planos</a>
    <h1>Dados do Plano</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="planName">
                        <mat-error *ngIf="model.get('planName').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="planDescription">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Módulos</mat-label>
                        <mat-select formControlName="listIdModule" multiple>
                            <mat-option *ngFor="let item of listModule" [value]="item.idModule"
                                (click)="setRequiredQuantityField(item.idModule)">
                                {{item.nameModule}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('listIdModule').invalid">Selecione ao menos um módulo</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="planIsActive">
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('planIsActive').invalid">Informe o Status</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Qtde. atendimento presencial</mat-label>
                        <input matInput type="number" formControlName="quantityPresentialAttendance">
                        <mat-error *ngIf="model.get('quantityPresentialAttendance').invalid">Qtde. atendimentos
                            presenciais é obrigatório para preenchimento.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Qtde. atendimento remoto</mat-label>
                        <input matInput type="number" formControlName="quantityRemoteAttendance">
                        <mat-error *ngIf="model.get('quantityRemoteAttendance').invalid">Qtde. atendimentos remotos é
                            obrigatório para preenchimento.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Produtos vinculados</mat-label>
                        <mat-select formControlName="listIdLinkedProduct" multiple (selectionChange)="verifyProduct()">
                            <mat-option *ngFor="let item of listLinkedProduct" [value]="item.idLinkedProduct">
                                {{item.linkedProductName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('listIdLinkedProduct').invalid">Selecione ao menos um
                            produto</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Valor mensal do plano</mat-label>
                        <input matInput type="number" formControlName="monthlyPlanFee" class="example-right-align"
                            placeholder="0,00">
                        <span matTextPrefix>R$&nbsp;</span>
                        <mat-error *ngIf="model.get('monthlyPlanFee').invalid">Informe o valor</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Valor por atendimento extra</mat-label>
                        <input matInput type="number" formControlName="valueExtraAttendance" class="example-right-align"
                            placeholder="0,00">
                        <span matTextPrefix>R$&nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3" *ngIf="showQuantity">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Quantidade de equipamentos</mat-label>
                        <input matInput type="number" formControlName="equipmentQuantity">
                        <mat-error *ngIf="model.get('equipmentQuantity').invalid">Informe a quantidade de
                            Trius</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/billing/plan-list" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>