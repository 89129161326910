<mat-form-field appearance="outline">
    <mat-label>{{labelField}}</mat-label>
    <mat-select (selectionChange)="onChangeSelect($event)" [multiple]="multiple" [value]="selected" (click)="click()">
        <input class="inputSearchSelect" type="text" [(ngModel)]="searchText"
            (keyup)="onKey($event.target.value)" placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
        <mat-checkbox *ngIf="optionAll == true && multiple == true" (click)="selectAllOptions()" [checked]="this.isAllOptions">
                {{allOptionText}}
        </mat-checkbox>
        <mat-option *ngIf="!multiple" [value]="default">SELECIONE</mat-option>
        <mat-option [disabled]="preSelectedBlock && preSelectedlist[i] && item[idField] == preSelectedlist[i][idField]" *ngFor="let item of selectedList; let i = index" [value]="item[idField]">{{item[nameField]}}</mat-option>
    </mat-select>
</mat-form-field>