import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {

  constructor() { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.master;

  ngOnInit(): void {
  }

}
