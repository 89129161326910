<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Gerenciamento de Planos</h1>
    <div class="white-body">
        <div class="col-6 col-sm-3">
            <a mat-button class="btn-primary add-user" routerLink="/billing/plan-register"><mat-icon aria-hidden="false"
                    aria-label="Plus">add</mat-icon> Cadastrar novo plano</a>
        </div>
        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
            </div> 
        </div>
        <div class="card-list" *ngIf="listBillingPlan != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listBillingPlan">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.planName}}</span>
                            <span class="secundary">{{item.quantityPresentialAttendance + ' atendimentos presenciais'}}</span>
                            <span *ngIf="item.quantityRemoteAttendance != null" class="secundary">{{item.quantityRemoteAttendance + ' atendimentos remotos'}}</span>
                            <span class="secundary">{{'Valor: R$ ' + item.monthlyPlanFee}}</span>
                            <span class="secundary-status last-info">{{item.planIsActive? "Ativo":"Inativo"}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/billing/plan-register', {idBillingPlan: item.idBillingPlan}]">
                                    edit</mat-icon>
                            </a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Excluir"
                                    (click)="openDeleteModal(item.idBillingPlan)">delete_forever</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-empty-list *ngIf="listBillingPlan != undefined && listBillingPlan.length == 0"></app-empty-list>
        <app-loading-list *ngIf="isLoading"></app-loading-list>
    </div>
</div>