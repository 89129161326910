<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Lista de compensação dos procedimentos</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/consulting/billing-sigtap/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Procedimentos</a>
        <div class="filters">
            <div class="row">   
                <div class="col-12 col-sm-4 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Data inicial</mat-label>
                        <input matInput type="text" [mask]="masks.monthYear" id="initial" name="initial" [(ngModel)]="initial">
                    </mat-form-field>
                </div> 
                <div class="col-12 col-sm-4 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Data Final</mat-label>
                        <input matInput type="text" [mask]="masks.monthYear" id="final" name="final" [(ngModel)]="final">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-4 col-md-2">
                    <a mat-button class="btn-primary" (click)="populateProcedures()">
                        <mat-icon matPrefix class="my-icon" >search</mat-icon>
                        Pesquisar
                    </a>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listBillingSigtap != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listBillingSigtap">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.dateCompetence | date: 'MM/yyyy'}} - {{item.adminUserName}}</span>
                            <span class="secundary">{{item.observation}}</span>
                            <span class="secundary">{{item.datetimeInclusion | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/consulting/billing-sigtap/register', {idProcedureSigtapCompetence: item.idProcedureSigtapCompetence}]">edit</mat-icon> </a>
                            <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idProcedureSigtapCompetence)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listBillingSigtap != undefined && listBillingSigtap.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>