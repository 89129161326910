import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { OriginAndArrivalTypeRequest } from "../../requests/medical-record/origin-and-arrival-type.request";
import { GetAllOriginAndArrivalTypeResponse } from "../../responses/medical-record/get-all-origin-and-arrival-type.response";

@Injectable({
    providedIn: 'root'
  })

export class OriginAndArrivalTypeService extends CommonService {
  
    constructor(private router: Router,
        private httpClient: HttpClient,) {
        super();
    }

    public createUpdateOriginAndArrivalType(body: OriginAndArrivalTypeRequest): Observable<ReturnStruct>{
        return this.httpClient.put<ReturnStruct>(environment.urlApiMedicalRecord + `OriginAndArrivalType`, body, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
      }
  
    public getOriginAndArrival(): Observable<GetAllOriginAndArrivalTypeResponse>{
        let uri = `OriginAndArrivalType/getAll`
    
        return this.httpClient.get<GetAllOriginAndArrivalTypeResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
    }
}