import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CommonService } from "../../../common-service";
import { ListRequestResponse } from "../../../responses/device/list-request.response";


@Injectable({
  providedIn: 'root'
})
export class RequestVisualizationService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listRequest(idDevice:number, beginDate: Date, endDate: Date): Observable<ListRequestResponse> {
    let uri = `RequestVisualization/idDevice/${idDevice}?`;

    if (beginDate)
      uri = uri + `&beginDate=${beginDate.toISOString()}`;

    if (endDate)
      uri = uri + `&endDate=${endDate.toISOString()}`;

    return this.httpClient.get<ListRequestResponse>(environment.urlApiDevice + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }
}
