<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-user-modal-list">
            <h2>Deseja excluir a linha de cuidado?</h2>
         </div>
         
         <div class="body-user-modal-list">
             Essa linha de cuidado não pode ser excluída pois está sendo utilizada em protocolos de saúde! 
             
             <div class="protocol-div" *ngFor="let item of data.listHealthGuidelineTriageStruct">
                <p><b>Protocolo: </b>{{item.healthGuidelineName}}</p>
                <p><b>Categorização: </b>{{item.categorizationName}}</p>
             </div>
             Será necessário desalocar essa linha de cuidado dos protocolos e esse procedimento é irreversível. Deseja prosseguir com o processo?
         </div>
         <div class="footer-user-modal-list">
            <button mat-button class="btn-primary block" (click)="clickConfirm()">
                <span *ngIf="isLoading == false">Sim</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-button class="btn-secundary block"(click)="clickCancel()">Não</button>
        </div>
    </div>
</div>
