import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListTokenAuthAdminResponse } from '../../responses/user/list-token-auth-admin.response';
import { TokenAuthAdminResponse } from '../../responses/user/token-auth-admin.response';
import { TokenAuthAdminModel } from '../../models/user/token-auth-admin.model';
import { ListUserResponse } from '../../responses/user/list-user.response';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public getUserWithAccessInMasterModule(idHealthUnit:number): Observable<ListUserResponse> {
    
    let uri = 'User'  
    
    uri = uri+ `/idHealthUnit/${idHealthUnit}`;
    
    return this.httpClient.get<ListUserResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    