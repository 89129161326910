import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataMeshComponent } from './pages/data-mesh/data-mesh.component';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { DataProductListComponent } from './pages/data-product-list/data-product-list.component';
import { DataProductRegisterComponent } from './pages/data-product-register/data-product-register.component';
import { AnalyticsListComponent } from './pages/analytics-list/analytics-list.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DataSetListComponent } from './pages/data-set-list/data-set-list.component';
import { DataSetRegisterComponent } from './pages/data-set-register/data-set-register.component';

const routes: Routes = [
  { path: '', component: DataMeshComponent, canActivate: [AuthGuard] },
  { path: 'analytics', component: AnalyticsListComponent, canActivate: [AuthGuard] },
  { path: 'analytics/read', component: AnalyticsComponent, canActivate: [AuthGuard] },
  { path: 'data-products', component: DataProductListComponent, canActivate: [AuthGuard] },
  { path: 'data-product/register', component: DataProductRegisterComponent, canActivate: [AuthGuard] },
  { path: 'data-sets', component: DataSetListComponent, canActivate: [AuthGuard] },
  { path: 'data-set/register', component: DataSetRegisterComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataMeshRoutingModule { }
