import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';

@Component({
  selector: 'app-business-intelligence',
  templateUrl: './business-intelligence.component.html',
  styleUrls: ['./business-intelligence.component.css']
})
export class BusinessIntelligenceComponent implements OnInit {

  constructor() { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.business_intelligence;

  ngOnInit(): void {
  }

}
