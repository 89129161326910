<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Cadastrar Origem de Dados</h1>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="form-container col-12 col-md-8 col-sm-12">
                    <div class="title-header">
                        <h1>Dados do produto</h1>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Nome da origem </mat-label>
                                        <input [disabled]="isLoading || readOnly" matInput type="text"
                                            formControlName="dataSetName">
                                        <mat-error *ngIf="model.controls.dataSetName.errors?.hasPunctuation">
                                            Nome inválido <mat-icon
                                                [matTooltip]="'Não pode haver caracteres especiais nem espaços em branco'">help</mat-icon>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Banco de dados</mat-label>
                                        <mat-select [disabled]="isLoading || readOnly"
                                            (selectionChange)="getSchema($event.value)" formControlName="database">
                                            <mat-option *ngFor="let item of listDataBase" [value]="item">
                                                {{item}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <mat-form-field class="text-area" appearnace="outline">
                                    <app-loading-list *ngIf="isLoading"></app-loading-list>
                                    <mat-label>Query</mat-label>
                                    <textarea [disabled]="isLoading || readOnly" (input)="updateQuery()" matInput
                                        type="text" formControlName="query"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-4 col-sm-4">
                                    <a mat-stroked-button type="button" routerLink="/datamesh/data-sets"
                                        class="btn-secundary btn-block button">
                                        Cancelar
                                    </a>
                                </div>
                                <div class="col-12 col-md-4 col-sm-4">
                                    <button [disabled]="!model.value.query || isLoading" mat-button type="button"
                                        (click)="queryTest()" class="btn-primary btn-block button">
                                        <span>Testar</span>
                                    </button>
                                </div>
                                <div class="col-12 col-md-4 col-sm-4">
                                    <button [disabled]="readOnly ||!model.value.success || isLoading" mat-button
                                        type="submit" class="btn-primary btn-block button">
                                        <span>Salvar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="schema-container col-12 col-md-4 col-sm-4">
                    <div class="title-header">
                        <h1>Listagem de tabelas e colunas</h1>
                    </div>
                    <app-loading-list *ngIf="isLoadingSchema"></app-loading-list>
                    <div *ngIf="!isLoadingSchema" class="col-list">
                        <div *ngFor="let item of schema | keyvalue">
                            <details>
                                <ul>
                                    <li *ngFor="let column of item.value">
                                        {{column}}
                                    </li>
                                </ul>
                                <summary>
                                    {{item.key}}
                                </summary>
                            </details>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>