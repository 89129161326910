<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consulting/template-medication">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Cadastrar Medicamento</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Código</mat-label>
                        <input matInput type="number" formControlName="code">
                        <mat-error *ngIf="model.get('code').invalid">Informe o Identificador</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="description">
                        <mat-error *ngIf="model.get('description').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidade do medicamento</mat-label>
                        <mat-select formControlName="idMeasurementUnit">
                            <mat-option *ngFor="let item of listMedicineMeasurementUnit"
                                [value]="item.idMeasurementUnit">{{item.measurementUnitName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Apresentação do Medicamento</mat-label>
                        <mat-select formControlName="idMedicinePresentationType"
                            (selectionChange)="checkOtherPresentation()">
                            <mat-option *ngFor="let item of listMedicinePresentationType"
                                [value]="item.idMedicinePresentationType">{{item.medicinePresentationTypeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Via do Medicamento</mat-label>
                        <mat-select formControlName="listIdMedicineAdministrationType"
                        multiple (selectionChange)="checkOtherAdministration()">
                            <mat-option *ngFor="let item of listMedicineAdministrationType"
                                [value]="item.idMedicineAdministrationType">{{item.medicineAdministrationTypeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Diluição do medicamento</mat-label>
                        <mat-select formControlName="idDilution">
                            <mat-option *ngFor="let item of listMedicineDilution"
                                [value]="item.idDilution">{{item.dilutionName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Outra Apresentação</mat-label>
                        <textarea matInput type="text" formControlName="medicinePresentationOther"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Outra Via</mat-label>
                        <textarea matInput type="text" formControlName="medicineAdministrationOther"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-active-principle-tag [inputListTags]="allergies" [listTags]="allergiesToSave"
                                [isError]="allergyError" (updateAllergiesToSave)="updateAllergiesToSave($event)">
                    </app-active-principle-tag>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-2">
                    <mat-radio-group aria-label="Campo obrigatório" formControlName="radioGroup"
                        (change)="onChange($event.value)">
                        <mat-radio-button [value]="true" [checked]="isGroup">Grupo</mat-radio-button>
                        <mat-radio-button [value]="false" [checked]="!isGroup">Subgrupo</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-12 col-sm-12 col-md-6" *ngIf="isGroup == true">
                    <mat-form-field appearance="outline">
                        <mat-label>Grupo de Medicamento</mat-label>
                        <mat-select formControlName="idMedicineGroup">
                            <mat-option *ngFor="let item of listMedicineGroup" [value]="item.idMedicineGroupOrSubgroup">
                                {{item.medicineGroupOrSubgroupName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-12 col-md-6" *ngIf="isGroup == false">
                    <mat-form-field appearance="outline">
                        <mat-label>Subgrupo de Medicamento</mat-label>
                        <mat-select formControlName="idMedicineSubgroup">
                            <mat-option *ngFor="let item of listMedicineSubgroup"
                                [value]="item.idMedicineGroupOrSubgroup">
                                {{item.medicineGroupOrSubgroupName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/template-medication"
                        class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>