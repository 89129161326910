import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateGroupSelectService } from 'src/app/shared/services/API/pharmacy/template-group-select.service';
import { TemplateSubgroupService } from 'src/app/shared/services/API/pharmacy/template-subgroup.service';
import { TemplateMedicineSubgroupRequest } from 'src/app/shared/services/requests/pharmacy/template-medicine-subgroup.request';
import { TemplateGroupSelectStruct } from 'src/app/shared/services/structs/pharmacy/template-group-select.struct';

@Component({
  selector: 'app-template-subgroup-register',
  templateUrl: './template-subgroup-register.component.html',
  styleUrls: ['./template-subgroup-register.component.css']
})
export class TemplateSubgroupRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private templateSubgroupService: TemplateSubgroupService,
    private templateGroupSelectService: TemplateGroupSelectService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_template_subgroup;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean = false;
  public idMedicineSubgroup: number;


  public listAllGroup: TemplateGroupSelectStruct[];

  ngOnInit(): void {
    this.isLoading = false;
    this.getGroups();
    if (this.activatedRoute.snapshot.paramMap.get('idMedicineSubgroup')){
      this.isUpdate = true;
      this.idMedicineSubgroup = parseInt(this.activatedRoute.snapshot.paramMap.get('idMedicineSubgroup'));
    }

    this.model = this.formBuilder.group({
      idMedicineGroup: [{ value: '', disabled: this.isUpdate }, [Validators.required]],
      medicineSubgroupName: ['', [Validators.required]],
      medicineSubgroupDescription: ['', [Validators.required]],
    });

    if (this.idMedicineSubgroup != null) {
      this.isFirstLoading = true;
      this.populateSubgroupData();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    let request: TemplateMedicineSubgroupRequest = new TemplateMedicineSubgroupRequest();
    request.medicineSubgroupName = this.model.get('medicineSubgroupName').value;
    request.medicineSubgroupDescription = this.model.get('medicineSubgroupDescription').value;
    request.idMedicineGroup = this.model.get('idMedicineGroup').value;
    
    if (this.isUpdate)
      this.updateSubgroup(request);
    else
      this.createSubgroup(request);
  }

  populateSubgroupData() {
    this.templateSubgroupService.read(this.idMedicineSubgroup).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.model.get('medicineSubgroupName').setValue(response.templateMedicineSubgroup.medicineSubgroupName);
      this.model.get('medicineSubgroupDescription').setValue(response.templateMedicineSubgroup.medicineSubgroupDescription);
      this.model.get('idMedicineGroup').setValue(response.templateMedicineSubgroup.idMedicineGroup);
      this.isLoading = false;
      this.isFirstLoading= false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  updateSubgroup(request: TemplateMedicineSubgroupRequest) {
    this.templateSubgroupService.update(this.idMedicineSubgroup, request).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/template-subgroup']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createSubgroup(request: TemplateMedicineSubgroupRequest) {
    this.templateSubgroupService.create(request).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/template-subgroup']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getGroups() {
    this.templateGroupSelectService.list().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listAllGroup = response.listGroupSelect;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}