<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/bi/template"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Relatórios</a>
    <h1>Templates de relatórios</h1>
    <div class="white-body">
        <div class="row">
            <div class="col-6 col-sm-3">
                <a mat-button class="btn-primary add-user" routerLink="/bi/template-register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Template</a>
            </div>
        </div>
        
        <div class="filters">
            <div class="row">   
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText" (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listTemplateBi != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listTemplateBi">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.templateBiName}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/bi/template-register', {idTemplateBi: item.idTemplateBi}]">edit</mat-icon> </a>
                            <a><mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idTemplateBi)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <app-empty-list *ngIf="listTemplateBi != undefined && listTemplateBi.length == 0"></app-empty-list>
    </div>
    
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>

