import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReportResponse } from '../../responses/report/report.response';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  viewTemplate(idTemplate: number): Observable<ReportResponse>{
    let uri = `Template/Sadt`;
    
    if(idTemplate)
      uri = uri + `?idTemplate=${idTemplate}`;

    return this.httpClient.get<ReportResponse>(environment.urlReport + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
