import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { GetKeyResponse } from 'src/app/shared/services/responses/emerges-key/get-key.response';
import { ListKeyResponse } from '../../responses/emerges-key/list-key.response';
import { CreateKeyResponse } from '../../responses/emerges-key/create-key.response';
import { KeyRequest } from '../../requests/emerges-key/key.request';

@Injectable({
  providedIn: 'root'
})
export class KeyService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public listKey(searchText: string): Observable<ListKeyResponse> {
    
    let uri = 'Key?'
    
    if(searchText != null)
    uri = uri+ `searchText=${searchText}&`;
    
    return this.httpClient.get<ListKeyResponse>(environment.urlApiEmergesKey + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getKey(idKey: number): Observable<GetKeyResponse>{
    return this.httpClient.get<GetKeyResponse>(environment.urlApiEmergesKey + `Key/idKey/${idKey}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getAllKey(): Observable<ListKeyResponse> {
    
    return this.httpClient.get<ListKeyResponse>(environment.urlApiEmergesKey + `Key/getALL`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createKey(createKeyRequest: KeyRequest): Observable<CreateKeyResponse>{
    return this.httpClient.post<CreateKeyResponse>(environment.urlApiEmergesKey + `Key`, createKeyRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateKey(idKey: number, updateKeyRequest: KeyRequest): Observable<ReturnStruct>{
    return this.httpClient.put<ReturnStruct>(environment.urlApiEmergesKey + `Key/idKey/${idKey}`, updateKeyRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteKey(idKey: number): Observable<ReturnStruct>{
    return this.httpClient.delete<ReturnStruct>(environment.urlApiEmergesKey + `Key/idKey/${idKey}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    