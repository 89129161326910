import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ModuleStruct } from 'src/app/shared/services/structs/admin-user/module.struct';
import { MenuModuleEnum } from '../menu.module.enum';

@Component({
  selector: 'app-module-infos',
  templateUrl: './module-infos.component.html',
  styleUrls: ['./module-infos.component.css']
})
export class ModuleInfosComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { }

  @Input() isVisible: boolean;
  @Input() menuModuleEnum: MenuModuleEnum;
  @Output() closeModuleInfo = new EventEmitter<any>();

  model: FormGroup;

  listModuleStruct: ModuleStruct[];
  defaultModuleName: string;
  idDefaultModule: number;
  newDefaultModule: ModuleStruct;
  actualModuleName: string;

  ngOnInit(): void {
    this.listModuleStruct = this.authService.getTokenMenu().listModule;
    this.defaultModuleName = this.authService.getTokenMenu().defaultModuleName;
    this.idDefaultModule = this.authService.getTokenMenu().idDefaultModule;

    let moduleSelected = this.listModuleStruct.find(module => module.idModule == this.menuModuleEnum);

    if (moduleSelected != undefined)
      this.actualModuleName = this.listModuleStruct.find(module => module.idModule == this.menuModuleEnum).nameModule;

    this.model = this.formBuilder.group({
      isDefaultModule: [''],
    });

    this.setToggle();
  }

  setToggle() {
    if (this.idDefaultModule === this.menuModuleEnum)
      this.model.get('isDefaultModule').setValue(true);
    else
      this.model.get('isDefaultModule').setValue(false);
  }

  close() {
    this.closeModuleInfo.emit();
  }

  redirect(module) {
    //this.setToggle();
    this.router.navigate([module.uri]);
  }
}