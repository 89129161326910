import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListTemplateBiResponse } from "../../responses/business-intelligence/list-template-bi.response";
import { GetTemplateBiAllResponse } from "../../responses/business-intelligence/get-template-bi-all.response";
import { TemplateBiRequest } from "../../requests/business-intelligence/template-bi.request";
import { GetHealthUnitTemplateResponse } from "../../responses/business-intelligence/get-health-unit-template.response";

@Injectable({
  providedIn: 'root'
})
export class TemplateBiService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listTemplateBi(searchText: string): Observable<ListTemplateBiResponse> {
    
    let uri = 'TemplateBi?'
    
    if(searchText != null)
      uri = uri+ `searchText=${searchText}&`;
    
    return this.httpClient.get<ListTemplateBiResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listTemplateBiSelect(): Observable<ListTemplateBiResponse> {
    
    let uri = 'TemplateBi/listTemplates'
        
    return this.httpClient.get<ListTemplateBiResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getTemplateBi(idTemplateBi: number): Observable<GetTemplateBiAllResponse> {
    
    let uri = `GetHealthUnitTemplate/idTemplateBi/${idTemplateBi}`;  
    
    return this.httpClient.get<GetTemplateBiAllResponse>(environment.urlApiOrchestratorBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getHealthUnitTemplate(idHealthUnit: number): Observable<GetHealthUnitTemplateResponse> {
    
    let uri = `TemplateBi/getHealthUnitTemplate/${idHealthUnit}`;  
    
    return this.httpClient.get<GetHealthUnitTemplateResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createUpdate(body: TemplateBiRequest): Observable<ReturnStruct> {
    
    let uri = `TemplateBi/CreateUpdate`;
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public delete(idTemplateBi): Observable<ReturnStruct> {
    
    let uri = `TemplateBi/delete/${idTemplateBi}`; 
    
    return this.httpClient.get<GetTemplateBiAllResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

}
