<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-profile-modal-list">
            <h2>{{data.ticket}}</h2>
        </div>
        <div class="body-profile-modal-list">
            <div class="row">
                <div class="col-12">Unidade: {{data.healthUnitName}}</div>
                <div class="col-12">Atentende: {{data.adminUserName }}</div>
                <div class="col-12">Usuário: {{data.userName }}</div>
                <div class="col-12">Motivo: {{data.accessMotive}}</div>
                <div class="col-12">Data de criação: {{data.dateTimeInclusion | date: 'dd/MM/yyyy h:mm'}}</div>
                <div class="col-12">Data de acesso: {{data.dateTimeAccess | date: 'dd/MM/yyyy h:mm'}}</div>
            </div>
        </div>
        <div class="footer-profile-modal-list">
            <button mat-button class="btn-secundary block" (click)="close()">Fechar</button>
        </div>
    </div>
</div>