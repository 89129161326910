export class UserRegisterRequest{
    
    public userName: string;
    
    public login: string;
    
    public cpf: string;
    
    public tempPassword: string;
    
    public council: string;
    
    public email: string;
    
    public idDepartment: number;
    
    public phone: string;
    
    public isActive: boolean;
    
    public listProfile: number[];

    public haveSignature: boolean;
}