import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetLogResponse } from '../../responses/srvlog/get-log.response';
import { GetLogsResponse } from '../../responses/srvlog/get-logs.response';
import { GetSensitiveLogDataResponse } from '../../responses/srvlog/get-sensitive-log-data.response';

@Injectable({
    providedIn: 'root'
})
export class ExternalLogVisualizerService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }


    public listExternalLog(
        idLog: number,
        idHealthUnit: number,
        type: string,
        searchText: string,
        startDateRange: Date | null,
        endDateRange: Date | null,
        pageIndex: number): Observable<GetLogsResponse> {

        let uri = 'ExternalLogVisualizer?'

        if (idLog)
            uri = uri + `idLog=${idLog}`;

        if (idHealthUnit)
            uri = uri + `&idHealthUnit=${idHealthUnit}`;

        if (type)
            uri = uri + `&type=${type}`;

        if (searchText)
            uri = uri + `&searchText=${searchText}`;

        if (startDateRange) {
            startDateRange.setHours(0);
            uri = uri + `&startDateRange=${startDateRange.toISOString()}`;
        }

        if (endDateRange) {
            endDateRange.setHours(23);
            endDateRange.setMinutes(59);
            uri = uri + `&endDateRange=${endDateRange.toISOString()}`;
        }

        if (pageIndex)
            uri = uri + `&pageIndex=${pageIndex}`;

        return this.httpClient.get<GetLogsResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public listExternalLogWithListHealthUnit(
        idLog: number,
        idHealthUnit: number[],
        type: string,
        searchText: string,
        startDateRange: Date | null,
        endDateRange: Date | null,
        pageIndex: number): Observable<GetLogsResponse> {

        let uri = 'ExternalLogVisualizer?'

        if (idLog)
            uri = uri + `idLog=${idLog}`;

        if (type)
            uri = uri + `&type=${type}`;

        if (searchText)
            uri = uri + `&searchText=${searchText}`;

        if (startDateRange) {
            startDateRange.setHours(0);
            uri = uri + `&startDateRange=${startDateRange.toISOString()}`;
        }

        if (endDateRange) {
            endDateRange.setHours(23);
            endDateRange.setMinutes(59);
            uri = uri + `&endDateRange=${endDateRange.toISOString()}`;
        }

        if (pageIndex)
            uri = uri + `&pageIndex=${pageIndex}`;

        return this.httpClient.post<GetLogsResponse>(environment.urlApiSrvLog + uri, idHealthUnit, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public getExternalLog(idLog: number): Observable<GetLogResponse> {

        let uri = `ExternalLogVisualizer/idLog/${idLog}`

        return this.httpClient.get<GetLogResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public getExternalLogSensitiveData(idLog: number): Observable<GetSensitiveLogDataResponse> {

        let uri = `ExternalLogVisualizer/idLog/${idLog}`

        return this.httpClient.put<GetSensitiveLogDataResponse>(environment.urlApiSrvLog + uri, null, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}
