export class CsatConfigRequest {

    public idCsatConfig: number;

    public listIdHealthUnit: number[];

    public csatConfigName: string;

    public isActive: boolean;

    public idPeriod: number;
}