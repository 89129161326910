import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelfTriageProtocolService } from 'src/app/shared/services/API/self-triage/self-triage-protocol.service';
import { SelfTriageFlowchartModel } from 'src/app/shared/services/models/self-triage/self-triage-flowchart.model';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { SelfTriageFlowchartService } from 'src/app/shared/services/API/self-triage/self-triage-flowchart.service';
import { SelfTriageProtocolRequest } from 'src/app/shared/services/requests/self-triage/self-triage-protocol.request';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-self-triage-protocol-register',
  templateUrl: './self-triage-protocol-register.component.html',
  styleUrls: ['./self-triage-protocol-register.component.css']
})
export class SelfTriageProtocolRegisterComponent implements OnInit {

  constructor(
    private protocolService: SelfTriageProtocolService,
    private flowchartService: SelfTriageFlowchartService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
    private healthUnitService: HealthUnitService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_protocol;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean = true;
  public isUpdate: boolean = false;
  public idProtocol: number;
  public listHealthUnit: ListHealthUnitStruct[];
  public listAssignedFlowchart: SelfTriageFlowchartModel[] = [];
  public listUnassignedFlowchart: SelfTriageFlowchartModel[] = [];
  public errorProtocol: string;
  public errorHealthUnit: string;
  public completeError: string;
  public transform: number;
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";

  ngOnInit(): void {
    this.isLoading = true;
    this.populateHealthUnitSelect();

    if (this.activatedRoute.snapshot.paramMap.get('idProtocol')) {
      this.idProtocol = parseInt(this.activatedRoute.snapshot.paramMap.get('idProtocol'));
    }
    if (this.idProtocol){
      this.isUpdate = true;
      this.populateProtocolData(this.idProtocol);
    }
    if (!this.isUpdate) {
      this.populateUnassignedFlowcharts();
    }

    this.model = this.formBuilder.group({
      protocolName: [{ value: '', disabled: false }, [Validators.required]],
      protocolDescription: [''],
      firstChildGroup: this.formBuilder.group({
        idHealthUnit: [{ value: '', disabled: false }, [Validators.required]],
      }),
    });
  }

  populateHealthUnitSelect() {
    this.healthUnitService.listHealthUnit().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHealthUnit = response.listHealthUnit;
      this.isLoading = false;
      if (!this.isUpdate) {
        this.isFirstLoading = false;
      }
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateUnassignedFlowcharts() {
    this.flowchartService.listUnassigned().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listUnassignedFlowchart = response.listFlowchart;
      this.listUnassignedFlowchart.sort((a, b) => a.order - b.order);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });

    this.isFirstLoading = false;
  }

  populateProtocolData(idProtocol:number){
    this.protocolService.getProtocol(idProtocol).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listAssignedFlowchart = response.listAssignedFlowcharts;
      this.listAssignedFlowchart.sort((a, b) => a.order - b.order);
      this.listUnassignedFlowchart = response.listUnassignedFlowcharts;
      this.listUnassignedFlowchart.sort((a, b) => a.order - b.order);
      let fullList = this.listAssignedFlowchart.concat(this.listUnassignedFlowchart);
      fullList.forEach(item => {
        let minAge = JSON.parse(item.minAge);
        let maxAge = JSON.parse(item.maxAge);
        item.minAge = `${minAge.years}a${minAge.months}m${minAge.days}d`;
        item.maxAge = `${maxAge.years}a${maxAge.months}m${maxAge.days}d`;
      });
      this.model.get('firstChildGroup').get('idHealthUnit').setValue(response.listHealthUnit);
      this.model.get('protocolName').setValue(response.protocol.protocolName);
      this.model.get('protocolDescription').setValue(response.protocol.protocolDescription);
    },
    (error) => {
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });

    this.isLoading = false;
    this.isFirstLoading = false;
  }

  exchange(id: number, sourceList: SelfTriageFlowchartModel[], targetList: SelfTriageFlowchartModel[]) {
    let index = sourceList.findIndex(flowchart => flowchart.idFlowchart == id);
    let flowchart: SelfTriageFlowchartModel = sourceList.splice(index, 1)[0];
    targetList.unshift(flowchart);
    sourceList.sort((a, b) => a.order - b.order);
    targetList.sort((a, b) => a.order - b.order);
  }

  submit() {
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    let request = new SelfTriageProtocolRequest();
    request.protocolName = this.model.get('protocolName').value;
    request.protocolDescription = this.model.get('protocolDescription').value;
    request.idHealthUnit = this.model.get('firstChildGroup').get('idHealthUnit').value;
    request.listIdFlowchart = this.listAssignedFlowchart.map(flowchart => flowchart.idFlowchart);

    if (!this.isUpdate) {
      this.protocolService.createProtocol(request).subscribe((response) => {
        if (response.isError) {
          if(response.errorCode = 1){
            this.errorProtocol = response.errorDescription.split(',')[0];
            this.errorHealthUnit = response.errorDescription.split(',')[1];
            this.transform = parseInt(this.errorHealthUnit);
            let findHealthUnit = this.listHealthUnit.find(x => x.idHealthUnit == this.transform);
            this.errorHealthUnit = findHealthUnit ? findHealthUnit.healthUnitName : "";
            this.completeError = "O protocolo " + this.errorProtocol + " já está ligado a Unidade de Saúde " + this.errorHealthUnit + "! É possível associar somente um protocolo de Autotriagem por unidade.";
            this.alertService.show('Erro', this.completeError, AlertType.error);
            this.isLoading = false;
          }
          else{
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
          }
          return;
        }
        else {
          this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
          this.isLoading = false;
          this.router.navigate(['/consulting/self-triage-protocol']);
        }
      })
    }
    else {
      this.protocolService.updateProtocol(request, this.idProtocol).subscribe((response) => {
        if (response.isError) {
          if(response.errorCode = 1){
            this.errorProtocol = response.errorDescription.split(',')[0];
            this.errorHealthUnit = response.errorDescription.split(',')[1];
            this.transform = parseInt(this.errorHealthUnit);
            let findHealthUnit = this.listHealthUnit.find(x => x.idHealthUnit == this.transform);
            this.errorHealthUnit = findHealthUnit ? findHealthUnit.healthUnitName : "";
            this.completeError = "O protocolo " + this.errorProtocol + " já está ligado a Unidade de Saúde " + this.errorHealthUnit + "! É possível associar somente um protocolo de Autotriagem por unidade.";
            this.alertService.show('Erro', this.completeError, AlertType.error);
            this.isLoading = false;
          }
          else{
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
          }
          return;
        }
        else {
          this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
          this.isLoading = false;
          this.router.navigate(['/consulting/self-triage-protocol']);
        }
      })
    }
  }
}
