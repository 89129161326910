import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SupervisionService } from 'src/app/shared/services/API/billing/supervision.service';
import { SupervisionRequest } from 'src/app/shared/services/requests/billing/supervision.request';

@Component({
  selector: 'app-supervision-register',
  templateUrl: './supervision-register.component.html',
  styleUrls: ['./supervision-register.component.css']
})
export class SupervisionRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private supervisionService: SupervisionService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_supervision;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public idSupervision: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.isUpdate = false;

    if (this.activatedRoute.snapshot.paramMap.get('idSupervision'))
      this.idSupervision = parseInt(this.activatedRoute.snapshot.paramMap.get('idSupervision'));

    this.isUpdate = this.idSupervision ? true : false;

    this.model = this.formBuilder.group({
      supervisionName: ['', [Validators.required]]
    });

    if(this.idSupervision){
      this.isLoading = true;
      this.populateSupervisionData();
    }      
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    let supervisionRequest: SupervisionRequest = new SupervisionRequest();
    supervisionRequest.supervisionName = this.model.get('supervisionName').value;
    
    if (this.isUpdate)
      this.updateSupervision(supervisionRequest);
    else
      this.createSupervision(supervisionRequest);
  }

  populateSupervisionData() {
    this.supervisionService.Get(this.idSupervision).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('supervisionName').setValue(response.supervision.supervisionName);
        this.isLoading = false;
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateSupervision(supervisionRequest: SupervisionRequest) {
    this.supervisionService.Update(this.idSupervision, supervisionRequest).subscribe({
      next: (response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/consulting/supervision']);
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createSupervision(supervisionRequest: SupervisionRequest) {
    this.supervisionService.Create(supervisionRequest).subscribe({ 
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/consulting/supervision']);
    },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}