<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <!-- <h1>Relatório {{reportName}}</h1> -->
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
       
        <div *ngIf="!isFirstLoading" class="container-powerbi">
            <div id="reportDiv" class="div-report" #reportDiv></div>
        </div>
        
        <!-- <div class="row margin-top">
            <div class="col-12 col-sm-6 col-md-3">
                <button mat-stroked-button type="button" routerLink="/bi/list" class="btn-secundary btn-block">
                    <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Voltar
                </button>
            </div>
        </div> -->
    </div>
</div>