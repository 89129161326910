import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { VariableTypeModel } from 'src/app/shared/services/models/integration-relation-config/variable-type.model';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.css']
})
export class RelationsComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() formArrayName: string;
  @Input() listVariableType: VariableTypeModel[];

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  createInput() {
    return this.formBuilder.group({
      idVariableType: [''],
      variableToLife: [''],
      variableIntegration: [''],
      description: ['']
    });
  }

  addNext() {
    (this.formGroup.controls[this.formArrayName] as FormArray).push(this.createInput())
  }

  removeLine(index: number) {
    (this.formGroup.controls[this.formArrayName] as FormArray).removeAt(index)
  }

  findVariableType(idVariableType: number): VariableTypeModel | undefined {
    return this.listVariableType.find(item => item.idVariableType === idVariableType);
  }
}