import { AllergyStruct } from "../../structs/medical-record/allergy.struct";

export class AllergyModel {
    public idAllergy: number;
    public allergyName: string;
    public isDeleted: boolean = false;
    public motive: string;
    public idUser: number;
    public userName: string;
    public dateTimeInclusion: Date;
    public dateTimeDelete: Date;

    constructor(allergy?: AllergyStruct) {
        if (allergy != null) {
            this.idAllergy = allergy.idAllergy;
            this.allergyName = allergy.allergyName;
            this.motive = allergy.motive;
            this.isDeleted = allergy.isDeleted;
            this.idUser = allergy.idUser;
        }
    }
}

