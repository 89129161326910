import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateBiService } from 'src/app/shared/services/API/business-intelligence/template-bi.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { TemplateBi} from 'src/app/shared/services/models/business-intelligence/template-bi.model';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { TemplateBusinessIntelligenceDeleteModalComponent } from './template-business-intelligence-delete-modal/template-business-intelligence-delete-modal.component';

@Component({
  selector: 'app-template-business-intelligence-list',
  templateUrl: './template-business-intelligence-list.component.html',
  styleUrls: ['./template-business-intelligence-list.component.css']
})
export class TemplateBusinessIntelligenceListComponent {

  constructor(private templateBiService: TemplateBiService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    public healthUnitService: HealthUnitService,
  ) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.business_intelligence;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.business_intelligence_template;
    
    public listTemplateBi: TemplateBi[];
    public isLoading: boolean;
    public isActive: boolean;
    public searchText: string;
    public ordenation: number;
    public idHealthUnit: number;
    public listHealthUnit: ListHealthUnitStruct[];
    public listOrdenation: number[];

  ngOnInit(): void {
    this.isLoading = true;
    this.search();
  }
  search(){
    this.isLoading = true;  
    if(this.searchText == "")
    this.searchText = null;
    
    this.templateBiService.listTemplateBi(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listTemplateBi = response.listTemplateBi;  
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  private timeoutKeySearch: any = null;
  
  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idReport){
    const dialogRef = this.dialog.open(TemplateBusinessIntelligenceDeleteModalComponent, {
      data: {
        idReport: idReport
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.search();
      });
  }
}
