import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { ProtocolService } from 'src/app/shared/services/API/risk-classification/protocol.service';
import { PriorityColorGroupModel } from 'src/app/shared/services/models/risk-classification/priority-color-group.model';
import { ProtocolModel } from 'src/app/shared/services/models/risk-classification/protocol.model';
import { CompleteFlowchartStruct } from 'src/app/shared/services/structs/risk-classification/complete-flowchart.struct';
import { ProtocolStruct } from 'src/app/shared/services/structs/risk-classification/protocol.struct';
import { FlowchartDeleteModalComponent } from './flowchart-delete-modal/flowchart-delete-modal.component';
import { FlowchartService } from 'src/app/shared/services/API/risk-classification/flowchart.service';

@Component({
  selector: 'app-protocol-register',
  templateUrl: './protocol-register.component.html',
  styleUrls: ['./protocol-register.component.css']
})
export class ProtocolRegisterComponent implements OnInit {

  constructor(
    private protocolService: ProtocolService,
    private lookupService: LookupRiskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
    private flowchartService: FlowchartService,
  ) { }


  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_protocol;
  public model: FormGroup;
  public isLoading: boolean;
  public isUpdate: boolean;
  public idProtocol: number;
  public listPriorityColorGroup: PriorityColorGroupModel[] = [];
  public listFlowchart: CompleteFlowchartStruct[] = [];
  private isTemplate: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;

    this.idProtocol = null;

    if (this.activatedRoute.snapshot.paramMap.get('idProtocol')) {
      this.idProtocol = parseInt(this.activatedRoute.snapshot.paramMap.get('idProtocol'));
    }

    this.isUpdate = false;

    this.model = this.formBuilder.group({
      protocolName: [{ value: '', disabled: this.isUpdate }, [Validators.required]],
      description: [''],
      isTemplate: [{ value: false, disabled: this.isUpdate }],
      isActive: [true],
      isOutOfOrder: [{ value: false, disabled: this.isUpdate }],
      idPriorityColorGroup: [{ value: '', disabled: this.isUpdate }, [Validators.required]],
      isTelephone: [false],
    });

    if (this.idProtocol && this.idProtocol > 0) {
      this.isUpdate = true;
      this.populateProtocolData();
    }

    this.getLookup();

  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if (this.isTemplate) {
      this.alertService.show('Erro', "Protocolo default não pode ser editado por tela, procure o responsável de TI!", AlertType.error);
      return;
    }

    let protocolStruct = new ProtocolStruct;

    protocolStruct.protocolName = this.model.get("protocolName").value;
    protocolStruct.description = this.model.get("description").value;
    protocolStruct.isTemplate = this.model.get("isTemplate").value;
    protocolStruct.isActive = this.model.get("isActive").value;
    protocolStruct.outOfOrder = this.model.get("isOutOfOrder").value;
    protocolStruct.idPriorityColorGroup = this.model.get("idPriorityColorGroup").value;
    protocolStruct.isTelephone = this.model.get("isTelephone").value;
    protocolStruct.listFlowchart = [];

    if (this.isUpdate) {
      this.updateProtocol(protocolStruct);
    }
    else {
      this.createProtocol(protocolStruct);
    }

  }

  populateProtocolData() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.protocolService.getProtocolAndFlowchasts(this.idProtocol).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isTemplate = response.protocolStruct.isTemplate;
        this.model.get("protocolName").setValue(response.protocolStruct.protocolName);
        this.model.get("description").setValue(response.protocolStruct.description);
        this.model.get("isTemplate").setValue(response.protocolStruct.isTemplate);
        this.model.get("isActive").setValue(response.protocolStruct.isActive);
        this.model.get("isOutOfOrder").setValue(response.protocolStruct.outOfOrder);
        this.model.get("idPriorityColorGroup").setValue(response.protocolStruct.idPriorityColorGroup);
        this.model.get("isTelephone").setValue(response.protocolStruct.isTelephone);

        this.disableModelFieldsToBlockUpdate();

        this.listFlowchart = [...response.protocolStruct.listFlowchart];

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  disableModelFieldsToBlockUpdate() {
    this.model.get("protocolName").disable();
    this.model.get("isTemplate").disable();
    this.model.get("isOutOfOrder").disable();
    this.model.get("idPriorityColorGroup").disable();
  }

  updateProtocol(protocolModel: ProtocolModel) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.protocolService.UpdateSimpleProtocol(protocolModel).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createProtocol(protocolModel: ProtocolModel) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.protocolService.PostSimpleProtocol(protocolModel).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.idProtocol = response.idProtocol;
        this.isUpdate = true;

        this.disableModelFieldsToBlockUpdate();

        let newUrl: string = `/protocol/register;idProtocol=${this.idProtocol}`;
        this.router.navigateByUrl(newUrl, { skipLocationChange: false });

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getLookup() {
    this.lookupService.getLookup().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.priorityColorGroup && response.priorityColorGroup.length > 0) {
          this.listPriorityColorGroup = [...response.priorityColorGroup];
        }

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openDeleteModal(index: number, idFlowchart: number) {
    if (this.isLoading) {
      return;
    }

    const dialogRef = this.dialog.open(FlowchartDeleteModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteFlowchart) {
        this.deleteFlowchart(index, idFlowchart);
      }
    });
  }

  deleteFlowchart(index: number, idFlowchart: number) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.flowchartService.DeleteFlowchart(idFlowchart).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listFlowchart.splice(index, 1);

        this.alertService.show('Sucesso', "Fluxograma deletado com sucesso", AlertType.success);
        this.isLoading = false;

      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  redirectToFlowchartRegister(idFlowchart: number = 0) {
    if (this.isLoading) {
      return;
    }

    if (!this.idProtocol || this.idProtocol == 0) {
      this.alertService.show('Erro', "Para criar um fluxograma é necessário antes criar o protocolo.", AlertType.error);
      return;
    }

    if (idFlowchart == 0) {
      this.router.navigate(['/protocol/flowchart/register', { idProtocol: this.idProtocol }]);
    }
    else {
      this.router.navigate(['/protocol/flowchart/register', { idProtocol: this.idProtocol, idFlowchart: idFlowchart }]);
    }

  }
}
