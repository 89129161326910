import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuModuleUserEnum } from 'src/app/shared/enum/user/menu-module-user.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupService } from 'src/app/shared/services/API/flow/lookup.service';
import { ListAdminModuleService } from 'src/app/shared/services/API/user/list-admin-module.service';
import { ModuleServiceSpecialtiesService } from 'src/app/shared/services/API/user/module-service-specialties.service';
import { SpecialtyAttendanceModel } from 'src/app/shared/services/models/flow/specialty-attendance.model';
import { ModuleServiceSpecialtiesRequest } from 'src/app/shared/services/requests/user/module-service-specialties.request';
import { ModuleStruct } from 'src/app/shared/services/structs/admin-user/module.struct';

@Component({
  selector: 'app-specialty',
  templateUrl: './specialty.component.html',
  styleUrls: ['./specialty.component.css']
})
export class SpecialtyComponent implements OnInit {
  menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_specialty;

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private listAdminModuleService: ListAdminModuleService,
    private listLookupService: LookupService,
    private moduleServiceSpecialtiesService: ModuleServiceSpecialtiesService,
    private router: Router,
  ) { }

  model: FormGroup;
  listModuleStruct: ModuleStruct[] = [];
  listSpecialtyAttendance: SpecialtyAttendanceModel[] = [];
  isLoading: boolean = true;
  isFirstLoading: boolean = true;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      listModules: this.formBuilder.array([])
    });

    let asyncCallsCounter = 0;

    const handleAsyncCallCompleted = () => {
      asyncCallsCounter++;
      if (asyncCallsCounter === 2)
        this.populateData();
    };

    this.populateModuleSelect(handleAsyncCallCompleted);
    this.populateSpecialtyAttendanceSelect(handleAsyncCallCompleted);
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    const formValues = this.model.value;
    let request = new ModuleServiceSpecialtiesRequest();
    request.listRelationModuleSpecialtiesStruct = formValues.listModules.map((module, index) => {
      return {
        idModule: this.listModuleStruct[index].idModule,
        listIdSpecialtyAttendance: module.listIdSpecialty || []
      };
    });

    this.postPut(request);
  }

  postPut(request: ModuleServiceSpecialtiesRequest) {
    this.moduleServiceSpecialtiesService.postPut(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        const url = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([`/${url}`]).then(() => { })
        });
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  addModuleForm(module: ModuleStruct): void {
    const moduleForm = this.formBuilder.group({
      idModule: new FormControl({ value: module.nameModule, disabled: true }),
      listIdSpecialty: new FormControl([])
    });

    this.listModules.push(moduleForm);
  }

  get listModules(): FormArray {
    return this.model.get('listModules') as FormArray;
  }

  populateModuleSelect(callback: () => void) {
    this.listAdminModuleService.listModule().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          callback();
          return;
        }

        this.listModuleStruct = response.listModuleStruct?.filter(x =>
          [MenuModuleUserEnum.classification, MenuModuleUserEnum.generic_call, MenuModuleUserEnum.sadt,
          MenuModuleUserEnum.digital_prompt_service, MenuModuleUserEnum.observation,
          MenuModuleUserEnum.medic, MenuModuleUserEnum.multi_professional, MenuModuleUserEnum.frontdesk]
            ?.includes(x.idModule)
        );

        this.listModuleStruct?.forEach(module => this.addModuleForm(module));

        callback();
      },
      error: (error) => {
        console.log(error);
        callback();
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateSpecialtyAttendanceSelect(callback: () => void) {
    this.listLookupService.listSpecialtyAttendance().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          callback();
          return;
        }

        this.listSpecialtyAttendance = response.listSpecialityAttendance;

        callback();
      },
      error: (error) => {
        console.log(error);
        callback();
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateData() {
    this.moduleServiceSpecialtiesService.getAll().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        response.listRelationModuleSpecialtiesStruct.forEach((moduleSpecialty: any) => {
          const moduleIndex = this.listModuleStruct.findIndex(module => module.idModule === moduleSpecialty.idModule);

          if (moduleIndex !== -1) {
            const moduleForm = this.listModules.at(moduleIndex);
            moduleForm?.get('listIdSpecialty')?.setValue(moduleSpecialty.listIdSpecialtyAttendance);
          }
        });

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  toggleSelectAll(index: number): void {
    const listIdSpecialtyControl = this.listModules.at(index).get('listIdSpecialty') as FormControl;
    const allSpecialtyIds = this.listSpecialtyAttendance.map(specialty => specialty.idSpecialityAttendance);
    const currentValue = listIdSpecialtyControl.value || [];

    const allSelected = allSpecialtyIds.every(id => currentValue.includes(id));

    if (allSelected)
      listIdSpecialtyControl.setValue([]);
    else
      listIdSpecialtyControl.setValue(allSpecialtyIds);
  }
}