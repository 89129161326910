import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SelfTriageProtocolService } from 'src/app/shared/services/API/self-triage/self-triage-protocol.service';
import { SelfTriageProtocolModel } from 'src/app/shared/services/models/self-triage/self-triage-protocol.model';
import { ListProtocolStruct } from 'src/app/shared/services/structs/self-triage/list-protocol.struct';
import { SelfTriageProtocolDeleteModalComponent } from './self-triage-protocol-delete-modal/self-triage-protocol-delete-modal.component';

@Component({
  selector: 'app-self-triage-protocol-list',
  templateUrl: './self-triage-protocol-list.component.html',
  styleUrls: ['./self-triage-protocol-list.component.css']
})
export class SelfTriageProtocolListComponent implements OnInit {

  constructor(
    private protocolService: SelfTriageProtocolService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_protocol;

  public protocolStruct: SelfTriageProtocolModel[];
  public searchText: string = "";
  public isLoading: boolean = true;

  ngOnInit(): void {
    this.search();
  }

  search(){
    this.isLoading = true;
    this.protocolService.listProtocol(this.searchText, null).subscribe((response) => {
      if (response.isError) {

        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.protocolStruct = response.protocolStruct;
      this.isLoading = false;
    }, (error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });

  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idProtocol){
    const dialogRef = this.dialog.open(SelfTriageProtocolDeleteModalComponent, {
      data: {
        idProtocol: idProtocol,
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteFlowchart){
        this.search();
      }
    });
  }
}
