import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../../common-service';
import { ListDeviceTypeResponse } from '../../../responses/device/list-device-type.response';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listAllDevices(): Observable<ListDeviceTypeResponse> {
    let uri = "DeviceType"

    return this.httpClient.get<ListDeviceTypeResponse>(environment.urlApiDevice + uri)
      .pipe(
        catchError(this.handleError)
    )
  }
}
