

export class OriginModel {
    public idOrigin: number;
    public originName: string;
    public isDeleted: boolean;
    public showToEveryone: boolean;
    public dateTimeInclusion: Date;
    constructor(originModel?: OriginModel) {
        if (originModel != null) {
            this.idOrigin = originModel.idOrigin;
            this.originName = originModel.originName;
            this.isDeleted = originModel.isDeleted;
            this.showToEveryone = originModel.showToEveryone;
            this.dateTimeInclusion = originModel.dateTimeInclusion;
        }
    }
}

