import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReportService } from 'src/app/shared/services/API/business-intelligence/report.service';

@Component({
  selector: 'app-report-delete-modal',
  templateUrl: './report-delete-modal.component.html',
  styleUrls: ['./report-delete-modal.component.css']
})
export class ReportDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ReportDeleteModalComponent>,
    private reportService: ReportService,
    private alertService: AlertService,
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteReport: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.reportService.deleteReport(this.data.idReport).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.alertService.show('Sucesso', "Relatório excluido com sucesso!", AlertType.success); 
      this.matDialogRef.close({deleteReport: true}); 
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteReport: false}); 
  }
}