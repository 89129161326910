import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FlowchartService } from 'src/app/shared/services/API/self-triage/flowchart.service';
import { QuestionService } from 'src/app/shared/services/API/self-triage/question.service';
import { Flowchart } from 'src/app/shared/services/models/self-triage/flowchart.model';
import { QuestionModel } from 'src/app/shared/services/models/self-triage/question.model';
import { SelfTriageDiscriminatorDeleteModalComponent } from './self-triage-discriminator-delete-modal/self-triage-discriminator-delete-modal.component';

@Component({
  selector: 'app-self-triage-discriminator-list',
  templateUrl: './self-triage-discriminator-list.component.html',
  styleUrls: ['./self-triage-discriminator-list.component.css']
})
export class SelfTriageDiscriminatorListComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    public dialog: MatDialog,
    public questionService: QuestionService,
    public flowchartService: FlowchartService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_self_triage_discriminator;

  public searchText: string = "";
  public isLoading: boolean = true;
  public questions: QuestionModel[] = [];
  public flowcharts: Flowchart[] = [];
  public idFlowchart: number;
  ngOnInit(): void {
    this.getFlowchartLooukup();
  }

  search() {
    this.isLoading = true;
    this.questionService.listQuestion(this.searchText, this.idFlowchart).subscribe((response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      this.questions = [];
      for (let item of response.listQuestion) {
        item.flowchartName = item.idFlowchart ? this.flowcharts.find(c => c.idFlowchart == item.idFlowchart).name : "";
        this.questions.push(item);
      }
      this.questions.sort((a, b) => a.order - b.order);
    }, (error) => {
      this.isLoading = false;
      this.alertService.show("Erro inesperado", error, AlertType.error);
    })
  }

  getFlowchartLooukup() {
    
    this.flowchartService.listAllFlowchart().subscribe((response) => {
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.flowcharts = response.listFlowchart;
      this.search();
    }, (error) => {
      this.isLoading = false;
      this.alertService.show("Erro inesperado", error, AlertType.error);
    })
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idQuestion) {
    const dialogRef = this.dialog.open(SelfTriageDiscriminatorDeleteModalComponent, { data: { idQuestion } });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteQuestion) {
        this.search();
      }
    });
  }
}
