<app-menu [isRenderMenu]="false" [menuModuleEnum]="menuModuleEnum"></app-menu>


<div class="container-body">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <div class="white-box">
                <h2>Redefinir senha</h2>
                <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
                    <p>Insira a matricula associado à sua conta para receber um link de redefinição de senha.</p>
                    <div class="row">   
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Matricula</mat-label>
                                <input matInput type="text" formControlName="login" required>
                                <mat-error *ngIf="model.get('login').invalid">Informe a sua matricula</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-button type="submit" class="btn-primary btn-block">
                                <span *ngIf="isLoading == false">Enviar e-mail de redefinição</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                            </button>
        
                            <button mat-stroked-button ype="button" routerLink="/" class="btn-secundary btn-block">
                                Voltar
                            </button>
                        </div>
                    </div>
                   
                </form> 
            </div>
        </div>
    </div>
</div>