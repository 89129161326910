<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Dispositivos</h1>
    <div class="white-body">
        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidade de Saúde</mat-label>
                        <mat-select [(ngModel)]="idHealthUnit">
                            <input class="inputSearchSelect" type="text" (keyup)="onKeySearch($event.target.value)"
                                (keydown)="$event.stopImmediatePropagation()" placeholder="Pesquisar"
                                autocomplete="off">
                            <mat-option></mat-option>
                            <mat-option *ngFor="let item of listHealthUnit" value="{{item.idHealthUnit}}">
                                {{item.healthUnitName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de Dispositivo</mat-label>
                        <mat-select [(ngModel)]="idDeviceType">
                            <mat-option></mat-option>
                            <mat-option *ngFor="let item of listDeviceType" value="{{item.idDeviceType}}">
                                {{item.deviceTypeName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Número de Patrimônio</mat-label>
                        <input matInput type="text" id="searchText" name="assetNumber" [(ngModel)]="assetNumber">
                    </mat-form-field>
                </div>
                <div class="col-6 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="isOnline" (selectionChange)="search()">
                            <mat-option value="">Todos</mat-option>
                            <mat-option value="true">Online</mat-option>
                            <mat-option value="false">Offline</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-button class="btn-primary btn-block" (click)="buttonSearch()" >
                            <span *ngIf="!isLoading">Pesquisar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="listDevices != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listDevices">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.assetNumber}}</span>
                            <span class="secundary">{{item.deviceTypeName}}</span>
                            <span class="secundary">{{this.getHealthUnitName(item.idHealthUnit)}}</span>
                            <span class="secundary-status last-info">{{this.getIsOnline(item.isOnline)}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Visualizar"
                                    [routerLink]="['/support/device-details', {idDevice: item.idDevice}]">
                                    visibility
                                </mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-loading-list *ngIf="isLoading"></app-loading-list>
        <app-empty-list *ngIf="listDevices != undefined && listDevices.length == 0"></app-empty-list>
    </div>
</div>