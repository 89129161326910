import { ArrivalTypeStruct } from "../../structs/medical-record/arrival-type.struct";
import { OriginStruct } from "../../structs/medical-record/origin.struct";

export class OriginAndArrivalTypeRequest { 
    public listOriginNotDefault: OriginStruct[];
    public listArrivalTypeNotDefault: ArrivalTypeStruct[];
    constructor() {
            this.listOriginNotDefault = [];
            this.listArrivalTypeNotDefault = [];        
    }
}