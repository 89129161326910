import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { LookupResponse } from '../../responses/billing-by-health-unit/billing-by-health-unit-lookup.response';

@Injectable({
  providedIn: 'root'
})
export class BillingByHealthUnitLookupService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listLookup(): Observable<LookupResponse> {

    let uri = `Lookup`;
    
    return this.httpClient.get<LookupResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
