import { AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { InternalReportProfileService } from 'src/app/shared/services/API/business-intelligence/internal-report-profile.service';
declare var powerbi: any;

@Component({
  selector: 'app-business-intelligence-internal-details',
  templateUrl: './business-intelligence-internal-details.component.html',
  styleUrls: ['./business-intelligence-internal-details.component.css']
})
export class BusinessIntelligenceInternalDetailsComponent implements OnInit, AfterViewInit {

  constructor(private bIService: InternalReportProfileService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.business_intelligence_internal_list_report;
  public isFirstLoading: boolean = true;
  public models;


  public internalReportName: string;
  public config = {
    type: 'report',
    tokenType: 1,
    accessToken: undefined,
    embedUrl: undefined,
    id: undefined,
    pageName: undefined,
    permissions: 0,
    viewMode: 0,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: 1 // Set the default layout type to Master
      // panes:{
      //   pageNavigation: {
      //       visible: true,
      //   }
      // }    
    }
  };
  @ViewChildren('reportDiv') reportDiv: QueryList<ElementRef>;
  ngOnInit(): void {
    console.log(this.activatedRoute.snapshot.paramMap);

    if (!this.activatedRoute.snapshot.paramMap.get('idInternalReportFunctionProfile')) {
      this.alertService.show('Erro', "Para conseguir ver o relatório precisa do seu id", AlertType.error);
      this.router.navigate(['/bi/internal-list']);
    }
  }


  ngAfterViewInit() {
    let idInternalReportFunctionProfile: number = parseInt(this.activatedRoute.snapshot.paramMap.get('idInternalReportFunctionProfile'));
    this.getInternalReport(idInternalReportFunctionProfile);
    this.models = window['powerbi-client'].models;
    this.getInternalReport(idInternalReportFunctionProfile);
  }

  getInternalReport(idInternalReportFunctionProfile: number) {
    this.bIService.getReport(idInternalReportFunctionProfile).subscribe((response) => {
      this.isFirstLoading = false;
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      this.config.embedUrl = response.internalReport.internalReportLink;
      this.config.id = response.internalReport.biReport;
      this.config.accessToken = response.internalReport.token;
      this.internalReportName = response.internalReport.internalReportName;

      const isMobile = window.innerWidth < 768; // Check if the page width is less than 768px (adjust the threshold as needed)
      if (isMobile) {
        this.config.settings.layoutType = this.models.LayoutType.MobilePortrait; // Set the layout type to MobilePortrait for mobile devices
      } else {
        this.config.settings.layoutType = this.models.LayoutType.Master; // Set the layout type to Custom for non-mobile devices
      }

      this.reportDiv.changes.subscribe(c => {
        c.toArray().forEach(item => {
          let report = powerbi.embed(item.nativeElement, this.config);
          report.on('loaded', function () {
            report.getPages().then(function (pages) {
              let page = pages[parseInt(response.internalReport.page) - 1];
              if (page) page.setActive();
            });
          });
        })
      });
    })
  }
}