import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListTimeZoneResponse } from '../../responses/user/list-time-zone.response';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public listTimeZone(): Observable<ListTimeZoneResponse> {

    let uri = 'TimeZone/getAll'

    return this.httpClient.get<ListTimeZoneResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
    
    