import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { MessageRequest } from '../../requests/notification/message.request';
import { PostMessageRequest } from '../../requests/orchestrator-user/post-message.request';
import { GetMessageResponse } from '../../responses/notification/get-message.response';
import { MessageHistoryResponse } from '../../responses/notification/message-history.response';
import { NotificationSelectResponse } from '../../responses/orchestrator-user/notification-select.response';
import { UserSelectResponse } from '../../responses/user/user-select.response';

@Injectable({
  providedIn: 'root'
})
export class NotificationSelectService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  populateSelect(): Observable<NotificationSelectResponse> {
    
    let uri = `NotificationSelect`
    
    return this.httpClient.get<NotificationSelectResponse>(environment.urlApiOrchestratorUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
