<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Detalhes</h1>
    <div class="white-body">
        <div class="filters">
            <div class="row">
                <div class="col-md-3">
                    <label><b>Dispositivo: </b></label>
                    <p>{{device.deviceTypeName}}</p>
                </div>
                <div class="col-md-3">
                    <label><b>Número do Património: </b></label>
                    <p>{{device.assetNumber}}</p>
                </div>
                <div class="col-md-3">
                    <label><b>Unidade de Saúde Designada: </b></label>
                    <p>{{device.idDesignatedHealthUnit}}</p>
                </div>
                <div class="col-md-3">
                    <label><b>Mac: </b></label>
                    <p>{{device.macAddress}}</p>
                </div>
                <div class="col-md-3">
                    <label><b>Último Request: </b></label>
                    <p>{{device.lastRequest | date:'dd/MM/yyyy HH:mm:ss'}}</p>
                </div>
                <div class="col-md-3">
                    <label><b>{{device.isOnline ? 'Online' : 'Offline:'}}</b></label>
                    <p *ngIf="!device.isOnline">{{device.timeOffline}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <canvas baseChart width="1400" height="400" [data]="connectionChartData" [options]="lineChartOptions"
                        [type]="lineChartType"></canvas>
                </div>
                <div class="col-md-12">
                    <canvas baseChart width="1400" height="400" [data]="resourceChartData" [options]="lineChartOptions"
                        [type]="lineChartType"></canvas>
                </div>
            </div>
            <div class="row">
                <div class="col-3 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Seleciona o período</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate [(ngModel)]="beginDate" placeholder="Início">
                            <input matEndDate [(ngModel)]="endDate" placeholder="Fim" (dateChange)="dateChange()">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Granularidade</mat-label>
                        <mat-select [(ngModel)]="granularityType" (selectionChange)="changeGranularity($event.value)">
                            <mat-option value="1">Meses</mat-option>
                            <mat-option value="2">Dias</mat-option>
                            <mat-option value="3">Horas</mat-option>
                            <mat-option value="4">Request</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="card-list" *ngIf="device.listRequest != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of device.listRequest">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.idRequest}}</span>
                                <span class="secundary">{{item.idDesignatedHealthUnit}}</span>
                                <span class="secundary">{{item.memoryUsage}}</span>
                                <span class="secundary">{{item.cpuUsage}}</span>
                                <span class="secundary">{{item.localNetworkIp}}</span>
                                <span class="secundary">{{item.reponseTime}}</span>
                                <span class="secundary">{{item.datetimeRequestGeneration | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                                <span class="secundary">{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                                <span class="secundary-status last-info">{{this.getIsOnline(item.haveConnection)}}</span>
                            </div>
                            <div class="actions">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/support/device-list"
                        class="btn-secundary btn-block">
                        Voltar
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>