import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { TemplateMedicineGroupRequest } from "../../requests/pharmacy/template-medicine-group.request";
import { GetTemplateMedicineGroupResponse } from "../../responses/pharmacy/get-template-medicine-group.response";
import { ListTemplateMedicineGroupResponse } from "../../responses/pharmacy/list-template-medicine-group.response";



@Injectable({
  providedIn: 'root'
})
export class TemplateMedicineGroupService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listTemplateMedicineGroup(searchText: string, idTemplate: number): Observable<ListTemplateMedicineGroupResponse> {
    let uri = "TemplateMedicineGroup?"
    if (searchText)
      uri = uri + `searchText=${searchText}`;

    if (idTemplate)
      uri = uri + `&idTemplate=${idTemplate}`;

    return this.httpClient.get<ListTemplateMedicineGroupResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public listAllTemplateMedicineGroup(): Observable<ListTemplateMedicineGroupResponse> {
    let uri = "TemplateMedicineGroup/getAll"

    return this.httpClient.get<ListTemplateMedicineGroupResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public getTemplateMedicineGroup(idMedicineGroup:number): Observable<GetTemplateMedicineGroupResponse> {
    let uri = `TemplateMedicineGroup/idMedicineGroup/${idMedicineGroup}`

    return this.httpClient.get<GetTemplateMedicineGroupResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public createTemplateMedicineGroup(request: TemplateMedicineGroupRequest): Observable<ReturnStruct> {
    let uri = `TemplateMedicineGroup`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public updateTemplateMedicineGroup(idMedicineGroup:number, request: TemplateMedicineGroupRequest): Observable<ReturnStruct> {
    let uri = `TemplateMedicineGroup/idMedicineGroup/${idMedicineGroup}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public deleteTemplateMedicineGroup(idMedicineGroup:number): Observable<ReturnStruct> {
    let uri = `TemplateMedicineGroup/idMedicineGroup/${idMedicineGroup}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }
}
