import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateBiService } from 'src/app/shared/services/API/business-intelligence/template-bi.service';

@Component({
  selector: 'app-template-business-intelligence-delete-modal',
  templateUrl: './template-business-intelligence-delete-modal.component.html',
  styleUrls: ['./template-business-intelligence-delete-modal.component.css']
})
export class TemplateBusinessIntelligenceDeleteModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<TemplateBusinessIntelligenceDeleteModalComponent>,
  private templateBiService: TemplateBiService,
  private alertService: AlertService,
) { }

public isLoading:boolean;  

ngOnInit(): void {
  this.isLoading = false;
}

close(){
  this.matDialogRef.close({deleteReport: false}); 
}

clickDelete(){
  
  this.isLoading = true;

  this.templateBiService.delete(this.data.idReport).subscribe((response)=>{
    
    if(response.isError){
      this.alertService.show('Erro', response.errorDescription, AlertType.error);
      this.isLoading = false;
      return;
    }
    
    this.alertService.show('Sucesso', "Relatório excluido com sucesso!", AlertType.success); 
    this.matDialogRef.close({deleteReport: true}); 
  },
  (error)=>{
    console.log(error)
    this.isLoading = false;
    this.alertService.show('Erro inesperado', error, AlertType.error);
  });
}

clickCancel(){
  this.matDialogRef.close({deleteReport: false}); 
}
}

