import { CounselingModel } from "../../models/risk-classification/counseling.model";
import { DiscriminatorModel } from "../../models/risk-classification/discriminator.model";
import { AgeStruct } from "./age.struct";
import { SubDiscriminatorStruct } from "./sub-discriminator.struct";

export class DiscriminatorStruct extends DiscriminatorModel {
    public subDiscriminator: SubDiscriminatorStruct[];
    public minAgeStruct: AgeStruct;
    public maxAgeStruct: AgeStruct;
    public listCounseling: CounselingModel[];
    public idCounseling: number[];
}