<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-profile-modal-list">
            <h2>Deseja adicionar essa trigger nesta Data de Compensação?</h2>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="clickAdd()">
            <div class="body-profile-modal-list">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Trigger</mat-label>
                            <mat-select formControlName="idTriggersProcedureSigtap">
                                <mat-option *ngFor="let item of triggerListSelect"
                                    value="{{item.idTriggersProcedureSigtap}}">{{item.triggersName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-select [preSelectedlist]="listPreselectedProcedure"
                            (selectedValue)="addItemProcedure($event)" [multiple]="true" [url]="urlListProcedure"
                            [idField]="'idProcedureSigtap'" [nameField]="'completeProcedureName'"
                            [labelField]="'Procedimentos Sigtap'"></app-select>
                    </div>
                </div>
            </div>
            <div class="footer-profile-modal-list">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span>Salvar</span>
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button mat-button class="btn-secundary block" (click)="clickCancel()">Cancelar</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>