import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProfileService } from 'src/app/shared/services/API/admin-user/profile.service';
import { InternalReportService } from 'src/app/shared/services/API/business-intelligence/internal-report.service';
import { InternalReport } from 'src/app/shared/services/models/business-intelligence/internal-report.model';
import { ListProfileStruct } from 'src/app/shared/services/structs/admin-user/list-profile.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { InternalReportDeleteModalComponent } from './internal-report-delete-modal/internal-report-delete-modal.component';
import { ReportScheduleModalComponent } from '../report-list/report-schedule-modal/report-schedule-modal.component';

@Component({
  selector: 'app-internal-report-list',
  templateUrl: './internal-report-list.component.html',
  styleUrls: ['./internal-report-list.component.css']
})
export class InternalReportListComponent implements OnInit {

  constructor(private internalReportService: InternalReportService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    public profileService: ProfileService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.business_intelligence;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.business_intelligence_internal_report;

  public listInternalReport: InternalReport[];
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public ordenation: number;
  public idProfile: number;
  public listProfile: ListProfileStruct[];
  public listOrdenation: number[];

  ngOnInit(): void {
    this.isLoading = true;

    this.populateProfileSelect();
    this.search();
  }
  search() {

    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.internalReportService.listInternalReport(this.searchText, this.isActive, this.idProfile, this.ordenation).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listInternalReport = response.listInternalReport;
      this.listOrdenation = response.listOrdenation;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateProfileSelect() {
    this.profileService.listAllProfile().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listProfile = response.listProfile;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  openModal(idInternalReport) {
    const dialogRef = this.dialog.open(InternalReportDeleteModalComponent, {
      data: {
        idInternalReport: idInternalReport
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteInternalReport) {
        this.search();
      }
    });
  }

  scheduleModal(idReport, powerBiReportName) {
    const dialogRef = this.dialog.open(ReportScheduleModalComponent, {
      data: {
        idReport: idReport,
        powerBiReportName: powerBiReportName,
        isInternal: true,
      },
    });
  }
}
