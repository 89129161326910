import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function VerifyDatasetName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        let isValid: boolean = true;
        const regexWhiteSpacePunctuation = /[À-ú]|\s/;

        if (!value) {
            return null;
        }

        if (regexWhiteSpacePunctuation.test(value)) {
            isValid = false;
        }

        else {
            control.setErrors(null);
        }
        return !isValid ? { hasPunctuation: true } : null;
    };
}