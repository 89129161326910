import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { CacheService } from 'src/app/shared/services/API/cache/cache.service';
import { ProjectCacheService } from 'src/app/shared/services/API/cache/project-cache.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProjectModel } from 'src/app/shared/services/models/cache/project.model';
import { CacheRequest } from 'src/app/shared/services/requests/cache/cache.request';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cache-register',
  templateUrl: './cache-register.component.html',
  styleUrls: ['./cache-register.component.css']
})
export class CacheRegisterComponent implements OnInit{
  constructor(private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cacheService: CacheService,
    private projectCacheService: ProjectCacheService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog) { }
    
    public idCache: number;
    public model: FormGroup;
    public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
    public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.caches;
    public isLoading: boolean = true;
    public listProject: ProjectModel[] = [];
    public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";

    ngOnInit(): void {
      this.model = this.formBuilder.group({
        firstChildGroup: this.formBuilder.group({
          idHealthUnit: ['', [Validators.required]],
        }),
        idCache: [null,],
        idProject: ['', [Validators.required]],
        cacheName: ['', [Validators.required]],
        cacheValue: ['', [Validators.required]],
      });
      if (this.activatedRoute.snapshot.paramMap.get('idCache'))
      {
        this.idCache = parseInt(this.activatedRoute.snapshot.paramMap.get('idCache'));
        this.search(this.idCache);
      }
      else
      this.isLoading = false;

      this.populateProjectSelect();
    }

    populateProjectSelect()
    {
      this.projectCacheService.ListProject().subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          if (!response.listProject || response.listProject.length == 0) {
            this.alertService.show('Erro', 'Não existem projetos cadastrados! Cadastre um projeto para cadastrar um cachê.', AlertType.error);
            this.isLoading = false;
            return;
          }

          this.listProject = response.listProject;
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }

    search(idCache: number){
      this.cacheService.GetCache(idCache).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.model.get('firstChildGroup').get("idHealthUnit").setValue(response.cache.idHealthUnit);
          this.model.get("idProject").setValue(response.cache.idProject);
          this.model.get("cacheName").setValue(response.cache.cacheName);
          this.model.get("cacheValue").setValue(response.cache.cacheValue);
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }

    submit()
    {
      if (this.isLoading)
      return;

      if (!this.listProject || this.listProject.length == 0) {
        this.alertService.show('Erro', 'Não existem projetos cadastrados! Cadastre um projeto para cadastrar um cachê.', AlertType.error);
        this.isLoading = false;
        return;
      }

    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    let body: CacheRequest = new CacheRequest();

    body.cacheName = this.model.get("cacheName").value;
    body.cacheValue = this.model.get("cacheValue").value;
    body.idProject = this.model.get("idProject").value;
    body.idHealthUnit = this.model.get('firstChildGroup').get("idHealthUnit").value;

    this.isLoading = true;
    if (this.idCache)
      this.Update(body);
    else
      this.Create(body);
  }

  Create(projectRequest: CacheRequest)
  {
      this.cacheService.CreateCache(projectRequest).subscribe({
        next: response => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          this.alertService.show('Sucesso', 'Projeto criado com sucesso!', AlertType.success);
          this.router.navigate(['/developer/cache-list']);
  
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
  }

  Update(projectRequest: CacheRequest)
  {
    this.cacheService.UpdateCache(projectRequest, this.idCache).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show('Sucesso', 'Cache alterado com sucesso!', AlertType.success);
        this.router.navigate(['/developer/cache-list']);

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
