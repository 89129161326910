import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { GetEmergesResponse } from 'src/app/shared/services/responses/emerges-key/get-emerges.response';
import { ListEmergesResponse } from '../../responses/emerges-key/list-emerges.response';
import { CreateEmergesResponse } from '../../responses/emerges-key/create-emerges.response';
import { EmergesRequest } from '../../requests/emerges-key/emerges.request';

@Injectable({
  providedIn: 'root'
})
export class EmergesService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public listEmerges(searchText: string): Observable<ListEmergesResponse> {
    
    let uri = 'Emerges?'
    
    if(searchText != null)
    uri = uri+ `searchText=${searchText}&`;
    
    return this.httpClient.get<ListEmergesResponse>(environment.urlApiEmergesKey + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getEmerges(idEmerges: number): Observable<GetEmergesResponse>{
    return this.httpClient.get<GetEmergesResponse>(environment.urlApiEmergesKey + `Emerges/idEmerges/${idEmerges}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getAllEmerges(): Observable<ListEmergesResponse> {
    
    return this.httpClient.get<ListEmergesResponse>(environment.urlApiEmergesKey + `Emerges/getALL`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createEmerges(createEmergesRequest: EmergesRequest): Observable<CreateEmergesResponse>{
    return this.httpClient.post<CreateEmergesResponse>(environment.urlApiEmergesKey + `Emerges`, createEmergesRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateEmerges(idEmerges: number, updateEmergesRequest: EmergesRequest): Observable<ReturnStruct>{
    return this.httpClient.put<ReturnStruct>(environment.urlApiEmergesKey + `Emerges/idEmerges/${idEmerges}`, updateEmergesRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public deleteEmerges(idEmerges: number): Observable<ReturnStruct>{
    return this.httpClient.delete<ReturnStruct>(environment.urlApiEmergesKey + `Emerges/idEmerges/${idEmerges}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    