import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { SuspicionService } from 'src/app/shared/services/API/medical-record/suspicion.service';
import { Suspicion } from 'src/app/shared/services/models/medical-record/suspicion.model';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-suspicion-list',
  templateUrl: './suspicion-list.component.html',
  styleUrls: ['./suspicion-list.component.css']
})
export class SuspicionListComponent implements OnInit{

  constructor(
    private alertService: AlertService,
    private suspicionService: SuspicionService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }
  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.suspicion;
  public listSuspicion: Suspicion[] = [];
  public searchText: string;
  public isLoading: boolean;

  ngOnInit(): void {
    this.populateScrean();
    this.isLoading = true;
    this.searchText = null;
  }

  populateScrean(){
    //Listando Suspeitas
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search(){

    if(this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.suspicionService.getAllSuspicion(this.searchText).subscribe({
      next: (response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.listSuspicion = response.suspicion;  
        this.isLoading = false;  
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

}
