import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetCompleteLogBatchResponse } from '../../responses/srvlog/get-complete-log-batch.response';
import { ListLogBatchResponse } from '../../responses/srvlog/list-log-batch.response';

@Injectable({
  providedIn: 'root'
})
export class LogBatchVisualizerService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listLogBatch(searchText: string, idStatus: number, pageIndex: number) : Observable<ListLogBatchResponse> {

    let uri = 'LogBatchVisualizer?'

    if (searchText)
      uri = uri + `searchText=${searchText}`;

    if (idStatus)
      uri = uri + `&idStatus=${idStatus}`;

    if (pageIndex)
      uri = uri + `&pageIndex=${pageIndex}`; 


    return this.httpClient.get<ListLogBatchResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getLog(idLogBatch: number): Observable<GetCompleteLogBatchResponse> {

    let uri = `LogBatchVisualizer/idLogBatch/${idLogBatch}`

    return this.httpClient.get<GetCompleteLogBatchResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
