<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Protocolos de saúde</h1>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/consulting/health-guideline/register"><mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Adicionar novo</a>
        <div class="card-list" *ngIf="listHealthGuideline != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listHealthGuideline">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.healthGuidelineName}}</span>
                            <span class="secundary last-info">{{item.isActive ? "Ativo" : "Inativo"}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Editar" [routerLink] = "['/consulting/health-guideline/register', {idHealthGuideline: item.idHealthGuideline}]">edit</mat-icon> </a>
                            <a><mat-icon aria-hidden="false" aria-label="Excluir" (click)="openModalDelete(item.idHealthGuideline)">delete_forever</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="listHealthGuideline != undefined && listHealthGuideline.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>