import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ProcedureSigtapRequest } from '../../requests/billing/procedure-sigtap.request';
import { ValidateProcedureSigtapRequest } from '../../requests/billing/validate-procedure-sigtap.request';
import { ProcedureSigtapCompetenceResponse } from '../../responses/billing/procedure-sigtap-competence.response';
import { ProcedureSigtapResponse } from '../../responses/billing/procedure-sigtap.response';
import { ValidateProcedureSigtapResponse } from '../../responses/billing/validate-procedure-sigtap.response';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';
import { CompetenceSelectResponse } from '../../responses/billing/competence-select.response';
import { LookupResponse } from '../../responses/billing/billing-lookup.response';

@Injectable({
  providedIn: 'root'
})
export class BillingLookupService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listLookup(): Observable<LookupResponse> {

    let uri = `Lookup`;
    
    return this.httpClient.get<LookupResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
