import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { TemplateMedicineSubgroupRequest } from '../../requests/pharmacy/template-medicine-subgroup.request';
import { ListTemplateSubgroupResponse } from '../../responses/pharmacy/list-template-subgroup.response';
import { TemplateMedicineSubgroupResponse } from '../../structs/pharmacy/template-medicine-subgroup.response';

@Injectable({
    providedIn: 'root'
})
export class TemplateSubgroupService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    list(searchText: string, idTemplate: number): Observable<ListTemplateSubgroupResponse> {
        let uri = `TemplateSubgroup?`
        if (searchText) {
            uri = uri + `subgroupName=${searchText}&`
        }
        if (idTemplate) {
            uri = uri + `idTemplate=${idTemplate}&`
        }
        return this.httpClient.get<ListTemplateSubgroupResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    delete(idMedicineSubgroup: number): Observable<ReturnStruct> {
        let uri = `TemplateSubgroup/idMedicineSubgroup/${idMedicineSubgroup}`
        return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    read(idMedicineSubgroup: number): Observable<TemplateMedicineSubgroupResponse> {
        let uri = `TemplateSubgroup/idMedicineSubgroup/${idMedicineSubgroup}`
        return this.httpClient.get<TemplateMedicineSubgroupResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    create(request: TemplateMedicineSubgroupRequest): Observable<ReturnStruct> {
        let uri = `TemplateSubgroup`
        return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    update(idMedicineSubgroup: number, request: TemplateMedicineSubgroupRequest): Observable<ReturnStruct> {
        let uri = `TemplateSubgroup/idMedicineSubgroup/${idMedicineSubgroup}`
        return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}
