<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-select-room-modal-list">
            <h2>Templates</h2>
        </div>
        <div class="body-select-room-modal-list">
            <div class="body-select-room-modal-list__options">
                <div *ngIf="hideFirstOption" mat-button class="btn-secundary body-select-room-modal-list__options--buttons"
                    (click)="openViewModal(null)">
                    <span>ToLife (Padrão)</span>
                </div>
                <div mat-button class="btn-secundary body-select-room-modal-list__options--buttons"
                    (click)="openViewModal(item.idTemplate)" *ngFor="let item of this.data.listTemplate">
                    <span>{{item.templateName}}</span>
                </div>
            </div>
        </div>
        <button mat-stroked-button class="btn-secundary block"(click)="close()">Fechar</button>
    </div>
</div>