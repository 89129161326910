import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostPutTemplateRequest } from '../../requests/pharmacy/post-put-template.resquest';
import { GetCompleteManipulationTemplateResponse } from '../../responses/pharmacy/get-complete-manipulation-template.response';
import { ListTemplateResponse } from '../../responses/pharmacy/list-template.response';

@Injectable({
  providedIn: 'root'
})
export class TemplateManipulationService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  Get(idTemplate: number): Observable<GetCompleteManipulationTemplateResponse>{
    let uri = `TemplateManipulation/idTemplate/${idTemplate}`;

    return this.httpClient.get<GetCompleteManipulationTemplateResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  GetAll(searchText: string, isActive: boolean): Observable<ListTemplateResponse>{
    let uri = `TemplateManipulation/getAll`;
    
    if(searchText)
      uri = uri + `?searchText=${searchText}`;

    if(isActive != null)
      uri = uri + `?isActive=${isActive}`;

    return this.httpClient.get<ListTemplateResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
  public listAllTemplate(): Observable<ListTemplateResponse> {
    let uri = "TemplateManipulation/getAll"

    return this.httpClient.get<ListTemplateResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Create(request: PostPutTemplateRequest): Observable<ReturnStruct>{
    let uri = `TemplateManipulation`;
  
    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Update(idTemplate: number, request: PostPutTemplateRequest): Observable<ReturnStruct>{
    let uri = `TemplateManipulation/idTemplate/${idTemplate}`;
  
    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Import(idHealthUnit: number, request: PostPutTemplateRequest): Observable<ReturnStruct>{
    let uri = `TemplateImport/idHealthUnit/${idHealthUnit}`;
  
    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Copy(idTemplate: number, request: PostPutTemplateRequest): Observable<ReturnStruct>{
    let uri = `TemplateCopy/idTemplate/${idTemplate}`;
  
    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  Delete(idTemplate: number): Observable<ReturnStruct>{
    let uri = `TemplateManipulation/idTemplate/${idTemplate}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
