import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { TemplateMedicineTypeRequest } from "../../requests/pharmacy/template-medicine-type.request";
import { GetTemplateMedicineTypeResponse } from "../../responses/pharmacy/get-template-medicine-type.response";
import { ListTemplateMedicineTypeResponse } from "../../responses/pharmacy/list-template-medicine-type.response";

@Injectable({
  providedIn: 'root'
})
export class TemplateMedicineTypeService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listTemplateMedicineType(searchText: string, idTemplate: number): Observable<ListTemplateMedicineTypeResponse> {
    let uri = "TemplateMedicineType?"
    if (searchText)
      uri = uri + `searchText=${searchText}`;

    if (idTemplate)
      uri = uri + `&idTemplate=${idTemplate}`;

    return this.httpClient.get<ListTemplateMedicineTypeResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public listAllTemplateMedicineType(): Observable<ListTemplateMedicineTypeResponse> {
    let uri = "TemplateMedicineType/getAll"

    return this.httpClient.get<ListTemplateMedicineTypeResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public getTemplateMedicineType(idMedicineType:number): Observable<GetTemplateMedicineTypeResponse> {
    let uri = `TemplateMedicineType/idMedicineType/${idMedicineType}`

    return this.httpClient.get<GetTemplateMedicineTypeResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public createTemplateMedicineType(request: TemplateMedicineTypeRequest): Observable<ReturnStruct> {
    let uri = `TemplateMedicineType`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public updateTemplateMedicineType(idMedicineType:number, request: TemplateMedicineTypeRequest): Observable<ReturnStruct> {
    let uri = `TemplateMedicineType/idMedicineType/${idMedicineType}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }

  public deleteTemplateMedicineType(idMedicineType:number): Observable<ReturnStruct> {
    let uri = `TemplateMedicineType/idMedicineType/${idMedicineType}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }
}
