<div class="container-generic">
    <app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
    <div class="container-body">
        <a class="back-link" routerLink="/medic/medic-config">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Lista de caches
        </a>
        <h1>Cadastrar Cache</h1>
        <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
        <div class="white-body">
            <form appFormscroll class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do cache</mat-label>
                            <input matInput type="text" formControlName="cacheName">
                            <mat-error *ngIf="model.get('cacheName').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Valor do cache</mat-label>
                            <input matInput type="text" formControlName="cacheValue">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Projeto</mat-label>
                                <mat-select formControlName="idProject">
                                    <mat-option *ngFor="let item of listProject" [value]="item.idProject">
                                        {{item.projectName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <app-select-2 [multiple]="false" [url]="this.urlListHealthUnit"
                            [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" 
                            [formControlField]="'idHealthUnit'">
                        </app-select-2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-stroked-button type="button" routerLink="/developer/cache-list" class="btn-secundary btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>