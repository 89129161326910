import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListStateResponse } from '../../responses/user/list-state.response';

@Injectable({
  providedIn: 'root'
})
export class HealthUnitStateService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public getStateByInitials(stateInitials: string): Observable<ListStateResponse> {

    let uri = `HealthUnitState/stateUf/${stateInitials}`;

    return this.httpClient.get<ListStateResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
    
    