import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ProcedureSigtapRequest } from 'src/app/shared/services/requests/billing/procedure-sigtap.request';
import { ValidateProcedureSigtapRequest } from 'src/app/shared/services/requests/billing/validate-procedure-sigtap.request';
import { ProcedureSigtapStruct } from 'src/app/shared/services/structs/billing/procedure-sigtap.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { ListUserService } from 'src/app/shared/services/API/admin-user/list-user.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserLoginResponse } from 'src/app/shared/services/responses/admin-user/auth.response';
import { CompetenceSelectService } from 'src/app/shared/services/API/billing/competence-select.service';
import { BillingSigtapTriggerModalComponent } from './billing-sigtap-trigger-modal/billing-sigtap-trigger-modal.component';
import { TriggerProcedureService } from 'src/app/shared/services/API/billing/trigger-procedure-sigtap.service';
import { RelationTriggersProcedureSigtapStruct } from 'src/app/shared/services/structs/billing/relation-triggers-procedure-sigtap.struct';
import { RelationStruct, RelationTriggersProcedureSigtapRequest } from 'src/app/shared/services/requests/billing/relation-triggers-procedure-sigtap.request';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-billing-sigtap-register',
  templateUrl: './billing-sigtap-register.component.html',
  styleUrls: ['./billing-sigtap-register.component.css']
})
export class BillingSigtapRegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private competenceSelectService: CompetenceSelectService,
    private alertService: AlertService,
    private utilService: UtilService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private listUserService: ListUserService,
    private authService: AuthService,
    private dialog: MatDialog,
    private triggerProcedureService: TriggerProcedureService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_billing;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public masks: Masks;
  public listErro: string[] = [];
  private fileContentProcedure: string;
  private fileContentCBO: string;
  public procedureSigtapStruct: ProcedureSigtapStruct[] = [];
  public needToValidate: boolean = true;
  public isValidate: number = 0;
  public canSave: boolean = false;
  public isReading: boolean = false;
  public listRelationTriggers: RelationTriggersProcedureSigtapStruct[] = [];
  public idProcedureSigtapCompetence: number;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isLoading = false;

    this.idProcedureSigtapCompetence = parseInt(this.activatedRoute.snapshot.paramMap.get('idProcedureSigtapCompetence'));
    let userLoginResponse: UserLoginResponse = null;
    if (this.idProcedureSigtapCompetence) {
      this.isReading = true;
      this.populate(this.idProcedureSigtapCompetence);
    } else {
      this.isReading = false;
      userLoginResponse = this.authService.getTokenMenu()
    }
    this.model = this.formBuilder.group({
      userName: [{ value: userLoginResponse ? userLoginResponse.userName : '', disabled: true }],
      observation: [{ value: '', disabled: this.isReading }],
      dateCompensation: [{ value: '', disabled: this.isReading }, [Validators.required]],
      fileProcedure: [{ value: '', disabled: this.isReading }, [Validators.required]],
      fileCBO: [{ value: '', disabled: this.isReading }, [Validators.required]],
    });

  }

  submit() {
    if (this.isValidate == 2) {
      this.canSave = false;
      this.validate();
    } else if (this.isValidate == 1) {
      this.save();
    }
  }

  save() {
    if (this.needToValidate || !this.procedureSigtapStruct || this.procedureSigtapStruct.length == 0) {
      this.alertService.show("Erro", "É preciso validar para poder salvar!", AlertType.error);
      this.isLoading = false;
      return;
    }
    if (!this.canSave) {
      this.alertService.show("Erro", "O arquivo contém erros, é necessário corriji-lo e depois validar!", AlertType.error);
      this.isLoading = false;
      return;
    }
    if (this.model.invalid) {
      this.alertService.show("Erro", "Os arquivos e a data de compensação são obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    let month = this.model.get("dateCompensation").value.substring(0,2);
    let year = this.model.get("dateCompensation").value.substring(2);
    let formatedDate = "01/"+month+"/"+ year;

    let body: ProcedureSigtapRequest = new ProcedureSigtapRequest();
    body.procedureSigtapStruct = this.procedureSigtapStruct;
    body.dateCompetence = this.utilService.toISOFormat(formatedDate);
    body.observation = this.model.get("observation").value;
    this.isLoading = true;
    this.competenceSelectService.createProcedures(body).subscribe({next:(response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isLoading = false;
      } else {
        this.alertService.show("Sucesso", "Procedimentos salvo com sucesso", AlertType.success);
        this.router.navigate(['/consulting/billing-sigtap'])
      }
    },
    error:error =>{
      console.log(error);
      this.alertService.show("Erro Inesperado", error, AlertType.error);
      this.isLoading = false;
    }});
  }


  populate(idProcedureSigtapCompetence: number) {
    this.competenceSelectService.getProcedures(idProcedureSigtapCompetence).subscribe({next:(response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      let dataFor = this.utilService.dataAtualFormatada(response.dateCompetence).split("/");
      this.model.get("dateCompensation").setValue(dataFor[1]+"/"+dataFor[2]);
      this.model.get("observation").setValue(response.observation);
      this.getUserName(response.idAdminUser);
      this.procedureSigtapStruct = response.procedureSigtapStruct;
      this.listRelationTriggers = response.relationTriggersStruct ? response.relationTriggersStruct : [];
    },
    error: error =>{
      console.log(error);
      this.alertService.show("Erro Inesperado", error, AlertType.error);
      this.isLoading = false;
    }});
  }


  getUserName(idUser: number) {
    let listUserAdmin = [idUser];
    this.listUserService.listAdminUserByIds(listUserAdmin).subscribe({next:(users) => {
      this.isFirstLoading = false;
      this.isLoading = false;
      if (users.isError) {
        this.alertService.show("Erro", users.errorDescription, AlertType.error);
      }
      this.model.get("userName").setValue(users.listAdminUser[0].adminUserName);
    },
    error: error =>{
      console.log(error);
      this.alertService.show("Erro Inesperado", error, AlertType.error);
      this.isLoading = false;
    }});
  }

  validate() {
    if (this.model.invalid) {
      this.alertService.show("Erro", "Os arquivos e a data de compensação são obrigatórios", AlertType.error);
      return;
    }

    let month = this.model.get("dateCompensation").value.substring(0,2);
    let year = this.model.get("dateCompensation").value.substring(2);
    let formatedDate = "01/"+month+"/"+ year;

    let body: ValidateProcedureSigtapRequest = new ValidateProcedureSigtapRequest();
    body.dateCompetence = this.utilService.toISOFormat(formatedDate);
    body.procedureSigtap = this.fileContentProcedure.split(/\r?\n/);
    body.procedureSigtapCBO = this.fileContentCBO.split(/\r?\n/);
    this.isLoading = true;
    this.competenceSelectService.validateProcedures(body).subscribe({next:(response) => {
      this.isLoading = false;
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }

      if (response.listErro.length > 0) {
        this.alertService.show("Erro", "Os arquivos contém erros, corrija e valide novamente!", AlertType.error);
      } else {
        this.canSave = true;
      }
      this.needToValidate = false;
      this.procedureSigtapStruct = response.procedureSigtapStruct;
      this.listErro = response.listErro;
    },
    error: error =>{
      console.log(error);
      this.alertService.show("Erro Inesperado", error, AlertType.error);
      this.isLoading = false;
    }});
  }

  inputChangeProcedure(event): void {
    this.procedureSigtapStruct = [];
    this.needToValidate = true;
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    let fileReader: FileReader = new FileReader();
    fileReader.onloadend = ((x) => {
      let base64ToString = atob(fileReader.result.toString().split(",")[1]);
      this.fileContentProcedure = base64ToString;
    });
    fileReader.readAsDataURL(this.model.get('fileProcedure').value);
  }

  dateCompensationChange() {
    this.procedureSigtapStruct = [];
    this.needToValidate = true;
  }

  inputChangeCBO(event): void {
    this.procedureSigtapStruct = [];
    this.needToValidate = true;
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    let fileReader: FileReader = new FileReader();
    fileReader.onloadend = ((x) => {
      this.fileContentCBO = fileReader.result.toString();
    });
    fileReader.readAsText(this.model.get('fileCBO').value);
  }

  openModalTrigger(index) {
    let relationTriggers;
    if (index || index == 0) {
      relationTriggers = this.listRelationTriggers[index];
    }
    const dialogRef = this.dialog.open(BillingSigtapTriggerModalComponent, {
      data: {
        idProcedureSigtapCompetence: this.idProcedureSigtapCompetence,
        relationTriggers
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (index || index == 0) {
          this.listRelationTriggers[index] = result.relationTrigger;
        } else {
          this.listRelationTriggers.push(result.relationTrigger);
        }
      }
    });
  }

  deleteTrigger(index: number) {
    this.listRelationTriggers.splice(index, 1);
  }

  saveTriggers() {
    let request: RelationTriggersProcedureSigtapRequest = new RelationTriggersProcedureSigtapRequest();
    request.idProcedureSigtapCompetence = this.idProcedureSigtapCompetence;
    request.listRelation = [];
    this.listRelationTriggers.forEach((c) => {
      let listRelation: RelationStruct[] = [];
      c.procedureSigtapCboStructs.forEach((d) => {
        let relation: RelationStruct = new RelationStruct();
        relation.idTriggersProcedureSigtap = Number(c.idTriggersProcedureSigtap);
        relation.idProcedureSigtap = d.idProcedureSigtap;
        relation.idProcedureSigtapCompetence = this.idProcedureSigtapCompetence;
        listRelation.push(relation);
      });
      request.listRelation = request.listRelation.concat(listRelation);
    });
    this.isLoading = true;
    this.triggerProcedureService.SaveTriggers(request).subscribe((response)=>{
      this.isLoading = false;
      if(response.isError){
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        return;
      }
      this.alertService.show("Sucesso", "Triggers Salvas com sucesso", AlertType.success);
    });
  }
}
