import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BillingDeleteModalComponent } from 'src/app/billing/pages/billing-plan-list/billing-delete-modal/billing-delete-modal.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BillingPlanService } from 'src/app/shared/services/API/billing-by-health-unit/billing-plan.service';

@Component({
  selector: 'app-delete-confirm-modal',
  templateUrl: './delete-confirm-modal.component.html',
  styleUrls: ['./delete-confirm-modal.component.css']
})
export class DeleteConfirmModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BillingDeleteModalComponent>,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
   
  }

  clickDelete(){
    this.matDialogRef.close({ delete: true });
  }

  clickCancel(){
    this.matDialogRef.close({ delete: false });
  }
}
