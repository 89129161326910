import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingPlanService } from 'src/app/shared/services/API/billing-by-health-unit/billing-plan.service';

@Component({
  selector: 'app-billing-delete-modal',
  templateUrl: './billing-delete-modal.component.html',
  styleUrls: ['./billing-delete-modal.component.css']
})
export class BillingDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BillingDeleteModalComponent>,
    private alertService: AlertService,
    private billingPlanService: BillingPlanService,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ deletePlan: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.billingPlanService.deleteBillingPlan(this.data.idBillingPlan).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Plano de faturamento excluido com sucesso!", AlertType.success);
        this.matDialogRef.close({ deletePlan: true });
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ deletePlan: false });
  }
}