<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consulting/self-triage-flowchart">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Relatórios
    </a>
    <h1>Cadastrar Fluxograma</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Dados do fluxograma</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="flowchartName">
                        <mat-error *ngIf="model.get('flowchartName').invalid">Informe o Nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="flowchartDescription">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Idade minima</mat-label>
                        <input matInput type="text" (blur)="validateAge($event.target.value)" formControlName="flowchartMinAge" [mask]="masks.age">
                        <mat-error *ngIf="model.get('flowchartMinAge').invalid">Informe o Nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Idade máxima</mat-label>
                        <input matInput type="text" (blur)="validateAge($event.target.value)" formControlName="flowchartMaxAge" [mask]="masks.age">
                        <mat-error *ngIf="model.get('flowchartMaxAge').invalid">Informe o Nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Perguntas</mat-label>
                        <mat-select formControlName="question">
                            <mat-option [value]="0">Nenhuma</mat-option>
                            <mat-option *ngFor="let item of questions" [value]="item.idQuestion">
                                {{item.statement}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/self-triage-flowchart"
                        class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>