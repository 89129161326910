import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BatchConfigurationVisualizerService } from 'src/app/shared/services/API/srvlog/batch-configuration-visualizer.service';
import { PostPutBatchConfigurationRequest } from 'src/app/shared/services/requests/srv-log/post-put-batch-configuration.request';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-batch-config-register',
  templateUrl: './batch-config-register.component.html',
  styleUrls: ['./batch-config-register.component.css']
})
export class BatchConfigRegisterComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private maskService: MaskService,
    private batchConfigurationVisualizerService: BatchConfigurationVisualizerService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.developer_batch_configuration;

  public model: FormGroup;
  public masks: Masks;
  public isLoading: boolean;
  public isFirstLoading: boolean;

  public idBatchConfig: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      projectName: ['', Validators.required],
      datetimeInit: [null, Validators.required],
      timeCourseHour: [null, Validators.required],
      subscriptions: [[]],
      subscribed: [false]
    });

    this.idBatchConfig = parseInt(this.activatedRoute.snapshot.paramMap.get('idBatchConfiguration'));
    if (this.idBatchConfig)
      this.getBatchConfigData();
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    if (this.idBatchConfig)
      this.update(this.mapRequest());
    else
      this.create(this.mapRequest());
  }

  getBatchConfigData() {
    this.isLoading = true;
    this.batchConfigurationVisualizerService.getBatch(this.idBatchConfig).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.model.get('projectName').setValue(response.batchConfigurationStruct.projectName);
      this.model.get('datetimeInit').setValue(this.dataAtualFormatada(response.batchConfigurationStruct.datetimeInit));
      this.model.get('timeCourseHour').setValue(response.batchConfigurationStruct.timeCourseHour);
      this.model.get('subscriptions').setValue(response.batchConfigurationStruct.subscriptions);
      let subscribed = response.batchConfigurationStruct.subscriptions.includes(this.authService.getTokenMenu().email)
      console.log(subscribed);
      this.model.get('subscribed').setValue(subscribed);
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  mapRequest(): PostPutBatchConfigurationRequest {
    let request: PostPutBatchConfigurationRequest = new PostPutBatchConfigurationRequest();

    request.projectName = this.model.get('projectName').value;
    request.datetimeInit = this.convertToDate(this.model.get('datetimeInit').value);
    request.timeCourseHour = this.model.get('timeCourseHour').value;
    request.subscribe = this.model.get('subscribed').value;

    return request;
  }

  update(request: PostPutBatchConfigurationRequest) {
    this.isLoading = true;
    this.batchConfigurationVisualizerService.updateBatch(request, this.idBatchConfig).subscribe((response) => {
      if (response.isError) {

        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.router.navigate(['/developer/batch-config']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  create(request: PostPutBatchConfigurationRequest) {
    this.isLoading = true;
    this.batchConfigurationVisualizerService.createBatch(request).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.router.navigate(['/developer/batch-config']);
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  convertToDate(dateString) {
    dateString = dateString.replaceAll('/', '').replaceAll(' ', '').replaceAll(':', '').replaceAll(',', '')
    let day = dateString.substring(0, 2);
    let month = dateString.substring(2, 4); // because months in JS start from 0 
    let year = dateString.substring(4, 8);
    let hour = dateString.substring(8, 10);
    let minutes = dateString.substring(10, 12);
    let seconds = dateString.substring(12, 14);

    let dat;
    dat = year + "-" + month + "-" + day + "T" + hour + ":" + minutes + ":" + seconds
    return dat;
  }
  dataAtualFormatada(date: Date) {
    if (date) {
      var data = new Date(date)
      return data.toLocaleString('pt-br');
    }
    return null;
  }
}
