export enum MenuFunctionalityEnum {
    master_user = 1,
    master_profile = 2,
    super_access = 5,
    consulting_template_subgroup = 16,
    consulting_healthUnit = 6,
    log_list = 3,
    device_list = 7,
    log_batch_list = 8,
    consulting_billing = 9,
    business_intelligence_report = 10,
    consulting_protocol = 11,
    developer_batch_configuration = 12,
    consulting_template = 13,
    consulting_template_type = 14,
    consulting_template_group = 15,
    consulting_template_medicine = 17,
    consulting_counseling = 18,
    support_emerges = 19,
    support_integration_config = 23,
    support_key = 20,
    support_log_siga = 31,
    consulting_flowchart = 21,
    consulting_selfTriage_protocol = 22,
    consulting_self_triage_counseling = 24,
    consulting_self_triage_discriminator = 25,
    business_intelligence_internal_report = 29,
    business_intelligence_internal_list_report = 30,
    consulting_notification = 26,
    billing_plan_list = 32,
    billing_config_list = 33,
    consulting_supervision = 35,
    consulting_coordination = 36,
    care_line_priority = 37,
    customer_success_csat_config = 38,
    customer_success_csat_result = 39,
    business_intelligence_template = 40,
    consulting_origin_arrival_type = 42,
    suspicion = 43,
    project = 45,
    caches = 46,
    cleaning = 47,
    datamesh_analytics = 48,
    datamesh_data_products = 57,
    datamesh_data_sets = 59,
    health_guideline = 60,
    batch_monitoring = 61,
    consulting_specialty = 62,
}