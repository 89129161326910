<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Deseja excluir o fluxograma?</h2>
         </div>
         
         <div class="body-modal-list">
            Tem certeza que deseja excluir esse fluxograma? Essa ação é irrevesível.
         </div>
         <div class="footer-modal-list">
            <button mat-button class="btn-primary block" (click)="clickDelete()">
                <span>Sim, excluir</span>
            </button>
            <button mat-button class="btn-secundary block"(click)="clickCancel()">Cancelar</button>
        </div>
    </div>
</div>
