<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/developer/log-list">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Visualização de Log</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Dados:</h1>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Id Log</mat-label>
                        <input matInput type="text" formControlName="idLog" [readonly]="true">
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Unidade de Saúde</mat-label>
                        <input matInput type="text" formControlName="idHealthUnit" [readonly]="true">
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Id Usuário</mat-label>
                        <input matInput type="text" formControlName="idUser" [readonly]="true">
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo</mat-label>
                        <input matInput type="text" formControlName="type" [readonly]="true">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Project Source</mat-label>
                        <input matInput type="text" formControlName="projectSource" [readonly]="true">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Function Source</mat-label>
                        <input matInput type="text" formControlName="functionSource" [readonly]="true">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Data de Inclusão</mat-label>
                        <input matInput type="text" formControlName="datetimeInclusion" [readonly]="true">
                    </mat-form-field>
                </div>
            </div>
        </form>
        <form class="form" [formGroup]="sensitiveDataModel">
            <div class="title-header">
                <h1>Dados Sensíveis:</h1>
            </div>
            <div class="row" *ngIf="isSensistiveDataRevealed">
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Input Parameter</mat-label>
                        <textarea  matInput type="text" formControlName="inputParameter" [readonly]="true"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Mensagem</mat-label>
                        <textarea  matInput type="text" formControlName="message" [readonly]="true"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="!isSensistiveDataRevealed">
                <div class="col-md-2">
                    <a mat-stroked-button type="button" class="btn-secundary btn-block" (click)="openModal()">
                        Revelar
                    </a>
                </div>
            </div>
        </form>
        <div class="title-header">
            <h1>Lista de Acessos aos Dados Sensíveis:</h1>
        </div>
        <div class="card-list" *ngIf="listLogAccess != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listLogAccess">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">User Id - {{item.idUserAdmin}}</span>
                            <span class="secundary-status last-info">{{item.datetimeInclusion}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <a mat-stroked-button type="button" routerLink={{backUrl}} class="btn-secundary btn-block">
                    Voltar
                </a>
            </div>
        </div>
    </div>
</div>