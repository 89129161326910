<app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

<div class="container-body">
    <div class="white-body">
        <div class="title-header">
            <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon> 
            <!-- <h1>{{ 'MASTER.DASHBOARD.TEXT.TITLE' | translate }}</h1> -->
            <h1>Módulo Consultoria</h1>
        </div>
        <!-- <p>{{ 'MASTER.DASHBOARD.TEXT.DESCRIPTION' | translate }}</p> -->
        <p>Bem vindo ao seu módulo de consultoria! Aqui você controla toda a base de unidades de saude.</p>
    </div>
</div>