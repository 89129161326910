<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Projetos</h1>
    <div class="white-body">
        <div class="row">
            <div class="col-6 col-sm-3">
                <a mat-button class="btn-primary add-user" routerLink="/developer/project-register">
                    <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Projeto
                </a>
            </div>
        </div>
        <div class="card-list" *ngIf="listProject != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listProject">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">Nome: {{item.projectName}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/developer/project-register', {idProject: item.idProject}]">
                                    edit</mat-icon>
                            </a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    (click)="DeleteProject(item.idProject)">
                                    delete_forever</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>