import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GroupOriginService } from 'src/app/shared/services/API/medical-record/group-origin.service';
import { OriginAndArrivalTypeService } from 'src/app/shared/services/API/medical-record/origin-and-arrival-type.service';
import { ArrivalTypeModel } from 'src/app/shared/services/models/medical-record/arrival-type.model';
import { OriginModel } from 'src/app/shared/services/models/medical-record/origin.model';
import { GroupOriginRequest } from 'src/app/shared/services/requests/medical-record/group-origin.request';

@Component({
  selector: 'app-origin-group-register',
  templateUrl: './origin-group-register.component.html',
  styleUrls: ['./origin-group-register.component.css']
})
export class OriginGroupRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private groupOriginService: GroupOriginService,
    private originAndArrivalTypeService: OriginAndArrivalTypeService,
  ) { }

  public model: FormGroup;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;

  public isLoading: boolean = false;
  public isUpdate: boolean = false;
  public idGroupOrigin: number;
  public listOrigin: OriginModel[] =[];
  public listArrivalType: ArrivalTypeModel[] = [];

  ngOnInit(): void {
    this.isLoading = true;
    this.getListOriginAndArrivalType();

    this.model = this.formBuilder.group({
      name: ['', [Validators.required]],
      listIdOrigin: ['', [Validators.required]],
      listIdArrivalType: ['', [Validators.required]],
    });

    if (this.activatedRoute.snapshot.paramMap.get('idGroupOrigin'))
      this.idGroupOrigin = parseInt(this.activatedRoute.snapshot.paramMap.get('idGroupOrigin'));

    if (this.idGroupOrigin != null) {
      this.isUpdate = true;
    } else {
      this.isLoading = false;
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      this.isLoading = false;
      return;
    }

    this.isLoading = true;

    let request = new GroupOriginRequest();
    

    request.groupOriginName = this.model.get('name').value;
    request.listIdOrigin = this.model.get('listIdOrigin').value;
    request.listIdArrivalType = this.model.get('listIdArrivalType').value;

    if (this.isUpdate) {
      request.idGroupOrigin = this.idGroupOrigin;
      this.putFavoritePrescription(request);
    }
    else
      this.postFavoritePrescription(request);
  }

  postFavoritePrescription(request: GroupOriginRequest) {
    this.groupOriginService.postGroupOrigin(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/origin-group']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  putFavoritePrescription(request: GroupOriginRequest) {
    this.groupOriginService.putGroupOrigin(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações atualizadas com sucesso", AlertType.success);
        this.isLoading = false;
        this.router.navigate(['/origin-group']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  populate() {
    this.groupOriginService.getGroupOrigin(this.idGroupOrigin).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.model.get('name').setValue(response.groupOrigin.groupOriginName);
        this.model.get('listIdOrigin').setValue(response.listOrigin.map(c => c.idOrigin.toString()));
        this.model.get('listIdArrivalType').setValue(response.listArrivalType.map(c => c.idArrivalType.toString()));
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  getListOriginAndArrivalType() {
    this.originAndArrivalTypeService.getOriginAndArrival().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listOrigin = response.listOriginDefault;
        if(response.listOriginNotDefault  && response.listOriginNotDefault.length > 0) {
          response.listOriginNotDefault.forEach((origin) => {
            let newOrigin = new OriginModel();
            newOrigin.idOrigin = origin.originNotDefault.idOrigin;
            newOrigin.originName = origin.originNotDefault.originName;
            newOrigin.isDeleted = origin.originNotDefault.isDeleted;
            newOrigin.showToEveryone = origin.originNotDefault.showToEveryone;
            newOrigin.dateTimeInclusion = origin.originNotDefault.dateTimeInclusion;
            this.listOrigin.push(newOrigin);
          })
        }

        this.listArrivalType = response.listArrivalTypeDefault;
        if(response.listArrivalTypeNotDefault  && response.listArrivalTypeNotDefault.length > 0) {
          response.listArrivalTypeNotDefault.forEach((arrivalType) => {
            let newArrivalType = new ArrivalTypeModel();
            newArrivalType.idArrivalType = arrivalType.arrivalTypeNotDefault.idArrivalType;
            newArrivalType.arrivalTypeName = arrivalType.arrivalTypeNotDefault.arrivalTypeName;
            newArrivalType.isDeleted = arrivalType.arrivalTypeNotDefault.isDeleted;
            newArrivalType.showToEveryone = arrivalType.arrivalTypeNotDefault.showToEveryone;
            newArrivalType.dateTimeInclusion = arrivalType.arrivalTypeNotDefault.dateTimeInclusion;
            this.listArrivalType.push(newArrivalType);
          })
        }
       
        if(this.isUpdate)
          this.populate();

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

}