import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterSelectModalComponent } from './filter-select-modal/filter-select-modal.component';
import { FilterSelectModel } from './filter-select.model';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.css']
})
export class FilterSelectComponent implements OnInit {

  @Input() public filterSelectModel:FilterSelectModel;
  @Input() public name:string;
  @Output() seachEvent = new EventEmitter<any>();

  public selected:boolean
  public lenght:number;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.verifySelected();
  }

  verifySelected(){
    this.selected = this.filterSelectModel.itemSelectModel.find(c=> c.checked == true) !== undefined;
    
    this.lenght = 0;

    if(this.selected)
      this.lenght = this.filterSelectModel.itemSelectModel.filter(c=> c.checked == true).length;
  }

  opemModal(){
    const dialogRef = this.dialog.open(FilterSelectModalComponent, {
      data: {
        filterSelectModel: this.filterSelectModel,
        name: this.name
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.searchAction){
        this.verifySelected();
        this.seachEvent.emit();
      }
    });
  }

}
