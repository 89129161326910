import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TemplateBiService } from 'src/app/shared/services/API/business-intelligence/template-bi.service';

@Component({
  selector: 'app-template-bi-confirm-modal',
  templateUrl: './template-bi-confirm-modal.component.html',
  styleUrls: ['./template-bi-confirm-modal.component.css']
})
export class TemplateBiConfirmModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<TemplateBiConfirmModalComponent>,
  private templateBiService: TemplateBiService,
  private alertService: AlertService,
) { }

public isLoading:boolean;  

ngOnInit(): void {
  this.isLoading = false;
}

close(){
  this.matDialogRef.close(); 
}
}

