<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/developer/log-batch-monitoring">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Projetos
    </a>
    <h1>{{batchConfig ? title + ' - ' + batchConfig.projectName : title}}</h1>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="getProjectLogs()">
            <div class="row filters">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Selecione um intervalo</mat-label>
                        <mat-date-range-input [formGroup]="model" [rangePicker]="picker">
                            <input matInput formControlName="datetimeStart" matStartDate placeholder="Data inicial">
                            <input matInput formControlName="datetimeEnd" matEndDate placeholder="Data final">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" formControlName="searchText">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status">
                            <mat-option ngValue="null">Todos</mat-option>
                            <mat-option *ngFor="let item of listLogBatchStatus" value="{{item.idLogBatchStatus}}">
                                {{item.logBatchStatusName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Pesquisar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-4 col-md-4">
                    <ngx-charts-bar-horizontal *ngIf="renderGraph" (select)="graphClickListener($event)"
                        [showGridLines]="false" [showDataLabel]="statusGraphOptions.showDataLabel"
                        [view]="statusGraphOptions.view" [scheme]="statusGraphOptions.colorScheme"
                        [results]="statusResults" [gradient]="statusGraphOptions.gradient"
                        [xAxis]="statusGraphOptions.showXAxis" [yAxis]="statusGraphOptions.showYAxis"
                        [legend]="statusGraphOptions.showLegend" [showXAxisLabel]="statusGraphOptions.showXAxisLabel"
                        [showYAxisLabel]="statusGraphOptions.showYAxisLabel"
                        [legendTitle]="statusGraphOptions.legendTitle" [xAxisLabel]="statusGraphOptions.xAxisLabel"
                        [yAxisLabel]="statusGraphOptions.yAxisLabel">
                    </ngx-charts-bar-horizontal>
                    <app-loading-list *ngIf="!renderGraph"></app-loading-list>
                </div>
                <div class="col-12 col-sm-8 col-md-8">
                    <div class="choice-results">
                        <button type="button" [ngClass]="{'btn-focus': (graphControl == graphControlHistogram)}"
                            (click)="graphSwitch(graphControlHistogram)">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">insert_chart_outlined</mat-icon>
                        </button>
                        <button type="button" [ngClass]="{'btn-focus': (graphControl == graphControlHistory)}"
                            (click)="graphSwitch(graphControlHistory)">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">history</mat-icon>
                        </button>
                    </div>
                    <ngx-charts-bar-vertical *ngIf="renderGraph && graphControl == graphControlHistogram"
                        (select)="graphClickListener($event)" [showGridLines]="true"
                        [showDataLabel]="performanceGraphOptions.showDataLabel" [view]="performanceGraphOptions.view"
                        [scheme]="performanceGraphOptions.colorScheme" [results]="performanceHistogramResults"
                        [gradient]="performanceGraphOptions.gradient" [xAxis]="performanceGraphOptions.showXAxis"
                        [yAxis]="performanceGraphOptions.showYAxis" [legend]="performanceGraphOptions.showLegend"
                        [showXAxisLabel]="performanceGraphOptions.showXAxisLabel"
                        [showYAxisLabel]="performanceGraphOptions.showYAxisLabel"
                        [legendTitle]="performanceGraphOptions.legendTitle"
                        [xAxisLabel]="performanceGraphOptions.xAxisLabel"
                        [yAxisLabel]="performanceGraphOptions.yAxisLabel">
                    </ngx-charts-bar-vertical>
                    <ngx-charts-bar-vertical class="history" *ngIf="renderGraph && graphControl == graphControlHistory"
                        (select)="graphClickListener($event)" [showGridLines]="true"
                        [showDataLabel]="historyGraphOptions.showDataLabel" [view]="historyGraphOptions.view"
                        [scheme]="historyGraphOptions.colorScheme" [results]="performanceHistoryResults"
                        [gradient]="historyGraphOptions.gradient" [xAxis]="historyGraphOptions.showXAxis"
                        [yAxis]="historyGraphOptions.showYAxis" [legend]="historyGraphOptions.showLegend"
                        [showXAxisLabel]="historyGraphOptions.showXAxisLabel"
                        [showYAxisLabel]="historyGraphOptions.showYAxisLabel"
                        [legendTitle]="historyGraphOptions.legendTitle" [xAxisLabel]="historyGraphOptions.xAxisLabel"
                        [yAxisLabel]="historyGraphOptions.yAxisLabel" [rotateXAxisTicks]="true">
                    </ngx-charts-bar-vertical>
                    <!-- <ngx-charts-line-chart *ngIf="renderGraph && graphControl == graphControlHistory"
                        [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="'Data'"
                        [yAxisLabel]="'Tempo de execução'" [xAxis]="true" [yAxis]="true"
                        (select)="graphClickListener($event)" [view]="historyGraphOptions.view" [timeline]="true"
                        [results]="performanceHistoryLineChartResults" (select)="onSelect($event)">
                    </ngx-charts-line-chart> -->
                    <app-loading-list *ngIf="!renderGraph"></app-loading-list>
                </div>
            </div>
            <h2>Lista de logs</h2>
            <div class="card-list" *ngIf="batchMonitoringResponse && batchMonitoringResponse.listLogBatch">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of batchMonitoringResponse.listLogBatch">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary">{{item.idLogBatch}} - {{item.nameProject.split('Worker.')[1]}}
                                </span>
                                <span class="secundary">{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                                <span class="secundary">{{item.datetimeFinish | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Visualizar"
                                        (click)="openDetailsModal(item)">
                                        visibility
                                    </mat-icon>
                                </a>
                            </div>
                            <app-chip-batch-status [status]="item.idLogBatchStatus"></app-chip-batch-status>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-paginator [length]="this.fullListSize" #paginator [pageIndex]="0" [pageSize]="30"
                        aria-label="Select page" (page)="changePage($event)">
                    </mat-paginator>
                </div>
            </div>
        </form>
    </div>
</div>