export class FilterSelectModel{
    constructor(){
        this.itemSelectModel = new Array();
    }
    public itemSelectModel:ItemSelectModel[];
}

export class ItemSelectModel{
    constructor(key:string, value:string, checked:boolean){
     this.key = key;
     this.value = value;
     this.checked = checked;
    }

    public key:string;
    public value:string;
    public checked:boolean;
}