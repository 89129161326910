import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { BillingPlanRequest } from '../../requests/billing-by-health-unit/billing-plan.request';
import { GetBillingPlanResponse } from '../../responses/billing-by-health-unit/get-billing-plan.response';
import { ListBillingPlanResponse } from '../../responses/billing-by-health-unit/list-billing-plan.response';

@Injectable({
  providedIn: 'root'
})
export class BillingPlanService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listBillingPlan(searchText: string, isActive: boolean): Observable<ListBillingPlanResponse> {
    let uri = `BillingPlan?`

    if (searchText)
      uri = uri + `searchText=${searchText}&`
    if (isActive)
      uri = uri + `isActive=${isActive}`;

    return this.httpClient.get<ListBillingPlanResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  listBillingPlanActive(isActive: boolean): Observable<ListBillingPlanResponse> {
    let uri = `BillingPlan/isActive/${isActive}`

    return this.httpClient.get<ListBillingPlanResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getBillingPlan(idBillingPlan: number): Observable<GetBillingPlanResponse> {
    let uri = `BillingPlan/idBillingPlan/${idBillingPlan}`

    return this.httpClient.get<GetBillingPlanResponse>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  postBillingPlan(body: BillingPlanRequest): Observable<ReturnStruct> {
    let uri = `BillingPlan`

    return this.httpClient.post<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  updateBillingPlan(idBillingPlan: number, body: BillingPlanRequest): Observable<ReturnStruct> {
    let uri = `BillingPlan/idBillingPlan/${idBillingPlan}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteBillingPlan(idBillingPlan: number): Observable<ReturnStruct> {
    let uri = `BillingPlan/idBillingPlan/${idBillingPlan}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiBillingByHealthUnit + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}