<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Notificação</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Disparo de notificações</h1>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <mat-form-field appearance="outline">
                            <mat-label>Título</mat-label>
                            <input matInput type="text" formControlName="messageTitle">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="message-container">
                            <mat-form-field appearance="outline">
                                <mat-label>Mensagem</mat-label>
                                <textarea matInput type="text" formControlName="messageContent"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <app-select-2 [multiple]="true" [optionAll]="true" [allOptionText]="'TODAS AS UNIDADES'" [url]="this.urlListHealthUnit"
                            [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" 
                            [formControlField]="'listIdHealthUnit'">
                        </app-select-2>
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>Unidades de saúde</mat-label>
                            <mat-select multiple formControlName="listIdHealthUnit">
                                <mat-checkbox (click)="selectAllHealthUnits()">
                                    Todas as unidades
                                </mat-checkbox>
                                <mat-option *ngFor="let item of listHealthUnit" [value]="item.idHealthUnit"
                                    [disabled]="isAllHealthUnits">
                                    {{item.healthUnitName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline">
                            <mat-label>Profissões</mat-label>
                            <mat-select multiple formControlName="listIdProfession">
                                <mat-checkbox (click)="selectAllProfessions()">
                                    Todas as profissões
                                </mat-checkbox>
                                <mat-option *ngFor="let item of listProfession" [value]="item.idProfession"
                                    [disabled]="isAllProfessions">
                                    {{item.professionName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
        <div class="title-header">
            <h1>Histórico de notificações</h1>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingHistory == true"></mat-spinner>
        </div>
        <form class="form" [formGroup]="historyModel" (ngSubmit)="populateHistory()">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Selecione um intervalo</mat-label>
                        <mat-date-range-input [formGroup]="historyModel" [rangePicker]="picker">
                            <input matInput formControlName="datetimeStart" matStartDate placeholder="Data inicial">
                            <input matInput formControlName="datetimeEnd" matEndDate placeholder="Data final">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Pesquisar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                        </mat-spinner>
                    </button>
                </div>
            </div>
        </form>
        <div *ngIf="!isLoading" class="row">
            <app-notification-accordion [messageHistory]="messageHistory">
            </app-notification-accordion>
        </div>
    </div>
</div>