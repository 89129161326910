import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-generic-alert-modal',
  templateUrl: './generic-alert-modal.component.html',
  styleUrls: ['./generic-alert-modal.component.css']
})
export class GenericAlertModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<GenericAlertModalComponent>,
    public sanitizer: DomSanitizer,
  ) { }

  public body: string = "";  
  ngOnInit(): void {
    this.body = this.data.body;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
}