import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DataProductService } from 'src/app/shared/services/API/data-mesh/data-product.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { DataProductModel } from 'src/app/shared/services/models/data-mesh/data-product.model';
import { ListProfileStruct } from 'src/app/shared/services/structs/admin-user/list-profile.struct';

@Component({
  selector: 'app-data-product-list',
  templateUrl: './data-product-list.component.html',
  styleUrls: ['./data-product-list.component.css']
})

export class DataProductListComponent implements OnInit {
  constructor(
    private alertService: AlertService,
    private dataProductService: DataProductService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }


  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.datamesh;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.datamesh_data_products;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public searchText: string;
  public listDataProduct: DataProductModel[] = [];
  public listProfile: ListProfileStruct[] = [];
  public masks: Masks;

  ngOnInit(): void {
    this.populateScreen();
    this.listDataProduct == null;
    this.isLoading = true;
    this.searchText = null;
  }

  populateScreen() {
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search() {
    if (this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.dataProductService.getAllDataProducts().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Error', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listDataProduct = response.dataProducts;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Unexpected Error', error, AlertType.error);
      });
  }

  deleteDataProduct(idDataProduct: number) {
    this.isLoading = true;
    this.dataProductService.deleteDataProduct(idDataProduct).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.search();
        this.alertService.show('Sucesso!', 'Produto excluído com sucesso', AlertType.success);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}