import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { ListBiHealthUnitResponse } from "../../responses/business-intelligence/list-bi-health-unit.response";
import { ListSchedulePowerBiResponse } from "../../responses/business-intelligence/list-schedule-power-bi.response";
import { ListSchedulePowerBiRequest } from "../../requests/business-intelligence/list-schedule-power-bi.request";

@Injectable({
  providedIn: 'root'
})
export class SchedulePowerBiService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public getScheduleList(idReport: number, isInternal: boolean = false): Observable<ListSchedulePowerBiResponse> {

    let uri = `SchedulePowerBi/getScheduleList/idReport/${idReport}/isInternal/${isInternal}`

    return this.httpClient.get<ListSchedulePowerBiResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateScheduleList(body: ListSchedulePowerBiRequest): Observable<ReturnStruct> {

    let uri = `SchedulePowerBi/updateSchedule`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiBusinessIntelligence + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}
