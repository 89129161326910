export class GraphOptions {
    constructor(
        showDataLabel: boolean,
        legend: boolean,
        legendPosition: string,
        showXAxis: boolean,
        showYAxis: boolean,
        gradient: boolean,
        showLegend: boolean,
        showXAxisLabel: boolean,
        showYAxisLabel: boolean,
        legendTitle: string,
        xAxisLabel: string,
        yAxisLabel: string,
        yScaleMax: number,
        yScaleMin: number,
        colorScheme: object,
        view: number[],
        xAxisTicks: any[] = [],
        xAxisTickFormat: any = null,
    ) {
        this.showDataLabel = showDataLabel;
        this.legend = legend;
        this.legendPosition = legendPosition;
        this.showXAxis = showXAxis;
        this.showYAxis = showYAxis;
        this.gradient = gradient;
        this.showLegend = showLegend;
        this.showXAxisLabel = showXAxisLabel;
        this.showYAxisLabel = showYAxisLabel;
        this.legendTitle = legendTitle;
        this.xAxisLabel = xAxisLabel;
        this.yAxisLabel = yAxisLabel;
        this.xAxisTicks = xAxisTicks;
        this.yScaleMax = yScaleMax;
        this.yScaleMin = yScaleMin;
        this.colorScheme = colorScheme;
        this.view = view;
        this.xAxisTickFormat = xAxisTickFormat;
    }
    public showDataLabel: boolean;
    public legend: boolean;
    public legendPosition: string;
    public showXAxis: boolean;
    public showYAxis: boolean;
    public gradient: boolean;
    public showLegend: boolean;
    public showXAxisLabel: boolean;
    public showYAxisLabel: boolean;
    public legendTitle: string;
    public xAxisLabel: string;
    public yAxisLabel: string;
    public yScaleMax: number;
    public yScaleMin: number;
    public colorScheme: object;
    public view: number[];
    public xAxisTicks: any[];
    public xAxisTickFormat: any;
}