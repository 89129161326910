import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SupervisionService } from 'src/app/shared/services/API/billing/supervision.service';

@Component({
  selector: 'app-supervision-delete-modal',
  templateUrl: './supervision-delete-modal.component.html',
  styleUrls: ['./supervision-delete-modal.component.css']
})
export class SupervisionDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<SupervisionDeleteModalComponent>,
    private alertService: AlertService,
    private supervisionService: SupervisionService
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteSupervision: false}); 
  }
  
  clickDelete(){
    
    this.isLoading = true;

    this.supervisionService.Delete(this.data.idSupervision).subscribe({
      next: (response)=>{      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }                
        this.alertService.show('Sucesso', "Coordenadoria excluída com sucesso!", AlertType.success); 
        this.matDialogRef.close({deleteSupervision: true}); 
    },
    error: (error)=>{
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }
  });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteSupervision: false}); 
  }
}