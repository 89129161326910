<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>Protocolos da Autotriagem</h1>
    <div class="white-body">
        <a mat-button class="btn-primary add-user" routerLink="/consulting/self-triage-protocol/register">
            <mat-icon aria-hidden="false" aria-label="Plus">add</mat-icon> Cadastrar Protocolo
        </a>
        <div class="filters">
            <div class="row">
                <div class="col-12 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-icon matPrefix class="my-icon">search</mat-icon>
                        <mat-label>Pesquisar</mat-label>
                        <input matInput type="text" id="searchText" name="searchText" [(ngModel)]="searchText"
                            (keyup)="onKeySearch($event)">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="card-list" *ngIf="protocolStruct != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of protocolStruct">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.protocolName}}</span>
                            <span class="secundary">{{item.datetimeInclusion | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                            <span class="secundary">{{item.protocolDescription}}</span>
                        </div>
                        <div class="actions">
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar"
                                    [routerLink]="['/consulting/self-triage-protocol/register', {idProtocol: item.idProtocol}]">
                                    edit
                                </mat-icon>
                            </a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Editar" (click)="openModal(item.idProtocol)">
                                    delete_forever</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-empty-list *ngIf="protocolStruct != undefined && protocolStruct.length == 0"></app-empty-list>
    </div>

    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>