import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericAlertModalComponent } from 'src/app/shared/components/generic-alert-modal/generic-alert-modal.component';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GroupOriginOrchestratorUserService } from 'src/app/shared/services/API/orchestrator-user/group-origin-orchestrator-user.service';

@Component({
  selector: 'app-origin-group-delete-modal',
  templateUrl: './origin-group-delete-modal.component.html',
  styleUrls: ['./origin-group-delete-modal.component.css']
})
export class OriginGroupDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<OriginGroupDeleteModalComponent>,
    private GroupOriginOrchestratorUserService: GroupOriginOrchestratorUserService,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;

  ngOnInit(): void {
    this.isLoading = false;
  }

  close() {
    this.matDialogRef.close({ isDeleted: false });
  }

  clickDelete() {
    this.isLoading = true;

    this.GroupOriginOrchestratorUserService.deleteGroupOrigin(this.data.idGroupOrigin).subscribe({
      next: (response) => {
        if (response.isError && response.errorCode == 1) {
          this.openGenericErrorMessageModal(response.errorDescription);
          this.isLoading = false;
          return;
      }
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Grupo excluído com sucesso!", AlertType.success);
        this.matDialogRef.close({ isDeleted: true });
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  clickCancel() {
    this.matDialogRef.close({ isDeleted: false });
  }

  openGenericErrorMessageModal(body: string){
    this.isLoading = true;
    const dialogRef = this.dialog.open(GenericAlertModalComponent, {
      data: {
        body: body
      },
      panelClass: 'generic-error',
    });
    
    dialogRef.afterClosed().subscribe(result => {
        this.isLoading = false;
    });
}
}