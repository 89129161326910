import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MasterRoutingModule } from './support-routing.module';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { SupportComponent } from './pages/support/support.component';
import { SuperAccessListComponent } from './pages/super-access-list/super-access-list.component';
import { SuperAccessModalComponent } from './pages/super-access-list/super-access-modal/super-access-modal.component';
import { SuperAccessRegisterComponent } from './pages/super-access-register/super-access-register.component';
import { DeviceListComponent } from './pages/device-list/device-list.component';
import { EmergesListComponent } from './pages/emerges-list/emerges-list.component';
import { EmergesDeleteModalComponent } from './pages/emerges-list/emerges-delete-modal/emerges-delete-modal.component';
import { EmergesRegisterComponent } from './pages/emerges-register/emerges-register.component';
import { IntegrationRelationConfigListComponent } from './pages/integration-relation-config-list/integration-relation-config-list.component';
import { RelationConfigDeleteModalComponent } from './pages/integration-relation-config-list/relation-config-delete-modal/relation-config-delete-modal.component';
import { IntegrationRelationConfigRegisterComponent } from './pages/integration-relation-config-register/integration-relation-config-register.component';
import { RelationsComponent } from './pages/integration-relation-config-register/relations/relations.component';
import { KeyListComponent } from './pages/key-list/key-list.component';
import { KeyDeleteModalComponent } from './pages/key-list/key-delete-modal/key-delete-modal.component';
import { KeyRegisterComponent } from './pages/key-register/key-register.component';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetailsComponent } from './pages/device-details/device-details.component';
import { NgChartsModule } from 'ng2-charts';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { LogSigaVisualizerListComponent } from './pages/log-siga-visualizer-list/log-siga-visualizer-list.component';
import { LogSigaVisualizerConfirmationModalComponent } from './pages/log-siga-visualizer-view/log-siga-visualizer-confirmation-modal/log-siga-visualizer-confirmation-modal.component';
import { LogSigaVisualizerViewComponent } from './pages/log-siga-visualizer-view/log-siga-visualizer-view.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SupportChannelsComponent } from './pages/support-channels/support-channels.component';


export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    SupportComponent,
    SuperAccessListComponent,
    SuperAccessModalComponent,
    SuperAccessRegisterComponent,
    DeviceListComponent,
    DeviceDetailsComponent,
    EmergesListComponent,
    EmergesDeleteModalComponent,
    EmergesRegisterComponent,
    IntegrationRelationConfigListComponent,
    RelationConfigDeleteModalComponent,
    IntegrationRelationConfigRegisterComponent,
    RelationsComponent,
    KeyListComponent,
    KeyDeleteModalComponent,
    KeyRegisterComponent,
    LogSigaVisualizerListComponent,
    LogSigaVisualizerViewComponent,
    LogSigaVisualizerConfirmationModalComponent,
    SupportChannelsComponent,
  ],
  imports: [
    MatDialogModule,
    CommonModule,
    MasterRoutingModule,
    SharedModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatTreeModule,
    ClipboardModule,
    MatToolbarModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgxMaskModule.forRoot(),
    NgChartsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatPaginatorModule,
    NgxMatFileInputModule,
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ],
})
export class SupportModule { }
