import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { CacheService } from 'src/app/shared/services/API/cache/cache.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CacheModel } from 'src/app/shared/services/models/cache/cache.model';
import { CacheStruct } from 'src/app/shared/services/structs/cache/cache.struct';

@Component({
  selector: 'app-cache-list',
  templateUrl: './cache-list.component.html',
  styleUrls: ['./cache-list.component.css']
})
export class CacheListComponent implements OnInit{
  constructor(private cacheService: CacheService,
    private alertService: AlertService,
    private dialog: MatDialog) { }
    public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
    public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.project;
    public isLoading: boolean;
    public listCache: CacheStruct[] = [];
    
    ngOnInit(): void {
      this.isLoading = true;
      this.populateCard();
    }

    populateCard()
    {
      this.isLoading = true;
      this.cacheService.ListCache().subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.listCache = response.listCache;
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }

    deleteCache(idCache: number) {
      this.isLoading = true;

      this.cacheService.Delete(idCache).subscribe({
        next: response => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          this.alertService.show('Sucesso', 'Cache deletado com sucesso!', AlertType.success);
          this.populateCard();
  
        },
        error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
      });
    }
}
