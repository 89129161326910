import { Component, OnInit } from '@angular/core';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.css']
})
export class DeveloperComponent implements OnInit {

  constructor() { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;

  ngOnInit(): void {
  }

}
