import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { IntegrationRelationConfigService } from 'src/app/shared/services/API/integration-relation-config/integration-relation-config.service';
import { LookupIntegrationConfigService } from 'src/app/shared/services/API/integration-relation-config/lookup-integration-config.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { IntegrationTypeModel } from 'src/app/shared/services/models/integration-relation-config/integration-type.model';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { HealthUnitRelationStruct } from 'src/app/shared/services/structs/integration-relation-config/health-unit-relation.struct';
import { RelationConfigDeleteModalComponent } from './relation-config-delete-modal/relation-config-delete-modal.component';
import { NavigationExtras, Router } from '@angular/router';
import { ListHealthUnitService } from 'src/app/shared/services/API/user/list-health-unit.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-integration-relation-config-list',
  templateUrl: './integration-relation-config-list.component.html',
  styleUrls: ['./integration-relation-config-list.component.css']
})
export class IntegrationRelationConfigListComponent implements OnInit {

  constructor(private integrationRelationConfigService: IntegrationRelationConfigService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private listHealthUnitService: ListHealthUnitService,
    private lookupService: LookupIntegrationConfigService,
    private router: Router
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.support_integration_config;

  public listRelationConfig: HealthUnitRelationStruct[];
  public isLoading: boolean;
  public idIntegrationType: number;
  public idHealthUnit: number;
  public listHealthUnit: ListHealthUnitStruct[];
  public listIntegrationType: IntegrationTypeModel[];
  public listIdHealthUnit: number[] = [];
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";


  ngOnInit(): void {
    this.isLoading = true;
    this.populateLookUpSelect();
    this.populateHealthUnitSelect(null);
  }

  search() {
    this.isLoading = true;
    this.integrationRelationConfigService.ListWithHealthUnitList(this.listIdHealthUnit, this.idIntegrationType).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listRelationConfig = response.listHealthUnitRelation;
        this.listRelationConfig.forEach((c) => {
          let health = this.listHealthUnit.find(x => c.idHealthUnit == x.idHealthUnit);
          c.healthUnitName = health ? health.healthUnitName : "Unidade inativa";
        });
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateLookUpSelect() {
    this.lookupService.List().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listIntegrationType = response.integrationTypes;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateHealthUnitSelect(searchText: string) {

    if(searchText == "")
      searchText = null;

      this.listHealthUnitService.listHealthUnit(searchText, null).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listHealthUnit = response.list;
        this.search();
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openModal(idRelationConfig) {
    const dialogRef = this.dialog.open(RelationConfigDeleteModalComponent, {
      data: { idRelationConfig },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.deleteRelationConfig)
          this.search();
      }
    });
  }

  navigateToRegister(idHealthUnitRelation: string) {
    const encodedId = this.getEncodedId(idHealthUnitRelation);
    const url = '/support/relation-config/register?idHealthUnitRelation=' + encodedId;

    this.router.navigateByUrl(url, { skipLocationChange: true });
  }

  getEncodedId(id: string): string {
    return encodeURIComponent(id);
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.populateHealthUnitSelect(event);
      }
    }, 1000);
  }

  selectHealthUnit(event){
    this.listIdHealthUnit = event.map(x => x.idHealthUnit);
    this.search();
  }
}