import { Component, OnInit,Input, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LogSigaVisualizerService } from 'src/app/shared/services/API/integration-siga/log-siga-visualizer.service';
import { LookupSigaService } from 'src/app/shared/services/API/integration-siga/lookup-siga.service';
import { LogRequestType } from 'src/app/shared/services/models/integration-siga/log-request-type-siga.model';
import { ListLogRequestsStruct } from 'src/app/shared/services/structs/integration-siga/list-log-siga.struct';

@Component({
  selector: 'app-log-siga-visualizer-list',
  templateUrl: './log-siga-visualizer-list.component.html',
  styleUrls: ['./log-siga-visualizer-list.component.css']
})
export class LogSigaVisualizerListComponent implements OnInit {

  constructor(private alertService: AlertService,
    private logRequestsVisualizerService: LogSigaVisualizerService,
    private lookupSigaService: LookupSigaService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.support_log_siga;

  public isLoading: boolean;

  public idLog: number;
  public idLogRequestType: number;
  public searchText: string;
  public idRequestType: number;
  public beginDate: Date;
  public endDate: Date;

  public listLogs: ListLogRequestsStruct[];
  public listLogRequestType: LogRequestType[];
  public fullListSize: number;
  public isPanelMode: boolean;

  ngOnInit(): void {
    this.populateRequestTypeSelect();
    this.search(0);
  }

  search(pageIndex: number) {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    if (this.beginDate)
      this.beginDate.setHours(0);

    if (this.endDate) {
      this.endDate.setHours(23);
      this.endDate.setMinutes(59);
    }

    this.logRequestsVisualizerService.listLog(this.idLog, this.idRequestType,this.idLogRequestType, this.searchText, this.beginDate, this.endDate, pageIndex).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.fullListSize = response.fullListSize;
      this.listLogs = response.listLogs;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  populateRequestTypeSelect() {
    this.lookupSigaService.listLookup().subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listLogRequestType = response.listLogRequestType;
      this.isLoading = false;
    },
    error:(error) => {
      console.log(error)
      this.isLoading = false;
      this.alertService.show('Erro inesperado', error, AlertType.error);
    }});
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  buttonSearch() {
    if (this.isLoading)
      return;

    this.paginator.firstPage();
    this.search(0);
  }

  changeMode(event: any) {
    if (event.checked) {
      var $this = this;
      var interval = setInterval(function () {
        $this.search(0);
        if (!$this.isPanelMode) clearInterval(interval);
      }, 10000);
    }
  }
}