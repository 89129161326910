import { Component, Input, OnInit } from "@angular/core";


@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.css'],
})
export class GaugeChartComponent implements OnInit {

  constructor() { }

  @Input() value: number;
  @Input() colorValue: string;

  public gaugeElement: HTMLElement;

  ngOnInit(): void {
    document.documentElement.style.setProperty('--colorValue', this.colorValue);
    this.gaugeElement = document.querySelector(".gauge");
    this.setGaugeValue(this.gaugeElement, (this.value/100));
  }

  setGaugeValue(gauge, value) {

    if (value < 0 || value > 1) {
      return;
    }

    gauge.querySelector(".gauge-fill").style.transform = `rotate(${
      -(value/2)
    }turn)`;
  }

}
