<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="report-container">
            <div class="white-body">
                <div *ngIf="!isFirstLoading" class="container-powerbi">
                    <div id="reportDiv" class="div-report" #reportDiv></div>
                </div>
            </div>
        </div>
    </div>
</div>