<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consultation/health-unit">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Cadastrar Unidade de Saúde</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Dados da unidade</h1>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome Unidade</mat-label>
                        <input matInput type="text" formControlName="healthUnitName">
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Fuso Horário</mat-label>
                        <mat-select formControlName="idTimeZone">
                            <mat-option *ngFor="let item of listTimeZone" value="{{item.idTimeZone}}">
                                {{item.timeZoneName}} <span style="font-weight: bold;">{{item.timeZoneValue}}
                                    (GMT)</span>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Informe o fuso horário</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Tempo para Evasão Automática</mat-label>
                        <mat-select formControlName="idEvasionTime">
                            <mat-option *ngFor="let item of listEvasionTime" value="{{item.idEvasionTime}}">
                                {{item.evasionTime}}h
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Informe o tempo de evasão
                            automática</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Duração do Login</mat-label>
                        <mat-select formControlName="idLoginTimeout">
                            <mat-option *ngFor="let item of listLoginTimeout" value="{{item.idLoginTimeout}}">
                                {{item.loginTimeoutName}} <span style="font-weight: bold;">{{item.idLoginTimeout ==
                                    this.idMaxValue ? "Sem Limite" : item.loginTimeoutValue+"h"}}</span>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Informe a duração do login</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Grupo da unidade de Saude</mat-label>
                        <mat-select formControlName="idHealthUnitGroup">
                            <mat-option *ngFor="let item of listHealthUnitGroup" value="{{item.idHealthUnitGroup}}">
                                {{item.nameHealthUnitGroup}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Módulos</mat-label>
                        <mat-select formControlName="listModulePermission" multiple>
                            <mat-option *ngFor="let item of listModule" value="{{item.idModule}}">
                                {{item.nameModule}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Template de relatórios de BI</mat-label>
                        <mat-select formControlName="templateBi" (selectionChange)="ConfirmTemplateChange()">
                            <mat-option value="-1">Não utilizar template de BI</mat-option>
                            <mat-option *ngFor="let item of listAllTemplateBi" value="{{item.idTemplateBi}}">
                                {{item.templateBiName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Grupo de procedência</mat-label>
                        <mat-select formControlName="idGroupOrigin">
                            <mat-option value="null">
                            </mat-option>
                            <mat-option *ngFor="let item of listGroupOrigin" value="{{item.idGroupOrigin}}">
                                {{item.groupOriginName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isHealthUnitGroupMaster" class="col-6 col-sm-6 col-md-2">
                    <h3><b>Esta unidade é mestre do grupo!</b></h3>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone</mat-label>
                        <input matInput type="text" formControlName="healthUnitPhone" [mask]="masks.phone">
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Tempo de expiração automática de contas inativas</mat-label>
                        <mat-select formControlName="idUserExpirationTime">
                            <mat-option [value]="null">
                                Não expira
                            </mat-option>
                            <mat-option *ngFor="let item of listUserExpirationTime" [value]="item.idUserExpirationTime">
                                {{item.valueUserExpirationTime}} dias
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Protocolos</mat-label>
                        <mat-select formControlName="listIdProtocol" multiple>
                            <mat-option *ngFor="let item of listProtocol" value="{{item.idProtocol}}"
                                [disabled]="item.isTemplate">
                                {{item.protocolName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Coordenadoria</mat-label>
                        <mat-select formControlName="coordination">
                            <mat-option *ngFor="let item of listCoordination" [value]="item.idCoordination">
                                {{item.coordinationName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Selecione uma coordenação</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Supervisão</mat-label>
                        <mat-select formControlName="supervision">
                            <mat-option *ngFor="let item of listSupervision" [value]="item.idSupervision">
                                {{item.supervisionName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Selecione uma supervisão</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>CNES</mat-label>
                        <input matInput type="text" formControlName="cnes" [mask]="masks.cnes">
                        <mat-error *ngIf="model.get('cnes').invalid">Informe o CNES</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Domínio do e-mail</mat-label>
                        <input matInput type="email" formControlName="healthUnitEmail" (keyup)="validateEmail()"
                            placeholder="exemplo.app">
                        <span matTextPrefix>{{baseEmail}}</span>
                        <mat-error *ngIf="model.get('healthUnitEmail').invalid">Informe o domínio</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Expiração de senha</mat-label>
                        <mat-select formControlName="idPasswordExpirationTime">
                            <mat-option *ngFor="let item of listPasswordExpirationTime"
                                value="{{item.idPasswordExpirationTime}}">
                                {{item.passwordExpirationTimeName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('idPasswordExpirationTime').invalid">Informe o tempo de expiração da
                            senha</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>Bloquear unidade</mat-label>
                        <mat-slide-toggle formControlName="isHealthUnitBlocked"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>{{model.get('canReturnToQueue').value ? 'Permitir' : 'Não permitir'}} retorno à
                            fila</mat-label>
                        <mat-slide-toggle formControlName="canReturnToQueue"></mat-slide-toggle>
                    </div>
                </div>
            </div>

            <div class="title-header">
                <h1>Configuração de login</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de login</mat-label>
                        <mat-select formControlName="idLoginType" (selectionChange)="verifyRequiredDomain()">
                            <mat-option *ngFor="let item of listLoginType" value="{{item.idLoginType}}">
                                {{item.loginTypeName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('idLoginType').invalid">Informe o tipo de login</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Domínio de e-mail permitido</mat-label>
                        <input matInput type="text" formControlName="emailDomain" (keyup)="validateEmailAllowed()">
                        <mat-error *ngIf="model.get('emailDomain').invalid">Informe os domínios de email
                            permitidos</mat-error>
                    </mat-form-field>
                    <span [ngStyle]="{ 'color': model.get('emailDomain').invalid ? '#fc0303' : '#000000' }">Insira os
                        domínios que podem fazer login separados por "," (ex: tolife.com.br, google.com.br).</span>
                </div>
            </div>

            <div class="title-header">
                <h1>Endereço</h1>
            </div>
            <div class="row">
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline" class="outline-spinner">
                        <mat-label>CEP</mat-label>
                        <input matInput type="text" formControlName="zipCode" (keyup)="onKeySearchCEP($event)">
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingCep == true"></mat-spinner>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Logradouro</mat-label>
                        <input matInput type="text" formControlName="street">
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Número</mat-label>
                        <input matInput type="number" formControlName="houseNumber">
                    </mat-form-field>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Complemento</mat-label>
                        <input matInput type="text" formControlName="addressSupplement">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Bairro</mat-label>
                        <input matInput type="text" formControlName="neighborhood">
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-select-2 [formControlField]="'idHealthUnitState'" [preSelectedlist]="preSelectState"
                        (selectedValue)="selectState($event)" [multiple]="false" [url]="urlListState"
                        [idField]="'idState'" [nameField]="'stateName'" [labelField]="'Estado'"></app-select-2>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <app-select-2 [formControlField]="'idHealthUnitCity'" [preSelectedlist]="preSelectCity"
                        (selectedValue)="selectCity($event)" [multiple]="false" [url]="urlListCity" [idField]="'idCity'"
                        [nameField]="'cityName'" [labelField]="'Cidade'"></app-select-2>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>País</mat-label>
                        <input matInput type="text" formControlName="country">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-sm-6 col-md-2">
                    <div class="form-field-toggle">
                        <mat-label>Usar Faturamento Sigtap</mat-label>
                        <mat-slide-toggle formControlName="isBillingSigtap"
                            (change)="checkBilling($event)"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline" *ngIf="this.model.get('isBillingSigtap').value">
                        <mat-label>Faturamentos</mat-label>
                        <mat-select formControlName="idProcedureSigtapCompetence">
                            <mat-option value="-1">
                                Sempre usar o mais atualizado
                            </mat-option>
                            <mat-option *ngFor="let item of listCompetenceSelectStruct"
                                value="{{item.idProcedureSigtapCompetence}}">
                                {{item.dateCompetence | date:'MM/yyyy'}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Selecione uma opção para
                            faturamento</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4" *ngIf="this.model.get('isBillingSigtap').value">
                    <mat-form-field appearance="outline">
                        <mat-label>Codigo IBGE da Cidade</mat-label>
                        <input matInput type="text" formControlName="cityIbgeCode">
                        <mat-error *ngIf="model.get('cityIbgeCode').invalid">Informe o Código IBGE</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-sm-6 col-md-2">
                    <div class="form-field-toggle">
                        <mat-label>Usar Logo</mat-label>
                        <mat-slide-toggle formControlName="haveLogo"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-6 col-sm-6 col-md-2">
                    <a class="link-uploaded-logo" (click)="openModal()" *ngIf="this.model.get('haveLogo').value">
                        <mat-icon class="img-uploaded-logo">image</mat-icon>
                        <span *ngIf="this.logo">Visualizar Logo</span>
                        <span *ngIf="!this.logo">Selecionar Logo</span>
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-sm-6 col-md-2">
                    <div class="form-field-toggle">
                        <mat-label>Usar IA na Classificação</mat-label>
                        <mat-slide-toggle formControlName="hasFlowchartIA"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-sm-6 col-md-2">
                    <div class="form-field-toggle">
                        <mat-label>Usar assinatura digital ICP Brasil</mat-label>
                        <mat-slide-toggle formControlName="hasICPBrasilCertificate"
                            (change)="checkICPBrasil($event)"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-2" *ngIf="model.get('hasICPBrasilCertificate').value">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantidade A3</mat-label>
                        <input matInput type="number" formControlName="a3Quantity">
                        <mat-error *ngIf="model.get('a3Quantity').invalid">Informe a quantidade de certificados
                            A3</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="title-header">
                <h1>Dados Usuário master</h1>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="userName">
                        <mat-error *ngIf="model.get('userName').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>CPF</mat-label>
                        <input [readonly]="this.isUpdate" matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                        <mat-error *ngIf="model.get('cpf').invalid">Informe o CPF</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone</mat-label>
                        <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                        <mat-error *ngIf="model.get('healthUnitName').invalid">Informe o telefone</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">

                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email">
                        <mat-error *ngIf="model.get('email').invalid">Informe a e-mail</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Profissão</mat-label>
                        <mat-select formControlName="idProfession">
                            <mat-option *ngFor="let item of listProfession" [value]="item.idProfession">
                                {{item.professionName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4 password-margin">
                    <mat-form-field appearance="outline">
                        <mat-label>Senha Temporária</mat-label>
                        <mat-icon matSuffix *ngIf="hasPermissionToViewPreviewPassword == true" class="password-icon"
                            (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        <input matInput #password type="password" [type]="hide ? 'text' : 'password'"
                            formControlName="temporaryPassword">
                        <mat-error *ngIf="model.get('temporaryPassword').invalid">
                            Use entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras
                            minúsculas, números e caracteres especiais.</mat-error>
                        <mat-hint
                            [ngStyle]="{ 'color': model.get('temporaryPassword').invalid ? '#fc0303' : '#000000' }">
                            Use entre oito ou mais caracteres, com uma combinação de letras maiúsculas, letras
                            minúsculas, números e caracteres especiais.
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Matrícula</mat-label>
                        <input matInput type="text" formControlName="login">
                        <mat-error *ngIf="model.get('login').invalid">Informe a matrícula</mat-error>
                    </mat-form-field>
                    <ng-container *ngIf="hasPermissionToChangeMaster">
                        <button *ngIf="!hideUserMasterSelect" mat-button type="button" class="btn-primary btn-block"
                            (click)="hideOrNotUserMasterSelect()">
                            <span *ngIf="isLoading == false">Alterar Usuário Master</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                        <mat-form-field *ngIf="hideUserMasterSelect" appearance="outline">
                            <mat-label>Selecione um Usuário</mat-label>
                            <mat-select formControlName="idNewUserMaster"
                                (selectionChange)="openChangeMasterUserModal()">
                                <mat-option *ngFor="let item of listUserWithAccessInMasterModule"
                                    value="{{item.idUser}}">
                                    {{item.userName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>

            <div class="title-header">
                <h1>Templates</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Templates de fichas</mat-label>
                        <mat-select formControlName="listIdTemplate" multiple>
                            <mat-option *ngFor="let item of listTemplate" [value]="item.idTemplate"
                                [disabled]="item.disabled" (click)="toggleTemplateSelection(item)">
                                {{ item.reportTypeName }} - {{ item.templateName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/health-unit"
                        class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>