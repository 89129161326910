import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { catchError } from 'rxjs/operators';
import { LoginTypeResponse } from '../../responses/user/login-type.response';
import { GetAllLookupResponse } from '../../responses/user/get-all-lookup.response';

@Injectable({
    providedIn: 'root'
  })
  export class LookupService extends CommonService {
  
    constructor(private router: Router,
      private httpClient: HttpClient) {
      super();
    }

  public listLoginType(): Observable<LoginTypeResponse> {

    let uri = `Lookup/GetLoginType`
    
    return this.httpClient.get<LoginTypeResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAllLookup(): Observable<GetAllLookupResponse> {

    let uri = `Lookup/GetAllLookup`
    
    return this.httpClient.get<GetAllLookupResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}