import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { CommonService } from '../../common-service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AnalyticsResponse } from '../../responses/data-mesh/analytics.response';
import { AnalyticsRequest } from '../../requests/data-mesh/analytics.request';
import { ListDataSetResponse } from '../../responses/data-mesh/list-data-set.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { DataSetResponse } from '../../responses/data-mesh/data-set.response';
import { DataSetCreateRequest } from '../../requests/data-mesh/data-set-create.request';
import { DatabaseResponse } from '../../responses/data-mesh/database.response';
import { QueryTestRequest } from '../../requests/data-mesh/query-test.request';
import { DatabaseSchemaResponse } from '../../responses/data-mesh/database-schema.response';
import { ValidateQueryResponse } from '../../responses/data-mesh/validate-query.response';

@Injectable({
    providedIn: 'root'
})
export class DataSetService extends CommonService {
    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    getDatabases(): Observable<DatabaseResponse> {
        let uri = `data-set/get-dbs`;
        return this.httpClient.get<DatabaseResponse>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    getDatabaseSchema(database: string): Observable<DatabaseSchemaResponse> {
        let uri = `data-set/database-schema/${database}`;
        return this.httpClient.get<DatabaseSchemaResponse>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    queryTest(request: QueryTestRequest): Observable<ValidateQueryResponse> {
        let uri = `data-set/query-test`;
        return this.httpClient.post<ValidateQueryResponse>(environment.urlApiDataMesh + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    getAllDataSets(): Observable<ListDataSetResponse> {
        let uri = `data-set/get-all`;
        return this.httpClient.get<ListDataSetResponse>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    getDataSetById(id: number): Observable<DataSetResponse> {
        let uri = `data-set/get/${id}`;
        return this.httpClient.get<DataSetResponse>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    createDataset(request: DataSetCreateRequest): Observable<ReturnStruct> {
        let uri = `data-set/create`;
        return this.httpClient.post<ReturnStruct>(environment.urlApiDataMesh + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteDataSet(id_data_set: number): Observable<ReturnStruct> {
        let uri = `data-set/delete/${id_data_set}`;
        return this.httpClient.delete<ReturnStruct>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }
}