import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { UserSelectResponse } from '../../responses/user/user-select.response';

@Injectable({
  providedIn: 'root'
})
export class UserSelectService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public listUserInfo(listIdHealthUnit, listIdProfession): Observable<UserSelectResponse> {
    
    let jsonListIdHealthUnit = JSON.stringify(listIdHealthUnit);
    let jsonListIdProfession = JSON.stringify(listIdProfession);
    
    let uri = `UserSelect/userSelect?jsonListIdHealthUnit=${jsonListIdHealthUnit}&jsonListIdProfession=${jsonListIdProfession}`
    
    return this.httpClient.get<UserSelectResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}

