

export class ArrivalTypeModel {
    public idArrivalType: number;
    public arrivalTypeName: string;
    public isDeleted: boolean;
    public showToEveryone: boolean;
    public dateTimeInclusion: Date;

    constructor(arrivalTypeModel?: ArrivalTypeModel) {
        if (arrivalTypeModel != null) {
            this.idArrivalType = arrivalTypeModel.idArrivalType;
            this.arrivalTypeName = arrivalTypeModel.arrivalTypeName;
            this.isDeleted = arrivalTypeModel.isDeleted;
            this.showToEveryone = arrivalTypeModel.showToEveryone;
            this.dateTimeInclusion = arrivalTypeModel.dateTimeInclusion;
        }
    }
}

