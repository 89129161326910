<app-menu [menuModuleEnum]='menuModuleEnum'></app-menu>

<div class="container-body">
    <div class="white-body">
        <div class="title-header">
            <mat-icon aria-hidden="false" aria-label="Menu apps">manage_accounts</mat-icon> 
            <!-- <h1>{{ 'DEVELOPER.DASHBOARD.TEXT.TITLE' | translate }}</h1> -->
            <h1>Módulo Desenvolvedor</h1>
        </div>
        <!-- <p>{{ 'DEVELOPER.DASHBOARD.TEXT.DESCRIPTION' | translate }}</p> -->
        <p>Bem vindo ao seu módulo desenvolvedor! Inserir frase de efeito do Lincoln aqui</p>
    </div>
</div>