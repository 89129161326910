import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/services/common-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { LookupMedicalCareResponse } from "../../responses/medical-record/lookup-medical-care-response";

@Injectable({
    providedIn: 'root'
  })
  export class LookupMedicalCareService extends CommonService{
  
    constructor(private router: Router, private httpClient: HttpClient) {
      super();
    }

    public getLookupMedicalCare(): Observable<LookupMedicalCareResponse>{
      let uri = `LookupMedicalCare`
  
      return this.httpClient.get<LookupMedicalCareResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}