import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CommonService } from "../../../common-service";
import { GetDeviceResponse } from "../../../responses/device/get-device.response";
import { ListDeviceResponse } from "../../../responses/device/list-device.response";


@Injectable({
  providedIn: 'root'
})
export class DeviceVisualizationService extends CommonService{

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listAllDevices(idHealthUnit: number, idDeviceType: number, assetNumber: string, isOnline: boolean): Observable<ListDeviceResponse> {
    let uri = "DeviceVisualization/getAll?"
    if (idHealthUnit)
      uri = uri + `idHealthUnit=${idHealthUnit}`;

    if (assetNumber)
      uri = uri + `&assetNumber=${assetNumber}`;

    if (isOnline)
      uri = uri + `&isOnline=${isOnline}`;

    if (isOnline)
      uri = uri + `&isOnline=${isOnline}`;

    return this.httpClient.get<ListDeviceResponse>(environment.urlApiDevice + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }
  public deviceDetails(idDevice:number): Observable<GetDeviceResponse> {
    let uri = `DeviceVisualization/idDevice/${idDevice}`;

    return this.httpClient.get<GetDeviceResponse>(environment.urlApiDevice + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
    )
  }
}
