import { AuthGuard } from '../shared/services/auth-guard.service';
import { SuperAccessListComponent } from './pages/super-access-list/super-access-list.component';
import { SuperAccessRegisterComponent } from './pages/super-access-register/super-access-register.component';
import { SupportComponent } from './pages/support/support.component';
import { DeviceListComponent } from './pages/device-list/device-list.component';
import { EmergesListComponent } from './pages/emerges-list/emerges-list.component';
import { EmergesRegisterComponent } from './pages/emerges-register/emerges-register.component';
import { IntegrationRelationConfigListComponent } from './pages/integration-relation-config-list/integration-relation-config-list.component';
import { IntegrationRelationConfigRegisterComponent } from './pages/integration-relation-config-register/integration-relation-config-register.component';
import { KeyListComponent } from './pages/key-list/key-list.component';
import { KeyRegisterComponent } from './pages/key-register/key-register.component';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { DeviceDetailsComponent } from './pages/device-details/device-details.component';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from '@angular/forms';
import { LogSigaVisualizerViewComponent } from './pages/log-siga-visualizer-view/log-siga-visualizer-view.component';
import { LogSigaVisualizerListComponent } from './pages/log-siga-visualizer-list/log-siga-visualizer-list.component';
import { SupportChannelsComponent } from './pages/support-channels/support-channels.component';

const routes: Routes = [
  { path: '', component: SupportComponent, canActivate: [AuthGuard] },
  { path: 'access-list', component: SuperAccessListComponent, canActivate: [AuthGuard] },
  { path: 'access-register', component: SuperAccessRegisterComponent, canActivate: [AuthGuard] },
  { path: 'device-list', component: DeviceListComponent, canActivate: [AuthGuard] },
  { path: 'device-details', component: DeviceDetailsComponent, canActivate: [AuthGuard] },
  { path: 'emerges', component: EmergesListComponent, canActivate: [AuthGuard] },
  { path: 'emerges-register', component: EmergesRegisterComponent, canActivate: [AuthGuard] },
  { path: 'relation-config', component: IntegrationRelationConfigListComponent, canActivate: [AuthGuard] },
  { path: 'relation-config/register', component: IntegrationRelationConfigRegisterComponent, canActivate: [AuthGuard] },
  { path: 'key', component: KeyListComponent, canActivate: [AuthGuard] },
  { path: 'key-register', component: KeyRegisterComponent, canActivate: [AuthGuard] },
  { path: 'log-siga-list', component: LogSigaVisualizerListComponent, canActivate: [AuthGuard] },
  { path: 'log-siga-list/view', component: LogSigaVisualizerViewComponent, canActivate: [AuthGuard] },
  { path: 'support-channels', component: SupportChannelsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FormsModule,
    MatInputModule
  ],
  exports: [RouterModule],
})
export class MasterRoutingModule { }
