import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/care-line-priority.service';
import { CareLinePriorityHealthGuidelineService } from 'src/app/shared/services/API/orchestrator-classification/care-line-priority-health-guideline.service';

@Component({
  selector: 'app-care-line-priority-delete-modal',
  templateUrl: './care-line-priority-delete-modal.component.html',
  styleUrls: ['./care-line-priority-delete-modal.component.css']
})
export class CareLinePriorityDeleteModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<CareLinePriorityDeleteModalComponent>,
    private careLinePriorityService: CareLinePriorityService,
    private alertService: AlertService,
    public careLinePriorityHealthGuidelineService: CareLinePriorityHealthGuidelineService,
  ) { }
  
  public isLoading:boolean;  

  ngOnInit(): void {
    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close({deleteCareLinePriority: false}); 
  }
  
  // clickDelete(){
    
  //   this.isLoading = true;

  //   this.careLinePriorityService.deleteCareLinePriority(this.data.idCareLinePriority).subscribe({
  //     next: (response)=>{
      
  //       if(response.isError){
  //         this.alertService.show('Erro', response.errorDescription, AlertType.error);
  //         this.isLoading = false;
  //         return;
  //       }
        
  //       this.alertService.show('Sucesso', "Linha de cuidado excluída com sucesso!", AlertType.success); 
  //       this.matDialogRef.close({deleteCareLinePriority: true}); 
  //     },
  //     error: (error)=>{
  //       console.log(error)
  //       this.isLoading = false;
  //       this.alertService.show('Erro inesperado', error, AlertType.error);
  //     }
  //   });
  // }

  clickDelete(){
    
    this.isLoading = true;

    this.careLinePriorityHealthGuidelineService.deleteCareLinePriority(this.data.idCareLinePriority, this.data.listIdHealthGuidelineCategorization).subscribe({
      next: (response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.alertService.show('Sucesso', "Linha de cuidado excluída com sucesso!", AlertType.success); 
        this.matDialogRef.close({deleteCareLinePriority: true}); 
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
  
  clickCancel(){
    this.matDialogRef.close({deleteCareLinePriority: false}); 
  }

}