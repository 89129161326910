import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserLoginResponse } from 'src/app/shared/services/responses/admin-user/auth.response';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-user-infos',
  templateUrl: './user-infos.component.html',
  styleUrls: ['./user-infos.component.css']
})
export class UserInfosComponent implements OnInit {

  constructor(private authService:AuthService, 
    private utilService:UtilService, 
    private socialAuthService: SocialAuthService,) { }
  
  @Input() isVisible:boolean;
  @Output() closeUserInfo = new EventEmitter<any>();
  

  userLoginResponse: UserLoginResponse
  initials:string;
  public socialUser!: SocialUser;

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getTokenMenu()
    this.initials = this.utilService.creatInitials(this.userLoginResponse.userName);

  }

  close(){
    this.closeUserInfo.emit();
  }


  logout(){
    this.socialAuthService.signOut();
    this.authService.logout();
  }
}
