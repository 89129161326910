import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { EmergesService } from 'src/app/shared/services/API/emerges-key/emerges.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { EmergesRequest } from 'src/app/shared/services/requests/emerges-key/emerges.request';

@Component({
  selector: 'app-emerges-register',
  templateUrl: './emerges-register.component.html',
  styleUrls: ['./emerges-register.component.css']
})
export class EmergesRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private emergesService: EmergesService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.support_emerges;
  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isUpdate: boolean;

  public masks: Masks;
  public idEmerges: number;
  public emergesRegisterRequest: EmergesRequest;

  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      healthUnitName: ['', [Validators.required]],
      cnpj: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      idHealthUnit: ['']
    });

    this.masks = this.maskService.getMasks();

    this.emergesRegisterRequest = new EmergesRequest();

    this.idEmerges == null;
    this.isUpdate == false;

    if (this.activatedRoute.snapshot.paramMap.get('idEmerges'))
      this.idEmerges = parseInt(this.activatedRoute.snapshot.paramMap.get('idEmerges'));

    if (this.idEmerges != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.model.get('cnpj').disable();
      this.populateEmergesData();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.emergesRegisterRequest.cnpj = this.model.get('cnpj').value;
    this.emergesRegisterRequest.email = this.model.get('email').value;
    this.emergesRegisterRequest.healthUnitName = this.model.get('healthUnitName').value;
    if (this.model.get('idHealthUnit').value && this.model.get('idHealthUnit').value != null)
      this.emergesRegisterRequest.idHealthUnit = this.model.get('idHealthUnit').value;

    if (this.isUpdate)
      this.updateEmerges();
    else
      this.createEmerges();
  }

  populateEmergesData() {
    this.emergesService.getEmerges(this.idEmerges).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('healthUnitName').setValue(response.emerges.healthUnitName);
      this.model.get('cnpj').setValue(response.emerges.cnpj);
      this.model.get('email').setValue(response.emerges.email);
      this.model.get('idHealthUnit').setValue(response.emerges.idHealthUnit);

      this.isLoading = false;
      this.isFirstLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  updateEmerges() {
    this.emergesService.updateEmerges(this.idEmerges, this.emergesRegisterRequest).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        if (response.errorCode != 2)
          return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/support/emerges']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  createEmerges() {
    this.emergesService.createEmerges(this.emergesRegisterRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        if (response.errorCode != 2)
          return;
      }

      this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
      this.isLoading = false;

      this.router.navigate(['/support/emerges']);

    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
} 