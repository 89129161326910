<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
<div class="container-body">
    <h1>Limpeza de cachês</h1>
    <div class="white-body">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Projetos</mat-label>
                            <mat-select formControlName="idProject" [multiple]="true">
                                <mat-option *ngFor="let item of listProject" value="{{item.idProject}}">
                                    {{item.projectName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-select (selectedValue)="selectHealthUnit($event)" [multiple]="true" [url]="this.urlListHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" [optionAll]="true" [allOptionText]="'TODAS AS UNIDADES'">
                        </app-select>
                    </div>
                <div class="row">
                    <div class="col-md-2">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span *ngIf="isLoading == false">Limpar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                </div>
                </div>
            </form>
        </div>
    </div>