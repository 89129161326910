<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
<div class="container-body">
    <h1>Cadastrar Protocolo</h1>
    <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="title-header">
                <h1>Protocolo</h1>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome Protocolo</mat-label>
                        <input matInput type="text" formControlName="protocolName">
                        <mat-error *ngIf="model.get('protocolName').invalid">Informe o nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Grupo de cor</mat-label>
                        <mat-select formControlName="idPriorityColorGroup">
                            <mat-option *ngFor="let item of listPriorityColorGroup" [value]="item.idPriorityColorGroup">
                                {{item.priorityColorGroupName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Descrição</mat-label>
                        <input matInput type="text" formControlName="description">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>Ativo</mat-label>
                        <mat-slide-toggle formControlName="isActive"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>É Padrão para todas as unidades</mat-label>
                        <mat-slide-toggle formControlName="isTemplate"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>É fora de ordem</mat-label>
                        <mat-slide-toggle formControlName="isOutOfOrder"></mat-slide-toggle>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-field-toggle">
                        <mat-label>Pode ser usado por Telefone?</mat-label>
                        <mat-slide-toggle formControlName="isTelephone"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar protocolo</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <a mat-stroked-button type="button" routerLink="/consulting/protocol"
                        class="btn-secundary btn-block">
                        Voltar
                    </a>
                </div>
            </div>
        </form>
    </div>
    <div class="white-body">
        <div class="row title-header flowchart-title-header">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <h1>Fluxogramas</h1>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <button class="btn-secundary" mat-stroked-button type="button" (click)="redirectToFlowchartRegister()">
                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                    Adicionar novo fluxograma
                </button>
            </div>
        </div>
        <div class="card-list" *ngIf="listFlowchart && listFlowchart.length > 0">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listFlowchart; let i = index;">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.flowchartName}}</span>
                            <span class="secundary">Ordem: {{item.order}}</span>
                            <span class="secundary">{{item.datetimeInclusion | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                        </div>
                        <div class="actions">
                            <a><mat-icon aria-hidden="false" aria-label="Editar"
                                    (click)="redirectToFlowchartRegister(item.idFlowchart)">edit</mat-icon>
                            </a>
                            <a>
                                <mat-icon aria-hidden="false" aria-label="Excluir"
                                    (click)="openDeleteModal(i, item.idFlowchart)">
                                    delete_forever</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-empty-list *ngIf="!listFlowchart || listFlowchart.length == 0"></app-empty-list>
    </div>
</div>