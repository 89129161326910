import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSetTypeEnum } from 'src/app/shared/enum/data_set_type.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProfileService } from 'src/app/shared/services/API/admin-user/profile.service';
import { DataProductService } from 'src/app/shared/services/API/data-mesh/data-product.service';
import { DataSetService } from 'src/app/shared/services/API/data-mesh/datasets.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DataComponentBaseModel, DataComponentModel } from 'src/app/shared/services/models/data-mesh/data-component.model';
import { DataProductCreateRequest } from 'src/app/shared/services/requests/data-mesh/data-product-create.request';
import { DataProductUpdateRequest } from 'src/app/shared/services/requests/data-mesh/data-product-update.request';
import { ListProfileStruct } from 'src/app/shared/services/structs/admin-user/list-profile.struct';
import { DataSetStruct, ColumnConfig } from 'src/app/shared/services/structs/data-mesh/data-set.struct';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-data-product-register',
  templateUrl: './data-product-register.component.html',
  styleUrls: ['./data-product-register.component.css']
})

export class DataProductRegisterComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private profileService: ProfileService,
    private dataSetService: DataSetService,
    private dataProductService: DataProductService,
  ) { }
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.datamesh;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.datamesh_analytics;
  public isLoading: boolean = false;
  public model: FormGroup;
  public masks: Masks;
  public idDataProduct: number;
  public isUpdate: boolean;

  public listProfile: ListProfileStruct[] = [];
  public listDataSet: DataSetStruct[] = [];

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('idDataProduct'))
      this.idDataProduct = parseInt(this.activatedRoute.snapshot.paramMap.get('idDataProduct'));

    if (this.idDataProduct != null) {
      this.isUpdate = true;
    }

    this.model = this.formBuilder.group({
      dataProductName: ['', [Validators.required]],
      datetimeColumn: ['', []],
      profiles: [[], [Validators.required]],
      primaryDataset: [null, [Validators.required]],
      datasets: this.formBuilder.array([]),
    });

    this.isLoading = true;
    this.getProfiles();
    this.listDataset();
  }

  populateDataProduct(idDataProduct: number) {
    this.isLoading = true;
    this.dataProductService.getDataProductById(idDataProduct).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        let dataProduct = response.dataProduct;
        if (dataProduct == null)
          return;
        console.log(response);

        let listIdProfile = dataProduct.profiles.map((profile) => profile.idProfile);

        this.model.controls['dataProductName'].setValue(dataProduct.name);
        this.model.controls['profiles'].setValue(listIdProfile);
        this.model.controls['primaryDataset'].setValue(dataProduct.idPrimaryDataSet);
        this.model.controls['datetimeColumn'].setValue(dataProduct.datetimeColumn);

        if (dataProduct.dataComponents != null && dataProduct.dataComponents.length > 0) {
          dataProduct.dataComponents.forEach((dataComponent) => {
            this.addNext(this.model.value.datasets.length, dataComponent);
          });
        }
        else {
          this.addNext(0);
        }

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getProfiles() {
    this.profileService.listAllProfile().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listProfile = response.listProfile;
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  listDataset() {
    this.dataSetService.getAllDataSets().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listDataSet = response.dataSets;

        console.log(response);

        if (this.isUpdate)
          this.populateDataProduct(this.idDataProduct);

        else {
          this.addNext(this.model.value.datasets.length, null);
          this.isLoading = false;
        }

      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', 'Preencha todos os campos obrigatórios', AlertType.error);
      return;
    }

    this.isLoading = true;
    let dataProduct = this.model.value;
    console.log(dataProduct);

    if (this.isUpdate) {
      this.updateDataProduct(dataProduct);
    } else {
      this.registerDataProduct(dataProduct);
    }
  }

  updateDataProduct(dataProduct: any) {
    let request = new DataProductUpdateRequest();
    request.idDataProduct = this.idDataProduct;
    request.name = dataProduct.dataProductName;
    request.datetimeColumn = dataProduct.datetimeColumn;

    request.profiles = dataProduct.profiles;
    request.idPrimaryDataSet = dataProduct.primaryDataset;

    dataProduct.datasets.filter(x => { return x.idDataSet != null }).forEach((dataset) => {
      let dataComponent = new DataComponentBaseModel();
      dataComponent.idDataSet = dataset.idDataSet;
      dataComponent.primaryColumn = dataset.primaryColumn;
      dataComponent.secondaryColumn = dataset.secondaryColumn;

      request.dataComponents.push(dataComponent);
    });


    this.dataProductService.updateDataProduct(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show('Sucesso!', 'Produto atualizado', AlertType.success);
        this.router.navigate(['/data-products']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  registerDataProduct(dataProduct: any) {
    let request = new DataProductCreateRequest();
    request.name = dataProduct.dataProductName;
    request.datetimeColumn = dataProduct.datetimeColumn;

    request.profiles = dataProduct.profiles;
    request.idPrimaryDataSet = dataProduct.primaryDataset;

    dataProduct.datasets.filter(x => { return x.idDataSet != null }).forEach((dataset) => {
      let dataComponent = new DataComponentBaseModel();
      dataComponent.idDataSet = dataset.idDataSet;
      dataComponent.primaryColumn = dataset.primaryColumn;
      dataComponent.secondaryColumn = dataset.secondaryColumn;

      request.dataComponents.push(dataComponent);
    });

    this.dataProductService.createDataProduct(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        this.alertService.show('Sucesso!', 'Produto cadastrado', AlertType.success);
        this.router.navigate(['/data-products']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createInput(dataComponent: DataComponentModel = null) {
    let fb = this.formBuilder.group({
      idDataSet: null,
      dataSetName: null,
      primaryColumn: '',
      secondaryColumn: '',
    });

    if (dataComponent != null) {
      let dataSet = this.listDataSet.find(dataset => dataset.idDataSet == dataComponent.idDataSet);
      if (dataSet != null) {
        fb.controls['idDataSet'].setValue(dataComponent.idDataSet);
        fb.controls['dataSetName'].setValue(dataSet.dataSetName);
        fb.controls['primaryColumn'].setValue(dataComponent.primaryColumn);
        fb.controls['secondaryColumn'].setValue(dataComponent.secondaryColumn);
      }
    }

    return fb
  }

  addNext(index: number, dataSet: DataComponentModel = null) {
    let formArray = this.model.controls['datasets'] as FormArray;
    if (dataSet != null) {
      formArray.push(this.createInput(dataSet))
    }

    else {
      formArray.push(this.createInput())
    }
  }

  removeDataSet(index: number) {
    let formArray = this.model.controls['datasets'] as FormArray;
    formArray.removeAt(index);

    if (formArray.length == 0) {
      this.addNext(0);
    }
  }

  getSecondaryDatasets(): DataSetStruct[] {
    return this.listDataSet.filter((dataset) => {
      return (
        !this.model.value.datasets.some((datasetForm) => {
          return datasetForm.idDataSet === dataset.idDataSet;
        }) &&
        dataset.idDataSet != this.model.value.primaryDataset
      );
    });
  }


  updatePrimaryDataSet(value: any) {
    (this.model.get('datasets') as FormArray).clear();
    this.addNext(0);
  }

  getDatetimeCols() {
    let cols: ColumnConfig[] = [];
    let dataset = this.listDataSet.find(dataset => dataset.idDataSet == this.model.value.primaryDataset);
    if (dataset != null && dataset.schemaConfig)
      cols = dataset.schemaConfig.filter(x => x.name.includes('timestamp') || x.name.includes('date') || x.name.includes('start_') || x.name.includes('end_'));
    return cols
  }

  getSecondaryColumns(index: number): ColumnConfig[] {
    let form = this.model.get('datasets').get(index.toString()) as FormGroup;
    let dataset = this.listDataSet.find(dataset => dataset.idDataSet == form.value.idDataSet);
    if (dataset != null && dataset.schemaConfig)
      return dataset.schemaConfig;
    return [];
  }

  getPrimaryColumns(): ColumnConfig[] {
    let dataset = this.listDataSet.find(dataset => dataset.idDataSet == this.model.value.primaryDataset);
    if (dataset != null && dataset.schemaConfig)
      return dataset.schemaConfig;
    return [];
  }

  checkSecondaryDataset(idDataSet: number) {
    return (this.model.value.primaryDataset === idDataSet ||
      this.model.value.datasets.some((dataset) => dataset.idDataSet === idDataSet));
  }
}
