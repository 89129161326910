<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Deseja confirmar a alteração de usuário Master da Unidade?</h2>
         </div>
         
         <div class="body-modal-list">
            Se você prosseguir com esta alteração, o usuário Master antigo ainda continuará tendo acesso ao sistema.
         </div>
         <div class="row footer-modal-list">
            <div class = "col-6">
                <button mat-button class="btn-primary block" (click)="updateJustMasterUser()">
                    <span *ngIf="isLoading == false">Confirmar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
            <div class = "col-6">
                <button mat-button class="btn-secundary block"(click)="clickCancel()">Cancelar</button>
            </div>
        </div>
    </div>
</div>
