import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ProcedureSigtapRequest } from '../../requests/billing/procedure-sigtap.request';
import { ValidateProcedureSigtapRequest } from '../../requests/billing/validate-procedure-sigtap.request';
import { ProcedureSigtapCompetenceResponse } from '../../responses/billing/procedure-sigtap-competence.response';
import { ProcedureSigtapResponse } from '../../responses/billing/procedure-sigtap.response';
import { ValidateProcedureSigtapResponse } from '../../responses/billing/validate-procedure-sigtap.response';
import { ListProtocolResponse } from '../../responses/risk-classification/list-protocol.response';
import { CompetenceSelectResponse } from '../../responses/billing/competence-select.response';

@Injectable({
  providedIn: 'root'
})
export class CompetenceSelectService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listProcedureSigtapCompetence(initial: string, final: string): Observable<ProcedureSigtapCompetenceResponse> {

    let uri = `ProcedureSigtap?`;
    if (initial) {
      uri = uri + `dateTimeInitial=${initial}&`;
    }
    if (final) {
      uri = uri + `dateTimeFinal=${final}`;
    }
    return this.httpClient.get<ProcedureSigtapCompetenceResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  delete(idProcedureSigtapCompetence): Observable<ReturnStruct> {
    let uri = `ProcedureSigtap/idProcedureSigtapCompetence/${idProcedureSigtapCompetence}`;
    return this.httpClient.delete<ProcedureSigtapCompetenceResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }


  createProcedures(body: ProcedureSigtapRequest): Observable<ReturnStruct>{
    let uri = `ProcedureSigtap`
      
    return this.httpClient.post<ReturnStruct>(environment.urlApiBilling + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  validateProcedures(body: ValidateProcedureSigtapRequest): Observable<ValidateProcedureSigtapResponse>{
    let uri = `ValidateProcedureSigtap`
      
    return this.httpClient.post<ValidateProcedureSigtapResponse>(environment.urlApiBilling + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }


  getProcedures(idProcedureSigtapCompetence: number): Observable<ProcedureSigtapResponse>{
    let uri = `ProcedureSigtap/idProcedureSigtapCompetence/${idProcedureSigtapCompetence}`
      
    return this.httpClient.get<ProcedureSigtapResponse>(environment.urlApiBilling + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  listProcedureSigtapCompetenceSelect(): Observable<CompetenceSelectResponse>{
    let uri = `CompetenceSelect`
      
    return this.httpClient.get<CompetenceSelectResponse>(environment.urlApiBilling + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
