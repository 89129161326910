<div class="container-out" (click)="close()" *ngIf="isVisible == true"></div>

<div class="container-sub-menu" *ngIf="isVisible == true">
    <h5>{{this.actualMainMenuName}}</h5>
    <div class="action">
        <ul *ngIf="listSubMenuStruct != null && listSubMenuStruct != undefined">
            <li *ngFor="let item of listSubMenuStruct">
                <a (click)="redirect(item)">
                    <span>{{item.name}}</span>
                    <mat-icon class="chevron" aria-hidden="false" aria-label="Menu apps">chevron_right</mat-icon>
                </a>
            </li>
        </ul>
    </div>
</div>