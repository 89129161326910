import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LookUpResponse } from '../../responses/integration-relation-config/look-up.response';

@Injectable({
  providedIn: 'root'
})
export class LookupIntegrationConfigService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  List(): Observable<LookUpResponse> {
    let uri = `LookUp`
    return this.httpClient.get<LookUpResponse>(environment.urlApiIntegrationRelationConfig + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
