import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { CommonService } from '../../common-service';
import { Router } from '@angular/router';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { ListDataProductResponse } from '../../responses/data-mesh/list-data-product.response';
import { DataProductResponse } from '../../responses/data-mesh/data-product.response';
import { DataProductCreateRequest } from '../../requests/data-mesh/data-product-create.request';
import { DataProductUpdateRequest } from '../../requests/data-mesh/data-product-update.request';

@Injectable({
    providedIn: 'root'
})
export class DataProductService extends CommonService {
    private apiUrl = 'https://api.example.com/data-products';

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    getAllDataProducts(): Observable<ListDataProductResponse> {
        let uri = `data-product/get-all`;
        return this.httpClient.get<ListDataProductResponse>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    getDataProductById(id: number): Observable<DataProductResponse> {
        let uri = `data-product/get/${id}`;
        return this.httpClient.get<DataProductResponse>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    createDataProduct(request: DataProductCreateRequest): Observable<ReturnStruct> {
        let uri = `data-product/create`;
        return this.httpClient.post<ReturnStruct>(environment.urlApiDataMesh + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    updateDataProduct(request: DataProductUpdateRequest): Observable<ReturnStruct> {
        let uri = `data-product/update`;
        return this.httpClient.post<ReturnStruct>(environment.urlApiDataMesh + uri, request, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }

    deleteDataProduct(id: number): Observable<ReturnStruct> {
        let uri = `data-product/delete/${id}`;
        return this.httpClient.delete<ReturnStruct>(environment.urlApiDataMesh + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }
}