import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkedProductEnum } from 'src/app/shared/enum/linked-product.enum';
import { ModuleCelerusEnum } from 'src/app/shared/enum/module-celerus.enum';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingConfigService } from 'src/app/shared/services/API/billing-by-health-unit/billing-config.service';
import { BillingPlanService } from 'src/app/shared/services/API/billing-by-health-unit/billing-plan.service';
import { ListAdminModuleService } from 'src/app/shared/services/API/user/list-admin-module.service';
import { LinkedProduct } from 'src/app/shared/services/models/billing-by-health-unit/linked-product.model';
import { BillingPlanRequest } from 'src/app/shared/services/requests/billing-by-health-unit/billing-plan.request';
import { ModuleStruct } from 'src/app/shared/services/structs/admin-user/module.struct';

@Component({
  selector: 'app-billing-plan-register',
  templateUrl: './billing-plan-register.component.html',
  styleUrls: ['./billing-plan-register.component.css']
})
export class BillingPlanRegisterComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private listAdminModuleService: ListAdminModuleService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private billingPlanService: BillingPlanService,
    private billingConfigService: BillingConfigService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.billing_config_list;

  public model: FormGroup;
  public isLoading: boolean;
  public billingPlanRequest: BillingPlanRequest;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public idBillingPlan: number;
  public listLinkedProduct: LinkedProduct[];
  public listModule: ModuleStruct[];
  public showQuantity: boolean = false;
  public listIdModuleSelect: number[] = [];
  public classification: ModuleCelerusEnum = ModuleCelerusEnum.classification;
  public telemedicine: ModuleCelerusEnum = ModuleCelerusEnum.telemedicine;
  public selfTriage: ModuleCelerusEnum = ModuleCelerusEnum.self_triage;

  ngOnInit(): void {
    this.isLoading = false;
    this.idBillingPlan == null;
    this.isUpdate == false;

    this.billingPlanRequest = new BillingPlanRequest();
    this.model = this.formBuilder.group({
      planIsActive: ['', [Validators.required]],
      planName: ['', [Validators.required]],
      listIdModule: [, [Validators.required]],
      planDescription: [''],
      valueExtraAttendance: [''],
      monthlyPlanFee: ['', [Validators.required]],
      quantityPresentialAttendance: [''],
      quantityRemoteAttendance: [''],
      listIdLinkedProduct: ['', [Validators.required]],
      equipmentQuantity: []
    });

    this.populateModuleSelect();
    this.populateProductSelect();

    if (this.activatedRoute.snapshot.paramMap.get('idBillingPlan')) {
      this.idBillingPlan = parseInt(this.activatedRoute.snapshot.paramMap.get('idBillingPlan'));
      this.isFirstLoading = true;
      this.isUpdate = true;
      this.findBillingConfigByPlan();
      this.populateBillingPlanData();
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid)
      return this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);

    this.billingPlanRequest.planName = this.model.get('planName').value;
    this.billingPlanRequest.planDescription = this.model.get('planDescription').value ? this.model.get('planDescription').value : null;
    this.billingPlanRequest.planIsActive = this.model.get('planIsActive').value === "true" ? true : false;;
    this.billingPlanRequest.listIdModule = this.model.get('listIdModule').value;
    this.billingPlanRequest.listIdLinkedProduct = this.model.get('listIdLinkedProduct').value;
    this.billingPlanRequest.monthlyPlanFee = this.model.get('monthlyPlanFee').value;
    this.billingPlanRequest.quantityPresentialAttendance = this.model.get('quantityPresentialAttendance').value ? this.model.get('quantityPresentialAttendance').value : 0;
    this.billingPlanRequest.quantityRemoteAttendance = this.model.get('quantityRemoteAttendance').value ? this.model.get('quantityRemoteAttendance').value : 0;
    this.billingPlanRequest.valueExtraAttendance = this.model.get('valueExtraAttendance').value ? this.model.get('valueExtraAttendance').value : null;
    this.billingPlanRequest.linkedProductQuantity = this.model.get('equipmentQuantity').value ? this.model.get('equipmentQuantity').value : null;

    this.isLoading = true;
    if (this.isUpdate)
      this.updateBillingPlan();
    else
      this.createBillingPlan();
  }

  populateModuleSelect() {
    this.listAdminModuleService.listModule().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listModule = response.listModuleStruct;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createBillingPlan() {
    this.billingPlanService.postBillingPlan(this.billingPlanRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/billing/plan-list']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateProductSelect() {
    this.billingPlanService.listBillingPlanActive(true).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listLinkedProduct = response.listLinkedProduct;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateBillingPlan() {
    this.billingPlanService.updateBillingPlan(this.idBillingPlan, this.billingPlanRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/billing/plan-list']);
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateBillingPlanData() {
    this.billingPlanService.getBillingPlan(this.idBillingPlan).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('planName').setValue(response.billingPlan.planName);
        this.model.get('planDescription').setValue(response.billingPlan.planDescription);
        this.model.get('planIsActive').setValue(response.billingPlan.planIsActive.toString());
        this.model.get('listIdModule').setValue(response.listIdModule);
        this.model.get('listIdLinkedProduct').setValue(response.listIdLinkedProduct);
        this.model.get('monthlyPlanFee').setValue(response.billingPlan.monthlyPlanFee);
        this.model.get('quantityPresentialAttendance').setValue(response.billingPlan.quantityPresentialAttendance);
        this.model.get('quantityRemoteAttendance').setValue(response.billingPlan.quantityRemoteAttendance);
        this.model.get('valueExtraAttendance').setValue(response.billingPlan.valueExtraAttendance);
        this.model.get('equipmentQuantity').setValue(response.linkedProductQuantity);

        let typeTrius = response.listIdLinkedProduct.find(x => x == LinkedProductEnum.trius);
        if (typeTrius) {
          this.showQuantity = true;
          this.model.get('equipmentQuantity').setValidators(Validators.required)
        }

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  verifyProduct() {
    let products: number[] = this.model.get('listIdLinkedProduct').value;
    if (products) {
      let trius = products.find(x => x == LinkedProductEnum.trius)
      if (trius) {
        this.showQuantity = true;
        this.model.get('equipmentQuantity').setValidators([Validators.required])
      }
      else {
        this.model.get('equipmentQuantity').setValue(null);
        this.model.get('equipmentQuantity').setValidators(null);
        this.showQuantity = false;
      }
      this.model.get('equipmentQuantity').updateValueAndValidity();
    }
  }

  setRequiredQuantityField(idModule: number) {
    this.listIdModuleSelect.push(idModule)
    const filteredIds = this.listIdModuleSelect.filter(id => id === idModule)
    if (idModule == this.telemedicine || idModule == this.selfTriage) {
      if (filteredIds.length == 1) {
        this.model.get('quantityRemoteAttendance').setValidators(Validators.required);
        this.model.get('quantityRemoteAttendance').updateValueAndValidity();
      }
      else {
        this.listIdModuleSelect = this.listIdModuleSelect.filter(id => id !== idModule);
        this.model.get('quantityRemoteAttendance').clearValidators();
        this.model.get('quantityRemoteAttendance').updateValueAndValidity();
      }
    }
    else if (idModule == this.classification) {
      if (filteredIds.length == 1) {
        this.model.get('quantityRemoteAttendance').setValidators(Validators.required);
        this.model.get('quantityRemoteAttendance').updateValueAndValidity();
        this.model.get('quantityPresentialAttendance').setValidators(Validators.required);
        this.model.get('quantityPresentialAttendance').updateValueAndValidity();
      }
      else {
        this.listIdModuleSelect = this.listIdModuleSelect.filter(id => id !== idModule);
        this.model.get('quantityPresentialAttendance').clearValidators();
        this.model.get('quantityPresentialAttendance').updateValueAndValidity();
        this.model.get('quantityRemoteAttendance').clearValidators();
        this.model.get('quantityRemoteAttendance').updateValueAndValidity();
      }
    }
    else {
      if (filteredIds.length == 1) {
        this.model.get('quantityPresentialAttendance').setValidators(Validators.required);
        this.model.get('quantityPresentialAttendance').updateValueAndValidity();
      }
      else {
        this.listIdModuleSelect = this.listIdModuleSelect.filter(id => id !== idModule);
        this.model.get('quantityPresentialAttendance').clearValidators();
        this.model.get('quantityPresentialAttendance').updateValueAndValidity();
      }
    }
  }

  findBillingConfigByPlan() {
    this.billingConfigService.getBillingConfigByPlan(this.idBillingPlan).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        if(response.listBillingConfig.length > 0) {
          this.model.get('planIsActive').disable();
          this.model.get('planName').disable();
          this.model.get('listIdModule').disable();
          this.model.get('planDescription').disable();
          this.model.get('valueExtraAttendance').disable();
          this.model.get('monthlyPlanFee').disable();
          this.model.get('quantityPresentialAttendance').disable();
          this.model.get('quantityRemoteAttendance').disable();
          this.model.get('listIdLinkedProduct').disable();
          this.model.get('equipmentQuantity').disable();
          this.alertService.show('Atenção!', "Não é possível alterar informações de planos ativos em Unidades de Saúde.", AlertType.warning);;
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}