<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/bi/report"><mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
        Relatórios</a>
    <h1>Cadastrar Relatório</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">

        <div class="title-header">
            <h1>Dados do relatório</h1>
        </div>

        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="reportName">
                        <mat-error *ngIf="model.get('reportName').invalid">Informe o Nome</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="isActive">
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Ordenação</mat-label>
                        <input matInput type="number" formControlName="ordenation">
                        <mat-error *ngIf="model.get('ordenation').invalid">Informe a ordenação</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Workspace</mat-label>
                        <mat-select formControlName="workspace" 
                            (selectionChange)="populateBiReportSelect($event.value)">
                            <mat-option *ngFor="let item of listWorkspace" [value]="item.id">{{item.workspaceName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Report BI</mat-label>
                        <mat-select formControlName="biReport" (selectionChange)="setReportLink($event)">
                            <mat-option *ngFor="let item of listBiReport" [value]="item.id">{{item.biReportName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Link relatório</mat-label>
                        <input matInput type="text" formControlName="reportLink">
                        <mat-error *ngIf="model.get('reportLink').invalid">Informe o link</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Largura</mat-label>
                        <input matInput type="number" formControlName="width">
                        <mat-error *ngIf="model.get('width').invalid">Informe a largura</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Altura</mat-label>
                        <input matInput type="number" formControlName="height">
                        <mat-error *ngIf="model.get('height').invalid">Informe a altura</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Número da página</mat-label>
                        <input matInput type="text" formControlName="pageNumber">
                        <mat-error *ngIf="model.get('pageNumber').invalid">Informe o número da página</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/bi/report" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
            <div class="title-header">
                <h1>Funções por unidade</h1>
            </div>
            <div formArrayName="listReportFunctionHealthUnit"
                *ngFor="let item of model.get('listReportFunctionHealthUnit')['controls']; let i = index">
                <div class="row" [formGroupName]="i">
                    <div class="col-4 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Função <span class="blue-template-name">{{item.get('templateBiName').value}}
                                </span> <span
                                    class="green-send-name">{{item.get('reportRuled').value}}</span></mat-label>
                            <input matInput type="text" formControlName="function"
                                (keyup)="OnChangeHealthUnit($event, i)">
                            <mat-error *ngIf="item.get('function').invalid">Informe a função</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4 col-sm-4 col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Unidade de Saúde <span
                                    class="blue-template-name">{{item.get('templateBiName').value}} </span> <span
                                    class="green-send-name">{{item.get('reportRuled').value}}</span></mat-label>
                            <mat-select formControlName="idHealthUnit"
                                (selectionChange)="OnChangeHealthUnit($event, i)">
                                <input class="inputSearchSelect" type="text" (keyup)="onKeySearch($event.target.value)"
                                    (keydown)="$event.stopImmediatePropagation()" placeholder="Pesquisar"
                                    autocomplete="off">
                                <mat-option [value]="default">SELECIONE</mat-option>
                                <mat-option *ngFor="let item of listAllHealthUnit"
                                    [disabled]="isInTemplateHealthUnit(item.idHealthUnit)"
                                    [value]="item.idHealthUnit">{{item.healthUnitName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-2 col-sm-2 col-md-3">
                        <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="addNext()">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                        </button>
                        <button class="totem-button btn-primary" mat-mini-fab type="button" (click)="removeButton(i)"
                            *ngIf="i != 0" [disabled]="item.get('isTemplate').value">
                            <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>