<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <h1>SuperAcesso</h1>
    <div class="white-body">
        <div class="filters">
            <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <app-select-2 [multiple]="false" [url]="this.urlListHealthUnit"
                            [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidades de Saúde'" 
                            [formControlField]="'idHealthUnit'" (selectedValue)="populateuserByHealthUnit($event)">
                        </app-select-2>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Usuário</mat-label>
                            <mat-select formControlName="idUser">
                                <mat-option *ngFor="let item of listUser" value="{{item.idUser}}">
                                    {{item.userName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Ticket</mat-label>
                            <input matInput type="text" formControlName="ticket">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Motivo</mat-label>
                            <input matInput type="text" formControlName="motive">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="wasSaved" class="row">
                    <div class="col-12">
                        url para acesso: <a type="button" class="direct-link" [href]="urlBase+'auth;token='+token"
                            target="_blank">{{urlBase+ "auth;token="+token}}</a>
                        <button matTooltip="Copiar" aria-label="Copiar" class="copy-to-clipboard" type="button"
                            [cdkCopyToClipboard]="urlBase+'auth;token='+token" [cdkCopyToClipboardAttempts]="5"
                            (click)="onCopy('item copiado')">
                            <mat-icon class="copy-icons">content_copy</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="!wasSaved" class="col-md-2">
                        <button mat-button type="submit" class="btn-primary btn-block">
                            <span *ngIf="isLoading == false">Salvar</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true">
                            </mat-spinner>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <a mat-stroked-button type="button" routerLink="/support/access-list" class="btn-secundary btn-block">
                            Cancelar
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>