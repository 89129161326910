import { IntegrationVariableStruct } from "./integration-variable.struct";

export class HealthUnitRelationStruct {
    public idHealthUnitRelation: number;
    public idHealthUnit: number;
    public healthUnitName: string;
    public idIntegrationType: number;
    public integrationTypeName: string;
    public url: string;
    public isActive: boolean;
    public sendMessage: boolean;
    public description: string;
    public localAccess: string;
    public integrationVariables: IntegrationVariableStruct[];
    public isOnline: boolean;
}