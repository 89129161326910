import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";


const routes: Routes = [
  { path: 'billing', loadChildren: () => import('./billing/billing.module').then(x => x.BillingModule) },
  { path: 'bi', loadChildren: () => import('./business-intelligence/business-intelligence.module').then(x => x.BusinessIntelligenceModule) },
  { path: 'consulting', loadChildren: () => import('./consulting/consulting.module').then(x => x.ConsultingModule) },
  { path: 'developer', loadChildren: () => import('./developer/developer.module').then(x => x.DeveloperModule) },
  { path: 'master', loadChildren: () => import('./master/master.module').then(x => x.MasterModule) },
  { path: 'support', loadChildren: () => import('./support/support.module').then(x => x.SupportModule) },
  { path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then(x => x.WelcomeModule) },
  { path: 'csat', loadChildren: () => import('./customer-success/customer-success.module').then(x => x.CustomerSuccessModule) },
  { path: 'datamesh', loadChildren: () => import('./data-mesh/data-mesh.module').then(x => x.DataMeshModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: "enabledBlocking"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
