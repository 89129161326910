import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { IntegrationRelationConfigService } from 'src/app/shared/services/API/integration-relation-config/integration-relation-config.service';
import { LookupIntegrationConfigService } from 'src/app/shared/services/API/integration-relation-config/lookup-integration-config.service';
import { MessageService } from 'src/app/shared/services/API/srv-message-queue/message.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { IntegrationTypeModel } from 'src/app/shared/services/models/integration-relation-config/integration-type.model';
import { VariableTypeModel } from 'src/app/shared/services/models/integration-relation-config/variable-type.model';
import { HealthUnitRelationRequest } from 'src/app/shared/services/requests/integration-relation-config/health-unit-relation.request';
import { MessageRequest } from 'src/app/shared/services/requests/srv-message-queue/message.request';
import { HealthUnitRelationStruct } from 'src/app/shared/services/structs/integration-relation-config/health-unit-relation.struct';
import { IntegrationVariableStruct } from 'src/app/shared/services/structs/integration-relation-config/integration-variable.struct';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-integration-relation-config-register',
  templateUrl: './integration-relation-config-register.component.html',
  styleUrls: ['./integration-relation-config-register.component.css']
})
export class IntegrationRelationConfigRegisterComponent implements OnInit {

  constructor(private integrationRelationConfigService: IntegrationRelationConfigService,
    private formBuilder: FormBuilder,
    private healthUnitService: HealthUnitService,
    private alertService: AlertService,
    private lookupService: LookupIntegrationConfigService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.support_integration_config;
  public model: FormGroup;
  public isLoading: boolean = true;
  public searchText: string;
  public listHealthUnit: HealthUnitStruct[] = [];
  public listIntegrationType: IntegrationTypeModel[];
  public listVariableType: VariableTypeModel[];
  public integrationTypeHasValue: boolean = false;
  private listVariableTypeComplete: VariableTypeModel[];
  private idHealthUnitRelation: number;
  private isCopy: boolean = false;
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";

  ngOnInit(): void {
    this.populateLookUpSelect();
    this.populateLookupHealthUnit();

    this.model = this.formBuilder.group({
      firstChildGroup: this.formBuilder.group({
        idHealthUnit: ['', [Validators.required]],
      }),
      idIntegrationType: ['', [Validators.required]],
      url: ['', [Validators.required]],
      relation: this.formBuilder.array([]),
      isActive: [false, [Validators.required]],
      sendMessage: [false, [Validators.required]],
      description: [''],
      localAccess: [''],
    });

    if (this.activatedRoute.snapshot.paramMap.get('idHealthUnitRelation'))
      this.idHealthUnitRelation = parseInt(this.activatedRoute.snapshot.paramMap.get('idHealthUnitRelation'));
    else
      this.isLoading = false;

    if (this.activatedRoute.snapshot.paramMap.get('idHealthUnitRelation') == null) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.idHealthUnitRelation = params['idHealthUnitRelation'];
      });
      if (this.idHealthUnitRelation != undefined && this.idHealthUnitRelation != null)
        this.isCopy = true;
    }
  }

  submit() {
    if (this.isLoading)
      return;

    if (this.model.invalid || !this.validaRelations()) {
      this.alertService.show('Erro', "Preencha os campos obrigatórios", AlertType.error);
      this.isLoading = false;
      return;
    }

    let body: HealthUnitRelationRequest = new HealthUnitRelationRequest();
    body.healthUnitRelation = new HealthUnitRelationStruct();

    body.healthUnitRelation.idHealthUnit = this.model.get('firstChildGroup').get("idHealthUnit").value;
    body.healthUnitRelation.idIntegrationType = this.model.get("idIntegrationType").value;
    body.healthUnitRelation.url = this.model.get("url").value;
    body.healthUnitRelation.integrationVariables = [];
    body.healthUnitRelation.isActive = this.model.get('isActive').value;
    body.healthUnitRelation.description = this.model.get("description").value;
    body.healthUnitRelation.localAccess = this.model.get("localAccess").value;
    body.healthUnitRelation.sendMessage = this.model.get('sendMessage').value;

    this.model.get('relation').value.forEach((x, i) => {
      var relation = new IntegrationVariableStruct();
      relation.idVariableType = x.idVariableType;
      relation.variableToLife = x.variableToLife;
      relation.variableIntegration = x.variableIntegration;
      body.healthUnitRelation.integrationVariables.push(relation);
    });

    this.isLoading = true;
    if (this.idHealthUnitRelation && !this.isCopy)
      this.Update(body);
    else
      this.Create(body);
  }

  Update(body: HealthUnitRelationRequest) {
    this.integrationRelationConfigService.Update(this.idHealthUnitRelation, body).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (body.healthUnitRelation.sendMessage)
          this.sendToMessageQueue(body.healthUnitRelation.idHealthUnit);
        else {
          this.alertService.show('Sucesso', 'Integração alterada com sucesso!', AlertType.success);
          this.router.navigate(['/support/relation-config']);
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  sendToMessageQueue(idHealthUnit: number) {
    let body: MessageRequest = new MessageRequest;
    body.message = JSON.stringify(true);
    body.idMessageType = "UpdateConfig";

    this.messageService.SendMessage(body, idHealthUnit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', 'Integração alterada com sucesso!', AlertType.success);
        this.router.navigate(['/support/relation-config']);
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  Read() {
    this.integrationRelationConfigService.Read(this.idHealthUnitRelation).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        if (!this.isCopy) {
          this.model.get("url").setValue(response.healthUnitRelation.url);
          this.model.get('firstChildGroup').get("idHealthUnit").setValue(response.healthUnitRelation.idHealthUnit);
          this.model.get('isActive').setValue(response.healthUnitRelation.isActive);
          this.model.get('sendMessage').setValue(response.healthUnitRelation.sendMessage);
          this.model.get("description").setValue(response.healthUnitRelation.description);
          this.model.get("localAccess").setValue(response.healthUnitRelation.localAccess);
        }

        this.model.get("idIntegrationType").setValue(response.healthUnitRelation.idIntegrationType);

        this.integrationTypeChange();
        response.healthUnitRelation.integrationVariables.forEach((x) => {
          (this.model.controls['relation'] as FormArray).push(
            this.formBuilder.group({
              idVariableType: [x.idVariableType],
              variableToLife: [x.variableToLife],
              variableIntegration: [x.variableIntegration],
            })
          );
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  Create(body: HealthUnitRelationRequest) {
    this.integrationRelationConfigService.Create(body).subscribe({
      next: response => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (body.healthUnitRelation.sendMessage)
          this.sendToMessageQueue(body.healthUnitRelation.idHealthUnit);
        else {
          this.alertService.show('Sucesso', 'Integração alterada com sucesso!', AlertType.success);
          this.router.navigate(['/support/relation-config']);
        }
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateLookupHealthUnit() {
    this.healthUnitService.listHealthUnit().subscribe(response => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listHealthUnit = response.listHealthUnit;
    });
  }

  populateLookUpSelect() {
    this.lookupService.List().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listIntegrationType = response.integrationTypes;
        this.listVariableTypeComplete = response.variableTypes;
        if (this.idHealthUnitRelation)
          this.Read();
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  integrationTypeChange() {
    let idIntegrationType = this.model.get("idIntegrationType").value;
    this.listVariableType = this.listVariableTypeComplete.filter(c => c.idIntegrationType == idIntegrationType);
    this.integrationTypeHasValue = idIntegrationType || false;
  }

  validaRelations(): boolean {
    let isRelationInvalid: boolean = true;
    this.model.get('relation').value.forEach((x) => {
      let result = this.listVariableType.find(c => c.idVariableType == x.idVariableType);
      if (!(x.idVariableType && x.variableIntegration && result))
        isRelationInvalid = false;
    });

    return isRelationInvalid || !this.model.get('relation').value.length;
  }
}