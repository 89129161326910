import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BillingPlanService } from 'src/app/shared/services/API/billing-by-health-unit/billing-plan.service';
import { BillingPlan } from 'src/app/shared/services/models/billing-by-health-unit/billing-plan.model';
import { BillingDeleteModalComponent } from './billing-delete-modal/billing-delete-modal.component';

@Component({
  selector: 'app-billing-plan-list',
  templateUrl: './billing-plan-list.component.html',
  styleUrls: ['./billing-plan-list.component.css']
})
export class BillingPlanListComponent implements OnInit {

  constructor(private alertService: AlertService,
    private dialog: MatDialog,
    private billingPlanService: BillingPlanService,) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.billing;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.billing_plan_list;

  public isLoading: boolean;
  public searchText: string;
  public planIsActive: boolean;
  public idBillingPlan: number;
  public listBillingPlan: BillingPlan[];

  ngOnInit(): void {
    this.isLoading = true;
    this.searchText = null;
    this.planIsActive = null;

    this.search();
  }

  openDeleteModal(idBillingPlan: number) {
    const dialogRef = this.dialog.open(BillingDeleteModalComponent, {
      data: {
        idBillingPlan: idBillingPlan,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deletePlan) {
        this.search();
      }
    });
  }

  search() {
    this.isLoading = true;
    if (this.searchText == "")
      this.searchText = null;

    this.billingPlanService.listBillingPlan(this.searchText, this.planIsActive).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.listBillingPlan = response.listBillingPlan;
        this.isLoading = false;
      },
      error: (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }
}