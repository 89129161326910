import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AnswerService } from 'src/app/shared/services/API/self-triage/answer.service';
import { LookupService } from 'src/app/shared/services/API/self-triage/lookup.service';
import { QuestionService } from 'src/app/shared/services/API/self-triage/question.service';
import { AnswerModel } from 'src/app/shared/services/models/self-triage/anwser.model';
import { ButtonTypeModel } from 'src/app/shared/services/models/self-triage/button-type.model';
import { QuestionEditStruct } from 'src/app/shared/services/structs/self-triage/question-edit.struct';

@Component({
  selector: 'app-self-triage-discriminator-register',
  templateUrl: './self-triage-discriminator-register.component.html',
  styleUrls: ['./self-triage-discriminator-register.component.css']
})
export class SelfTriageDiscriminatorRegisterComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private questionService: QuestionService,
    private router: Router,
    private answerService: AnswerService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_self_triage_discriminator;
  public isFirstLoading: boolean = true;
  public idQuestion: number;
  public idParent: number;
  public idAnswer: number;
  public question: QuestionEditStruct;
  public answerChild: AnswerModel[];
  public answerParent: AnswerModel;
  public listButtonType: ButtonTypeModel[] = [];

  ngOnInit(): void {
    this.isFirstLoading = true;
    if (this.activatedRoute.snapshot.paramMap.get('idParent')) {
      this.idParent = parseInt(this.activatedRoute.snapshot.paramMap.get('idParent'));
    }
    if (this.activatedRoute.snapshot.paramMap.get('idQuestion')) {
      this.getQuestion(parseInt(this.activatedRoute.snapshot.paramMap.get('idQuestion')));
    } else {
      if (this.activatedRoute.snapshot.paramMap.get('idAnswer')) {
        this.getAnswerParent(parseInt(this.activatedRoute.snapshot.paramMap.get('idAnswer')));
      } else {
        this.isFirstLoading = false;
      }
    }
  }

  startPage(idParent: number, idQuestion: number, idAnswer: number) {
    this.isFirstLoading = true;
    this.question = null;
    this.answerParent = null;
    this.answerChild = null;
    this.idParent = idParent;
    if (idQuestion) {
      this.getQuestion(idQuestion);
    } else {
      if (idAnswer) {
        this.getAnswerParent(idAnswer);
      }
    }
  }

  getAnswerParent(idAnswer: number) {
    this.answerService.getAnswer(idAnswer).subscribe((response) => {

      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isFirstLoading = false;
        return;
      }
      this.answerParent = response.answer;
      this.isFirstLoading = false;
    }, (error) => {
      this.isFirstLoading = false;
      this.alertService.show("Erro Inesperado", error, AlertType.error);
    })
  }

  getQuestion(idQuestion: number) {
    this.isFirstLoading = true;
    this.questionService.getQuestion(idQuestion).subscribe((response) => {

      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.isFirstLoading = false;
        return;
      }
      this.question = response.question;
      this.answerChild = response.answerChild;
      this.answerParent = response.answerParent;
      this.isFirstLoading = false;
    }, (error) => {
      this.isFirstLoading = false;
      this.alertService.show("Erro Inesperado", error, AlertType.error);
    })
  }

  reloadQuestion(values: any) {
    if (values.idQuestion) {
      this.router.navigate(["consulting/self-triage-discriminator/register", { idParent: values.idParent, idAnswer: values.idAnswer, idQuestion: values.idQuestion }])
      this.startPage(values.idParent, values.idQuestion, null);
    } else {
      this.router.navigate(["consulting/self-triage-discriminator/register", { idParent: values.idParent, idAnswer: values.idAnswer }])
      this.startPage(values.idParent, null, values.idAnswer);
    }
  }
}
