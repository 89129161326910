import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { LookupResponse } from '../../responses/integration-siga/lookup-siga.response';

@Injectable({
  providedIn: 'root'
})
export class LookupSigaService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  
  public listLookup(): Observable<LookupResponse> {

    let uri = `Lookup`
    
    return this.httpClient.get<LookupResponse>(environment.urlApiIntegrationSiga + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

}
