import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListSelfTriageFlowchartResponse } from '../../responses/self-triage/list-self-triage-flowchart.response';
import { ListSelfTriageProtocolResponse } from '../../responses/self-triage/list-self-triage-protocol.response';
import { SelfTriageProtocolResponse } from '../../responses/self-triage/self-triage-protocol.response';

@Injectable({
  providedIn: 'root'
})
export class SelfTriageFlowchartService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listUnassigned(): Observable<ListSelfTriageFlowchartResponse> {

    let uri = `Flowchart/GetUnassigned`

    return this.httpClient.get<ListSelfTriageFlowchartResponse>(environment.urlApiSelfTriage + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
