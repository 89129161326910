import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ModuleStruct } from 'src/app/shared/services/structs/admin-user/module.struct';
import { MenuModuleEnum } from '../menu.module.enum';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css']
})
export class SubMenuComponent implements OnInit {

  constructor(private router: Router) { }

  @Input() isVisible: boolean;
  @Input() actualMainMenuName: string;
  @Input() listSubMenuStruct: Array<any>;
  @Output() closeSubMenu = new EventEmitter<any>();

  defaultModuleName: string;
  idDefaultModule: number;
  newDefaultModule: ModuleStruct;

  ngOnInit(): void {
  }

  close() {
    this.closeSubMenu.emit();
  }

  redirect(module) {
    this.router.navigate([module.uri]);
  }

}
