import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CounselingRequest } from '../../requests/risk-classification/counseling.request';
import { GetCounselingResponse } from '../../responses/risk-classification/get-counseling.response';
import { ListCounselingResponse } from '../../responses/risk-classification/list-counseling.response';

@Injectable({
  providedIn: 'root'
})
export class CounselingService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listCounseling(searchText: string): Observable<ListCounselingResponse> {
    let uri = `Counseling?`
    if (searchText) {
      uri = uri + `searchText=${searchText}`
    }

    return this.httpClient.get<ListCounselingResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  getCounseling(idCounseling: number): Observable<GetCounselingResponse> {
    let uri = `Counseling/idCounseling/${idCounseling}`

    return this.httpClient.get<GetCounselingResponse>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  postCounseling(body: CounselingRequest): Observable<ReturnStruct> {
    let uri = `Counseling`

    return this.httpClient.post<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  updateCounseling(idCounseling: number, body: CounselingRequest): Observable<ReturnStruct> {
    let uri = `Counseling/idCounseling/${idCounseling}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiRiskClassification + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  deleteCounseling(idCounseling: number): Observable<ReturnStruct> {
    let uri = `Counseling/idCounseling/${idCounseling}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiRiskClassification + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
