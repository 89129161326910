<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/billing/config"><mat-icon aria-hidden="false"
            aria-label="Plus">arrow_back</mat-icon> Planos</a>
    <h1>Configurações gerais</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <app-select-2 [multiple]="false" [url]="this.urlListHealthUnit"
                        [idField]="'idHealthUnit'" [nameField]="'healthUnitName'" [labelField]="'Unidade de Saúde'" 
                        [formControlField]="'idHealthUnitAccess'">
                    </app-select-2>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Plano</mat-label>
                        <mat-select formControlName="idBillingPlan">
                            <mat-option *ngFor="let item of listBillingPlan" [value]="item.idBillingPlan">
                                {{item.planName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('idBillingPlan').invalid">Selecione um Plano</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Início da ativação</mat-label>
                        <input matInput type="text" inputmode="date" formControlName="startActivation"
                            [mask]="masks.date">
                        <mat-error *ngIf="model.get('startActivation').invalid">Insira uma data válida</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Fim da ativação</mat-label>
                        <input matInput type="text" inputmode="date" formControlName="endActivation"
                            [mask]="masks.date">
                        <mat-error *ngIf="model.get('endActivation').invalid">Insira uma data válida</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dia da cobrança</mat-label>
                        <mat-select formControlName="idBillingDay">
                            <mat-option *ngFor="let item of listBillingDay" [value]="item.idBillingDay">
                                {{item.billingDay}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('idBillingDay').invalid">Selecione um dia</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="isActive" (selectionChange)="checkChangeStatus()">
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="title-header">
                <h1>Contrato</h1>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <mat-card class="btn-primary">
                        <mat-card-content class="align-card">
                            <div
                                class="elementor-field-type-upload elementor-field-group elementor-column elementor-field-group-field_53c80dc elementor-col-50 elementor-field-required">
                                <label for="form-field-field_53c80dc" class="elementor-field-label">
                                    Escolher arquivo
                                </label>
                                <input #file type="file" name="form_fields[field_53c80dc]" id="form-field-field_53c80dc"
                                    class="elementor-field elementor-size-lg  elementor-upload-field"
                                    aria-required="true" [disabled]="isLoading"
                                    (change)="onSelectFile(file.files)" [multiple]="'multiple'" [accept]="'.pdf'" />
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div class="card-list margin-top" *ngIf="listContract != null">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2" *ngFor="let item of listContract">
                        <div class="card-item">
                            <div class="infos">
                                <span class="primary"><b>{{item.billingContractName}}</b></span>
                                <span class="secundary">{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm'}}</span>
                                <span class="secundary">{{item.capacity}}</span>
                            </div>
                            <div class="actions">
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Editar"
                                        (click)="generatePDF(item.idBillingConfigContract)">
                                        download</mat-icon>
                                </a>
                                <a>
                                    <mat-icon aria-hidden="false" aria-label="Excluir"
                                        (click)="openDeleteModal(item.idBillingConfigContract)">delete_forever</mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row margin-top">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/billing/config" class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>