export class SchedulePowerBi {
    public idSchedulePowerBi: number;

    public biReport: string;

    public workspace: string;

    public lastUpdate: Date;

    public updateInterval: number;

    public status: string;

    public idRefreshType: number;

    public isActive: boolean;

    public isDeleted: boolean;

    public datetimeInclusion: Date;

    public datetimeInit: Date;
}












