import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ListUserService } from 'src/app/shared/services/API/admin-user/list-user.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { TokenAuthAdminService } from 'src/app/shared/services/API/user/token-auth-admin.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { AdminUserModel } from 'src/app/shared/services/models/admin-user/admin-user.model';
import { HealthUnitModel } from 'src/app/shared/services/models/user/health-unit.model';
import { TokenAuthAdminModel } from 'src/app/shared/services/models/user/token-auth-admin.model';
import { ListAdminUserStruct } from 'src/app/shared/services/structs/admin-user/list-admin-user.struct';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { SuperAccessModalComponent } from './super-access-modal/super-access-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-super-access-list',
  templateUrl: './super-access-list.component.html',
  styleUrls: ['./super-access-list.component.css']
})
export class SuperAccessListComponent implements OnInit {

  constructor(private tokenService: TokenAuthAdminService,
    private formBuilder: FormBuilder,
    private adminServiceAdmin: ListUserService,
    private healthUnitService: HealthUnitService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
    public maskService: MaskService) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.super_access;

  public model: FormGroup;
  public isLoading: boolean;
  public searchText: string;
  public listSuperAccess: TokenAuthAdminModel[] = [];
  public listUserAdmin: ListAdminUserStruct[] = [];
  public listHealthUnit: HealthUnitStruct[] = [];
  public masks: Masks;
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";
  public idHealthUnit: number;
  public listIdHealthUnit: number[] = [];

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      user: [''],
      healthUnit: [''],
      dateAccess: [''],
    });
    this.populateLookup();
  }

  openModal(item: TokenAuthAdminModel) {
    this.dialog.open(SuperAccessModalComponent, {
      data: { ...item },
    });
  }

  pesquisar() {
    let searchDate = this.formatDateBack(this.model.get("dateAccess").value);
    this.getListTokenAuthAdmin(this.model.get("user").value, this.listIdHealthUnit, searchDate);
  }

  formatDateBack(dateUnformatted: string): string {
    if (dateUnformatted) {
      let darr = dateUnformatted.split("/");    // ["29", "1", "2016"]
      let dobj = parseInt(darr[2]) + "-" + (parseInt(darr[1])) + "-" + parseInt(darr[0]);
      return dobj;
    }
    return null;
  }

  populateLookup() {
    this.adminServiceAdmin.listAdminUser().subscribe(response => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listUserAdmin = response.listAdminUser;
      this.getListTokenAuthAdmin(null, null, null);
    });
    this.healthUnitService.listHealthUnit().subscribe(response => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHealthUnit = response.listHealthUnit;
    });
  }

  getListTokenAuthAdmin(idAdminUser: number, idHealthUnit: number[], dateAccess: string) {
    if(!idHealthUnit)
    idHealthUnit = [];
    
    this.tokenService.listTokenAuthAdminWithListHealthUnit(idAdminUser, idHealthUnit, dateAccess).subscribe(response => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      response.listTokenAuthAdmin.forEach((c) => {
        let result = this.listUserAdmin.find(x => x.idAdminUser == c.idAdminUser);
        c.adminUserName = result ? result.adminUserName : null;
      });

      this.listSuperAccess = response.listTokenAuthAdmin;
    });
  }

  selectHealthUnit(event){
    this.listIdHealthUnit = event.map(x => x.idHealthUnit);
  }

}
