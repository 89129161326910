import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { } 

   // Manipulação de erros
   handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;

      if(error.status == 401){
        //Desloga usuário
        sessionStorage.removeItem("token");
        window.location.href = '/';
      }
    }
    return throwError(errorMessage);
  };

  addHeaderToken(){
    let token: string = JSON.parse(sessionStorage.getItem('token')).token;
    
    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token
    }
    
    let requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    return requestOptions;
  }

  addHeaderHashEmailSms() {
    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': environment.hashEmailSmsAuthorization
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }
}
