<div class="row" *ngFor="let item of messageHistory; let i = index;">
    <div class="col-md-12">
        <cdk-accordion class="profile-register-accordion">
            <cdk-accordion-item #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item"
                role="button" tabindex="0" [attr.id]="'accordion-header-' + i"
                [attr.aria-expanded]="accordionItemModule.expanded" [attr.aria-controls]="'accordion-body-' + i">
                <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                    class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                    <b>{{messageHistory[i].messageTitle}}</b>
                    <span
                        [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-description">
                        Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                    </span>
                </div>
                <div class="profile-register-accordion-item-body" role="region"
                    [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + i"
                    [attr.aria-labelledby]="'accordion-header-' + i">
                    <div class="feature-content">
                        <div class="row">
                            <div class="col-6 col-sm-4 col-md-3">
                                <mat-form-field appearance="outline" class="outline-spinner">
                                    <mat-label>Título</mat-label>
                                    <input matInput type="text" readonly [value]="messageHistory[i].messageTitle">
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-4 col-md-3">
                                <mat-form-field appearance="outline" class="outline-spinner">
                                    <mat-label>Usuário</mat-label>
                                    <input matInput type="text" readonly
                                        [value]="messageHistory[i].adminUserSenderName">
                                </mat-form-field>
                            </div>
                            <div class="col-6 col-sm-4 col-md-4">
                                <mat-form-field appearance="outline" class="outline-spinner">
                                    <mat-label>Data e hora de envio</mat-label>
                                    <input matInput type="text" readonly
                                        [value]="messageHistory[i].datetimeInclusion | date:'dd/MM/yy, H:mm'">

                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" floatLabel="always">
                                    <mat-label>Corpo da mensagem</mat-label>
                                    <input matInput type="text" readonly [value]="">
                                    <div contenteditable="true" readonly [innerHTML]="messageHistory[i].messageContent">
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline"
                                    *ngIf="messageHistory[i].listHealthUnit.length > 1">
                                    <mat-label>Unidades de saúde</mat-label>
                                    <mat-select multiple readonly [value]="messageHistory[i].listHealthUnit">
                                        <mat-option *ngFor="let item of messageHistory[i].listHealthUnit" [value]="item"
                                            [disabled]="true">
                                            {{item.healthUnitName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="outline-spinner"
                                    *ngIf="messageHistory[i].listHealthUnit.length == 1">
                                    <mat-label>Unidade de saúde</mat-label>
                                    <input matInput type="text" readonly
                                        [value]="messageHistory[i].listHealthUnit[0].healthUnitName">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="outline"
                                    *ngIf="messageHistory[i].listProfession.length > 1">
                                    <mat-label>Profissões</mat-label>
                                    <mat-select multiple readonly [value]="messageHistory[i].listProfession">
                                        <mat-option *ngFor="let item of messageHistory[i].listProfession" [value]="item"
                                            [disabled]="true">
                                            {{item.professionName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="outline-spinner"
                                    *ngIf="messageHistory[i].listProfession.length == 1">
                                    <mat-label>Profissão</mat-label>
                                    <input matInput type="text" readonly
                                        [value]="messageHistory[i].listProfession[0].professionName">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </cdk-accordion-item>
        </cdk-accordion>
    </div>
</div>