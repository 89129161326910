import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { HealthUnitAdminRequest } from '../../requests/admin-user/health-unit-admin.request';
import { UpdateMasterUserHealthUnitRequest } from '../../requests/user/update-master-user-health-unit.request';
import { GetHealthUnitAdminResponse } from '../../responses/user/get-health-unit-admin.response';
import { ListHealthUnitAdminResponse } from '../../responses/user/list-health-unit-admin.response';
import { PostHealthUnitAdminResponse } from '../../responses/user/post-health-unit-admin-response';

@Injectable({
  providedIn: 'root'
})
export class HealthUnitAdminService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public listHealthUnit(searchText: string, idHealthUnitGroup: number): Observable<ListHealthUnitAdminResponse> {
    
    let uri = 'HealthUnitAdmin?'
  
    if(searchText != null)
    uri = uri+ `searchText=${searchText}&`;

    if(idHealthUnitGroup != null)
    uri = uri+ `idHealthUnitGroup=${idHealthUnitGroup}&`;
    
    return this.httpClient.get<ListHealthUnitAdminResponse>(environment.urlApiUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getHealthUnit(idHealthUnit: number): Observable<GetHealthUnitAdminResponse>{
    return this.httpClient.get<GetHealthUnitAdminResponse>(environment.urlApiUser + `HealthUnitAdmin/idHealthUnit/${idHealthUnit}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateHealthUnit(idHealthUnit: number, updateHealthUnitRequest: HealthUnitAdminRequest): Observable<ReturnStruct>{
    return this.httpClient.put<ReturnStruct>(environment.urlApiUser + `HealthUnitAdmin/idHealthUnit/${idHealthUnit}`, updateHealthUnitRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public createHealthUnit(createHealthUnitRequest: HealthUnitAdminRequest): Observable<PostHealthUnitAdminResponse>{
    return this.httpClient.post<PostHealthUnitAdminResponse>(environment.urlApiUser + `HealthUnitAdmin`, createHealthUnitRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updateMasterUser(idHealthUnit: number, updateMasterUserHealthUnitRequest: UpdateMasterUserHealthUnitRequest): Observable<ReturnStruct>{
    return this.httpClient.put<ReturnStruct>(environment.urlApiUser + `HealthUnitAdmin/updateMasterUser/idHealthUnit/${idHealthUnit}`, updateMasterUserHealthUnitRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    
    