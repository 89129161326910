<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/developer/log-batch-list">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Usuários
    </a>
    <h1>Visualização de Log Batch</h1>
    <div class="white-body">
        <div class="title-header">
            <h1>Dados:</h1>
        </div>
        <div class="row">
            <div class="col-md-3">
                <app-chip-batch-status [status]="logBatch.idLogBatchStatus"></app-chip-batch-status>
            </div>
            <div class="col-md-3">
                <label>ID Processamento: </label>
                <p>{{logBatch.idLogBatch}}</p>
            </div>
            <div class="col-md-3">
                <label>Nome do Projeto: </label>
                <p>{{logBatch.nameProject}}</p>
            </div>
            <div class="col-md-3">
                <label>Mensagem: </label>
                <p>{{logBatch.message}}</p>
            </div>
            <div class="col-md-3">
                <label>Mensagem de Conclusão: </label>
                <p>{{logBatch.messageFinish}}</p>
            </div>
            <div class="col-md-3">
                <label>DateHora de início do processo: </label>
                <p>{{logBatch.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</p>
            </div>
            <div class="col-md-3">
                <label>DateHora de fim do processo: </label>
                <p>{{logBatch.datetimeFinish | date:'dd/MM/yyyy HH:mm:ss'}}</p>
            </div>
        </div>
        <div class="title-header">
            <h1>Mensagens:</h1>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12" *ngFor="let item of listLogBatchMessage">
                <div class="message">
                    <label><p>{{item.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss'}}</p></label>
                    <p>{{item.message}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <a mat-stroked-button type="button" routerLink="/developer/log-batch-list"
                    class="btn-secundary btn-block">
                    Voltar
                </a>
            </div>
        </div>
    </div>
</div>