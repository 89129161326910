import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { CreateDiscriminatorResponse } from '../../responses/risk-classification/create-discriminator.response';
import { SelectResponse } from '../../responses/risk-classification/select.response';

@Injectable({
  providedIn: 'root'
})
export class DiscriminatorSelectService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  listDiscriminator(listIdFlowchart: number): Observable<SelectResponse> {
    let uri = `DiscriminatorSelect`

    return this.httpClient.post<SelectResponse>(environment.urlApiRiskClassification + uri, listIdFlowchart, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
