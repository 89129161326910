export enum MenuModuleEnum {
    auth = -1,
    welcome = -2,
    master = 1,
    support = 3,
    consulting = 4,
    developer = 2,
    business_intelligence = 5,
    billing = 6,
    customer_success = 7,
    datamesh = 8,
}