import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MasterRoutingModule } from './master-routing.module';
import { MasterComponent } from './pages/master/master.component';
import { MatTree, MatTreeModule } from '@angular/material/tree';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { ProfileRegisterComponent } from './pages/profile-register/profile-register.component';
import { ProfileListComponent } from './pages/profile-list/profile-list.component';
import { ProfileDeleteModalComponent } from './pages/profile-list/profile-delete-modal/profile-delete-modal.component';
import { UserDeleteModalComponent } from './pages/user-list/user-delete-modal/user-delete-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    MasterComponent,
    UserListComponent,
    UserRegisterComponent,
    ProfileRegisterComponent,
    ProfileListComponent,
    ProfileDeleteModalComponent,
    UserDeleteModalComponent
  ],
  imports: [
    CommonModule,
    MasterRoutingModule,
    SharedModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    ClipboardModule,
    MatTooltipModule,
    MatToolbarModule,
    NgxMatFileInputModule,
    CdkAccordionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatOptionModule,
    NgxMaskModule.forRoot(),
    MatDialogModule
  ],
  providers: [
    AuthGuard,
    MatCheckbox,
    MatCheckboxChange,
    MatTree,
    MatRadioButton,
  ],
})
export class MasterModule { }
