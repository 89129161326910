<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Cadastro de resposta</h2>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-modal-list">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Resposta</mat-label>
                            <input matInput type="text" formControlName="answer">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Ordem</mat-label>
                            <input matInput type="number" formControlName="order">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Redirecionar para</mat-label>
                            <mat-select formControlName="idButtonType" (selectionChange)="buttonTypeChange()">
                                <mat-option *ngFor="let item of data.listButtonType" [value]="item.idButtonType">
                                    {{item.buttonTypeName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="idButtonType == buttonTypeNewQuestion.valueOf() && (saveAnswer || (this.data.answer && this.data.answer.idButtonType == buttonTypeNewQuestion.valueOf()))"
                    class="row">
                    <div class="col-12">
                        <a *ngIf="this.answer && !this.answer.idChildQuestion" mat-button class="btn-primary block"
                            (click)="createQuestion(data.idParent, this.answer.idAnswer, null)">
                            Cadastrar Pergunta
                        </a>
                        <a *ngIf="this.answer && this.answer.idChildQuestion" mat-button class="btn-primary block"
                            (click)="createQuestion(data.idParent, this.answer.idAnswer, this.answer.idChildQuestion)">
                            Editar Pergunta
                        </a>
                    </div>
                </div>
                <div *ngIf="idButtonType == buttonTypeCounseling.valueOf()" class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Aconselhamento</mat-label>
                            <mat-select formControlName="idCounseling">
                                <mat-option *ngFor="let item of data.listSelfTriageCounseling"
                                    [value]="item.idSelfTriageCounseling">
                                    {{item.selfTriageCounselingName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="footer-modal-list">
                <button mat-button class="btn-primary block" type="submit">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <a mat-button class="btn-secundary block" (click)="clickCancel()">Cancelar</a>
            </div>
        </form>
    </div>
</div>