import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProfileService } from 'src/app/shared/services/API/admin-user/profile.service';

@Component({
  selector: 'app-super-access-modal',
  templateUrl: './super-access-modal.component.html',
  styleUrls: ['./super-access-modal.component.css']
})
export class SuperAccessModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<SuperAccessModalComponent>) { }


  ngOnInit(): void {
  }
  
  close(){
    this.matDialogRef.close(); 
  }
}
