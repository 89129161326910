import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { TemplateMedicineSubgroupRequest } from '../../requests/pharmacy/template-medicine-subgroup.request';
import { ListTemplateSubgroupResponse } from '../../responses/pharmacy/list-template-subgroup.response';
import { TemplateGroupSelectResponse } from '../../responses/pharmacy/template-group-select.response';
import { TemplateMedicineSubgroupResponse } from '../../structs/pharmacy/template-medicine-subgroup.response';

@Injectable({
    providedIn: 'root'
})
export class TemplateGroupSelectService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }

    list(): Observable<TemplateGroupSelectResponse> {
        let uri = `TemplateGroupSelect?`
        return this.httpClient.get<TemplateGroupSelectResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}
