import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { VerifyHealthGuidelineCategorizationResponse } from '../../responses/orchestrator-classification/verify-health-guideline-categorization.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Injectable({
  providedIn: 'root'
})
export class CareLinePriorityHealthGuidelineService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public verifyCategorization(idCareLinePriority: number): Observable<VerifyHealthGuidelineCategorizationResponse> {
    return this.httpClient.get<VerifyHealthGuidelineCategorizationResponse>(environment.urlApiOrchestratorClassification + `CareLinePriorityHealthGuideline/idCareLinePriority/${idCareLinePriority}`, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public deleteCareLinePriority(idCareLinePriority: number, listIdHealthGuidelineCategorization: number[]): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiOrchestratorClassification + `CareLinePriorityHealthGuideline/idCareLinePriority/${idCareLinePriority}`, listIdHealthGuidelineCategorization, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}