import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { ListAdminUserResponse } from '../../responses/admin-user/list-admin-user.response';

@Injectable({
  providedIn: 'root'
})
export class ListUserService extends CommonService{
  
  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  
  public listAdminUser(): Observable<ListAdminUserResponse> {
    
    let uri = 'listUser'
    
    return this.httpClient.get<ListAdminUserResponse>(environment.urlApiAdminUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listAdminUserByIds(ids:number[]): Observable<ListAdminUserResponse> {
    
    let uri = `listUser/ByIds?ids=[${ids}]`;
    
    return this.httpClient.get<ListAdminUserResponse>(environment.urlApiAdminUser + uri, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
    