import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LogVisualizerService } from 'src/app/shared/services/API/srvlog/log-visualizer.service';
import { LogAccess } from 'src/app/shared/services/models/srvlog/LogAccess.model';
import { LogVisualizerConfirmationModalComponent } from './log-visualizer-confirmation-modal/log-visualizer-confirmation-modal.component';
import { ExternalLogVisualizerService } from 'src/app/shared/services/API/srvlog/external-log-visualizer.service';

@Component({
  selector: 'app-log-visualizer-view',
  templateUrl: './log-visualizer-view.component.html',
  styleUrls: ['./log-visualizer-view.component.css']
})
export class LogVisualizerViewComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private logVisualizerService: LogVisualizerService,
    private externalLogVisualizerService: ExternalLogVisualizerService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.log_list;

  public idLog: number;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public model: FormGroup;
  public sensitiveDataModel: FormGroup;
  public listLogAccess: LogAccess[];
  public isSensistiveDataRevealed: boolean;
  public externalLogs: boolean = false;
  public backUrl: string = "/developer/";
  public dateOptions = {
    dateStyle: 'full',
    timeStyle: 'medium',
  };

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      idLog: [''],
      idHealthUnit: [''],
      idUser: [''],
      type: [''],
      projectSource: [''],
      functionSource: [''],
      datetimeInclusion: [''],
    });

    this.sensitiveDataModel = this.formBuilder.group({
      inputParameter: [''],
      message: [''],
    });

    this.isSensistiveDataRevealed = false;

    if (this.activatedRoute.snapshot.paramMap.get('idLog')) {
      this.idLog = parseInt(this.activatedRoute.snapshot.paramMap.get('idLog'));
    }

    if (this.activatedRoute.snapshot.paramMap.get('externalLogs')) {
      this.externalLogs = true;
    }

    this.backUrl += this.externalLogs ? 'external-log-list' : 'log-list';

    this.populateLogData();
  }

  submit() {

  }

  viewSensitiveData() {
    this.logVisualizerService.getLogSensitiveData(this.idLog).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.sensitiveDataModel.get('inputParameter').setValue(response.logSensitiveData.inputParameter);
      this.sensitiveDataModel.get('message').setValue(response.logSensitiveData.message);

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openModal() {
    const dialogRef = this.dialog.open(LogVisualizerConfirmationModalComponent, {

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.viewData) {
        this.isSensistiveDataRevealed = true;
        this.viewSensitiveData();
      }
    });
  }

  populateLogData() {
    if (this.externalLogs) {
      this.getExternalLog();
    }
    else {
      this.getInternalLog();
    }
  }

  getInternalLog() {
    this.logVisualizerService.getLog(this.idLog).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.model.get('idLog').setValue(response.log.idLog);
      this.model.get('idHealthUnit').setValue(response.log.idHealthUnit);
      this.model.get('idUser').setValue(response.log.idUser);

      if (response.log.type == 'E')
        this.model.get('type').setValue("Error");

      if (response.log.type == 'W')
        this.model.get('type').setValue("Warning");

      if (response.log.type == 'S')
        this.model.get('type').setValue("Success");

      this.model.get('projectSource').setValue(response.log.projectSource);
      this.model.get('functionSource').setValue(response.log.functionSource);
      this.model.get('datetimeInclusion').setValue(response.log.datetimeInclusion);

      this.listLogAccess = response.log.listLogAccesses;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getExternalLog() {
    this.externalLogVisualizerService.getExternalLog(this.idLog).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.model.get('idLog').setValue(response.log.idLog);
      this.model.get('idHealthUnit').setValue(response.log.idHealthUnit);
      this.model.get('idUser').setValue(response.log.idUser);

      if (response.log.type == 'E')
        this.model.get('type').setValue("Error");

      if (response.log.type == 'W')
        this.model.get('type').setValue("Warning");

      if (response.log.type == 'S')
        this.model.get('type').setValue("Success");

      this.model.get('projectSource').setValue(response.log.projectSource);
      this.model.get('functionSource').setValue(response.log.functionSource);
      this.model.get('datetimeInclusion').setValue(new Date(response.log.datetimeInclusion).toLocaleString());

      this.listLogAccess = response.log.listLogAccesses;

      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
