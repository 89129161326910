import { FlowchartModel } from "../../models/risk-classification/flowchart.model";
import { AgeStruct } from "./age.struct";

export class SimpleFlowchartStruct extends FlowchartModel {

    public flowchartGroup: number[];

    public protocolName: string;

    public minAgeStruct:AgeStruct;

    public maxAgeStruct:AgeStruct;
}