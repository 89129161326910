import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { CsatConfigService } from 'src/app/shared/services/API/customer-success/csat-config.service';
import { CsatService } from 'src/app/shared/services/API/orchestrator-user/csat.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { ListAdminModuleService } from 'src/app/shared/services/API/user/list-admin-module.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { CsatResultRequest } from 'src/app/shared/services/requests/customer-success/csat-result.request';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { ModuleStruct } from 'src/app/shared/services/structs/admin-user/module.struct';
import { CsatResultStruct } from 'src/app/shared/services/structs/orchestrator-user/csat-result.struct';

@Component({
  selector: 'app-csat-result',
  templateUrl: './csat-result.component.html',
  styleUrls: ['./csat-result.component.css']
})
export class CsatResultComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private healthUnitService: HealthUnitService,
    private csatConfigService: CsatConfigService,
    private csatService: CsatService,
    private moduleService: ListAdminModuleService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.customer_success;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.customer_success_csat_result;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean = true;
  public isUpdate: boolean;

  public listHealthUnit: ListHealthUnitStruct[] = [];
  public listHealthUnitDisplay: ListHealthUnitStruct[] = [];
  public listIdAssignedHealthUnit: number[] = [];
  public allHealthUnits: boolean = true;

  public listModule: ModuleStruct[] = [];
  public listModuleDisplay: ModuleStruct[] = [];
  public listIdAssignedModule: number[] = [];
  public allModules: boolean = true;

  public csatResult: CsatResultStruct[] = [];
  public csatResultComment: CsatResultStruct[] = [];

  public satisfiedCount: number;
  public satisfiedRelative: number;

  public unsatisfiedCount: number;
  public unsatisfiedRelative: number;

  public totalAnswers: number;

  public averageScore: number;

  public showTotal: boolean = true;
  public csat: number = null;

  public readonly colorDict = Object.freeze({
    5: "#049E25",
    4: "#81C433",
    3: "#FFC200",
    2: "#FF6700",
    1: "#FF0000",
  });

  public colorScheme = {
    domain: ['#7FCCC7']
  };

  public colorSchemeBar = {
    domain: [this.colorDict[1], this.colorDict[2], this.colorDict[3], this.colorDict[4], this.colorDict[5]]
  };

  public view: any[] = [600, 400];

  public legend: boolean = true;

  public legendPosition: string = 'below';

  public results = new Array();
  public barResults = [
    {
      "name": "Muito insatisfeitos",
      "value": 0
    },
    {
      "name": "Insatisfeitos",
      "value": 0
    },
    {
      "name": "Neutros",
      "value": 0
    },
    {
      "name": "Satisfeitos",
      "value": 0
    },
    {
      "name": "Muito satisfeitos",
      "value": 0
    }
  ];

  public referenceLines = [{
    "name": "Média",
    "value": 0
  }];

  //Graph options
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = false;
  public legendTitle = "";
  public showXAxisLabel = false;
  public xAxisLabel = 'Categoria';
  public showYAxisLabel = true;
  public yAxisLabel = 'Número de votos';
  public yScaleMax = 0;
  public yScaleMin = 0;

  public colorCsat: string;

  public renderGraph: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      datetimeStart: [null],
      datetimeEnd: [null],
      listIdHealthUnit: [],
      listIdModule: [],
    });

    this.populateCsatConfig();
    this.populateModule();
    this.search();
  }

  search(datetimeStart: Date = null, datetimeEnd: Date = null, listIdHealthUnit: number[] = null, listIdModule: number[] = null) {
    this.isLoading = true;
    this.renderGraph = false;
    let request: CsatResultRequest = new CsatResultRequest(datetimeStart, datetimeEnd, listIdHealthUnit, listIdModule);

    this.csatService.getResult(request).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.renderGraph = true;
          return;
        }

        this.csatResult = response.listCsat;
        this.csatResultComment = this.csatResult.filter(x => x.voteDescription != null);

        this.satisfiedCount = response.satisfiedCount;
        this.unsatisfiedCount = response.unsatisfiedCount;
        this.totalAnswers = response.totalAnswers;

        this.unsatisfiedRelative = ((1 - response.satisfiedCount / response.totalAnswers) * 100);
        this.satisfiedRelative = ((response.satisfiedCount / response.totalAnswers) * 100);

        this.averageScore = response.averageScore;

        //calculo csat
        let occurrences = (response.scores[0]) + (response.scores[1] * 2) + (response.scores[2] * 3) + (response.scores[3] * 4) + (response.scores[4] * 5);
        let respondents = response.scores[0] + response.scores[1] + response.scores[2] + response.scores[3] + response.scores[4];
        if(respondents > 0)
          this.csat = Math.round((occurrences / respondents) * 5);

        if (this.csat <= 20) {
          this.colorCsat = '#FF0000';
        } else if (this.csat <= 40) {
          this.colorCsat = '#FF6700';
        } else if (this.csat <= 60) {
          this.colorCsat = '#FFC200';
        } else if (this.csat <= 80) {
          this.colorCsat = '#81C433';
        } else {
          this.colorCsat = '#049E25';
        }

        response.scores.forEach((categoryValue, index) => {
          this.barResults[index].value = categoryValue;
          if (this.barResults[index].value > this.yScaleMax) {
            this.yScaleMax = this.barResults[index].value;
          }
        });

        document.documentElement.style.setProperty('--graphWidth', `${(this.satisfiedCount / this.totalAnswers) * 100}%`);

        if (this.isFirstLoading) {
          this.model.get('datetimeStart').setValue(response.minDate);
          this.model.get('datetimeEnd').setValue(new Date());
        }

        this.renderGraph = true;

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.isFirstLoading = false;
        this.renderGraph = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  updateSelection() {
    let datetimeStart = this.model.get('datetimeStart').value;
    let datetimeEnd = this.model.get('datetimeEnd').value;
    let listIdHealthUnit = this.model.get('listIdHealthUnit').value;
    let listIdModule = this.model.get('listIdModule').value;
    this.search(datetimeStart, datetimeEnd, listIdHealthUnit, listIdModule);
  }

  updateDates() {
    let datetimeStart = this.model.get('datetimeStart').value;
    let datetimeEnd = this.model.get('datetimeEnd').value;

    if (!datetimeStart || !datetimeEnd) {
      return;
    }

    let listIdHealthUnit = this.model.get('listIdHealthUnit').value;
    let listIdModule = this.model.get('listIdModule').value;
    this.search(datetimeStart, datetimeEnd, listIdHealthUnit, listIdModule);
  }


  populateCsatConfig() {
    this.isLoading = true;
    this.csatConfigService.getConfigs().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if (response.listCsatConfig && response.listCsatConfig.length > 0) {
          response.listCsatConfig.forEach(element => {
            this.listIdAssignedHealthUnit = this.listIdAssignedHealthUnit.concat(element.listIdHealthUnit);
          });

        }

        this.model.get('listIdHealthUnit').setValue(this.listIdAssignedHealthUnit);
        this.populateHealthUnitSelect();
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateHealthUnitSelect() {
    this.healthUnitService.listHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        response.listHealthUnit.forEach((value, index) => {
          if (this.listIdAssignedHealthUnit.includes(value.idHealthUnit)) {
            this.listHealthUnit.push(value);
            this.listHealthUnitDisplay.push(value);
          }

          if (index == response.listHealthUnit.length) {

          }
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateModule() {
    this.isLoading = true;
    this.moduleService.listModule().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listModule = response.listModuleStruct;
        this.listModuleDisplay = this.listModule;

        this.model.get('listIdModule').setValue(this.listModule.map(x => x.idModule));
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  onKeySearchHealthUnit(event: any) {
    if (event != null) {
      this.listHealthUnitDisplay = this.listHealthUnit.filter(x => x.healthUnitName.toLocaleLowerCase().includes(event.toLocaleLowerCase()));
    }

    else {
      this.listHealthUnitDisplay = this.listHealthUnit;
    }
  }

  onKeySearchModule(event: any) {
    if (event != null) {
      this.listModuleDisplay = this.listModule.filter(x => x.nameModule.toLocaleLowerCase().includes(event.toLocaleLowerCase()));
    }

    else {
      this.listModuleDisplay = this.listModule;
    }
  }

  selectAllHealthUnits() {
    this.allHealthUnits = !this.allHealthUnits;
    if (this.allHealthUnits) {
      this.model.get('listIdHealthUnit').setValue(this.listHealthUnit.map(x => x.idHealthUnit));
    }
    else {
      this.model.get('listIdHealthUnit').setValue(null);
    }
    let datetimeStart = this.model.get('datetimeStart').value;
    let datetimeEnd = this.model.get('datetimeEnd').value;
    let listIdHealthUnit = this.model.get('listIdHealthUnit').value;
    let listIdModule = this.model.get('listIdModule').value;
    this.search(datetimeStart, datetimeEnd, listIdHealthUnit, listIdModule);
  }

  selectAllModules() {
    this.allModules = !this.allModules;
    if (this.allModules) {
      this.model.get('listIdModule').setValue(this.listModule.map(x => x.idModule));
    }
    else {
      this.model.get('listIdModule').setValue(null);
    }
    let datetimeStart = this.model.get('datetimeStart').value;
    let datetimeEnd = this.model.get('datetimeEnd').value;
    let listIdHealthUnit = this.model.get('listIdHealthUnit').value;
    let listIdModule = this.model.get('listIdModule').value;
    this.search(datetimeStart, datetimeEnd, listIdHealthUnit, listIdModule);
  }

  resetHealthUnits() {
    if (this.listHealthUnitDisplay == null || this.listHealthUnitDisplay.length == 0) {
      this.listHealthUnitDisplay = this.listHealthUnit;
    }
  }

  resetModules() {
    if (this.listModuleDisplay == null || this.listModuleDisplay.length == 0) {
      this.listModuleDisplay = this.listModule;
    }
  }

  changeGraphTotal() {
    this.showTotal = !this.showTotal
  }

  renderChartTotal() {

  }

}
