import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { GroupOriginService } from 'src/app/shared/services/API/medical-record/group-origin.service';
import { GroupOrigin } from 'src/app/shared/services/models/medical-record/group-origin.model';
import { OriginGroupDeleteModalComponent } from './origin-group-delete-modal/origin-group-delete-modal.component';

@Component({
  selector: 'app-origin-group-list',
  templateUrl: './origin-group-list.component.html',
  styleUrls: ['./origin-group-list.component.css']
})
export class OriginGroupListComponent implements OnInit {

  constructor(private alertService: AlertService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private groupOriginService: GroupOriginService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_origin_arrival_type;

  public isLoading: boolean = false;
  public searchText: string = "";
  public listGroupOrigin: GroupOrigin[] = [];
  public listGroupOriginFiltered: GroupOrigin[] = [];

  ngOnInit(): void {
    this.isLoading = true;

    this.populateCardList();
  }

  populateCardList() {
    this.groupOriginService.listGroupOrigin().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listGroupOrigin = response.listGroupOrigin;
        this.listGroupOriginFiltered = response.listGroupOrigin;
        this.isLoading = false;
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.filterList();
      }
    }, 1000);
  }

  filterList(){
    if (this.listGroupOrigin && this.listGroupOrigin.length > 0)
      this.listGroupOriginFiltered =  this.listGroupOrigin.filter(item => item.groupOriginName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()));
    else this.listGroupOriginFiltered = [];
  }

  openDeleteModal(idGroupOrigin: number) {
    const dialogRef = this.dialog.open(OriginGroupDeleteModalComponent, {
      data: {
        idGroupOrigin: idGroupOrigin
      },
    });
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.isDeleted)
          this.ngOnInit();
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  clickBack() {
    this.router.navigate(['/origin-arrival-type']);
  }
}